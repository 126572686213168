import styled, { css } from 'styled-components';

export const EquipmentEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.bodySecondary};
`;

export const EquipmentEditHead = styled.div`
  padding: 1.7rem 2rem 2.5rem;
`;

export const EquipmentEditContent = styled.div`
  overflow: auto;
  padding: 0 2rem 3rem;
  &::-webkit-scrollbar {
    width: 0rem;
    height: 0rem;
  }
`;

export const EquipmentEditBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  ${({ $mt }) =>
    $mt &&
    css`
      margin-top: ${$mt}rem;
    `}
`;

export const EquipmentEditActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;

export const EquipmentEditAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.4rem;
  height: 3.4rem;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  transition: 0.5s;
  cursor: pointer;
  .icon {
    stroke: ${({ theme }) => theme.palette.secondaryText};
    width: 2rem;
    height: 2rem;
  }
  &:hover {
    &.edit {
      background-color: ${({ theme }) => theme.palette.sider};
      .icon {
        stroke: ${({ theme }) => theme.palette.buttonText};
      }
    }
    &.delete {
      background-color: ${({ theme }) => theme.palette.actionDelete};
      .icon {
        stroke: ${({ theme }) => theme.palette.buttonText};
      }
    }
  }
`;
