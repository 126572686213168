import styled, { css } from 'styled-components';

import { USER_ROLES } from '@constants/common';

export const PageSider = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 36rem;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.bodySecondary};
  border-right: 1px solid ${({ theme }) => theme.palette.borderNeutral};
`;

export const PageTitle = styled.h2`
  color: ${({ theme }) => theme.palette.text};
  font-size: 2.2rem;
  line-height: 2.8rem;
  margin: 0;
  ${({ $large }) =>
    $large &&
    css`
      font-size: 2.4rem;
      line-height: 3.2rem;
    `}
`;

export const PageText = styled.p`
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.palette.text};
  margin: 0;
  margin-top: ${({ $mt }) => ($mt ? `${$mt}rem` : '0')};

  ${({ $error }) =>
    $error &&
    css`
      color: ${({ theme }) => theme.palette.inputError};
    `}

  ${({ $success }) =>
    $success &&
    css`
      color: ${({ theme }) => theme.palette.submit};
    `}

  ${({ $comment }) =>
    $comment &&
    css`
      max-width: 60rem;
      background-color: ${({ theme }) => theme.palette.card};
      padding: 1.5rem;
      color: ${({ theme }) => theme.palette.text};
      margin-bottom: 2rem;
    `}

    ${({ $small }) =>
    $small &&
    css`
      font-size: 1.4rem;
      line-height: 2rem;
    `}
`;

export const PageBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ $margin }) => ($margin ? `${$margin}rem` : '0rem')};
  gap: ${({ $gap }) => ($gap ? `${$gap}rem` : '0rem')};
  padding: ${({ $padding }) => $padding || '0rem'};
  ${({ $horizontal }) =>
    $horizontal &&
    css`
      margin-bottom: 0;
      margin-right: ${({ $margin }) => ($margin ? `${$margin}rem` : '0rem')};
    `}

  ${({ $block }) =>
    $block &&
    css`
      flex-direction: column;
      justify-content: stretch;
      align-items: stretch;
      margin-bottom: 0;
      margin-right: ${({ $margin }) => ($margin ? `${$margin}rem` : '0rem')};
    `}

  ${({ $center }) =>
    $center &&
    css`
      align-items: center;
    `}

  ${({ $relative }) =>
    $relative &&
    css`
      position: relative;
    `}
`;

export const PageContent = styled.div`
  overflow: hidden;
  padding: ${({ $padding }) => ($padding ? '1.6rem 2.5rem' : '0rem')};

  ${({ $settings }) =>
    $settings &&
    css`
      padding: 1.8rem 4rem 0;
    `}
`;

export const PageList = styled.ul`
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  ${({ $scroll }) =>
    $scroll &&
    css`
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0rem;
        height: 0rem;
      }
    `}

  ${({ $padding }) =>
    $padding &&
    css`
      padding: 1rem 2rem;
    `}
`;

export const PageListItem = styled.li`
  &.active {
    .request-card {
      background-color: ${({ theme }) => theme.palette.card};
      &-title {
        color: ${({ theme }) => theme.palette.cardButton};
      }
      &-subtitle {
        color: ${({ theme }) => theme.palette.cardText};
      }
      &-description {
        color: ${({ theme }) => theme.palette.cardText};
      }
      .icon.more-collapse {
        fill: ${({ theme }) => theme.palette.sider};
      }
    }
  }
`;

export const PageHead = styled.div`
  padding: 6rem 4rem 0.6rem;

  ${({ $row }) =>
    $row &&
    css`
      display: flex;
      align-items: center;
      gap: 1rem;
    `}
`;

export const PageSpace = styled.div`
  display: flex;
  gap: ${({ $gap }) => ($gap ? `${$gap}rem` : '0')};
`;

export const PageHeader = styled.div`
  padding: 1.3rem 3.7rem 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.borderNeutral};
`;

export const PageInner = styled.div`
  width: ${({ $width }) => ($width ? `${$width}rem` : '100%')};
  padding: ${({ $padding }) => $padding || '0'};
  .register-image {
    align-self: center;
  }
  ${({ $role }) =>
    $role &&
    css`
      background-color: ${({ theme }) =>
        $role === USER_ROLES.CLIENT ? theme.palette.loginClientBackground : theme.palette.sider};
    `}

  ${({ $column }) =>
    $column &&
    css`
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      gap: ${({ $gap }) => ($gap ? `${$gap}rem` : '0')};
    `}

  ${({ $loginPage }) =>
    $loginPage &&
    css`
      justify-content: flex-end;
      background-color: ${({ theme }) => theme.palette.siderSecondary};
      padding: 10rem 0rem 0rem 0rem;
      .login-image {
        width: 100%;
        object-fit: contain;
      }
    `}

  ${({ $centered }) =>
    $centered &&
    css`
      display: flex;
      flex-direction: column;
      ${PageBlock} {
        height: 100%;
        justify-content: center;
        margin-top: -3rem;
      }
    `}

  ${({ $confirmPage }) =>
    $confirmPage &&
    css`
      justify-content: flex-end;
      background-color: ${({ theme }) => theme.palette.loginClientBackground};
      padding: 10rem 0rem 0rem 0rem;
      .login-image {
        width: 100%;
        object-fit: contain;
      }
    `}
`;

export const PageElement = styled.div`
  ${({ $mb }) =>
    $mb &&
    css`
      margin-bottom: ${$mb}rem;
    `}

  ${({ $mt }) =>
    $mt &&
    css`
      margin-top: ${$mt}rem;
    `}

  ${({ $bordered }) =>
    $bordered &&
    css`
      border-top: 1px solid ${({ theme }) => theme.palette.borderNeutral};
      border-bottom: 1px solid ${({ theme }) => theme.palette.borderNeutral};
    `}
`;

export const PageContainer = styled.div`
  display: flex;
  height: 100%;
  ${({ $background }) =>
    $background &&
    css`
      background-color: ${({ theme }) => theme.palette.bodySecondary};
    `}

  ${({ $column }) =>
    $column &&
    css`
      flex-direction: column;
    `}

  ${({ $scroll }) =>
    $scroll &&
    css`
      min-height: 100%;
      height: auto;
      padding-bottom: 8rem;
    `}

  ${({ $noPadding }) =>
    $noPadding &&
    css`
      padding: 0;
    `}

  ${({ $admin }) =>
    $admin &&
    css`
      ${PageSiderHead} {
        ${PageBlock} {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    `}
`;

export const PageSiderHead = styled.div`
  padding: 1.7rem 2rem 1.1rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.borderNeutral};

  ${PageTitle} {
    max-width: 23rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.custom-padding {
    padding-top: 2rem;
  }

  ${({ $noBorder }) =>
    $noBorder &&
    css`
      border: none;
    `}

  ${({ $mb }) =>
    $mb &&
    css`
      margin-bottom: ${$mb}rem;
    `}
`;

export const PageSubtitle = styled.span`
  max-width: 100rem;
  display: inline-block;
  margin-top: 0.5rem;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.palette.tertiaryText};
  margin-bottom: ${({ $mb }) => ($mb ? `${$mb}rem` : '0')};

  span {
    color: ${({ theme }) => theme.palette.notification};
    font-size: 1.8rem;
  }

  ${({ $bold }) =>
    $bold &&
    css`
      font-weight: 700;
      color: ${({ theme }) => theme.palette.text};
    `}
`;
