import URI from 'urijs';

import { clientAPI } from '@api';

const _clientAPI = clientAPI.enhanceEndpoints({
  addTagTypes: ['EquipmentList', 'Equipment', 'EquipmentQuestionnaire', 'UserEquipment'],
});

const equipmentAPI = _clientAPI.injectEndpoints({
  endpoints: (build) => ({
    // список категорий оборудования
    getEquipmentCategoryList: build.query({
      query: () => {
        const URL = new URI('/Equipment/equipmentCategoryList');

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['EquipmentList'],
    }),
    // список категорий оборудования в виде дерева
    getEquipmentCategoryTreeList: build.query({
      query: () => {
        const URL = new URI('/Equipment/equipmentCategoryTreeList');

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['EquipmentList'],
    }),
    // добавить категорию оборудования
    addEquipmentCategory: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Equipment/equipmentCategoryAdd');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['EquipmentList'],
    }),
    // отредактировать категорию оборудования
    editEquipmentCategory: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Equipment/equipmentCategoryEdit');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['EquipmentList'],
    }),
    // удалить категорию оборудования
    deleteEquipmentCategory: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Equipment/equipmentCategoryRemove');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['EquipmentList'],
    }),
    // вернет категорию по ID
    getEquipmentCategory: build.query({
      query: (categoryId) => {
        const URL = new URI('/Equipment/equipmentCategoryGet');

        URL.addQuery({ ID: categoryId });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['EquipmentList'],
    }),
    // =================================================================
    // список оборудования пользователя
    getUserEquipmentList: build.query({
      query: (id) => {
        const URL = new URI('/Equipment/usersEquipmentList');

        if (id) {
          URL.addQuery({ ID_User: id });
        }

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['UserEquipment', 'Authenticate'],
    }),
    // список оборудования с изменениями
    getUserChangesEquipmentList: build.query({
      query: (id) => {
        const URL = new URI('/Users/requestsChangesEquipmentList');

        if (id) {
          URL.addQuery({ ID_User: id });
        }

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['EquipRequests'],
    }),
    // установить значение оборудования
    setUserEquipmentType: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Equipment/usersEquipmentSetJson');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['UserEquipment', 'UserParameters', 'ModerationCheck'],
    }),
    setUsersEquipmentSet: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Equipment/usersEquipmentSet');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['UserEquipment', 'UserParameters', 'ModerationCheck'],
    }),
    setModerationSend: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Equipment/moderationSend');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['UserEquipment', 'UserParameters', 'ModerationCheck'],
    }),
    // добавить файлы к оборудованию
    addFileToUserEquipment: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Equipment/usersEquipmentFileLoad');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['UserEquipment'],
    }),
    // список оборудования сервиса
    getEquipmentList: build.query({
      query: () => {
        const URL = new URI('/Equipment/equipmentList');

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['Equipment'],
    }),
    // список оборудования по категории
    getEquipmentListByCategory: build.query({
      query: (categoryId) => {
        const URL = new URI('/Equipment/equipmentListByCategory');

        URL.addQuery({ ID_Category: categoryId });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['Equipment'],
    }),
    // список оборудования с пометкой Available
    getAvailableEquipmentList: build.query({
      query: () => {
        const URL = new URI('/Equipment/equipmentListAvailable');

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['UserEquipment', 'Authenticate'],
    }),
    getEquipmentById: build.query({
      query: (equipId) => {
        const URL = new URI('/Equipment/equipmentGetById');

        URL.addQuery({ ID_Equipment: equipId });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
    }),
    // добавить оборудование
    addEquipment: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Equipment/equipmentAdd');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['Equipment'],
    }),
    // отредактировать оборудование
    editEquipment: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Equipment/equipmentEdit');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['Equipment'],
    }),
    // удалить оборудование
    deleteEquipment: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Equipment/equipmentRemove');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['Equipment'],
    }),
    // =================================================================
    // опросный лист оборудования
    getEquipmentQuestionnaire: build.query({
      query: (equipId) => {
        const URL = new URI('/Equipment/equipmentQuestionnaireList');

        URL.addQuery({ ID_Equipment: equipId });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['EquipmentQuestionnaire'],
    }),
    // добавить поле опросного листа
    addEquipmentQuestionnaireField: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Equipment/equipmentQuestionsAdd');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['EquipmentQuestionnaire'],
    }),
    // отредактировать поле опросного листа
    editEquipmentQuestionnaireField: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Equipment/equipmentQuestionsEdit');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['EquipmentQuestionnaire'],
    }),
    // удалить поле опросного листа
    deleteEquipmentQuestionnaireField: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Equipment/equipmentQuestionsRemove');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['EquipmentQuestionnaire'],
    }),
  }),
});

export const {
  useGetEquipmentCategoryListQuery,
  useGetEquipmentCategoryTreeListQuery,
  useAddEquipmentCategoryMutation,
  useEditEquipmentCategoryMutation,
  useDeleteEquipmentCategoryMutation,
  useGetEquipmentCategoryQuery,

  useGetUserEquipmentListQuery,
  useGetUserChangesEquipmentListQuery,
  useSetUserEquipmentTypeMutation,
  useAddFileToUserEquipmentMutation,
  useGetEquipmentListQuery,
  useGetEquipmentListByCategoryQuery,
  useGetAvailableEquipmentListQuery,
  useGetEquipmentByIdQuery,
  useAddEquipmentMutation,
  useEditEquipmentMutation,
  useDeleteEquipmentMutation,

  useGetEquipmentQuestionnaireQuery,
  useAddEquipmentQuestionnaireFieldMutation,
  useEditEquipmentQuestionnaireFieldMutation,
  useDeleteEquipmentQuestionnaireFieldMutation,
  useSetUsersEquipmentSetMutation,
  useSetModerationSendMutation,
} = equipmentAPI;
