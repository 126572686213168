import { Tabs } from 'antd';
import styled, { css } from 'styled-components';

export const TabsContainer = styled(Tabs)`
  .ant-tabs {
    &-nav {
      margin: 0;
      &-wrap {
        padding: 0 2.6rem;
      }
    }
    &-tab {
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 0.4px;
      &-btn {
        color: ${({ theme }) => theme.palette.text};

        span {
          position: absolute;
          top: 1.2rem;
          right: -1rem;
          display: inline-block;
          margin-left: 0.3rem;
          color: ${({ theme }) => theme.palette.notification};
          font-size: 1.8rem;
        }
      }
      &-active {
        .ant-tabs-tab-btn {
          color: ${({ theme }) => theme.palette.button};
        }
      }
      + .ant-tabs-tab {
        margin-left: 2.2rem;
      }
      &:hover {
        &:not(.ant-tabs-tab-active) {
          .ant-tabs-tab-btn {
            color: ${({ theme }) => theme.palette.text};
          }
        }
      }
    }
    &-ink-bar {
      background-color: ${({ theme }) => theme.palette.button};
    }
  }
  ${({ $settings }) =>
    $settings &&
    css`
      .ant-tabs {
        &-nav {
          &-wrap {
            padding-left: 4rem;
          }
        }
        &-tabpane {
          padding: 3rem 2.5rem 3rem 4rem;
        }
      }
    `}
`;

export const TabPaneContainer = styled(Tabs.TabPane)`
  padding: 3rem 2.5rem 3rem;
`;
