import { Modal } from 'antd';
import styled, { css } from 'styled-components';

import { Button } from '@atoms';

export const ModalContainer = styled(Modal)`
  .ant-modal {
    &-content {
      border-radius: 8px;
      overflow: hidden;
      border: 1px solid ${({ theme }) => theme?.palette?.borderNeutral};
    }
    &-body {
      padding: 0;
    }
    &-close {
      background-color: ${({ theme }) => theme?.palette?.body};
      border-radius: 50%;
      top: 0.8rem;
      right: 0.8rem;
      &-x {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 3rem;
      }
    }
  }
  .icon {
    &.modal-close {
      stroke: ${({ theme }) => theme?.palette?.sider};
      width: 1.1rem;
      height: 1.1rem;
    }
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.palette.drawerText};
`;

export const ModalHeader = styled.div`
  margin-bottom: ${({ $margin }) => ($margin ? `${$margin}rem` : '0')};
`;

export const ModalButton = styled(Button)`
  width: 100%;
  height: 5.5rem;
  border-radius: 0;
`;

export const ModalBody = styled.div`
  background-color: ${({ theme }) => theme.palette.bodySecondary};
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 3rem 5rem; */
`;

export const ModalContent = styled.div``;

export const ModalTitle = styled.h2`
  text-align: center;
  margin: 0rem 0rem 1.2rem 0rem;
  font-size: 2.2rem;
  line-height: 2.8rem;
  color: ${({ theme }) => theme.palette.text};
`;

export const ModalSubtitle = styled.p`
  text-align: center;
  max-width: 23.5rem;
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.palette.statusDraft};
`;

export const ModalText = styled.p`
  margin: 0;
  text-align: center;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.palette.text};

  &.error {
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 0;
    max-width: 29rem;
    text-align: left;
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.palette.inputError};
    transform: translateY(-5px);
    opacity: 0;
    visibility: hidden;
    transition: 0.2s;
    &.visible {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
  }

  ${({ $error }) =>
    $error &&
    css`
      color: ${({ theme }) => theme.palette.inputError};
    `}

  ${({ $mt }) =>
    $mt &&
    css`
      margin-top: ${$mt}rem;
    `}
`;

export const ModalObjectCreationContainer = styled(ModalContainer)`
  max-width: 50rem;
  .ant-form {
    width: 100%;
    &-item {
      margin-bottom: 2.8rem !important;
    }
  }
  ${ModalContent} {
    padding: 0 5rem 5.5rem;
  }
  ${ModalHeader} {
    padding: 3rem 4.5rem;
  }
  ${ModalFooter} {
    .ant-form-item {
      margin: 0 !important;
      width: 100%;
    }
  }
  &.add-category {
    ${ModalContent} {
      padding: 0 5rem 4.2rem;
    }
  }
  &.add-equip {
    ${ModalContent} {
      padding: 0 5rem 3.3rem;
      .ant-form-item {
        margin: 0 !important;
        &:not(:last-child) {
          margin-bottom: 2.8rem !important;
        }
        &.margin {
          margin-bottom: 2rem !important;
        }
      }
    }
  }
`;

export const ModalSendRequestsContainer = styled(ModalContainer)`
  max-width: 36rem;
  ${ModalBody} {
    padding: 3rem 5rem 4rem;
  }
  ${ModalHeader} {
    margin: 0rem 0rem 2.2rem 0rem;
  }
  ${ModalTitle} {
    margin: 0;
  }
`;

export const ModalAcceptResponseContainer = styled(ModalContainer)`
  max-width: 37rem;
  &.full-width-form {
    .ant-form {
      width: 100%;
    }
  }
  .ant-modal {
    &-content {
      border-radius: 5px;
    }
    &-close {
      background-color: ${({ theme }) => theme.palette.bodySecondary};
    }
  }
  .form-title {
    margin-bottom: 1rem;
  }
  textarea {
    font-size: 1.4rem;
    min-height: 10rem;
    border-color: ${({ theme }) => theme.palette.borderNeutral} !important;
    &:focus {
      box-shadow: none;
    }
    &::-webkit-scrollbar {
      width: 0.4rem;
      height: 0.4rem;
    }
    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.palette.borderNeutral};
      border-radius: 10px;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
    }
    &::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.palette.bodySecondary};
      border-radius: 10px;
    }
    & {
      scrollbar-face-color: ${({ theme }) => theme.palette.borderNeutral};
      scrollbar-track-color: ${({ theme }) => theme.palette.bodySecondary};
    }
  }
  ${ModalButton} {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  ${ModalText} {
    text-align: left;
    font-size: 1.4rem;
    line-height: 2rem;
  }
  ${ModalHeader} {
    padding: 1.2rem 2rem 1.3rem;
    background-color: ${({ theme }) => theme.palette.body};
    width: 100%;
  }
  ${ModalTitle} {
    margin: 0;
    text-align: left;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 600;
  }
  ${ModalContent} {
    padding: 2.2rem 2rem 6rem;
  }
  ${ModalFooter} {
    /* border-color: ; */
  }

  ${({ $secondary }) =>
    $secondary &&
    css`
      ${ModalContent} {
        padding: 2.2rem 2rem 2rem;
      }
    `}
`;

export const ModalRequestCreationContainer = styled(ModalContainer)`
  max-width: 84rem;
  width: 100% !important;
  .ant-modal {
    &-content {
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      min-height: 57.5rem;
    }
    &-body {
      display: flex;
      flex-direction: column;
      flex: 100%;
    }
    &-close {
      background-color: ${({ theme }) => theme.palette.bodySecondary};
    }
  }
  ${ModalBody} {
    flex: 100%;
  }
  ${ModalHeader} {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0rem 7rem;
    background-color: ${({ theme }) => theme.palette.body};
    width: 100%;
  }
  ${ModalTitle} {
    flex-shrink: 0;
    margin: 0rem 3.5rem 0rem 0rem;
    text-align: left;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 600;
  }
  ${ModalContent} {
    display: flex;
    flex-direction: column;
    flex: 100%;
    width: 100%;
    padding: 4rem 7rem 0;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0rem;
      height: 0rem;
    }
  }
  ${ModalFooter} {
    border-color: ${({ theme }) => theme.palette.body};
  }
`;

export const ModalAnswerRequestContainer = styled(ModalContainer)`
  max-width: 37rem;
  .ant-form {
    &-item {
      margin-bottom: 0 !important;
      &:not(:last-child) {
        margin-right: 1.2rem;
      }
    }
  }
  .form {
    &-text {
      margin-bottom: 2rem;
    }
    &-row {
      margin-bottom: 2rem;
    }
  }
  .ant-select:not(.ant-select-show-search) .ant-select-selector {
    height: 3.5rem !important;
  }
  .ant-input {
    padding: 0.7rem 1.5rem 0.6rem;
  }
  .ant-modal {
    &-content {
      border-radius: 5px;
    }
    &-close {
      background-color: ${({ theme }) => theme.palette.bodySecondary};
    }
  }
  .icon {
    &.upload-icon {
      margin-right: 1rem;
      width: 1.4rem;
      height: 1.6rem;
      stroke: ${({ theme }) => theme.palette.buttonText};
    }
  }
  .upload-button {
    padding: 0.85rem 1rem;
  }
  ${ModalButton} {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  ${ModalHeader} {
    padding: 1.2rem 2rem 1.3rem;
    background-color: ${({ theme }) => theme.palette.body};
    width: 100%;
  }
  ${ModalTitle} {
    margin: 0;
    text-align: left;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 600;
  }
  ${ModalContent} {
    padding: 2.9rem 3.9rem 4.4rem;
  }

  ${({ $secondary }) =>
    $secondary &&
    css`
      .ant-form {
        width: 100%;
      }
      .form-title {
        font-weight: 500;
        margin-bottom: 1.2rem;
      }
      .ant-input {
        padding-bottom: 2rem;
        border-color: ${({ theme }) => theme.palette.drawerText};
        box-shadow: none;
        height: 17rem;
        &::-webkit-scrollbar {
          width: 0.4rem;
        }
        &::-webkit-scrollbar-track {
          background: ${({ theme }) => theme.palette.bodySecondary};
          margin: 0.5rem 0;
        }
        &::-webkit-scrollbar-thumb {
          background: ${({ theme }) => theme.palette.body};
          border-radius: 10px;
        }
      }
      ${ModalContent} {
        padding: 2rem 2rem 4rem 4rem;
      }
    `}
`;
