import React, { memo } from 'react';

import { DropdownContainer, DropdownMenuContainer, DropdownMenuItemContainer } from './styles';

const Dropdown = memo(({ children, ...rest }) => {
  return <DropdownContainer {...rest}>{children}</DropdownContainer>;
});

const DropdownMenu = memo(({ ...rest }) => {
  return <DropdownMenuContainer {...rest} />;
});

const DropdownMenuItem = memo(({ children, ...rest }) => {
  return <DropdownMenuItemContainer {...rest}>{children}</DropdownMenuItemContainer>;
});

Dropdown.Menu = DropdownMenu;
Dropdown.Menu.Item = DropdownMenuItem;

export default Dropdown;
