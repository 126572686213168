import { Skeleton } from 'antd';
import styled, { css } from 'styled-components';

export const SkeletonContainer = styled(Skeleton)``;

export const SkeletonInput = styled(Skeleton.Input)``;

export const SkeletonButton = styled(Skeleton.Button)`
  .ant-skeleton-button {
    ${({ $width }) => css`
      min-width: ${$width}rem;
    `}
  }
`;
