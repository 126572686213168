import { Steps } from 'antd';
import styled from 'styled-components';

import stepsArrow from '@assets/images/steps-arrow.svg';

export const StepsContainer = styled(Steps)`
  .ant-steps-item {
    position: relative;
    padding: 1.1rem 3.5rem 1.3rem !important;
    overflow: unset !important;
    flex: unset !important;
    .steps-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.6rem;
      height: 2.6rem;
      font-size: 1.4rem;
      line-height: 2rem;
      border-radius: 50%;
      color: ${({ theme }) => theme.palette.buttonText};
      background-color: ${({ theme }) => theme.palette.drawerText};
      transition: 0.2s;
    }
    .ant-steps {
      &-item {
        &-title {
          padding: 0;
          font-size: 1.2rem;
          line-height: 1.6rem;
          color: ${({ theme }) => theme.palette.text};
          transition: 0.2s;
          &:after {
            display: none;
          }
        }
        &-icon {
          margin-right: 1rem;
        }
        &-container {
          display: flex;
          align-items: center;
        }
      }
    }
    &.ant-steps-item {
      &-finish,
      &-active {
        .ant-steps-item {
          &-title {
            color: ${({ theme }) => theme.palette.secondaryText};
          }
        }
        .steps-icon {
          color: ${({ theme }) => theme.palette.buttonText};
          background-color: ${({ theme }) => theme.palette.button};
        }
      }
    }
    &:not(:last-child) {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: -1.7rem;
        width: 2.9rem;
        height: 5rem;
        background-image: url(${stepsArrow});
        background-position: center;
        background-size: contain;
        z-index: 1;
      }
    }
  }
`;

export const StepsStep = styled(Steps.Step)`
  position: relative;
  padding: 1.1rem 3.5rem 1.3rem !important;
  overflow: unset !important;
  flex: unset !important;
  .steps-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.6rem;
    height: 2.6rem;
    font-size: 1.4rem;
    line-height: 2rem;
    border-radius: 50%;
    color: ${({ theme }) => theme.palette.buttonText};
    background-color: ${({ theme }) => theme.palette.drawerText};
    transition: 0.2s;
  }
  .ant-steps {
    &-item {
      &-title {
        padding: 0;
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: ${({ theme }) => theme.palette.text};
        transition: 0.2s;
        &:after {
          display: none;
        }
      }
      &-icon {
        margin-right: 1rem;
      }
      &-container {
        display: flex;
        align-items: center;
      }
    }
  }
  &.ant-steps-item {
    &-finish,
    &-active {
      .ant-steps-item {
        &-title {
          color: ${({ theme }) => theme.palette.secondaryText};
        }
      }
      .steps-icon {
        color: ${({ theme }) => theme.palette.buttonText};
        background-color: ${({ theme }) => theme.palette.button};
      }
    }
  }
  &:not(:last-child) {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: -1.7rem;
      width: 2.9rem;
      height: 5rem;
      background-image: url(${stepsArrow});
      background-position: center;
      background-size: contain;
      z-index: 1;
    }
  }
`;
