import { commonTheme as theme } from '@theme';

const breakpoint = {
  xl: `(max-width: ${theme.breakpoints.xl})`, // 1920px
  md: `(max-width: ${theme.breakpoints.md})`, // 1700px
  sm: `(max-width: ${theme.breakpoints.sm})`, // 1440px
  xs: `(max-width: ${theme.breakpoints.xs})`, // 1000px
  xxs: `(max-width: ${theme.breakpoints.xxs})`, // 991.9px
};

export default breakpoint;
