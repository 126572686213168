import URI from 'urijs';

import { clientAPI } from '@api';

const _clientAPI = clientAPI.enhanceEndpoints({
  addTagTypes: ['Chats', 'Messages'],
});

export const chatAPI = _clientAPI.injectEndpoints({
  endpoints: (build) => ({
    // статусы объектов
    addChat: build.mutation({
      query: (attributes) => {
        const URL = new URI('/api/Chat/chatAdd');
        URL.addQuery(attributes);

        return {
          url: URL.toString(),
          method: 'POST',
        };
      },
      invalidatesTags: ['Chats'],
    }),
    // список объектов пользователя
    getList: build.query({
      query: (attributes) => {
        const URL = new URI('/api/Chat/chatGetList');
        URL.addQuery(attributes);

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['Chats'],
    }),
    getChatUsersByChatId: build.query({
      query: ({ chatId }) => {
        const URL = new URI('/api/Chat/chatUserGetList');

        URL.addQuery({ ID_Chat: chatId });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['Chats'],
    }),
    getChatIdByRequestAnswer: build.query({
      query: ({ requestAnswerId }) => {
        const URL = new URI('/api/Chat/chatIdByRequestAnswer');

        URL.addQuery({ ID_Request: requestAnswerId });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['Chats'],
    }),
    getChatIdByRequest: build.query({
      query: (requestId) => {
        const URL = new URI('/api/Chat/chatIdByRequest');

        URL.addQuery({ ID_Request: requestId });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['Chats'],
    }),
    // создать объект
    addMessage: build.mutation({
      query: (attributes) => {
        const URL = new URI('/api/Chat/messageAdd');
        URL.addQuery(attributes);

        return {
          url: URL.toString(),
          method: 'POST',
        };
      },
      invalidatesTags: ['Messages'],
    }),
    confirmMessage: build.mutation({
      query: (messageId) => {
        const URL = new URI('/api/Chat/messageConfirmReceive');
        URL.addQuery({ id_message: messageId });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      invalidatesTags: ['Messages', 'Chats'],
    }),
    // обновить объект
    addFileToMessage: build.mutation({
      query: (attributes) => {
        const URL = new URI('/api/Chat/messageFileAdd');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['Messages'],
    }),
    getMessages: build.query({
      query: (attributes) => {
        const URL = new URI('/api/Chat/messageGetList');
        URL.addQuery(attributes);

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        // // create a websocket connection when the cache subscription starts
        // const ws = new WebSocket('ws://localhost:8080');
        // try {
        //   // wait for the initial query to resolve before proceeding
        //   await cacheDataLoaded;
        //
        //   // when data is received from the socket connection to the server,
        //   // if it is a message and for the appropriate channel,
        //   // update our query result with the received message
        //   const listener = (event) => {
        //     const data = JSON.parse(event.data);
        //     if (data.channel !== arg.ID_Chat) return;
        //
        //     updateCachedData((draft) => {
        //       draft.push(data);
        //     });
        //   };
        //
        //   ws.addEventListener('message', listener);
        // } catch {
        //   // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
        //   // in which case `cacheDataLoaded` will throw
        // }
        // // cacheEntryRemoved will resolve when the cache subscription is no longer active
        // await cacheEntryRemoved;
        // // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        // ws.close();
      },
      providesTags: ['Messages', 'Chats'],
    }),
  }),
});

export const {
  useAddChatMutation,
  useGetListQuery,
  useGetChatIdByRequestQuery,
  useAddMessageMutation,
  useConfirmMessageMutation,
  useAddFileToMessageMutation,
  useGetMessagesQuery,
  useGetChatUsersByChatIdQuery,
  useGetChatIdByRequestAnswerQuery,
} = chatAPI;
