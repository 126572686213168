import React, { memo } from 'react';

import { TooltipContainer, TooltipTitle } from './styles';

const Tooltip = memo(({ children, ...rest }) => {
  return <TooltipContainer {...rest}>{children}</TooltipContainer>;
});

Tooltip.Title = TooltipTitle;

export default Tooltip;
