import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  FILTERS_DROPDOWN_MENU,
  FILTERS,
  FILTERS_STATUS_MENU,
  FILTERS_PROVIDER_STATUS_MENU,
  FILTERS_TYPE_MENU,
  USER_ROLES,
} from '@constants/common';
import { DatePicker, Dropdown, Icon } from '@atoms';
import { useGetEquipmentListQuery } from '@api/equipment';
import { setFiltersVisibility } from '@reducers/application';

import {
  FilterAction,
  FilterButton,
  FilterContainer,
  FilterDropdownWrap,
  FilterOptionButton,
  FilterOptionContainer,
  FilterOptions,
  FilterText,
} from './styles';

const FilterOption = ({ title, removeOption }) => {
  return (
    <FilterOptionContainer>
      <FilterText>{title}</FilterText>
      <FilterOptionButton onClick={removeOption}>
        <Icon $transition name="close" className={cn('close')} />
      </FilterOptionButton>
    </FilterOptionContainer>
  );
};

const Filter = ({ filters, setFilters, navigateURL }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentUser } = useSelector(({ user }) => user);
  const { filtersVisibility } = useSelector(({ application }) => application);

  // filter options
  const [chosenDate, setChosenDate] = useState(null);
  const [filterOptions, setFilterOptions] = useState([]);
  const [statusFilterOptions, setStatusFilterOptions] = useState([]);
  const [dateFilterOptions, setDateFilterOptions] = useState([]);
  const [equipFilterOptions, setEquipFilterOptions] = useState([]);

  // dropdowns visibility
  const [mainFilterVisible, setMainFilterVisible] = useState(false);
  const [dateFilterVisible, setDateFilterVisible] = useState(false);
  const [statusFilterVisible, setStatusFilterVisible] = useState(false);
  const [typeFilterVisible, setTypeFilterVisible] = useState(false);

  const { data: equipmentList, isFetching: isFetchingEquipmentList } = useGetEquipmentListQuery();

  const equipmentItems = equipmentList?.map((item) => ({
    key: item.id,
    label: item.name,
  }));

  const equipDropdownMenuItems = !isFetchingEquipmentList ? [...FILTERS_TYPE_MENU, ...equipmentItems] : [];

  const isDropdownOpened = mainFilterVisible || dateFilterVisible || statusFilterVisible || typeFilterVisible;

  useEffect(() => {
    setFilters({
      date: dateFilterOptions,
      status: statusFilterOptions,
      equip: equipFilterOptions,
    });
  }, [dateFilterOptions, equipFilterOptions, setFilters, statusFilterOptions]);

  const closeInnerDropdowns = useCallback(() => {
    setStatusFilterVisible(false);
    setDateFilterVisible(false);
    setTypeFilterVisible(false);
    setChosenDate(null);
  }, []);

  const handleBackButtonClick = useCallback(() => {
    closeInnerDropdowns();
    setMainFilterVisible(true);
  }, [closeInnerDropdowns]);

  const handleFiltersClick = useCallback((data) => {
    switch (data.key) {
      case FILTERS.DATE.name:
        setMainFilterVisible(false);
        setDateFilterVisible(true);
        break;
      case FILTERS.STATUS.name:
        setMainFilterVisible(false);
        setStatusFilterVisible(true);
        break;
      case FILTERS.TYPE.name:
        setMainFilterVisible(false);
        setTypeFilterVisible(true);
        break;
      default:
        break;
    }
  }, []);

  const chooseDate = useCallback((date) => {
    setChosenDate(date);
  }, []);

  const addFilterOption = useCallback(
    (key, label) => {
      const keysArray = filterOptions.map((option) => option.key);

      if (!keysArray.includes(key)) {
        const filterOption = { key, label };

        setFilterOptions([...filterOptions, filterOption]);
        closeInnerDropdowns();
      }
    },
    [closeInnerDropdowns, filterOptions],
  );

  const handleMainFiltersButtonClick = useCallback(() => {
    dispatch(setFiltersVisibility(true));
    if (mainFilterVisible) {
      setMainFilterVisible(false);
    } else {
      closeInnerDropdowns();
      setMainFilterVisible(true);
    }
  }, [closeInnerDropdowns, dispatch, mainFilterVisible]);

  const handleSetEquipFilter = (item) => {
    if (item.key === 'back') {
      if (item.domEvent.target.id === 'back') {
        handleBackButtonClick();
      } else if (item.domEvent.target.id === 'close') {
        closeInnerDropdowns();
      }
    } else {
      addFilterOption(item.key, item.domEvent.target.textContent);
      setEquipFilterOptions([...equipFilterOptions, item.key]);
      navigate(navigateURL);
    }
  };

  const handleSetStatusFilter = (item) => {
    if (item.key === 'back') {
      if (item.domEvent.target.id === 'back') {
        handleBackButtonClick();
      } else if (item.domEvent.target.id === 'close') {
        closeInnerDropdowns();
      }
    } else {
      addFilterOption(item.key, item.domEvent.target.textContent);
      setStatusFilterOptions([...statusFilterOptions, item.key]);
      navigate(navigateURL);
    }
  };

  const handleSetDateFilter = useCallback(() => {
    const dateKey = chosenDate.format('l');
    const dateLabel = chosenDate.format('D MMMM, YYYY');

    if (chosenDate) {
      addFilterOption(dateKey, dateLabel);
      setDateFilterOptions([...dateFilterOptions, dateKey]);
      navigate(navigateURL);
    }
  }, [addFilterOption, chosenDate, dateFilterOptions, navigate, navigateURL]);

  const renderPanel = useCallback(
    (panel) => {
      return (
        <DatePicker.Panel>
          <DatePicker.Panel.Inner>
            <DatePicker.Panel.Wrap>
              <DatePicker.Panel.Back onClick={handleBackButtonClick}>
                <Icon name="arrow-1" className={cn('back')} />
                <DatePicker.Panel.Text>Дата создания</DatePicker.Panel.Text>
              </DatePicker.Panel.Back>
              <DatePicker.Panel.Back $padding onClick={closeInnerDropdowns}>
                <Icon name="close" className={cn('close')} />
              </DatePicker.Panel.Back>
            </DatePicker.Panel.Wrap>
            <DatePicker.Panel.Input value={chosenDate?.format('D MMMM, YYYY')} placeholder="Выберите дату" />
          </DatePicker.Panel.Inner>
          {panel}
          <DatePicker.Panel.Wrap>
            <DatePicker.Panel.Button
              $secondary
              $disabled={!chosenDate}
              className={cn('ant-picker-ok')}
              onClick={handleSetDateFilter}
            >
              Применить
            </DatePicker.Panel.Button>
            <DatePicker.Panel.Button onClick={handleBackButtonClick}>
              Отменить <Icon name="close" className={cn('close', 'footer')} />
            </DatePicker.Panel.Button>
          </DatePicker.Panel.Wrap>
        </DatePicker.Panel>
      );
    },
    [chosenDate, closeInnerDropdowns, handleBackButtonClick, handleSetDateFilter],
  );

  const removeOption = (id) => {
    const filteredDateOptions = [...filters.date].filter((item) => item !== id);
    const filteredStatusOptions = [...filters.status].filter((item) => item !== id);
    const filteredEquipOptions = [...filters.equip].filter((item) => item !== id);

    const filteredOptions = [...filterOptions].filter((item) => item.key !== id);

    setFilterOptions(filteredOptions);
    setEquipFilterOptions(filteredEquipOptions);
    setDateFilterOptions(filteredDateOptions);
    setStatusFilterOptions(filteredStatusOptions);
  };

  useEffect(() => {
    if (!filtersVisibility) {
      closeInnerDropdowns();
      setMainFilterVisible(false);
    }
  }, [closeInnerDropdowns, filtersVisibility]);

  const mainDropdownMenu = <Dropdown.Menu onClick={handleFiltersClick} items={FILTERS_DROPDOWN_MENU} />;

  const statusDropdownMenu = (
    <Dropdown.Menu
      $head
      onClick={handleSetStatusFilter}
      items={currentUser === USER_ROLES.PROVIDER ? FILTERS_PROVIDER_STATUS_MENU : FILTERS_STATUS_MENU}
    />
  );

  const typeDropdownMenu = <Dropdown.Menu $head onClick={handleSetEquipFilter} items={equipDropdownMenuItems} />;

  return (
    <FilterContainer>
      <FilterOptions>
        {filterOptions?.map((option) => (
          <FilterOption
            removeOption={() => removeOption(option.key)}
            key={option.key}
            title={option.label}
            id={option.key}
          />
        ))}
        <FilterAction>
          <Dropdown
            visible={mainFilterVisible}
            overlay={mainDropdownMenu}
            overlayClassName={cn('dropdown', 'filters-dropdown')}
          >
            <FilterButton onClick={handleMainFiltersButtonClick} className={cn(isDropdownOpened && 'dropdown-opened')}>
              <Icon $transition name="filter-icon" className={cn('filter')} />
              <FilterText>Добавить фильтр</FilterText>
            </FilterButton>
          </Dropdown>
          <DatePicker
            value={chosenDate}
            showToday={false}
            onChange={chooseDate}
            dropdownClassName={cn('filters-date-picker')}
            panelRender={renderPanel}
            open={dateFilterVisible}
            $filter
          />
          <Dropdown
            visible={statusFilterVisible}
            overlay={statusDropdownMenu}
            overlayClassName={cn('dropdown', 'inner-dropdown')}
          >
            <FilterDropdownWrap />
          </Dropdown>
          <Dropdown
            visible={typeFilterVisible}
            overlay={typeDropdownMenu}
            overlayClassName={cn('dropdown', 'inner-dropdown')}
          >
            <FilterDropdownWrap />
          </Dropdown>
        </FilterAction>
      </FilterOptions>
    </FilterContainer>
  );
};

export default Filter;
