import React from 'react';

import {
  PageListItem,
  PageBlock,
  PageContainer,
  PageContent,
  PageList,
  PageSider,
  PageSiderHead,
  PageText,
  PageTitle,
  PageHead,
  PageSpace,
  PageHeader,
  PageInner,
  PageElement,
  PageSubtitle,
} from './styles';

const Page = ({ children, ...rest }) => {
  return <PageContainer {...rest}>{children}</PageContainer>;
};

Page.Sider = PageSider;
Page.Sider.Head = PageSiderHead;
Page.Title = PageTitle;
Page.Text = PageText;
Page.Block = PageBlock;
Page.Content = PageContent;
Page.List = PageList;
Page.List.Item = PageListItem;
Page.Head = PageHead;
Page.Space = PageSpace;
Page.Header = PageHeader;
Page.Inner = PageInner;
Page.Element = PageElement;
Page.Subtitle = PageSubtitle;

export default Page;
