import styled from 'styled-components';

export const RequestCardBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RequestCardTitle = styled.h3`
  margin: 0rem 0rem 1rem 0rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  transition: 0.2s;
  color: ${({ theme }) => theme.palette.text};
`;

export const RequestCardSubtitle = styled.span`
  display: block;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  transition: 0.2s;
  color: ${({ theme }) => theme.palette.cardText};
`;

export const RequestCardDescription = styled.p`
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin: 0rem 0rem 1.2rem 0rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  transition: 0.2s;
  color: ${({ theme }) => theme.palette.tertiaryText};
`;

export const RequestCardDate = styled.span`
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.palette.cardTextSecondary};
`;

export const RequestCardContainer = styled.div`
  padding: 2.1rem 1.9rem 1.3rem 3rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.borderNeutral};
  transition: 0.2s;
  &:hover,
  &.selected {
    background-color: ${({ theme }) => theme.palette.card};
    .icon.more-collapse {
      fill: ${({ theme }) => theme.palette.sider};
    }
    ${RequestCardTitle} {
      color: ${({ theme }) => theme.palette.cardButton};
    }
    ${RequestCardDescription} {
      color: ${({ theme }) => theme.palette.cardText};
    }
    ${RequestCardSubtitle} {
      color: ${({ theme }) => theme.palette.cardText};
    }
  }
`;

export const SelectedRequestCardContainer = styled.div`
  padding: 2.1rem 1.9rem 1.3rem 3rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.borderNeutral};
  transition: 0.2s;
  background-color: ${({ theme }) => theme.palette.siderSecondaryHover};
  &:hover,
  &.selected {
    background-color: ${({ theme }) => theme.palette.siderSecondaryHover};
    .icon.more-collapse {
      fill: ${({ theme }) => theme.palette.sider};
    }
    ${RequestCardTitle} {
      color: ${({ theme }) => theme.palette.button};
    }
    ${RequestCardDescription} {
      color: ${({ theme }) => theme.palette.text};
    }
    ${RequestCardSubtitle} {
      color: ${({ theme }) => theme.palette.text};
    }
  }
`;
