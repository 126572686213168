import React, { memo } from 'react';

import {
  DatePickerContainer,
  DatePickerPanelBack,
  DatePickerPanelButton,
  DatePickerPanelContainer,
  DatePickerPanelInner,
  DatePickerPanelInput,
  DatePickerPanelText,
  DatePickerPanelWrap,
} from './styles';

const DatePicker = memo(({ ...rest }) => {
  return <DatePickerContainer {...rest} />;
});

const DatePickerPanel = memo(({ children, ...rest }) => {
  return <DatePickerPanelContainer {...rest}>{children}</DatePickerPanelContainer>;
});

DatePicker.Panel = DatePickerPanel;
DatePicker.Panel.Button = DatePickerPanelButton;
DatePicker.Panel.Back = DatePickerPanelBack;
DatePicker.Panel.Inner = DatePickerPanelInner;
DatePicker.Panel.Text = DatePickerPanelText;
DatePicker.Panel.Input = DatePickerPanelInput;
DatePicker.Panel.Wrap = DatePickerPanelWrap;

export default DatePicker;
