import React from 'react';

import { QuestionnaireHeatPoint, QuestionnaireChiller, QuestionnaireSupplyUnit } from '@molecules';
import { EQUIP_IDS } from '@constants/common';

const Questionnaire = ({ equipID, values, readOnly, form, onFinish, ...rest }) => {
  const formProps = form ? { form, onFinish, ...rest } : {};

  // heat point questionnaire
  if (equipID === EQUIP_IDS.HEAT_POINT) {
    if (readOnly) {
      return (
        <fieldset disabled="disabled">
          <QuestionnaireHeatPoint form={form} values={values} readOnly />
        </fieldset>
      );
    }
    return <QuestionnaireHeatPoint {...formProps} values={values} />;
  }

  // chiller questionnaire
  if (equipID === EQUIP_IDS.CHILLER) {
    if (readOnly) {
      return (
        <fieldset disabled="disabled">
          <QuestionnaireChiller form={form} values={values} readOnly />
        </fieldset>
      );
    }
    return <QuestionnaireChiller {...formProps} values={values} />;
  }

  // supply unit questionnaire
  if (equipID === EQUIP_IDS.SUPPLY_UNIT) {
    if (readOnly) {
      return (
        <fieldset disabled="disabled">
          <QuestionnaireSupplyUnit form={form} values={values} readOnly />
        </fieldset>
      );
    }
    return <QuestionnaireSupplyUnit {...formProps} values={values} />;
  }

  return <div>Опросный лист не найден</div>;
};

export default Questionnaire;
