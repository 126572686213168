import React, { Fragment, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useForm } from 'antd/lib/form/Form';
import { message } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import loading from '@helpers/loading';
import { Button, Form, Icon, Modal, Skeleton, Status, Tabs, Tooltip } from '@atoms';
import {
  PROVIDER_TABS,
  PROVIDER_REQUEST_ACTIONS,
  ACTION_TYPES,
  REQUEST_STATUSES,
  UNMODERATED_PROVIDER_REQUEST_ACTIONS,
} from '@constants/common';
import { ProviderInfo, Page } from '@organisms';
import { requestInfoForm } from '@constants/forms';
import { setAnswerRequestModalVisibility } from '@reducers/application';
import { Questionnaire } from '@molecules';
import { useGetQuestionnaireQuery } from '@api/clientRequests';
import {
  useArchiveProviderRequestMutation,
  useCancelProviderRequestMutation,
  useGetAnswerQuery,
  useGetAnswerFileQuery,
  useCancelProviderAnswerMutation,
} from '@api/providerRequests';
import transformToFormData from '@helpers/transformToFormData';
import getProviderRequestStatus from '@helpers/getProviderRequestInfo';
import useModerationStatus from '@hooks/useModerationStatus';
import { useGetEquipmentByIdQuery } from '@api/equipment';
import { ROUTES } from '@constants/routes';

import ChatCard from '../../molecules/ChatCard';
import { setCurrentChatId } from '../../../reducers/chat';

import {
  RequestInfoAction,
  RequestInfoActions,
  RequestInfoBlock,
  RequestInfoComment,
  RequestInfoContainer,
  RequestInfoContent,
  RequestInfoDate,
  RequestInfoHead,
  RequestInfoItem,
  RequestInfoText,
  RequestInfoTitle,
  RequestInfoWrap,
} from './styles';

const questionnaireFields = {};

const RequestInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { onModerationStatus } = useModerationStatus();

  const { currentRequestInfo } = useSelector(({ requests }) => requests);

  const [userInfoFormInstance] = useForm();
  const [questionnaireFormInstance] = useForm();

  const { data: questionnaireData, isFetching: isFetchingQuestionnaireData } = useGetQuestionnaireQuery({
    ID_Request: currentRequestInfo?.iD_Request,
    ID_Equipment: currentRequestInfo?.iD_Equipment,
  });
  const { data: requestAnswer, isFetching: isFetchingRequestAnswer } = useGetAnswerQuery(
    {
      ID_Request: currentRequestInfo?.iD_Request,
    },
    { skip: !currentRequestInfo?.iD_Request },
  );
  const { data: answerFiles, isFetching: isFetchingAnswerFiles } = useGetAnswerFileQuery(
    {
      ID_Requests_Answer: requestAnswer?.id,
    },
    { skip: !requestAnswer },
  );
  const { data: equipmentInfo, isSuccess: isSuccessEquipmentInfo } = useGetEquipmentByIdQuery(
    currentRequestInfo?.iD_Equipment,
  );

  const hasAnswer = requestAnswer?.sum && requestAnswer?.delivery_Week;
  const hasRequest = Object.keys(currentRequestInfo).length > 0;
  const canceledByProvider = currentRequestInfo?.iD_Status_Provider === -1;
  const requestActions = onModerationStatus ? UNMODERATED_PROVIDER_REQUEST_ACTIONS : PROVIDER_REQUEST_ACTIONS;

  const allowCancelAnswer =
    currentRequestInfo?.status !== REQUEST_STATUSES.ARCHIVE.name && currentRequestInfo?.iD_Status_Answer === 0;

  const [cancelProviderRequest, { isSuccess: isSuccessCanceledProviderRequest }] = useCancelProviderRequestMutation();
  const [archiveProviderRequest, { isSuccess: isSuccessArchivedProviderRequest }] = useArchiveProviderRequestMutation();
  const [cancelProviderAnswer, { isSuccess: isSuccessCancelProviderAnswer }] = useCancelProviderAnswerMutation();

  useEffect(() => {
    questionnaireData?.forEach((field) => {
      Object.assign(questionnaireFields, { [field.name]: field.value });
    });

    userInfoFormInstance.setFieldsValue({
      object_name: currentRequestInfo?.request_Name,
      object_code: currentRequestInfo?.object_Code,
      eq_category: equipmentInfo?.category_Name,
      eq_type: currentRequestInfo?.name_Equipment,
    });
    questionnaireFormInstance.setFieldsValue(questionnaireFields);
  }, [
    currentRequestInfo,
    equipmentInfo?.category_Name,
    questionnaireData,
    questionnaireFormInstance,
    userInfoFormInstance,
  ]);

  useEffect(() => {
    if (isSuccessCanceledProviderRequest) {
      message.success({
        content: 'Запрос отклонен',
        key: 'isSuccessCanceledProviderRequest-message-1',
      });
    }
    if (isSuccessArchivedProviderRequest) {
      message.success({
        content: 'Запрос отправлен в архив',
        key: 'isSuccessArchivedProviderRequest-message-1',
      });
    }
    if (isSuccessCancelProviderAnswer) {
      message.success({
        content: 'Ответ отозван',
        key: 'isSuccessCancelProviderAnswer-message-1',
      });
    }
  }, [isSuccessArchivedProviderRequest, isSuccessCancelProviderAnswer, isSuccessCanceledProviderRequest]);

  const acceptRequest = () => {
    dispatch(setAnswerRequestModalVisibility(true));
  };

  const cancelAnswer = () => {
    cancelProviderAnswer(transformToFormData({ ID: requestAnswer?.id }));
  };

  const cancelRequest = () => {
    cancelProviderRequest(transformToFormData({ ID_Request: currentRequestInfo?.iD_Request }));
    navigate(`${ROUTES.REQUESTS}/${id || ''}`);
  };

  const archiveRequest = () => {
    archiveProviderRequest(transformToFormData({ ID_Request: currentRequestInfo?.iD_Request }));
  };

  const handleActionClick = (actionType) => {
    switch (actionType) {
      case ACTION_TYPES.ARCHIVE:
        archiveRequest();
        break;
      case ACTION_TYPES.CANCEL:
        cancelRequest();
        break;
      default:
        break;
    }
  };

  const getClientAnswerStatus = () => {
    if (currentRequestInfo?.iD_Status_Request === -1) {
      return (
        <RequestInfoText $error>
          Клиент <span>отменил</span> свой запрос
        </RequestInfoText>
      );
    }

    if (currentRequestInfo?.iD_Status_Provider === 0 || currentRequestInfo?.iD_Status_Provider === -2) {
      return <RequestInfoText $error>На данный запрос вы еще не отправляли свое предложение</RequestInfoText>;
    }

    if (canceledByProvider) {
      return (
        <RequestInfoText $error>
          Вы <span>отклонили</span> заявку
        </RequestInfoText>
      );
    }

    if (currentRequestInfo?.iD_Status_Answer === -3 || currentRequestInfo?.iD_Status_Client === -1) {
      return (
        <RequestInfoText $error>
          Клиент <span>отклонил</span> ваше предложение
        </RequestInfoText>
      );
    }

    if (currentRequestInfo?.iD_Status_Answer === 1) {
      return (
        <RequestInfoText $success>
          Клиент принял решение <span>в Вашу пользу</span>
        </RequestInfoText>
      );
    }

    if (currentRequestInfo?.iD_Status_Answer === -1) {
      return <RequestInfoText $error>Клиент выбрал другое предложение</RequestInfoText>;
    }

    return (
      <RequestInfoText $error>
        Решение Клиентом <span>еще не принято</span>
      </RequestInfoText>
    );
  };
  const { hash, pathname } = useLocation();
  const clearHash = useCallback(() => {
    setTimeout(() => navigate(pathname), 1);
  }, [pathname, navigate]);

  const clearChatId = useCallback(() => {
    dispatch(setCurrentChatId(undefined));
  }, [dispatch]);

  const { chatsR } = useSelector(({ chat }) => chat);
  return (
    <RequestInfoContainer>
      <RequestInfoHead>
        <RequestInfoBlock $margin="2.2">
          <RequestInfoDate>
            {loading(
              !hasRequest,
              dayjs(currentRequestInfo?.date_Add).format('D MMMM YYYY, HH:mm'),
              <Skeleton.Button size="small" active />,
            )}
          </RequestInfoDate>
        </RequestInfoBlock>
        <RequestInfoBlock $margin="0.2">
          <RequestInfoTitle>
            {loading(
              !hasRequest,
              currentRequestInfo?.name_Equipment,
              <Skeleton.Button $width="15" size="small" active />,
            )}{' '}
            -{' '}
            {loading(!hasRequest, currentRequestInfo?.object_Code, <Skeleton.Button $width="15" size="small" active />)}
            {loading(
              !hasRequest,
              <Status status={getProviderRequestStatus(currentRequestInfo)} />,
              <Skeleton.Button $width="7" size="small" active />,
            )}
          </RequestInfoTitle>
          <RequestInfoActions>
            {hasRequest &&
              requestActions[getProviderRequestStatus(currentRequestInfo)]?.map(({ icon, title, key, placement }) => (
                <Tooltip
                  key={key}
                  overlayClassName={cn('small-tooltip', key)}
                  title={title}
                  placement={placement || 'bottom'}
                  arrowPointAtCenter
                >
                  <RequestInfoAction className={cn(key)} onClick={() => handleActionClick(key)}>
                    <Icon $transition name={icon} className={cn(key)} />
                  </RequestInfoAction>
                </Tooltip>
              ))}
          </RequestInfoActions>
        </RequestInfoBlock>
      </RequestInfoHead>
      <RequestInfoContent>
        <Tabs
          defaultActiveKey={hash === '#chat' ? PROVIDER_TABS.CHAT.name : PROVIDER_TABS.INFO.name}
          activeKey={hash === '#chat' ? PROVIDER_TABS.CHAT.name : undefined}
          onChange={(key) => {
            if (key !== 'chat') clearChatId();
          }}
        >
          <Tabs.TabPane tab={PROVIDER_TABS.INFO.title} key={PROVIDER_TABS.INFO.name}>
            {loading(
              !isSuccessEquipmentInfo,
              <Form readOnly form={userInfoFormInstance} $grid $gap="3.2" className={cn('request-info-form')}>
                <fieldset disabled="disabled">
                  {requestInfoForm.map((item) => (
                    <Form.Item key={item.props.name} {...item.props}>
                      {item.element}
                    </Form.Item>
                  ))}
                </fieldset>
              </Form>,
            )}
          </Tabs.TabPane>

          <Tabs.TabPane tab={PROVIDER_TABS.FORMS.title} key={PROVIDER_TABS.FORMS.name}>
            {loading(
              isFetchingQuestionnaireData,
              <Questionnaire
                values={questionnaireData}
                form={questionnaireFormInstance}
                readOnly
                equipID={currentRequestInfo?.iD_Equipment}
              />,
            )}
          </Tabs.TabPane>

          <Tabs.TabPane tab={PROVIDER_TABS.RESPONSES.title} key={PROVIDER_TABS.RESPONSES.name}>
            {hasAnswer ? (
              loading(
                isFetchingRequestAnswer,
                <Fragment>
                  <ProviderInfo provider status={requestAnswer} files={answerFiles} />
                  {allowCancelAnswer && (
                    <Page.Element $mt="2">
                      <Button onClick={cancelAnswer}>Отозвать ответ</Button>
                    </Page.Element>
                  )}
                </Fragment>,
              )
            ) : (
              <RequestInfoWrap>
                <RequestInfoText>
                  {canceledByProvider ? (
                    <RequestInfoText $error>
                      Вы <span>отклонили</span> заявку
                    </RequestInfoText>
                  ) : (
                    'На данный запрос вы еще не отправляли свое предложение'
                  )}
                </RequestInfoText>
                {!currentRequestInfo?.status && (
                  <RequestInfoActions $mt="3">
                    <Button onClick={acceptRequest}>Ответить на заявку</Button>
                    <Button $neutral onClick={cancelRequest}>
                      Отклонить заявку
                    </Button>
                  </RequestInfoActions>
                )}
              </RequestInfoWrap>
            )}
          </Tabs.TabPane>

          <Tabs.TabPane tab={PROVIDER_TABS.STATUS.title} key={PROVIDER_TABS.STATUS.name}>
            {loading(isFetchingRequestAnswer, <Fragment>{getClientAnswerStatus()}</Fragment>)}
            {currentRequestInfo?.client_Comment && (
              <RequestInfoItem $mt="3">
                <RequestInfoText>Комментарий клиента:</RequestInfoText>
                <RequestInfoComment>{currentRequestInfo?.client_Comment}</RequestInfoComment>
              </RequestInfoItem>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={`${PROVIDER_TABS.CHAT.title} (${
              chatsR[currentRequestInfo?.iD_Requests_Answer]?.unread_Message_Count || 0
            })`}
            key={PROVIDER_TABS.CHAT.name}
            style={{ height: '85vh' }}
          >
            <ChatCard requestAnswerId={currentRequestInfo?.iD_Requests_Answer} />
            {hash && clearHash() && ''}
          </Tabs.TabPane>
        </Tabs>
      </RequestInfoContent>
      <Modal.AnswerRequest />
    </RequestInfoContainer>
  );
};

export default RequestInfo;
