import styled from 'styled-components';

export const MoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  &:hover {
    &:not(.collapse-button) {
      .icon {
        &:not(.cancel) {
          stroke: ${({ theme }) => theme.palette.button};
        }
      }
    }
  }
`;

export const MoreActions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 3rem;
  display: flex;
  gap: 1rem;
  transform: translateX(0.8rem);
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
`;

export const MoreContainer = styled.div`
  position: relative;
  z-index: 100;
  cursor: pointer;
  &.collapsed {
    .icon {
      &.more-collapse {
        fill: ${({ theme }) => theme.palette.button};
        transform: rotate(90deg);
      }
    }
    ${MoreActions} {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }
  }
  .icon {
    width: 2rem;
    height: 2rem;
    stroke: ${({ theme }) => theme.palette.secondaryText};
    &.archive {
      width: 1.8rem;
      height: 1.4rem;
    }
    &.cancel {
      width: 1.7rem;
      height: 1.7rem;
      stroke: ${({ theme }) => theme.palette.notification};
    }
    &.more-collapse {
      fill: ${({ theme }) => theme.palette.drawerText};
      stroke: none;
    }
  }
  &.hideCancelButton {
    .cancel {
      display: none;
    }
  }
  .editSent {
    cursor: not-allowed;
  }
`;
