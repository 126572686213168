import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoadingApp: true,
  hasNotification: false,
  moderated: true,
  objectCreationModalVisibility: false,
  sendRequestsModalVisibility: false,
  requestCreationModalVisibility: false,
  acceptResponseModalVisibility: false,
  cancelResponseModalVisibility: false,
  answerRequestModalVisibility: false,
  addCategoryModalVisibility: false,
  addEquipmentModalVisibility: false,
  addFieldModalVisibility: false,
  sendMessageModalVisibility: false,
  innModalVisibility: false,
  filtersVisibility: true,
};

export const application = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setObjectCreationModalVisibility: (state, { payload }) => {
      state.objectCreationModalVisibility = payload;
    },
    setSendRequestsModalVisibility: (state, { payload }) => {
      state.sendRequestsModalVisibility = payload;
    },
    setRequestCreationModalVisibility: (state, { payload }) => {
      state.requestCreationModalVisibility = payload;
    },
    setAcceptResponseModalVisibility: (state, { payload }) => {
      state.acceptResponseModalVisibility = payload;
    },
    setCancelResponseModalVisibility: (state, { payload }) => {
      state.cancelResponseModalVisibility = payload;
    },
    setAnswerRequestModalVisibility: (state, { payload }) => {
      state.answerRequestModalVisibility = payload;
    },
    setAddCategoryModalVisibility: (state, { payload }) => {
      state.addCategoryModalVisibility = payload;
    },
    setAddEquipmentModalVisibility: (state, { payload }) => {
      state.addEquipmentModalVisibility = payload;
    },
    setAddFieldModalVisibility: (state, { payload }) => {
      state.addFieldModalVisibility = payload;
    },
    setSendMessageModalVisibility: (state, { payload }) => {
      state.sendMessageModalVisibility = payload;
    },
    setModerated: (state, { payload }) => {
      state.moderated = payload;
    },
    setHasNotification: (state, { payload }) => {
      state.hasNotification = payload;
    },
    setIsLoadingApp: (state, { payload }) => {
      state.isLoadingApp = payload;
    },
    setFiltersVisibility: (state, { payload }) => {
      state.filtersVisibility = payload;
    },
    setInnModalVisibility: (state, { payload }) => {
      state.innModalVisibility = payload;
    },
  },
});

export const {
  setObjectCreationModalVisibility,
  setSendRequestsModalVisibility,
  setRequestCreationModalVisibility,
  setAcceptResponseModalVisibility,
  setAnswerRequestModalVisibility,
  setAddCategoryModalVisibility,
  setAddEquipmentModalVisibility,
  setAddFieldModalVisibility,
  setSendMessageModalVisibility,
  setModerated,
  setHasNotification,
  setIsLoadingApp,
  setFiltersVisibility,
  setCancelResponseModalVisibility,
  setInnModalVisibility,
} = application.actions;

export default application.reducer;
