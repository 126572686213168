import { Select } from 'antd';
import styled, { css } from 'styled-components';

export const SelectContainer = styled(Select)`
  :not(.ant-select-show-search) {
    .ant-select {
      &-arrow {
        width: 1rem;
        height: 1rem;
        margin-top: -0.5rem;
      }
      &-selector {
        border: none !important;
        height: 1.9rem !important;
        align-items: center;
        padding: 0 1rem !important;
        border-radius: 4px !important;
      }
      &-selection-item {
        color: ${({ theme }) => theme.palette.secondaryText};
        font-size: 1.1rem;
        line-height: 1.5rem !important;
        padding-right: 1.5rem !important;
        .icon {
          &.checked {
            display: none;
          }
          &.select-list-style {
            fill: ${({ theme }) => theme.palette.statusSent};
            width: 0.6rem;
            height: 0.6rem;
            margin-right: 1rem;
          }
        }
      }
    }
  }

  .icon {
    &.select-arrow {
      fill: ${({ theme }) => theme.palette.secondaryText};
      width: 0.8rem;
      height: 0.5rem;
    }
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
  }
  &.ant-select-open {
    .ant-select {
      &-selection-item {
        color: ${({ theme }) => theme.palette.text};
      }
      &-arrow {
        transform: rotateX(180deg);
        fill: ${({ theme }) => theme.palette.text};
        margin-top: -0.4rem;
      }
      &-selector {
        background-color: ${({ theme }) => theme.palette.body};
      }
    }
  }
  &:hover {
    .ant-select {
      &-selector {
        background-color: ${({ theme }) => theme.palette.body};
      }
      &-selection-item {
        color: ${({ theme }) => theme.palette.text};
      }
      &-arrow {
        fill: ${({ theme }) => theme.palette.text};
      }
    }
  }
  &.sent {
    .ant-select-selection-item {
      color: ${({ theme }) => theme.palette.statusSent};
    }
  }
  &.draft {
    .ant-select-selection-item {
      color: ${({ theme }) => theme.palette.secondaryText};
    }
  }
  &.canceled {
    .ant-select-selection-item {
      color: ${({ theme }) => theme.palette.statusCanceled};
    }
  }
  &.archive {
    .ant-select-selection-item {
      color: ${({ theme }) => theme.palette.statusArchive};
    }
  }
  &.hasProvider {
    .ant-select-selection-item {
      color: ${({ theme }) => theme.palette.providerChosen};
    }
  }
  ${({ $common }) =>
    $common &&
    css`
      position: relative;
      &.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
        .ant-select-selector {
        border-color: ${({ theme }) => theme.palette.inputError} !important;
      }
      &::before {
        content: '${({ placeholder }) => placeholder}';
        position: absolute;
        top: -1.4rem;
        left: 1rem;
        padding: 0.3rem 0.5rem;
        background-color: ${({ theme }) => theme.palette.bodySecondary};
        color: ${({ theme }) => theme.palette.inputLabel};
        font-size: 1.1rem;
        line-height: 1.5rem;
        z-index: 1;
        pointer-events: none;
        transform: translateY(0.2rem);
        opacity: 0;
        visibility: hidden;
        transition: 0.2s;
      }
      :not(.ant-select-show-search) {
        .icon {
          &.select-arrow {
            width: 1.2rem;
            height: 1.2rem;
          }
        }
        .ant-select {
          &-arrow {
            width: 1.2rem;
            height: 1.2rem;
            right: 1.7rem;
            margin-top: -0.5rem;
            svg {
              fill: ${({ theme }) => theme.palette.text};
            }
          }
          &-selector {
            border: 1px solid ${({ theme }) => theme.palette.borderNeutral} !important;
            height: 4rem !important;
            align-items: center;
            padding: 0 1.5rem !important;
            border-radius: 2px !important;
          }
          &-selection {
            &-placeholder {
              font-size: 1.4rem;
              line-height: 2rem;
              color: ${({ theme }) => theme.palette.secondaryText};
            }
            &-item {
              color: ${({ theme }) => theme.palette.text};
              font-size: 1.4rem;
              line-height: 2rem !important;
              padding-right: 1.5rem !important;
              .icon {
                &.checked {
                  display: none;
                }
              }
            }
          }
        }
        &:hover {
          .ant-select {
            &-selector {
              background-color: transparent;
            }
            &-selection-item {
              color: ${({ theme }) => theme.palette.secondaryText};
            }
          }
        }
        &.ant-select-open {
          &:before {
            transform: translateY(0rem);
            opacity: 1;
            visibility: visible;
          }
          .ant-select {
            &-selection {
              &-placeholder {
                color: ${({ theme }) => theme.palette.inputPlaceholder};
              }
            }
            &-arrow {
              transform: rotateX(180deg);
              margin-top: -0.6rem;
              svg {
                fill: ${({ theme }) => theme.palette.statusSent};
              }
            }
            &-selector {
              border-color: ${({ theme }) => theme.palette.statusSent} !important;
              background-color: transparent !important;
            }
          }
        }
        &.ant-select-status {
          &-error {
            &:before {
              color: ${({ theme }) => theme.palette.inputError};
            }
            .ant-select {
              &-arrow {
                svg {
                  fill: ${({ theme }) => theme.palette.text};
                }
              }
              &-selector {
                background-color: transparent !important;
              }
              &-selection-item {
                color: ${({ theme }) => theme.palette.text};
              }
            }
          }
          &-success {
            &:before {
              color: ${({ theme }) => theme.palette.cardText};
              transform: translateY(0rem);
              opacity: 1;
              visibility: visible;
            }
            .ant-select {
              &-arrow {
                svg {
                  fill: ${({ theme }) => theme.palette.text};
                }
              }
              &-selector {
                border-color: ${({ theme }) => theme.palette.borderHighlighted} !important;
                background-color: transparent;
              }
              &-selection-item {
                color: ${({ theme }) => theme.palette.text};
              }
            }
          }
        }
      }
    `}

  ${({ $secondary }) =>
    $secondary &&
    css`
      max-width: 25rem;
      &.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
        .ant-select-selector {
        border-color: ${({ theme }) => theme.palette.inputError} !important;
      }
      :not(.ant-select-show-search) {
        .icon {
          &.select-arrow {
            width: 1.2rem;
            height: 1.2rem;
          }
        }
        .ant-select {
          &-arrow {
            width: 1.2rem;
            height: 1.2rem;
            right: 1.7rem;
            margin-top: -0.5rem;
            svg {
              fill: ${({ theme }) => theme.palette.text};
            }
          }
          &-selector {
            border: 1px solid ${({ theme }) => theme.palette.borderNeutral} !important;
            height: 4rem !important;
            align-items: center;
            padding: 0 1.5rem !important;
            border-radius: 2px !important;
          }
          &-selection {
            &-placeholder {
              font-size: 1.4rem;
              line-height: 2rem;
              color: ${({ theme }) => theme.palette.secondaryText};
            }
            &-item {
              color: ${({ theme }) => theme.palette.text};
              font-size: 1.4rem;
              line-height: 2rem !important;
              padding-right: 1.5rem !important;
              .icon {
                &.checked {
                  display: none;
                }
              }
            }
          }
        }
        &:hover {
          .ant-select {
            &-selector {
              background-color: transparent;
            }
            &-selection-item {
              color: ${({ theme }) => theme.palette.secondaryText};
            }
          }
        }
        &.ant-select-open {
          &.ant-select-status-success {
            .ant-select {
              &-selector {
                border-color: ${({ theme }) => theme.palette.statusSent} !important;
              }
              &-arrow {
                svg {
                  fill: ${({ theme }) => theme.palette.statusSent};
                }
              }
            }
          }
          .ant-select {
            &-selection {
              &-placeholder {
                color: ${({ theme }) => theme.palette.inputPlaceholder};
              }
            }
            &-arrow {
              transform: rotateX(180deg);
              margin-top: -0.6rem;
              svg {
                fill: ${({ theme }) => theme.palette.statusSent};
              }
            }
            &-selector {
              border-color: ${({ theme }) => theme.palette.statusSent} !important;
              background-color: transparent !important;
            }
          }
        }
        &.ant-select-status {
          &-error {
            .ant-select {
              &-arrow {
                svg {
                  fill: ${({ theme }) => theme.palette.text};
                }
              }
              &-selector {
                background-color: transparent !important;
              }
              &-selection-item {
                color: ${({ theme }) => theme.palette.text};
              }
            }
          }
          &-success {
            .ant-select {
              &-arrow {
                svg {
                  fill: ${({ theme }) => theme.palette.text};
                }
              }
              &-selector {
                border-color: ${({ theme }) => theme.palette.borderHighlighted} !important;
                background-color: transparent;
              }
              &-selection-item {
                color: ${({ theme }) => theme.palette.text};
              }
            }
          }
        }
      }
    `}

  ${({ $noLabel }) =>
    $noLabel &&
    css`
      &:before {
        display: none;
      }
    `}

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width}rem !important;
    `}
`;

export const SelectOption = styled(Select.Option)``;

export const SelectOptGroup = styled(Select.OptGroup)``;
