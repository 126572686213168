import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  lastMessage: undefined,
  needRefresh: false,
  currentChatId: undefined,
  adminUnreadCount: 0,
  chats: {},
  chatsR: {},
  showPopupChatsInfo: false,
  adminChatRefresh: 0,
};

export const chat = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setShowPopupChatsInfo: (state, { payload }) => {
      state.showPopupChatsInfo = payload;
    },
    setAdminUnreadCount: (state, { payload }) => {
      state.adminUnreadCount = payload;
    },
    setCurrentChatId: (state, { payload }) => {
      state.currentChatId = payload;
    },
    setLastMessage: (state, { payload }) => {
      // unread_Message_Count
      /*
      "iD_Type_Chat",
    "iD_Object",
    "iD_Request",
    "iD_Requests_Answer"
       */
      state.lastMessage = payload;
    },
    setNeedRefresh: (state, { payload }) => {
      state.needRefresh = payload;
    },
    setAdminChatRefresh: (state) => {
      state.adminChatRefresh += 1;
    },
    setChats: (state, { payload }) => {
      state.chats = payload;
    },
    setChatsR: (state, { payload }) => {
      state.chatsR = payload;
    },
  },
});

export const updateChats = (payload) => async (dispatch) => {
  const authorizationToken = localStorage.getItem('token');
  axios
    .get(`https://teststim.biz-office.ru/api/Chat/chatGetList?ID_Type=${payload}&Take=0&Count=1000`, {
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
      },
    })
    .then(({ data }) => {
      const totalCount = data?.reduce((a, v) => a + v.unread_Message_Count, 0);
      const newChats = {};
      const newChatsR = {};
      if (+payload === 1) dispatch(chat.actions.setAdminUnreadCount(totalCount));
      else if (data) {
        data?.forEach((d) => {
          newChats[d.id] = d;
        });
        data?.forEach((d) => {
          newChatsR[d.iD_Requests_Answer] = d;
        });
        dispatch(chat.actions.setChats(newChats));
        dispatch(chat.actions.setChatsR(newChatsR));
      }
    });
};

export const {
  setLastMessage,
  setNeedRefresh,
  setCurrentChatId,
  setChats,
  setChatsR,
  setShowPopupChatsInfo,
  setAdminChatRefresh,
} = chat.actions;

export default chat.reducer;
