import React from 'react';
import cn from 'classnames';

import { Button, Form, Icon, Input, Logo } from '@atoms';
import icon from '@assets/icons/sad-icon.svg';

import { MobileContainer, MobileContent, MobileDescription, MobileHeader, MobileIcon, MobileTitle } from './styles';

const Mobile = () => {
  const sendEmail = () => {
    console.log('send');
  };

  return (
    <MobileContainer>
      <MobileHeader>
        <Logo className={cn('logo')} tertiary link="https://stimlanding.biz-office.ru" />
      </MobileHeader>
      <MobileContent>
        <MobileIcon src={icon} alt="" />
        <MobileTitle>
          Сожалеем, личный кабинет не доступен со смартфона или при малом размере окна браузера.
        </MobileTitle>
        <MobileDescription>
          Войти в личный кабинет или зарегистрироваться Вы можете через ноутбук или компьютер. Если Вы видите это
          сообщение на экране компьютера, попробуйте увеличить размер окна браузера и перезагрузить страницу.
        </MobileDescription>
        <Button $mobile href="https://stimlanding.biz-office.ru">
          <Icon name="back-arrow-small" className={cn('back')} />
          Назад
        </Button>
      </MobileContent>
    </MobileContainer>
  );
};

export default Mobile;
