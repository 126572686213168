import { Divider } from 'antd';
import styled from 'styled-components';

export const DividerContainer = styled(Divider)`
  border-top-color: ${({ theme }) => theme.palette.borderNeutral} !important;
  margin: 0 !important;
  .ant-divider {
    &-inner-text {
      font-size: 1.1rem;
      line-height: 1.5rem;
      color: ${({ theme }) => theme.palette.divider};
    }
  }
`;
