import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Badge } from 'antd';

import { useGetChatIdByRequestAnswerQuery, useAddChatMutation } from '@api/chat';

import { ROUTES } from '../../../constants/routes';

import { ChatButtonBlock } from './styles';

const ChatButton = ({ requestData, answerId, response, ...rest }) => {
  const navigate = useNavigate();
  const { chats } = useSelector(({ chat }) => chat);

  const [chatId, setChatId] = useState(undefined);

  const { data: requestAnswerChatId } = useGetChatIdByRequestAnswerQuery({ requestAnswerId: answerId });

  const [addChatRequest, { isSuccess: isSuccessChat, data: chatData }] = useAddChatMutation();
  const { id, requestId } = useParams();
  const openChat = useCallback(() => {
    navigate(
      `${ROUTES.REQUESTS}/${id}/${requestId}/${chatId || chatData}/${response.id}/${+response.iD_Status >= 0 ? 1 : 0}`,
    );
  }, [chatId, requestId, chatData, id, navigate, response]);
  useEffect(() => {
    setChatId(requestAnswerChatId);
  }, [requestAnswerChatId, setChatId]);
  useEffect(() => {
    if (isSuccessChat) {
      // open chat
      openChat();
    }
  }, [isSuccessChat, openChat]);

  const { currentUserId } = useSelector(({ user }) => user);
  if (!chatId) {
    return (
      <ChatButtonBlock>
        <button
          type="button"
          onClick={() => {
            addChatRequest({
              ID_Type: 3,
              Name: '',
              // ID_Users: `${currentUserId},${requestData.iD_User}`,
              Data: answerId,
            });
          }}
        >
          Начать чат
        </button>
      </ChatButtonBlock>
    );
  }
  return (
    <ChatButtonBlock>
      <button type="button" onClick={openChat}>
        Открыть чат
        <Badge count={chats[chatId]?.unread_Message_Count} />
      </button>
    </ChatButtonBlock>
  );
};

export default ChatButton;
