import React, { Fragment, useEffect, useState } from 'react';
import cn from 'classnames';

import { Select } from '@molecules';
import { Icon, Input } from '@atoms';
import {
  useGetEquipmentListQuery,
  useGetEquipmentCategoryListQuery,
  useGetEquipmentListByCategoryQuery,
} from '@api/equipment';

import {
  FormBlock,
  FormContainer,
  FormElement,
  FormElementBlock,
  FormElementWrap,
  FormHead,
  FormItem,
  FormRow,
  FormSubtitle,
  FormText,
  FormTitle,
  FormWrap,
} from './styles';

const Form = ({ children, ...rest }) => {
  return <FormContainer {...rest}>{children}</FormContainer>;
};

const FormRequestCreateInfo = ({
  className,
  code,
  changeFormValue,
  category,
  editMode,
  isSuccessEquipmentInfo,
  hasRequestInfo,
  ...rest
}) => {
  const [currentCategoryId, setCurrentCategoryId] = useState(null);

  useEffect(() => {
    if ((isSuccessEquipmentInfo && editMode) || hasRequestInfo) {
      setCurrentCategoryId(category);
    }
  }, [category, editMode, hasRequestInfo, isSuccessEquipmentInfo]);

  const { data: categoryList, isFetching: isFetchingCategoryList } = useGetEquipmentCategoryListQuery();
  const { data: equipmentList, isFetching: isFetchingEquipmentList } = useGetEquipmentListByCategoryQuery(
    currentCategoryId,
    { skip: !currentCategoryId },
  );

  const categoryItems = categoryList?.map((item) => ({
    key: item.id,
    label: item.name,
    content: (
      <Fragment>
        {item.name}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  }));

  const equipmentItems = equipmentList?.map((item) => ({
    key: item.id,
    label: item.name,
    content: (
      <Fragment>
        {item.name}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  }));

  const notFound = currentCategoryId ? (
    <div>В данной категории пока нет оборудования</div>
  ) : (
    <div>Вы не выбрали категорию</div>
  );

  const handleCategorySelect = (categoryId) => {
    setCurrentCategoryId(categoryId);
    changeFormValue('ID_Equipment', null);
  };

  return (
    <Form className={cn('request-info-form', className)} {...rest}>
      <fieldset disabled="disabled">
        <Form.Item name="Name" label="Название объекта" validateStatus="success">
          <Input.TextArea.Secondary
            width="43"
            autoSize
            placeholder="Введите название объекта, как в проектной документации, по ГОСТу"
          />
        </Form.Item>
        <Form.Item name="Code" label="Шифр проекта" validateStatus="success">
          <Input.Secondary width="25" />
        </Form.Item>
      </fieldset>

      <Form.Item
        name="category"
        label="Категория оборудования"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          $secondary
          defaultActiveFirstOption
          suffixIcon={<Icon name="arrow-2" className="select-arrow" />}
          dropdownClassName={cn(
            'select-dropdown',
            'request-create-info-select-dropdown',
            'object-status-select-dropdown',
          )}
          className={cn(editMode && 'ant-select-status-success')}
          value={currentCategoryId}
          onSelect={handleCategorySelect}
          loading={isFetchingCategoryList}
          notFoundContent={<div>Категорий нет</div>}
          items={categoryItems}
          optionLabelProp="label"
          placeholder="Выбрать"
        />
      </Form.Item>
      <Form.Item
        name="ID_Equipment"
        label="Тип оборудования"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          $secondary
          suffixIcon={<Icon name="arrow-2" className="select-arrow" />}
          dropdownClassName={cn(
            'select-dropdown',
            'request-create-info-select-dropdown',
            'object-status-select-dropdown',
          )}
          loading={isFetchingEquipmentList}
          notFoundContent={notFound}
          items={equipmentItems}
          optionLabelProp="label"
          placeholder="Выбрать"
        />
      </Form.Item>
    </Form>
  );
};

Form.Item = FormItem;
Form.Block = FormBlock;
Form.Title = FormTitle;
Form.Subtitle = FormSubtitle;
Form.Row = FormRow;
Form.Wrap = FormWrap;
Form.Head = FormHead;
Form.Text = FormText;
Form.Element = FormElement;
Form.Element.Wrap = FormElementWrap;
Form.Element.Block = FormElementBlock;

Form.RequestCreateInfo = FormRequestCreateInfo;

export default Form;
