import React, { Fragment, memo } from 'react';

import { ListContainer, ListItem } from './styles';

const List = memo(({ items, children, listStyle, ...rest }) => {
  if (items) {
    return (
      <ListContainer {...rest}>
        {items.map((item) => (
          <ListItem key={item}>
            {listStyle}
            {item}
          </ListItem>
        ))}
      </ListContainer>
    );
  }
  return <ListContainer {...rest}>{children}</ListContainer>;
});

List.Item = ListItem;

export default List;
