import { combineReducers } from 'redux';

import { clientAPI } from '@api';

import application from './application';
import sider from './sider';
import user from './user';
import requests from './requests';
import chat from './chat';

export const rootReducer = combineReducers({
  application,
  sider,
  user,
  chat,
  requests,
  [clientAPI.reducerPath]: clientAPI.reducer,
});
