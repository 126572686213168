import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import cn from 'classnames';
import { Button, Form, Space, Typography } from 'antd';
import { SearchOutlined, SendOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import Input from 'antd/lib/input/Input';

import { USER_ROLES, REQUEST_STATUSES } from '@constants/common';
import {
  useGetChatIdByRequestQuery,
  useAddMessageMutation,
  useGetMessagesQuery,
  useGetChatIdByRequestAnswerQuery,
  useConfirmMessageMutation,
  useGetChatUsersByChatIdQuery,
  useGetListQuery,
} from '@api/chat';
import { setNeedRefresh, setCurrentChatId, updateChats } from '@reducers/chat';

import Menu from '../Menu';

import {
  ChatCardBlock,
  ChatCardContainer,
  ChatCardDate,
  ChatCardDescription,
  ChatCardSubtitle,
  ChatCardTitle,
  ChatInfoRow,
  ChatMessage,
  CompanyInfoBlock,
} from './styles';

const { Title } = Typography;

const { Text, Link } = Typography;

const ChatCard = ({
  requestId,
  requestAnswerId,
  initialChatId,
  admin,
  status,
  setCurrentRequest,
  handleClick,
  hideCancelButton,
  oCompanyName,
  isAdminChat,
  isAdminerChat,
  readOnly,
  chatWith,
  idType = 3,
  ...rest
}) => {
  const [msg, setMsg] = useState('');
  const [opponentId, setOpponentId] = useState(undefined);
  const [opponentCompany, setOpponentCompany] = useState(undefined);

  const [opponentName, setOpponentName] = useState(undefined);
  const [scrollToEnd, setScrollToEnd] = useState(false);

  const { needRefresh } = useSelector(({ chat }) => chat);

  const { data: requestChatId } = useGetChatIdByRequestQuery(requestId);
  const { data: requestAnswerChatId } = useGetChatIdByRequestAnswerQuery(
    { requestAnswerId, needRefresh },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [chatId, setChatId] = useState(initialChatId);

  const { data: chatUsers } = useGetChatUsersByChatIdQuery(
    { chatId, needRefresh },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (requestChatId) setChatId(requestChatId);
    else if (requestAnswerChatId) setChatId(requestAnswerChatId);
    else if (initialChatId) setChatId(initialChatId);
    else setChatId(undefined);
    dispatch(setCurrentChatId(requestChatId || requestAnswerChatId || initialChatId));
  }, [requestChatId, requestAnswerChatId, initialChatId, dispatch, needRefresh]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentChatId(undefined));
    };
  }, [dispatch]);

  const { data: chatMessages } = useGetMessagesQuery(
    { ID_Chat: chatId, Take: 0, Count: 1000, Order: 0, needRefresh },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: 10000,
    },
  );

  const [sendMessageRequest, { isSuccess: isSuccessMessage }] = useAddMessageMutation();
  const { currentUser, currentUserId } = useSelector(({ user }) => user);

  const { data: chats } = useGetListQuery({
    ID_Type: idType || 3,
    Take: 0,
    Count: 1000,
    chatId,
  });

  const [chatInfo, setChatInfo] = useState(undefined);
  useEffect(() => {
    if (chats && chatId) {
      setChatInfo(chats.find((c) => +c.id === +chatId));
    }
  }, [setChatInfo, chats, chatId]);

  useEffect(() => {
    if (!opponentId) {
      const opponentUser = (chatUsers || []).find((o) => o.iD_User !== currentUserId);
      const opponentMsg = (chatMessages || []).find((o) => o.user_Add !== currentUserId);
      if (opponentMsg) {
        setOpponentId(opponentMsg.iD_User);
        setOpponentName(isAdminChat ? 'Администратор' : opponentMsg.user_Add_Fio);
        setOpponentCompany(opponentUser?.company_Name_Short);
      } else if (isAdminChat) {
        setOpponentName('Администратор');
      } else if (opponentUser) {
        setOpponentId(opponentUser.iD_User);
        setOpponentName(opponentUser?.user_Fio);
        setOpponentCompany(opponentUser?.company_Name_Short);
      } else {
        setOpponentId(undefined);
        setOpponentName('-');
      }
      if (chatWith) setOpponentName(chatWith);
    }
  }, [chatMessages, opponentId, currentUserId, chatUsers, isAdminChat, chatWith]);
  useEffect(() => {
    if (isAdminChat) {
      setOpponentName('Администратор');
    }
  }, [isAdminChat]);

  useEffect(() => {
    if (chatWith) {
      setOpponentName(chatWith);
    }
  }, [chatWith]);

  const { currentRequestInfo } = useSelector(({ requests }) => requests);

  const isClient = currentUser === USER_ROLES.CLIENT;
  const isProvider = currentUser === USER_ROLES.PROVIDER;

  const messagesEndRef = useRef(null);
  const messageInputRef = useRef(null);
  const searchInputRef = useRef(null);
  const foundedMsgRef = useRef(null);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [sendConfirmMessageRequest] = useConfirmMessageMutation();
  const [foundedIndex, setFoundedIndex] = useState(undefined);
  const [foundedId, setFoundedId] = useState(undefined);

  const scrollToBottom = useCallback(() => {
    setTimeout(() => messagesEndRef.current?.scrollBy({ top: 99999999, behavior: 'smooth' }), 10);
  }, [messagesEndRef]);

  const doSearchMessage = useCallback(() => {
    if (searchText.length > 0) {
      /* empty */
      const funded = chatMessages.find(
        (cm, index) =>
          cm.text?.toUpperCase().includes(searchText?.toUpperCase()) &&
          (foundedIndex === undefined || index > foundedIndex),
      );
      if (funded) {
        const lastIndex = chatMessages.indexOf(funded, foundedIndex + 1);
        setFoundedIndex(lastIndex);
        setFoundedId(funded.id);
        setTimeout(() => {
          foundedMsgRef?.current?.scrollIntoView();
          // if (foundedMsgRef?.current) messagesEndRef.current?.scrollIntoView(foundedMsgRef?.current);
        }, 100);
      } else {
        setFoundedIndex(undefined);
        setFoundedId(undefined);
        setShowSearchInput(false);
        setSearchText(undefined);
        // eslint-disable-next-line no-alert
        alert('Соответствий не найдено. Поиск завершён.');
      }
    }
  }, [chatMessages, searchText, setShowSearchInput, setFoundedIndex, setFoundedId, foundedIndex, foundedMsgRef]);

  const doSendMessage = useCallback(() => {
    if (msg.length > 0) {
      sendMessageRequest({ ID_Chat: chatId, Text: msg }).then((d) => {
        if (d.error && d.error.data) {
          setChatInfo({ access: false });
        }
      });
      setMsg('');
      // scrollToBottom();
      setScrollToEnd(true);
      messageInputRef.current?.focus();
    }
  }, [chatId, msg, sendMessageRequest, setChatInfo]);

  const handleSearchClick = useCallback(() => {
    setShowSearchInput(!showSearchInput);
    searchInputRef.current?.focus();
  }, [setShowSearchInput, searchInputRef, showSearchInput]);

  const [firstRun, setFirstRun] = useState(true);

  useEffect(() => {
    setFirstRun(true);
  }, [chatId, chatMessages]);

  useEffect(() => {
    if (firstRun && chatMessages?.length > 0 && currentUserId) {
      const promises = [];
      [...chatMessages].reverse().forEach((m) => {
        if (m.user_Add !== currentUserId) promises.push(sendConfirmMessageRequest(m.id));
      });
      Promise.all(promises).then(() => {
        setTimeout(() => dispatch(updateChats(chatMessages[0]?.iD_Type_Chat)), 2000);
      });
      setFirstRun(false);
    }
  }, [chatMessages, firstRun, setFirstRun, currentUserId, sendConfirmMessageRequest, dispatch, chatId]);

  useEffect(() => {
    scrollToBottom();
  }, [chatId, scrollToBottom]);
  useEffect(
    () => {
      dispatch(setNeedRefresh(false));
      if (
        scrollToEnd ||
        (chatMessages && chatMessages.length > 0 && chatMessages[chatMessages.length - 1].user_Add !== currentUserId)
      ) {
        sendConfirmMessageRequest(chatMessages[chatMessages.length - 1].id);
        scrollToBottom();
        setScrollToEnd(false);
      }
    },
    // eslint-disable-next-line
    [chatMessages],
  );

  if (admin) {
    return (
      <div>
        <span />
      </div>
    );
  }

  const waitChatText = 'Вы сможете начать общение после того, как Заказчик вам напишет';
  const hasMessages = !!chatId;
  const companyName =
    isAdminChat || !currentRequestInfo?.organization_Name_Short ? '' : `${currentRequestInfo?.organization_Name_Short}`;

  const chatRender = (
    <ChatCardContainer className={cn('chat-card')}>
      <ChatInfoRow>
        <CompanyInfoBlock>
          <Title level={5} type="secondary">
            {companyName || (!isAdminChat && !isAdminerChat && opponentCompany)}
          </Title>
          <Menu.Item.User userId={opponentId} userName={opponentName} />
        </CompanyInfoBlock>
        {showSearchInput && (
          <Input
            placeholder="Строка для поиска"
            ref={searchInputRef}
            value={searchText}
            onChange={(v) => setSearchText(v.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                doSearchMessage();
              }
            }}
          />
        )}
        <SearchOutlined onClick={() => handleSearchClick()} />
      </ChatInfoRow>
      <Content
        className={cn('chat-with-line')}
        style={{
          padding: 24,
          margin: 10,
          minHeight: 280,
          overflow: 'auto',
        }}
        ref={messagesEndRef}
      >
        {(chatMessages || []).map((el, index) => (
          <ChatMessage key={el.id} ref={el.id === foundedId ? foundedMsgRef : undefined}>
            {el.user_Add === currentUserId && <div className="spacer" />}
            <div className={cn(`msg-wrapper${el.id === foundedId ? ' c-f-m' : ''}`)}>
              {!searchText && <div className="msg">{el.text}</div>}
              {searchText && (
                <div className="msg">
                  {el.text?.split(new RegExp(searchText, 'i'))?.map((t, i, a) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <span key={`${el.id}-${i}`}>
                      {t}
                      {i + 1 !== a.length && (
                        <span className={cn(el.id === foundedId ? 'a-highlighted' : 'highlighted')}>{searchText}</span>
                      )}
                    </span>
                  ))}
                </div>
              )}
              <sub>{dayjs(el.date_Add).format('HH:mm')}</sub>
            </div>
            {el.user_Add !== currentUserId && <div className="spacer" />}
          </ChatMessage>
        ))}
      </Content>
      {chatInfo && !chatInfo.access && (
        <Space.Compact style={{ width: '100%' }}>
          <span>Ввод не доступен в связи с неактивным статусом заявки</span>
        </Space.Compact>
      )}
      {(!chatInfo || chatInfo.access) && (
        <Space.Compact style={{ width: '100%' }}>
          <Input
            ref={messageInputRef}
            placeholder="Ваше сообщение"
            value={msg}
            onChange={(v) => setMsg(v.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                doSendMessage();
              }
            }}
          />
          <Button
            type="secondary"
            onClick={() => {
              doSendMessage();
            }}
          >
            <SendOutlined color="#000" />
            <Text>Отправить</Text>
          </Button>
        </Space.Compact>
      )}
    </ChatCardContainer>
  );

  if (isProvider) {
    if (!hasMessages) {
      return (
        <ChatCardContainer>
          <Title level={3} type="secondary">
            {waitChatText}
          </Title>
        </ChatCardContainer>
      );
    }

    return chatRender;
  }

  return chatRender;
};

export default ChatCard;
