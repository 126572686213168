import React, { memo } from 'react';

import { DescriptionContainer, DescriptionItem } from './styles';

const Description = memo(({ children, ...rest }) => {
  return <DescriptionContainer {...rest}>{children}</DescriptionContainer>;
});

Description.Item = DescriptionItem;

export default Description;
