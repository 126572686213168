import React, { Fragment } from 'react';
import cn from 'classnames';

import { Icon, Input } from '@atoms';
import { Select } from '@molecules';
import { PROJECT_STATUSES } from '@constants/common';

// select options

const statusOptions = [
  {
    key: 1,
    label: PROJECT_STATUSES.ACTIVE.title,
    content: (
      <Fragment>
        <Icon $transition name="active" className={cn('prefix')} />
        {PROJECT_STATUSES.ACTIVE.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
  {
    key: 2,
    label: PROJECT_STATUSES.FROZEN.title,
    content: (
      <Fragment>
        <Icon $transition name="frozen" className={cn('prefix')} />
        {PROJECT_STATUSES.FROZEN.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
  {
    key: 3,
    label: PROJECT_STATUSES.DONE.title,
    content: (
      <Fragment>
        <Icon $transition name="done" className={cn('prefix')} />
        {PROJECT_STATUSES.DONE.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
];

export const currencyOptions = [
  {
    key: 'rub',
    label: 'RUB',
    content: <Fragment>RUB</Fragment>,
  },
  {
    key: 'usd',
    label: 'USD',
    content: <Fragment>USD</Fragment>,
  },
  {
    key: 'eur',
    label: 'EUR',
    content: <Fragment>EUR</Fragment>,
  },
];

// forms

export const objectCreationForm = [
  {
    props: {
      name: 'Name',
      rules: [
        {
          required: true,
          message: 'Введите название объекта',
        },
      ],
    },
    element: <Input.Common placeholder="Название объекта" />,
  },
  {
    props: {
      name: 'Name_Full',
      rules: [
        {
          required: true,
          message: 'Введите полное название объекта',
        },
      ],
    },
    element: <Input.Common placeholder="Название объекта как в проектной документации" />,
  },
  {
    props: {
      name: 'Code',
      rules: [
        {
          required: true,
          message: 'Введите шифр проекта',
        },
      ],
    },
    element: <Input.Common placeholder="Шифр проекта" />,
  },
  {
    props: {
      name: 'ID_Status',
      rules: [
        {
          required: true,
          message: 'Укажите статус объекта',
        },
      ],
    },
    element: (
      <Select
        $common
        suffixIcon={<Icon name="arrow-2" className="select-arrow" />}
        dropdownClassName={cn('select-dropdown', 'object-status-select-dropdown')}
        items={statusOptions}
        optionLabelProp="label"
        placeholder="Статус объекта"
      />
    ),
  },
];

export const requestInfoForm = [
  {
    props: {
      name: 'object_name',
      label: 'Название объекта',
    },
    element: <Input.TextArea.Secondary width="43" autoSize />,
  },
  {
    props: {
      name: 'object_code',
      label: 'Шифр проекта',
    },
    element: <Input.Secondary checked width="25" />,
  },
  {
    props: {
      name: 'eq_category',
      label: 'Категория оборудования',
    },
    element: <Input.Secondary width="25" />,
  },
  {
    props: {
      name: 'eq_type',
      label: 'Тип оборудования',
    },
    element: <Input.Secondary width="25" />,
  },
];

export const changePasswordForm = [
  {
    props: {
      name: 'Old_Password',
      rules: [
        {
          required: true,
          message: 'Введите старый пароль',
        },
      ],
    },
    element: <Input.Common type="password" placeholder="Текущий пароль" />,
  },
  {
    props: {
      name: 'New_Password',
      rules: [
        {
          type: 'string',
        },
        {
          min: 6,
          message: 'Минимальное число знаков 6',
        },
        {
          required: true,
          message: 'Введите новый пароль',
        },
      ],
    },
    element: <Input.Common type="password" placeholder="Новый пароль" />,
  },
  {
    props: {
      name: 'New_Password_Confirm',
      rules: [
        {
          required: true,
          message: 'Подтвердите пароль',
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('New_Password') === value) {
              return Promise.resolve();
            }

            return Promise.reject(new Error('Пароль не совпадает'));
          },
        }),
      ],
    },
    element: <Input.Common type="password" placeholder="Подтвердите новый пароль" />,
  },
];

export const authForm = [
  {
    props: {
      name: 'Email',
      rules: [
        {
          required: true,
          message: 'Введите электронную почту',
        },
      ],
    },
    element: <Input.Common $rounded placeholder="Электронная почта" />,
  },
  {
    props: {
      name: 'Password',
      rules: [
        {
          required: true,
          message: 'Введите пароль',
        },
      ],
    },
    element: <Input.Password.Common $rounded placeholder="Пароль" />,
  },
];

export const registerForm = [
  {
    props: {
      name: 'Email',
      rules: [
        {
          type: 'email',
          message: 'Пожалуйста, укажите корректный адрес электронной почты',
        },
        {
          required: true,
          message: 'Укажите электронную почту',
        },
      ],
    },
    element: <Input.Common $rounded placeholder="Электронная почта" />,
  },
  {
    props: {
      name: 'Inn',
      rules: [
        {
          type: 'string',
        },
        {
          len: 10,
          message: 'ИНН должен состоять из 10 цифр',
        },
        {
          required: true,
          message: 'Введите ИНН организации',
        },
      ],
    },
    element: <Input.Common type="number" $rounded placeholder="ИНН организации" />,
  },
  {
    props: {
      name: 'Password',
      rules: [
        {
          type: 'string',
        },
        {
          min: 6,
          message: 'Минимальное число знаков 6',
        },
        {
          required: true,
          message: 'Придумайте новый пароль',
        },
      ],
    },
    element: <Input.Password.Common $rounded placeholder="Придумайте пароль" />,
  },
  {
    props: {
      name: 'ConfirmPassword',
      rules: [
        {
          required: true,
          message: 'Подтвердите новый пароль',
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('Password') === value) {
              return Promise.resolve();
            }

            return Promise.reject(new Error('Пароль не совпадает'));
          },
        }),
      ],
    },
    element: <Input.Password.Common $rounded placeholder="Подтвердите новый пароль" />,
  },
];
