import React, { memo, useCallback, useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import {
  USER_ROLES,
  CLIENT_MORE_REQUEST_ACTIONS,
  ADMIN_MORE_REQUEST_ACTIONS,
  UNMODERATED_MORE_REQUEST_ACTIONS,
  PROVIDER_MORE_REQUEST_ACTIONS,
} from '@constants/common';
import { Icon, Tooltip } from '@atoms';
import useModerationStatus from '@hooks/useModerationStatus';

import { MoreActions, MoreButton, MoreContainer } from './styles';

const More = memo(({ status, handleClick, hideCancelButton }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { currentUser } = useSelector(({ user }) => user);
  const { onModerationStatus } = useModerationStatus();

  const isAdmin = currentUser === USER_ROLES.ADMIN;
  const isClient = currentUser === USER_ROLES.CLIENT;

  const getActionsList = useCallback(() => {
    if (isAdmin) {
      return ADMIN_MORE_REQUEST_ACTIONS;
    }

    if (onModerationStatus) {
      return UNMODERATED_MORE_REQUEST_ACTIONS;
    }

    if (isClient) {
      return CLIENT_MORE_REQUEST_ACTIONS;
    }

    return PROVIDER_MORE_REQUEST_ACTIONS;
  }, [isAdmin, isClient, onModerationStatus]);

  const actionsList = getActionsList();
  const hasActions = actionsList[status]?.length > 0;

  const handleMouseEnter = () => {
    setCollapsed(true);
  };

  const handleMouseLeave = () => {
    setCollapsed(false);
  };

  const handleButtonClick = (event, id) => {
    event.preventDefault();
    if (handleClick) {
      handleClick(id);
    }
  };

  if (!hasActions) {
    return false;
  }

  return (
    <MoreContainer
      className={cn(collapsed && 'collapsed', hideCancelButton && 'hideCancelButton')}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <MoreButton className={cn('collapse-button')}>
        <Icon name="more" className={cn('more-collapse')} $transition />
      </MoreButton>
      <MoreActions>
        {actionsList[status]?.map((action) => (
          <MoreButton
            key={action.icon}
            className={cn(action.icon)}
            onClick={(event) => handleButtonClick(event, action.icon)}
          >
            <Tooltip overlayClassName={cn('small-tooltip')} title={action.title} placement="bottom">
              <Icon id={action.icon} $transition name={action.icon} className={cn(action.icon)} />
            </Tooltip>
          </MoreButton>
        ))}
      </MoreActions>
    </MoreContainer>
  );
});

export default More;
