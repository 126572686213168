import React, { memo, useState } from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import { Icon, Button } from '@atoms';
import { setSendRequestsModalVisibility } from '@reducers/application';

import {
  TableContainer,
  TableDropdown,
  TableHideButton,
  TableSearchButton,
  TableSearchInput,
  TableSearchWrap,
  TableText,
  TableWrapper,
} from './styles';

const Table = ({ ...rest }) => {
  return <TableContainer {...rest} />;
};

const TableEmpty = ({ text, hideButton }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setSendRequestsModalVisibility(true));
  };

  return (
    <TableWrapper>
      <TableText $wide={hideButton}>{text}</TableText>
      {!hideButton && (
        <Button $send onClick={handleClick}>
          <Icon name="send-request" className={cn('send-request')} /> Отправить запрос
        </Button>
      )}
    </TableWrapper>
  );
};

const TableCanceled = ({ requestName, className }) => {
  return (
    <TableWrapper className={className}>
      <TableText $warning>Запрос на {requestName} - Отменен</TableText>
    </TableWrapper>
  );
};

Table.Empty = TableEmpty;
Table.Canceled = TableCanceled;
Table.Dropdown = TableDropdown;
Table.SearchInput = TableSearchInput;
Table.SearchButton = TableSearchButton;
Table.SearchWrap = TableSearchWrap;
Table.HideButton = TableHideButton;

export default Table;
