import styled, { css } from 'styled-components';
import { Layout } from 'antd';

export const SiderContainer = styled(Layout.Sider)`
  position: relative;
  z-index: 999;
  background-color: ${({ theme }) => theme.palette.sider};
  color: ${({ theme }) => theme.palette.siderText};
  overflow: hidden;
  padding: 1.7rem 0 2.2rem 0;
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  ${({ $admin }) =>
    $admin &&
    css`
      background-color: ${({ theme }) => theme.palette.siderSecondary};
    `}
`;

export const SiderLogo = styled.div`
  padding: 0 2rem 1.5rem 2rem;
`;

export const SiderHeader = styled.div``;

export const SiderFooter = styled.div``;
