import React from 'react';

import { Icon } from '@atoms';

import {
  DrawerBlock,
  DrawerBlockHead,
  DrawerContainer,
  DrawerLink,
  DrawerList,
  DrawerListItem,
  DrawerTitle,
} from './styles';

const Drawer = ({ children, ...rest }) => {
  return (
    <DrawerContainer closeIcon={<Icon name="close" className="close-icon" />} {...rest}>
      {children}
    </DrawerContainer>
  );
};

Drawer.Block = DrawerBlock;
Drawer.Block.Head = DrawerBlockHead;
Drawer.Title = DrawerTitle;
Drawer.List = DrawerList;
Drawer.List.Item = DrawerListItem;
Drawer.Link = DrawerLink;

export default Drawer;
