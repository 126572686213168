import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { message } from 'antd';

import { Page } from '@organisms';
import { Button, Checkbox, Icon, Modal, Skeleton, Table, Tooltip } from '@atoms';
import { ROUTES } from '@constants/routes';
import { setAddEquipmentModalVisibility } from '@reducers/application';
import {
  useGetEquipmentListByCategoryQuery,
  useGetEquipmentCategoryQuery,
  useDeleteEquipmentMutation,
} from '@api/equipment';
import loading from '@helpers/loading';
import transformToFormData from '@helpers/transformToFormData';

import {
  EquipmentInfoAction,
  EquipmentInfoActions,
  EquipmentInfoContainer,
  EquipmentInfoContent,
  EquipmentInfoHead,
} from './styles';

const EquipmentInfo = ({ category }) => {
  const dispatch = useDispatch();

  const [currentEditingEquipment, setCurrentEditingEquipment] = useState(null);

  // get equip data
  const { data: equipmentList, isFetching: isFetchingEquipmentList } = useGetEquipmentListByCategoryQuery(category);
  const { data: equipmentCategory, isFetching: isFetchingEquipmentCategory } = useGetEquipmentCategoryQuery(category);

  // set equip data
  const [deleteEquip, { isError: deleteEquipmentError, isSuccess: deleteEquipmentSuccess }] =
    useDeleteEquipmentMutation();

  useEffect(() => {
    if (deleteEquipmentSuccess) {
      message.success({
        content: 'Оборудование удалено',
        key: 'deleteEquipmentSuccess-equip-msg-1',
      });
    }
  }, [deleteEquipmentSuccess]);

  const addEquipment = () => {
    dispatch(setAddEquipmentModalVisibility(true));
  };

  const editEquipment = (id) => {
    setCurrentEditingEquipment(id);
    dispatch(setAddEquipmentModalVisibility(true));
  };

  const deleteEquipment = (id) => {
    deleteEquip(transformToFormData({ ID: id }));
  };

  const equipmentTableColumns = [
    {
      title: 'Оборудование',
      dataIndex: 'name',
      className: 'first-column',
      key: 'name',
      render: (_, record) => <p className={cn('table-title')}>{record.name}</p>,
    },
    {
      title: (
        <p className={cn('table-head-title')}>
          Опубликовано
          <Icon $transition name="sort" className={cn('sort-icon')} />
        </p>
      ),
      dataIndex: 'draft',
      key: 'draft',
      align: 'center',
      width: '18rem',
      sorter: (a, b) => a.draft - b.draft,
      render: (_, record) => (
        <div className={cn('table-tag', record.draft && 'secondary')}>{record.draft ? 'Нет' : 'Да'}</div>
      ),
    },
    {
      title: 'Опросный лист',
      dataIndex: '',
      key: 'questionnaire',
      render: (_, record) => (
        <div className={cn('table-link')}>
          <Checkbox $light checked={!record.draft} />
          <Tooltip
            overlayClassName={cn('small-tooltip')}
            title="Редактировать опросный лист"
            placement="bottom"
            arrowPointAtCenter
          >
            <NavLink to={`${ROUTES.EQUIPMENT}/${category}/${record.id}`} className={cn('table-text')}>
              Опросный лист {record.name}
            </NavLink>
          </Tooltip>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: 'actions',
      render: (_, record) => (
        <EquipmentInfoActions>
          <Tooltip
            overlayClassName={cn('small-tooltip', 'edit')}
            title="Редактировать"
            placement="bottom"
            arrowPointAtCenter
          >
            <EquipmentInfoAction className={cn('edit')} onClick={() => editEquipment(record.id)}>
              <Icon $transition name="edit" className={cn('edit')} />
            </EquipmentInfoAction>
          </Tooltip>
          <Tooltip
            overlayClassName={cn('small-tooltip', 'delete')}
            title="Удалить"
            placement="bottom"
            arrowPointAtCenter
          >
            <EquipmentInfoAction className={cn('delete')} onClick={() => deleteEquipment(record.id)}>
              <Icon $transition name="delete" className={cn('delete')} />
            </EquipmentInfoAction>
          </Tooltip>
        </EquipmentInfoActions>
      ),
    },
  ];

  const equipmentSource = equipmentList?.map((equipment) => ({
    ...equipment,
    key: equipment.id,
  }));

  return (
    <EquipmentInfoContainer>
      <EquipmentInfoHead>
        {isFetchingEquipmentCategory ? (
          <Skeleton.Input size="small" active />
        ) : (
          <Page.Title>{equipmentCategory?.name}</Page.Title>
        )}

        <Button.Add onClick={addEquipment}>Добавить оборудование</Button.Add>
      </EquipmentInfoHead>
      {loading(
        isFetchingEquipmentList,
        <EquipmentInfoContent>
          <Table className={cn('equip-table')} columns={equipmentTableColumns} dataSource={equipmentSource} />
        </EquipmentInfoContent>,
      )}
      <Modal.AddEquipment
        categoryId={category}
        equipID={currentEditingEquipment}
        resetCurrent={setCurrentEditingEquipment}
      />
    </EquipmentInfoContainer>
  );
};

export default EquipmentInfo;
