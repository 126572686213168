import { Radio } from 'antd';
import styled, { css } from 'styled-components';

import checkboxArrow from '@assets/images/checkbox-arrow.svg';

export const RadioContainer = styled(Radio)`
  margin-right: 3rem;
  align-items: center;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.palette.text};
  .ant-radio {
    top: 0;
    &-input {
      &:focus {
        & + .ant-radio-inner {
          box-shadow: none;
        }
      }
    }
    &-inner {
      width: 2.5rem;
      height: 2.5rem;
      border-color: ${({ theme }) => theme.palette.drawerText};
      &:after {
        display: none;
      }
    }
    &-checked {
      .ant-radio {
        &-inner {
          border-color: ${({ theme }) => theme.palette.button};
          border-width: 5px;
        }
      }
    }
    &:not(.ant-radio-checked) {
      .ant-radio-inner {
        border-color: ${({ theme }) => theme.palette.drawerText} !important;
      }
    }
    &::after {
      display: none;
    }
  }

  ${({ $checkbox }) =>
    $checkbox &&
    css`
      color: ${({ theme }) => theme.palette.secondaryText};
      position: relative;
      .ant-radio {
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0.8rem;
          transform: translate(-50%, -50%);
          background-image: url(${checkboxArrow});
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          width: 0.8rem;
          height: 0.6rem;
          z-index: 1;
          opacity: 0;
          visibility: hidden;
          transition: 0.2s;
        }
      }
      .ant-radio-inner {
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 2px;
        border-width: 1px !important;
        border-color: ${({ theme }) => theme.palette.drawerText};
      }
      &:hover {
        .ant-radio-inner {
          border-color: ${({ theme }) => theme.palette.drawerText};
        }
      }
      &.ant-radio-wrapper-checked {
        color: ${({ theme }) => theme.palette.text};
      }
      .ant-radio-checked {
        .ant-radio-inner {
          background-color: ${({ theme }) => theme.palette.button};
          border-color: ${({ theme }) => theme.palette.button};
        }
        &::before {
          opacity: 1;
          visibility: visible;
        }
        &:hover {
          .ant-radio-inner {
            border-color: ${({ theme }) => theme.palette.button};
          }
        }
      }
      input[type='radio'] {
        width: 1.4rem !important;
        height: 1.4rem !important;
      }
    `}
`;

export const RadioGroup = styled(Radio.Group)`
  ${({ $grid }) =>
    $grid &&
    css`
      display: grid;
      grid-template-columns: repeat(2, 9rem);
      gap: 4rem;
      .ant-radio-wrapper {
        margin: 0;
        justify-content: center;
      }
    `}
`;
