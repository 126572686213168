import { useSelector } from 'react-redux';

import { USER_ROLES } from '@constants/common';

const useModerationStatus = () => {
  const {
    currentUser,
    moderationChecked,
    moderatedStatus,
    userInfoStatus,
    organizationInfoStatus,
    userEquipmentStatus,
    isEmailConfirmed,
  } = useSelector(({ user }) => user);
  const { isLoadingApp } = useSelector(({ application }) => application);

  const isClient = currentUser === USER_ROLES.CLIENT;

  const equipParam = isClient ? true : !!userEquipmentStatus;

  const allParametersSent = !!userInfoStatus && !!organizationInfoStatus && equipParam;
  const someParametersNull = userInfoStatus === null || userInfoStatus === null || userEquipmentStatus === null;

  const notModeratedStatus = !isLoadingApp && !moderationChecked && !allParametersSent && !someParametersNull;
  const onModerationStatus = !isLoadingApp && !moderationChecked && allParametersSent && !someParametersNull;
  const rejectedStatus = !isLoadingApp && moderatedStatus === -1;

  return { notModeratedStatus, onModerationStatus, rejectedStatus, moderationChecked, isEmailConfirmed };
};

export default useModerationStatus;
