import URI from 'urijs';

import { clientAPI } from '@api';

const _clientAPI = clientAPI.enhanceEndpoints({
  addTagTypes: [
    'EmailConfirm',
    'UserInfo',
    'Authenticate',
    'RegisterRequests',
    'EquipRequests',
    'UserParameters',
    'ModerationCheck',
    'Comment',
    'CommentStatus',
    'Register',
    'UserInn',
    'UserCategoriesList',
  ],
});

const userAPI = _clientAPI.injectEndpoints({
  endpoints: (build) => ({
    // авторизация
    authenticate: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Users/authenticate');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['Authenticate'],
    }),
    // регистрация
    registration: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Users/register');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['Register'],
    }),
    // проверка, пройдена ли модерация у пользователя
    checkIsModerated: build.query({
      query: () => {
        const URL = new URI('/Users/moderationCheck');

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['Authenticate', 'ModerationCheck'],
    }),
    // проверка, подтверждена ли почта
    checkIsEmailConfirmed: build.query({
      query: () => {
        const URL = new URI('/Users/emailConfirmed');

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['EmailConfirm', 'Authenticate', 'Register'],
    }),
    // отправить код на подтверждение почты
    setIsEmailConfirmed: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Users/emailConfirmation');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['EmailConfirm'],
    }),
    // отправить запрос на повторную отправку кода
    requestEmailConfirmedCode: build.mutation({
      query: () => {
        const URL = new URI('/Users/emailConfirmationCode');

        return {
          url: URL.toString(),
          method: 'POST',
        };
      },
    }),
    // =================================================================
    // получить персональные данные пользователя
    getUserInfo: build.query({
      query: () => {
        const URL = new URI('/Settings/userInfoGet');

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['UserInfo', 'Authenticate'],
    }),
    setUserInfo: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Settings/userInfoSet');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['UserInfo', 'UserParameters', 'ModerationCheck'],
    }),
    changePassword: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Users/passwordChange');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
    }),
    getUserInn: build.query({
      query: () => {
        const URL = new URI('/Settings/userInnGet');

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['Authenticate', 'UserInn'],
    }),
    // установить данные организации
    setOrganizationInfo: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Organizations/organizationAdd');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['UserParameters', 'ModerationCheck'],
    }),
    // обновить данные организации
    updateOrganizationInfo: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Organizations/organizationUpdate');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['UserParameters', 'ModerationCheck'],
    }),
    // =================================================================
    // запросы на регистрацию
    getUserRequestsList: build.query({
      query: (params) => {
        const URL = new URI(`/Users/requestsList${params.path}`);

        if (params?.inn) {
          URL.addQuery({ inn: params.inn });
        }

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['RegisterRequests', 'Authenticate'],
    }),
    // получить запрос на регистрацию по айди
    getUserRequest: build.query({
      query: ({ path, requestId }) => {
        const URL = new URI(`/Users/requests${path}Get`);

        URL.addQuery({ ID_User: requestId });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['RegisterRequests', 'Authenticate'],
    }),
    // запросы на изменение оборудования
    getEquipmentRequestsList: build.query({
      query: (params) => {
        const URL = new URI('/Users/requestsChangesList');

        if (params?.inn) {
          URL.addQuery({ inn: params.inn });
        }

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['EquipRequests', 'Authenticate'],
    }),
    // получить запрос на изменение оборудования по айди
    getEquipmentRequest: build.query({
      query: (requestId) => {
        const URL = new URI(`/Users/requestsChangesGet`);

        URL.addQuery({ ID_User: requestId });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['EquipRequests', 'Authenticate'],
    }),
    // принять запрос на регистрацию
    acceptRegistrationRequest: build.mutation({
      query: (params) => {
        const URL = new URI('/Users/requestsAccept');

        URL.addQuery({ ...params });

        return {
          url: URL.toString(),
          method: 'POST',
          body: '',
        };
      },
      invalidatesTags: ['RegisterRequests'],
    }),
    // отклонить запрос на регистрацию
    denyRegistrationRequest: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Users/requestsDeny');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['RegisterRequests'],
    }),
    // принять запрос на изменение оборудования
    acceptEquipmentRequest: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Users/requestsChangesAccept');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['EquipRequests'],
    }),
    // отклонить запрос на изменение оборудования
    denyEquipmentRequest: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Users/requestsChangesDeny');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['EquipRequests'],
    }),
    // количество запросов на изменение оборудования
    getRegistrationRequestCount: build.query({
      query: () => {
        const URL = new URI(`/Users/requestsUnreadCount`);

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['RegisterRequests', 'Authenticate'],
    }),
    // количество запросов на изменение оборудования
    getEquipmentRequestCount: build.query({
      query: () => {
        const URL = new URI(`/Users/requestsChangesUnreadCount`);

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['EquipRequests', 'Authenticate'],
    }),
    // отправить сообщение
    sendMessage: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Users/requestsCommentSend');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['Comment', 'EquipRequests', 'RegisterRequests'],
    }),
    // вернет комментарий для пользователя
    getComment: build.query({
      query: () => {
        const URL = new URI(`/Comments/commentGet`);

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['Authenticate'],
    }),
    // подтвердить исправление требования в комментарии
    confirmComment: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Comments/commentConfirm');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['CommentStatus'],
    }),
    // вернет статус комментария
    getCommentStatus: build.query({
      query: () => {
        const URL = new URI(`/Users/userParameterFloatGet`);

        URL.addQuery({ ID_Parameter: 12 });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['CommentStatus', 'Authenticate'],
    }),
    // ======================
    // вернет статусы модерации
    getUserParameter: build.query({
      query: (parameter) => {
        const URL = new URI(`/Users/userParameterBoolGet`);

        URL.addQuery({ ID_Parameter: parameter });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['Authenticate', 'UserParameters'],
    }),
    // статус модерации отдельно
    getUserModerationStatus: build.query({
      query: (parameter) => {
        const URL = new URI(`/Users/moderationStatus`);

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['Authenticate', 'UserParameters'],
    }),
    resetUser: build.mutation({
      query: () => {
        const URL = new URI('/Users/userReset');

        return {
          url: URL.toString(),
          method: 'POST',
        };
      },
      invalidatesTags: [
        'CommentStatus',
        'ModerationCheck',
        'UserInfo',
        'UserParameters',
        'UserEquipment',
        'UserInn',
        'OrganizationInfo',
        'UserCategoriesList',
      ],
    }),
  }),
});

export const {
  useAuthenticateMutation,
  useRegistrationMutation,
  useCheckIsModeratedQuery,
  useCheckIsEmailConfirmedQuery,
  useSetIsEmailConfirmedMutation,
  useRequestEmailConfirmedCodeMutation,

  useGetUserInfoQuery,
  useSetUserInfoMutation,
  useGetUserInnQuery,
  useSetOrganizationInfoMutation,
  useChangePasswordMutation,
  useUpdateOrganizationInfoMutation,

  useGetUserRequestsListQuery,
  useGetUserRequestQuery,
  useGetEquipmentRequestsListQuery,
  useGetEquipmentRequestQuery,
  useAcceptRegistrationRequestMutation,
  useDenyRegistrationRequestMutation,
  useAcceptEquipmentRequestMutation,
  useDenyEquipmentRequestMutation,
  useGetEquipmentRequestCountQuery,
  useGetRegistrationRequestCountQuery,
  useSendMessageMutation,
  useGetCommentQuery,
  useGetCommentStatusQuery,
  useConfirmCommentMutation,

  useGetUserParameterQuery,
  useGetUserModerationStatusQuery,
  useResetUserMutation,
} = userAPI;
