import React from 'react';

import {
  NotificationCardContainer,
  NotificationCardDate,
  NotificationCardDescription,
  NotificationCardTitle,
} from './styles';

const NotificationCard = ({ notificationInfo }) => {
  return (
    <NotificationCardContainer>
      <NotificationCardDescription>{notificationInfo}</NotificationCardDescription>
    </NotificationCardContainer>
  );
};

export default NotificationCard;
