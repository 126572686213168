import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import dayjs from 'dayjs';
import { Badge, Space } from 'antd';

import { useGetChatUsersByChatIdQuery, useAddChatMutation } from '@api/chat';
import { More, Status } from '@atoms';

import { ROUTES } from '../../../constants/routes';
import {
  RequestCardBlock,
  RequestCardContainer,
  RequestCardDate,
  RequestCardDescription,
  RequestCardSubtitle,
  RequestCardTitle,
  SelectedRequestCardContainer,
} from '../RequestCard/styles';

const ChatInfo = ({ chatId, selected, onClick, chat, mini, admin, notification, ...rest }) => {
  const navigate = useNavigate();

  const [addChatRequest, { isSuccess: isSuccessChat, data: chatData }] = useAddChatMutation();
  const { currentUserId } = useSelector(({ user }) => user);

  const { data: chatUsers } = useGetChatUsersByChatIdQuery({ chatId });
  const chatUser = chatUsers && chatUsers[0];
  const chatUserInn = admin ? (chat?.client_Data?.split(',') || [])[0] : chatUser?.inn;
  const chatUserCompany = admin ? (chat?.client_Data?.split(',') || [])[2] : chatUser?.company_Name_Short;
  const chatUserFio = admin ? (chat?.client_Data?.split(',') || [])[1] : (chat?.client_Data?.split(',') || [])[1];
  if (mini)
    return (
      <>
        {chat?.inn} - {chat?.company_Name_Short} - {chat?.user_Fio}
      </>
    );
  if (notification)
    return (
      <RequestCardContainer className={cn('request-card')} onClick={onClick} {...rest}>
        <RequestCardBlock>
          <RequestCardSubtitle className={cn('request-card-subtitle')}>ИНН: {chatUserInn}</RequestCardSubtitle>
          <RequestCardSubtitle className={cn('request-card-title')}>{chatUserCompany}</RequestCardSubtitle>
          <RequestCardSubtitle className={cn('request-card-title')}>{chatUserFio}</RequestCardSubtitle>

          <Space />
          <RequestCardSubtitle className={cn('request-card-title')}>
            Сообщений: <Badge count={chat?.unread_Message_Count} /> {chat?.unread_Message_Count === 0 ? '0' : ''}
          </RequestCardSubtitle>
        </RequestCardBlock>
      </RequestCardContainer>
    );
  return selected ? (
    <SelectedRequestCardContainer className={cn('request-card')} onClick={onClick} $admin {...rest}>
      <RequestCardBlock>
        <RequestCardSubtitle className={cn('request-card-subtitle')}>ИНН: {chatUserInn}</RequestCardSubtitle>
      </RequestCardBlock>
      <RequestCardTitle className={cn('request-card-title')}>{chatUserCompany}</RequestCardTitle>
      <RequestCardSubtitle className={cn('request-card-title')}>{chatUserFio}</RequestCardSubtitle>
      <RequestCardTitle className={cn('request-card-title')}>
        Сообщений: <Badge count={chat?.unread_Message_Count} /> {chat?.unread_Message_Count === 0 ? '0' : ''}
      </RequestCardTitle>
    </SelectedRequestCardContainer>
  ) : (
    <RequestCardContainer className={cn('request-card')} onClick={onClick} $admin {...rest}>
      <RequestCardBlock>
        <RequestCardSubtitle className={cn('request-card-subtitle')}>ИНН: {chatUserInn}</RequestCardSubtitle>
      </RequestCardBlock>
      <RequestCardTitle className={cn('request-card-title')}>{chatUserCompany}</RequestCardTitle>
      <RequestCardSubtitle className={cn('request-card-title')}>{chatUserFio}</RequestCardSubtitle>
      <RequestCardTitle className={cn('request-card-title')}>
        Сообщений: <Badge count={chat?.unread_Message_Count} /> {chat?.unread_Message_Count === 0 ? '0' : ''}
      </RequestCardTitle>
    </RequestCardContainer>
  );
};

export default ChatInfo;
