import URI from 'urijs';

import { clientAPI } from '@api';

const _clientAPI = clientAPI.enhanceEndpoints({
  addTagTypes: ['UserCategoriesList', 'OrganizationInfo'],
});

const additionalAPI = _clientAPI.injectEndpoints({
  endpoints: (build) => ({
    // вернет типы данных для полей опросного листа
    getTypeList: build.query({
      query: () => {
        const URL = new URI('/Additional/parameterTypeList');

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      transformResponse: (response) => {
        const transformedResponse = response.map((item) => ({ key: item.id, label: item.name }));

        return transformedResponse;
      },
    }),
    // проверит ИНН в налоговой
    checkInn: build.query({
      query: (params) => {
        const URL = new URI('/Organizations/innCheck');

        URL.addQuery({ ...params });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
    }),
    // вернет данные об организации по ИНН
    getOrganizationInfo: build.query({
      query: (inn) => {
        const URL = new URI('/Organizations/organizationGet');

        URL.addQuery({ Inn: inn });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['Authenticate', 'OrganizationInfo'],
    }),
    // вернет курсы валют
    getCurrency: build.query({
      query: () => {
        const URL = new URI('/Additional/currencyList');

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
    }),
    // установить технический параметр
    parameterSet: build.mutation({
      // ID=19 - (категории оорудования в настройках)
      query: (attributes) => {
        const URL = new URI('/Users/userParameterSet');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['UserCategoriesList'],
    }),
    // вернуть технический параметр
    parameterGet: build.query({
      // ID=19 - (категории оорудования в настройках)
      query: (id) => {
        const URL = new URI('/Users/userParameterStringGet');

        URL.addQuery({ ID_Parameter: id });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['UserCategoriesList', 'Authenticate'],
    }),
    // проверка капчи Google
    recaptchaCheck: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Additional/googleRecaptchaCheck1');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
    }),
  }),
});

export const {
  useGetTypeListQuery,
  useCheckInnQuery,
  useGetOrganizationInfoQuery,
  useGetCurrencyQuery,
  useParameterSetMutation,
  useParameterGetQuery,
  useRecaptchaCheckMutation,
} = additionalAPI;
