import { Dropdown } from 'antd';
import styled, { css } from 'styled-components';

import { Menu } from '@molecules';

export const DropdownContainer = styled(Dropdown)``;

export const DropdownMenuContainer = styled(Menu)`
  background-color: ${({ theme }) => theme.palette.bodySecondary};
  border-radius: 4px;
  padding: 0;
  border: 1px solid ${({ theme }) => theme.palette.drawerText};
  min-width: 13.5rem !important;
  .ant-dropdown {
    &-menu {
      &-item {
        padding: 0.7rem 1.1rem;
        &-group {
          &-title {
            color: ${({ theme }) => theme.palette.text};
            font-size: 1.1rem;
            line-height: 1.5rem;
            border-bottom: 1px solid ${({ theme }) => theme.palette.borderNeutral};
            padding: 0.8rem 1.1rem 0.6rem 1.1rem;
          }
          &-list {
            margin: 0;
            padding: 0.2rem 0 0.1rem;
          }
        }
        &-selected {
          background-color: transparent;
          .ant-dropdown-menu-title-content {
            color: ${({ theme }) => theme.palette.button};
          }
          .icon.checked {
            opacity: 1;
            visibility: visible;
          }
        }
        &-active {
          background-color: transparent;
        }
      }
      &-title-content {
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.palette.tertiaryText};
        font-size: 1.2rem;
        line-height: 1.6rem;
      }
    }
    .icon {
      &.move-arrow {
        width: 0.8rem;
        height: 0.8rem;
        margin-right: 0.5rem;
      }
      &.checked {
        width: 0.9rem;
        height: 0.6rem;
        margin-left: 1rem;
        opacity: 0;
        visibility: hidden;
      }
    }
    ${({ $head }) =>
      $head &&
      css`
        &-menu {
          padding: 0;
          &-item {
            &-active {
              background-color: transparent;
            }
            &:first-child {
              background-color: ${({ theme }) => theme.palette.card};
              padding: 0 1.8rem 0 0 !important;
            }
            .dropdown-back-item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              background-color: ${({ theme }) => theme.palette.card};
              &-button {
                color: ${({ theme }) => theme.palette.text};
                font-size: 1.3rem;
                line-height: 1.5rem;
                padding: 0.9rem 0 1rem 1.8rem;
                width: 100%;
              }
              &-close {
                padding: 0.3rem;
              }
              .icon {
                &.close {
                  width: 1.2rem;
                  height: 1.2rem;
                  stroke: ${({ theme }) => theme.palette.text};
                  pointer-events: none;
                }
                &.back-arrow {
                  width: 0.8rem;
                  height: 1rem;
                  margin-right: 0.6rem;
                  stroke: ${({ theme }) => theme.palette.text};
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      `}
  }
`;

export const DropdownMenuItemContainer = styled.div``;
