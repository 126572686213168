import React, { memo, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { Icon } from '@atoms';

import {
  InputCommonContainer,
  InputContainer,
  InputElement,
  InputEnhancedContainer,
  InputIcon,
  InputLabel,
  InputPassword,
  InputTextArea,
  InputWrapper,
} from './styles';

const Input = memo(({ ...rest }) => {
  return <InputContainer {...rest} />;
});

const InputCommon = memo(({ ...rest }) => {
  const [inputFocused, setInputFocused] = useState(false);
  const [inputValue, setInputValue] = useState(false);
  const input = useRef();

  useEffect(() => {
    setInputValue(!!input.current.input.value);
  }, []);

  const handleFocus = () => {
    setInputFocused(true);
  };

  const handleBlur = () => {
    setInputFocused(false);
  };

  return (
    <InputWrapper className={cn(inputFocused && 'focused', inputValue && 'has-value', 'input-wrapper')}>
      <InputLabel className={cn('input-label')}>{rest.placeholder}</InputLabel>
      <InputCommonContainer ref={input} onFocus={handleFocus} onBlur={handleBlur} {...rest} />
      <InputIcon name="error" className={cn('error')} />
      <InputIcon name="checked" className={cn('success')} />
    </InputWrapper>
  );
});

const InputTextAreaCommon = memo(({ width, ...rest }) => {
  const [textareaFocused, setTextareaFocused] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState(false);
  const textArea = useRef();

  useEffect(() => {
    setTextAreaValue(!!textArea.current.resizableTextArea.textArea.value);
  }, []);

  const handleFocus = () => {
    setTextareaFocused(true);
  };

  const handleBlur = () => {
    setTextareaFocused(false);
  };

  return (
    <InputWrapper className={cn(textareaFocused && 'focused', textAreaValue && 'has-value', 'input-wrapper')}>
      <InputLabel className={cn('input-label')}>{rest.placeholder}</InputLabel>
      <Input.TextArea ref={textArea} $common onFocus={handleFocus} onBlur={handleBlur} {...rest} />
    </InputWrapper>
  );
});

const InputPasswordCommon = memo(({ width, ...rest }) => {
  const [passwordInputFocused, setPasswordInputFocused] = useState(false);
  const passwordInput = useRef();

  const setVisibilityIcon = (visible) => {
    return visible ? (
      <Icon name="pass-visible" className={cn('eye-icon', 'visible')} />
    ) : (
      <Icon name="pass-hidden" className={cn('eye-icon', 'hidden')} />
    );
  };

  const handleFocus = () => {
    setPasswordInputFocused(true);
  };

  const handleBlur = () => {
    setPasswordInputFocused(false);
  };

  return (
    <InputWrapper className={cn(passwordInputFocused && 'focused', 'input-wrapper')}>
      <InputLabel className={cn('input-label')}>{rest.placeholder}</InputLabel>
      <Input.Password
        ref={passwordInput}
        $common
        onFocus={handleFocus}
        onBlur={handleBlur}
        iconRender={(visible) => setVisibilityIcon(visible)}
        {...rest}
      />
      <InputIcon name="error" className={cn('error')} />
      <InputIcon name="checked" className={cn('success')} />
    </InputWrapper>
  );
});

const InputSecondary = memo(({ width, checked, ...rest }) => {
  return (
    <InputWrapper $width={width} className={cn(checked && 'checked', 'input-wrapper', 'secondary')}>
      <InputContainer {...rest} />
      <InputIcon name="error" className={cn('error')} />
      <InputIcon name="checked" className={cn('success')} />
    </InputWrapper>
  );
});

const InputTextAreaSecondary = memo(({ width, ...rest }) => {
  return (
    <InputWrapper $width={width} className={cn('input-wrapper', 'secondary')}>
      <Input.TextArea {...rest} />
    </InputWrapper>
  );
});

const InputEnhanced = memo(
  ({ suffix, after, before, afterPale, beforePale, beforeAbsolute, afterAbsolute, ...rest }) => {
    return (
      <InputEnhancedContainer>
        {before && (
          <InputElement className={cn('input-element-before', beforePale && 'pale', beforeAbsolute && 'absolute')}>
            {before}
          </InputElement>
        )}
        <InputContainer $general {...rest} />
        {suffix && <InputElement className={cn('input-element-suffix')}>{suffix}</InputElement>}
        {after && (
          <InputElement className={cn('input-element-after', afterPale && 'pale', afterAbsolute && 'absolute')}>
            {after}
          </InputElement>
        )}
      </InputEnhancedContainer>
    );
  },
);

Input.Common = InputCommon;
Input.Secondary = InputSecondary;
Input.TextArea = InputTextArea;
Input.TextArea.Common = InputTextAreaCommon;
Input.TextArea.Secondary = InputTextAreaSecondary;
Input.Password = InputPassword;
Input.Password.Common = InputPasswordCommon;
Input.Enhanced = InputEnhanced;

export default Input;
