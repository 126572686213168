const getProviderRequestStatus = (request) => {
  if (request?.iD_Status_Request === -1) {
    return 'canceled';
  }

  if (request?.iD_Status_Provider === -1) {
    return 'canceledByProvider';
  }

  if (request?.iD_Status_Provider === 3) {
    return 'archive';
  }

  if (request?.iD_Status_Answer === -3 || request?.iD_Status_Client === -1) {
    return 'clientCanceled';
  }

  if (request?.iD_Status_Answer === -1 && request?.iD_Status_Request !== 2) {
    return 'clientChosenOther';
  }

  if (request?.iD_Status_Answer === 1) {
    return 'clientChosen';
  }

  if (request?.iD_Status_Provider === 2) {
    return 'answered';
  }

  return 'notAnswered';
};

export default getProviderRequestStatus;
