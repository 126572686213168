import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  drawerVisibility: false,
};

export const sider = createSlice({
  name: 'sider',
  initialState,
  reducers: {
    setDrawerVisibility: (state, { payload }) => {
      state.drawerVisibility = payload;
    },
  },
});

export const { setDrawerVisibility } = sider.actions;

export default sider.reducer;
