import React from 'react';

import { CheckboxContainer, CheckboxGroup } from './styles';

const Checkbox = ({ children, ...rest }) => {
  return <CheckboxContainer {...rest}>{children}</CheckboxContainer>;
};

Checkbox.Group = CheckboxGroup;

export default Checkbox;
