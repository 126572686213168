import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const RegisterContainer = styled.div`
  max-width: 36.2rem;
  width: 100%;
  margin-right: 30%;
`;

export const RegisterBlock = styled.div`
  margin-bottom: ${({ $margin }) => ($margin ? `${$margin}rem` : '0')};
  ${({ $rel }) =>
    $rel &&
    css`
      position: relative;
    `}
`;

export const RegisterText = styled.span`
  display: inline-block;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.palette.tertiaryText};
  ${({ $secondary }) =>
    $secondary &&
    css`
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: ${({ theme }) => theme.palette.quaternaryText};
      a {
        color: ${({ theme }) => theme.palette.inputLabel};
        &:hover {
          text-decoration: underline;
          color: ${({ theme }) => theme.palette.inputLabel};
        }
      }
    `}

  ${({ $link }) =>
    $link &&
    css`
      color: ${({ theme }) => theme.palette.inputLabel};
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    `}

  ${({ $error }) =>
    $error &&
    css`
      display: block;
      margin-bottom: 1rem;
      text-align: center;
      color: ${({ theme }) => theme.palette.inputError};
    `}

  ${({ $absolute }) =>
    $absolute &&
    css`
      position: absolute;
      top: 100%;
      left: 0;
    `}

  ${({ $mt }) =>
    $mt &&
    css`
      margin-top: ${$mt}rem;
    `}
`;

export const RegisterTitle = styled.h1`
  font-size: 3.6rem;
  line-height: 4.4rem;
  color: ${({ theme }) => theme.palette.text};
  margin: 0 0 1.2rem 0;
`;

export const RegisterLink = styled(NavLink)`
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.palette.inputLabel};
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.inputLabel};
  }
`;
