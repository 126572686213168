import React, { memo } from 'react';

import spinner from '@assets/images/spinner.svg';

import { LoaderContainer, LoaderImage } from './styles';

const Loader = memo(({ ...rest }) => {
  return (
    <LoaderContainer {...rest}>
      <LoaderImage src={spinner} alt="" />
    </LoaderContainer>
  );
});

export default Loader;
