import styled, { createGlobalStyle } from 'styled-components';
import { Layout as AntLayout } from 'antd';

import 'normalize.css';
import breakpoint from '@constants/media';

export const GlobalStyles = createGlobalStyle`
  html {
    --antd-wave-shadow-color: ${({ theme }) => theme.palette.accent};
    font-size: 10px;
    @media ${breakpoint.md} {
      font-size: 9px
    }
    @media ${breakpoint.sm} {
      font-size: 8px
    }
    @media ${breakpoint.xxs} {
      font-size: 10px
    }
  }

  body {
    height: 100%;
    font-family: ${({ theme }) => theme.fonts.primary}, Helvetica, Arial, sans-serif;
    font-size: 1.8rem;
    background: ${({ theme }) => theme.palette.body};
    color: ${({ theme }) => theme.palette.text};
    margin: 0;
    padding: 0;
  }

  #root {
    height: 100%;
  }

  fieldset {
    padding: 0;
    .ant-select, .ant-radio-wrapper, .ant-checkbox-wrapper {
      pointer-events: none;
    }
  }

  .ant-tooltip {
    filter: drop-shadow(0px 12px 12px rgba(126, 126, 133, 0.24));
    &-inner {
      background-color: ${({ theme }) => theme.palette.sider};
      border-radius: 5px;
      padding: 0.8rem 1.1rem 1.1rem;
      font-size: 1.1rem;
      line-height: 1.3rem;
    }
    &-arrow {
      &-content {
        --antd-arrow-background-color: ${({ theme }) => theme.palette.sider};
      }
    }
    &.small-tooltip {
      pointer-events: none;
      .ant-tooltip-inner {
        padding: 0.8rem 1.6rem 0.8rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
      }
    }
    &.duplicate {
      .ant-tooltip {
        &-inner {
          background-color: ${({ theme }) => theme.palette.siderSecondary};
          color: ${({ theme }) => theme.palette.text};
        }
        &-arrow {
          &-content {
            --antd-arrow-background-color: ${({ theme }) => theme.palette.siderSecondary};
          }
        }
      }
    }
    &.cancel {
      .ant-tooltip {
        &-inner {
          background-color: ${({ theme }) => theme.palette.statusCanceledSecondary};
        }
        &-arrow {
          &-content {
            --antd-arrow-background-color: ${({ theme }) => theme.palette.statusCanceledSecondary};
          }
        }
      }
    }
    &.delete {
      .ant-tooltip {
        &-inner {
          background-color: ${({ theme }) => theme.palette.actionDelete};
        }
        &-arrow {
          &-content {
            --antd-arrow-background-color: ${({ theme }) => theme.palette.actionDelete};
          }
        }
      }
    }
    &.send {
      .ant-tooltip {
        &-inner {
          background-color: ${({ theme }) => theme.palette.actionSend};
        }
        &-arrow {
          &-content {
            --antd-arrow-background-color: ${({ theme }) => theme.palette.actionSend};
          }
        }
      }
    }
    &.archive {
      .ant-tooltip {
        &-inner {
          background-color: ${({ theme }) => theme.palette.actionArchive};
        }
        &-arrow {
          &-content {
            --antd-arrow-background-color: ${({ theme }) => theme.palette.actionArchive};
          }
        }
      }
    }
    &.accept {
      .ant-tooltip {
        &-inner {
          background-color: ${({ theme }) => theme.palette.providerChosen};
        }
        &-arrow {
          &-content {
            --antd-arrow-background-color: ${({ theme }) => theme.palette.providerChosen};
          }
        }
      }
    }
  }
  .ant-select-dropdown {
    &.select-dropdown {
      border-radius: 4px;
      border: 1px solid ${({ theme }) => theme.palette.drawerText};
      box-shadow: 0px 12px 12px rgba(126, 126, 133, 0.24);
      background-color: ${({ theme }) => theme.palette.bodySecondary};
      .icon {
        &.checked {
          stroke: ${({ theme }) => theme.palette.button};
          width: 0.9rem;
          height: 0.6rem;
          margin-left: 1rem;
          opacity: 0;
          visibility: hidden;
        }
      }
      .ant-select {
        &-item {
          &-option {
            padding: 0.7rem 1.1rem;
            &-active {
              background-color: transparent;
            }
            &-selected {
              background-color: transparent;
              .checked {
                opacity: 1;
                visibility: visible;
              }
            }
            &-content {
              display: flex;
              align-items: center;
              color: ${({ theme }) => theme.palette.tertiaryText};
              font-size: 1.2rem;
              line-height: 1.6rem;
            }
          }
          &-group {
            color: ${({ theme }) => theme.palette.text};
            font-size: 1.1rem;
            line-height: 1.5rem;
            border-bottom: 1px solid ${({ theme }) => theme.palette.borderNeutral};
            padding: 0.8rem 1.1rem 0.6rem 1.1rem;
          }
        }
      }
    }
    &.request-select-dropdown {
      min-width: 18.5rem !important;
      width: 18.5rem !important;
      padding: 0;
      .icon {
        &.checked {
          stroke: ${({ theme }) => theme.palette.sider};
        }
        &.select-list-style {
          fill: ${({ theme }) => theme.palette.statusSent};
          width: 0.6rem;
          height: 0.6rem;
          margin-right: 1rem;
        }
      }
      .ant-select {
        &-item {
          &-option {
            &.sent {
              .ant-select-item-option-content {
                color: ${({ theme }) => theme.palette.statusSent};
              }
            }
            &.draft {
              .ant-select-item-option-content {
                color: ${({ theme }) => theme.palette.secondaryText};
              }
            }
            &.canceled {
              .ant-select-item-option-content {
                color: ${({ theme }) => theme.palette.statusCanceled};
              }
            }
            &.archive {
              .ant-select-item-option-content {
                color: ${({ theme }) => theme.palette.statusArchive};
              }
            }
            &.hasProvider {
              .ant-select-item-option-content {
                color: ${({ theme }) => theme.palette.providerChosen};
              }
            }
            &-selected {
              .ant-select-item-option-content {
                font-weight: 500;
              }
            }
          }
        }
      }
    }
    &.status-select-dropdown {
      min-width: 20rem !important;
      width: 20rem !important;
      padding: 0;
      .icon {
        &.move-arrow {
          width: 0.8rem;
          height: 0.8rem;
          margin-right: 0.5rem;
        }
      }
      .ant-select {
        &-item {
          &-option {
            &-selected {
              .ant-select-item-option-content {
                color: ${({ theme }) => theme.palette.button};
                font-weight: 500;
              }
            }
          }
        }
      }
    }
    &.search-select-dropdown {
      top: 9.2rem !important;
      padding: 0;
      .ant-select {
        &-item {
          &-option {
            padding: 1rem 1.8rem;
            &-selected {
              .ant-select-item-option-content {
                color: ${({ theme }) => theme.palette.button};
                font-weight: 500;
              }
            }
            &-active {
              background-color: ${({ theme }) => theme.palette.input};
              .ant-select-item-option-content {
                h3 {
                  color: ${({ theme }) => theme.palette.button};
                }
                p {
                  color: ${({ theme }) => theme.palette.text};
                }
              }
              
            }
            &-content {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              div {
                width: 100%;
                max-width: 100%;
              }
              h3 {
                color: ${({ theme }) => theme.palette.text};
                font-size: 1.4rem;
                line-height: 2rem;
                margin: 0rem 0rem 0.6rem 0rem;
                transition: 0.2s;
              }
              span {
                color: ${({ theme }) => theme.palette.text};
                font-size: 1.2rem;
                line-height: 1.6rem;
                margin-bottom: 0.6rem;
                transition: 0.2s;
              }
              p {
                width: 100%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                margin: 0;
                color: ${({ theme }) => theme.palette.secondaryText};
                transition: 0.2s;
              }
              a {
                display: block;
                width: 100%;
              }
            }
          }
        }
      }
    }
    &.object-status-select-dropdown {
      .ant-select {
        &-item {
          padding: 0.7rem 2rem 0.7rem 1.4rem;
          &-option {
            &-active {
              background-color: ${({ theme }) => theme.palette.input};
              .icon {
                &.prefix {
                  stroke: ${({ theme }) => theme.palette.button};
                }
              }
              .ant-select-item-option-content {
                color: ${({ theme }) => theme.palette.button};
              }
            }
            &-selected {
              font-weight: 500;
              .icon {
                &.prefix {
                  stroke: ${({ theme }) => theme.palette.button};
                }
              }
              .ant-select-item-option-content {
                color: ${({ theme }) => theme.palette.button};
              }
            }
            &-content {
              font-size: 1.4rem;
              line-height: 2rem;
              color: ${({ theme }) => theme.palette.secondaryText};
              >div {
                display: flex;
                align-items: center;
                position: relative;
                width: 100%;
              }
            }
          }
        }
      }
      .icon {
        &.prefix {
          stroke: ${({ theme }) => theme.palette.secondaryText};
          width: 1.5rem;
          height: 1.5rem;
          margin-right: 1.3rem;
        }
        &.checked {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }
    }
    &.request-create-info-select-dropdown {
      width: 25rem !important;
      .ant-select {
        &-item {
          padding: 0.7rem 2rem 0.7rem 1.4rem;
          &-option {
            &-content {
              color: ${({ theme }) => theme.palette.text};
            }
          }
        }
      }
    }
  }
  .ant-dropdown {
    &.dropdown {
      background-color: ${({ theme }) => theme.palette.bodySecondary};
      .ant-dropdown {
        &-menu {
          overflow: hidden;
          &-item {
            padding: 1.1rem 1.8rem;
          }
          &-title-content {
            color: ${({ theme }) => theme.palette.text};
            font-size: 1.3rem;
            line-height: 1.5rem;
          }
        }
      }
    }
    &.inner-dropdown {
      width: 24.5rem;
    }
    &.filters-dropdown {
      width: 24.5rem;
    }
    &.move-dropdown {
      width: 13.5rem !important;
      .ant-dropdown-menu {
        .icon {
          &.move-arrow {
            width: 0.8rem;
            height: 0.8rem;
            margin-right: 0.5rem;
          }
          &.checked {
            width: 0.9rem;
            height: 0.6rem;
            margin-left: 1rem;
            opacity: 0;
            visibility: hidden;
            stroke: ${({ theme }) => theme.palette.button};
          }
        }
        &-item {
          padding: 0.7rem 1.1rem;
          &-group {
            &-title {
              color: ${({ theme }) => theme.palette.text};
              font-size: 1.1rem;
              line-height: 1.5rem;
              border-bottom: 1px solid ${({ theme }) => theme.palette.borderNeutral};
              padding: 0.8rem 1.1rem 0.6rem 1.1rem;
            }
          }
          &-selected {
            background-color: transparent;
            .ant-dropdown-menu-title-content {
              color: ${({ theme }) => theme.palette.button};
            }
            .icon {
              &.checked {
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
        &-title-content {
          display: flex;
          align-items: center;
          color: ${({ theme }) => theme.palette.tertiaryText};
          font-size: 1.2rem;
          line-height: 1.6rem;
        }
      }
    }
    &.category-item-dropdown {
      /* width: 18.5rem !important; */
      .ant-dropdown-menu {
        padding: 0.2rem 0;
        .icon {
          width: 2rem;
          height: 2rem;
          stroke: ${({ theme }) => theme.palette.text};
          margin-right: 1rem;
        }
        &-item {
          padding: 0.7rem 1rem;
          &-active {
            background-color: ${({ theme }) => theme.palette.input};
            .ant-dropdown-menu-title-content {
              color: ${({ theme }) => theme.palette.button};
              .icon {
                stroke: ${({ theme }) => theme.palette.button};
              }
            }
          }
        }
        &-title-content {
          display: flex;
          align-items: center;
          color: ${({ theme }) => theme.palette.tertiaryText};
          font-size: 1.2rem;
          line-height: 1.6rem;
          transition: 0.2s;
        }
      }
    }
  .ant-picker {
    &-cell {
      color: ${({ theme }) => theme.palette.secondaryText};
      &-in-view {
        color: ${({ theme }) => theme.palette.text};
        &.ant-picker-cell {
          &-today {
            .ant-picker-cell-inner {
              &::before {
                display: none;
              }
            }
          }
          &-selected {
            .ant-picker-cell-inner {
              background-color: ${({ theme }) => theme.palette.statusCanceledSecondary};
            }
          }
        }
      }
    }
    &-header {
      padding: 0 1.8rem;
      &-view {
        pointer-events: none;
        display: flex;
      }
      &-super {
        &-prev-btn, &-next-btn {
          display: none;
        }
      }
      &-prev-btn {
        order: 2;
      }
      &-next-btn {
        order: 3;
      }
    }
    &-content {
      thead {
        th {
          color: ${({ theme }) => theme.palette.secondaryText};
        }
      }
    }
  }
  }
  .ant-table {
    &-filter-dropdown {
      min-width: auto;
      width: max-content;
    }
  }
  .ant-form {
    &-item {
      &-has-success {
        .input {
          &-label {
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
            color: ${({ theme }) => theme.palette.cardText} !important;
          }
        }
        .icon.success {
          opacity: 1;
          visibility: visible;
        }
      }
      &-has-error {
        .input {
          &-label {
            color: ${({ theme }) => theme.palette.inputError} !important;
          }
        }
        .icon.error {
          opacity: 1;
          visibility: visible;
        }
      }
      &-explain {
        padding-left: 1.1rem;
        font-size: 1.2rem;
        line-height: 1.6rem;
        &-error {
          color: ${({ theme }) => theme.palette.inputError};
        }
      }
      &-label {
        label {
          font-size: 1.2rem;
          line-height: 2rem;
          color: ${({ theme }) => theme.palette.text};
        }
        
      }
    }
  }
  .ant-layout-sider {
    width: 23rem !important;
    flex: 0 0 23rem !important;
    min-width: 23rem !important;
    max-width: 23rem !important;
  }
  .ant-tree-select-dropdown {
    background-color: ${({ theme }) => theme.palette.bodySecondary};
    border: 1px solid ${({ theme }) => theme.palette.drawerText};
    box-shadow: 0px 12px 12px rgba(126, 126, 133, 0.24);
    padding: 0.8rem 0;
    border-radius: 4px;

    .ant-select-tree {
      &-switcher-icon {
        svg {
          fill: ${({ theme }) => theme.palette.text};
        }
      }
      &-title {
        font-size: 1.4rem;
        line-height: 2rem;
        color: ${({ theme }) => theme.palette.text};
        transition: .2s;
      }
      &-treenode {
        transition: .2s;
        &-selected {
          background-color: ${({ theme }) => theme.palette.input};
          .ant-select-tree {
            &-title {
              color: ${({ theme }) => theme.palette.button};
            }
            &-switcher-icon {
              svg {
                fill: ${({ theme }) => theme.palette.button};
              }
            }
          }
          .ant-select-tree-node-content-wrapper {
            background-color: transparent;
          }
        }
        &:hover {
          background-color: ${({ theme }) => theme.palette.input};
          .ant-select-tree {
            &-title {
              color: ${({ theme }) => theme.palette.button};
            }
            &-switcher-icon {
              svg {
                fill: ${({ theme }) => theme.palette.button};
              }
            }
          }
        }
      }
      &-node-content-wrapper {
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
  .custom-message {
    .ant-message-notice-content {
      max-width: 60rem;
    }
  }
  .recaptcha {
    margin-bottom: 2rem;
    > div {
      display: flex;
      justify-content: center;
    }
  }
`;

export const LayoutContainer = styled(AntLayout)`
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  &.ant-layout {
    background-color: transparent;
  }
`;

export const ContentContainer = styled.div`
  /* overflow: hidden; */
  width: 100%;
  min-height: 100%;
  margin: 0;
  overflow-x: hidden;
  /* overflow-y: scroll; */
  .ant-layout-content {
    height: 100%;
  }
`;
