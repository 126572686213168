import React, { Fragment, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

import { Button, Checkbox, Form, Icon, Input, Radio, Tag } from '@atoms';
import { Select } from '@molecules';

import { QuestionnaireHeatPointContainer } from './styles';

const powerOptions = [
  {
    key: '1100',
    label: '1x 100%',
    content: <Fragment>1x 100%</Fragment>,
  },
  {
    key: '2100',
    label: '2x 100%',
    content: <Fragment>2x 100%</Fragment>,
  },
  {
    key: '275',
    label: '2x 75%',
    content: <Fragment>2x 75%</Fragment>,
  },
  {
    key: '250',
    label: '2x 50%',
    content: <Fragment>2x 50%</Fragment>,
  },
];

const pumpPowerOptions = [
  {
    key: '1100',
    label: '1x 100%',
    content: <Fragment>1x 100%</Fragment>,
  },
  {
    key: '2100',
    label: '2x 100%',
    content: <Fragment>2x 100%</Fragment>,
  },
  {
    key: '210',
    label: '2 x 100% : насос-дубль',
    content: <Fragment>2 x 100% : насос-дубль</Fragment>,
  },
  {
    key: '350',
    label: '3x 50%',
    content: <Fragment>3x 50%</Fragment>,
  },
];

const frequencyDriveOptions = [
  {
    key: '1',
    label: 'Да',
    content: <Fragment>Да</Fragment>,
  },
  {
    key: '0',
    label: 'Нет',
    content: <Fragment>Нет</Fragment>,
  },
];

const questionnaireFields = {};

const QuestionnaireHeatPoint = ({ values, ...rest }) => {
  const [heatingChosen, setHeatingChosen] = useState(false);
  const [hotWaterChosen, setHotWaterChosen] = useState(false);
  const [ventilationChosen, setVentilationChosen] = useState(false);

  useEffect(() => {
    values?.forEach((field) => {
      Object.assign(questionnaireFields, { [field.name]: field.value });
    });
  }, [values]);

  useEffect(() => {
    if (values) {
      setHeatingChosen(!!questionnaireFields?.heating);
      setHotWaterChosen(!!questionnaireFields?.hot_water);
      setVentilationChosen(!!questionnaireFields?.ventilation);
    }
  }, [values]);

  const handleCheckboxClick = (type) => {
    switch (type) {
      case 'heating':
        if (heatingChosen) {
          setHeatingChosen(false);
        } else {
          setHeatingChosen(true);
        }
        break;
      case 'hot-water':
        if (hotWaterChosen) {
          setHotWaterChosen(false);
        } else {
          setHotWaterChosen(true);
        }
        break;
      case 'ventilation':
        if (ventilationChosen) {
          setVentilationChosen(false);
        } else {
          setVentilationChosen(true);
        }
        break;
      default:
        break;
    }
  };

  return (
    <QuestionnaireHeatPointContainer>
      <Form initialValues={questionnaireFields} className={cn('questionnaire', 'questionnaire-heat-point')} {...rest}>
        {/* Теплообменники */}
        <Form.Block className={cn('form-block')}>
          <Form.Head className={cn('grid', 'title-padding')}>
            <Form.Title $bold className={cn('form-title')}>
              Теплообменники:
            </Form.Title>
            <Form.Element className={cn(heatingChosen && 'active')}>
              <Form.Item name="heating" valuePropName="checked">
                <Checkbox checked={heatingChosen} onClick={() => handleCheckboxClick('heating')} $dark>
                  Отопление
                </Checkbox>
              </Form.Item>
            </Form.Element>
            <Form.Element className={cn(hotWaterChosen && 'active')}>
              <Form.Item name="hot_water" valuePropName="checked">
                <Checkbox checked={hotWaterChosen} onClick={() => handleCheckboxClick('hot-water')} $dark>
                  ГВС (гор. вода)
                </Checkbox>
              </Form.Item>
            </Form.Element>
            <Form.Element className={cn(ventilationChosen && 'active')}>
              <Form.Item name="ventilation" valuePropName="checked">
                <Checkbox checked={ventilationChosen} onClick={() => handleCheckboxClick('ventilation')} $dark>
                  Вентиляция
                </Checkbox>
              </Form.Item>
            </Form.Element>
          </Form.Head>

          <Form.Row $bordered $padding="2rem 0rem" className={cn('grid')}>
            <Form.Title className={cn('form-title')} $large>
              Мощность<Form.Subtitle $mt="0.7">(макс.нагрузка )</Form.Subtitle>
            </Form.Title>
            <Form.Element disabled={!heatingChosen}>
              <Form.Subtitle $secondary $mb="0.4">
                кВт
              </Form.Subtitle>
              <Form.Item
                name="heating_power"
                rules={[
                  {
                    required: heatingChosen,
                    message: '',
                  },
                ]}
              >
                <Select
                  $common
                  $noLabel
                  suffixIcon={<Icon name="arrow-2" className="select-arrow" />}
                  dropdownClassName={cn('select-dropdown', 'object-status-select-dropdown')}
                  items={powerOptions}
                  optionLabelProp="label"
                  placeholder="Выбрать"
                />
              </Form.Item>
            </Form.Element>
            <Form.Element disabled={!hotWaterChosen}>
              <Form.Subtitle $secondary $mb="0.4">
                кВт
              </Form.Subtitle>
              <Form.Item
                name="hot_water_power"
                rules={[
                  {
                    required: hotWaterChosen,
                    message: '',
                  },
                ]}
              >
                <Select
                  $common
                  $noLabel
                  suffixIcon={<Icon name="arrow-2" className="select-arrow" />}
                  dropdownClassName={cn('select-dropdown', 'object-status-select-dropdown')}
                  items={powerOptions}
                  optionLabelProp="label"
                  placeholder="Выбрать"
                />
              </Form.Item>
            </Form.Element>
            <Form.Element disabled={!ventilationChosen}>
              <Form.Subtitle $secondary $mb="0.4">
                кВт
              </Form.Subtitle>
              <Form.Item
                name="ventilation_power"
                rules={[
                  {
                    required: ventilationChosen,
                    message: '',
                  },
                ]}
              >
                <Select
                  $common
                  $noLabel
                  suffixIcon={<Icon name="arrow-2" className="select-arrow" />}
                  dropdownClassName={cn('select-dropdown', 'object-status-select-dropdown')}
                  items={powerOptions}
                  optionLabelProp="label"
                  placeholder="Выбрать"
                />
              </Form.Item>
            </Form.Element>
          </Form.Row>

          <Form.Row $padding="2.4rem 0rem 1.2rem" className={cn('grid')}>
            <Form.Title className={cn('form-title')} $large>
              Температура <br /> греющей среды
            </Form.Title>
            <Form.Element $spaceBetween $gap="1" disabled={!heatingChosen}>
              <Form.Element.Wrap>
                <Form.Subtitle $secondary $mb="0.4">
                  Вход
                </Form.Subtitle>
                <Form.Item
                  name="heating_object_temperature_in"
                  rules={[
                    {
                      required: heatingChosen,
                      message: '',
                    },
                  ]}
                >
                  <Input.Enhanced type="number" suffix="° С" />
                </Form.Item>
              </Form.Element.Wrap>
              <Form.Element.Wrap>
                <Form.Subtitle $secondary $mb="0.4">
                  Выход
                </Form.Subtitle>
                <Form.Item
                  name="heating_object_temperature_out"
                  rules={[
                    {
                      required: heatingChosen,
                      message: '',
                    },
                  ]}
                >
                  <Input.Enhanced type="number" suffix="° С" />
                </Form.Item>
              </Form.Element.Wrap>
            </Form.Element>
            <Form.Element $spaceBetween $gap="1" disabled={!hotWaterChosen}>
              <Form.Element.Wrap>
                <Form.Subtitle $secondary $mb="0.4">
                  Вход
                </Form.Subtitle>
                <Form.Item
                  name="hot_water_object_temperature_in"
                  rules={[
                    {
                      required: hotWaterChosen,
                      message: '',
                    },
                  ]}
                >
                  <Input.Enhanced type="number" suffix="° С" />
                </Form.Item>
              </Form.Element.Wrap>
              <Form.Element.Wrap>
                <Form.Subtitle $secondary $mb="0.4">
                  Выход
                </Form.Subtitle>
                <Form.Item
                  name="hot_water_object_temperature_out"
                  rules={[
                    {
                      required: hotWaterChosen,
                      message: '',
                    },
                  ]}
                >
                  <Input.Enhanced type="number" suffix="° С" />
                </Form.Item>
              </Form.Element.Wrap>
            </Form.Element>
            <Form.Element $spaceBetween $gap="1" disabled={!ventilationChosen}>
              <Form.Element.Wrap>
                <Form.Subtitle $secondary $mb="0.4">
                  Вход
                </Form.Subtitle>
                <Form.Item
                  name="ventilation_object_temperature_in"
                  rules={[
                    {
                      required: ventilationChosen,
                      message: '',
                    },
                  ]}
                >
                  <Input.Enhanced type="number" suffix="° С" />
                </Form.Item>
              </Form.Element.Wrap>
              <Form.Element.Wrap>
                <Form.Subtitle $secondary $mb="0.4">
                  Выход
                </Form.Subtitle>
                <Form.Item
                  name="ventilation_object_temperature_out"
                  rules={[
                    {
                      required: ventilationChosen,
                      message: '',
                    },
                  ]}
                >
                  <Input.Enhanced type="number" suffix="° С" />
                </Form.Item>
              </Form.Element.Wrap>
            </Form.Element>
          </Form.Row>

          <Form.Row $bordered $padding="1.2rem 0rem 2rem" className={cn('grid')}>
            <Form.Title className={cn('form-title')} $large>
              Температура нагреваемой среды
            </Form.Title>
            <Form.Element.Block>
              <Form.Element $spaceBetween $gap="1" $mb="1" disabled={!heatingChosen}>
                <Form.Element.Wrap>
                  <Form.Subtitle $secondary $mb="0.4">
                    Вход
                  </Form.Subtitle>
                  <Form.Item
                    name="heating_subject_temperature_in"
                    rules={[
                      {
                        required: heatingChosen,
                        message: '',
                      },
                    ]}
                  >
                    <Input.Enhanced type="number" suffix="° С" />
                  </Form.Item>
                </Form.Element.Wrap>
                <Form.Element.Wrap>
                  <Form.Subtitle $secondary $mb="0.4">
                    Выход
                  </Form.Subtitle>
                  <Form.Item
                    name="heating_subject_temperature_out"
                    rules={[
                      {
                        required: heatingChosen,
                        message: '',
                      },
                    ]}
                  >
                    <Input.Enhanced type="number" suffix="° С" />
                  </Form.Item>
                </Form.Element.Wrap>
              </Form.Element>
              <Form.Element $mb="2.4" disabled={!heatingChosen}>
                <Tag>Зимнего режима</Tag>
              </Form.Element>
              <Form.Element disabled={!heatingChosen}>
                <Form.Item
                  name="heating_type"
                  rules={[
                    {
                      required: heatingChosen,
                      message: '',
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio $checkbox value="soldered">
                      паяный
                    </Radio>
                    <Radio $checkbox value="demountable">
                      разборный
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Form.Element>
            </Form.Element.Block>
            <Form.Element.Block>
              <Form.Element $spaceBetween $gap="1" $mb="1" disabled={!hotWaterChosen}>
                <Form.Element.Wrap>
                  <Form.Subtitle $secondary $mb="0.4">
                    Вход
                  </Form.Subtitle>
                  <Form.Item
                    name="hot_water_subject_temperature_in"
                    rules={[
                      {
                        required: hotWaterChosen,
                        message: '',
                      },
                    ]}
                  >
                    <Input.Enhanced type="number" suffix="° С" />
                  </Form.Item>
                </Form.Element.Wrap>
                <Form.Element.Wrap>
                  <Form.Subtitle $secondary $mb="0.4">
                    Выход
                  </Form.Subtitle>
                  <Form.Item
                    name="hot_water_subject_temperature_out"
                    rules={[
                      {
                        required: hotWaterChosen,
                        message: '',
                      },
                    ]}
                  >
                    <Input.Enhanced type="number" suffix="° С" />
                  </Form.Item>
                </Form.Element.Wrap>
              </Form.Element>
              <Form.Element $mb="2.4" disabled={!hotWaterChosen}>
                <Tag $green>Летнего режима</Tag>
              </Form.Element>
              <Form.Element $mb="2.4" disabled={!hotWaterChosen}>
                <Form.Item
                  name="hot_water_type_secondary"
                  rules={[
                    {
                      required: hotWaterChosen,
                      message: '',
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio $checkbox value="first_stage">
                      1-ступ.ТО ГВС
                    </Radio>
                    <Radio $checkbox value="second_stage">
                      {' '}
                      2-ступ.ТО ГВС
                    </Radio>
                    <Radio $checkbox value="monoblock">
                      моноблок
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Form.Element>
              <Form.Element disabled={!hotWaterChosen}>
                <Form.Item
                  name="hot_water_type"
                  rules={[
                    {
                      required: hotWaterChosen,
                      message: '',
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio $checkbox value="soldered">
                      паяный
                    </Radio>
                    <Radio $checkbox value="demountable">
                      разборный
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Form.Element>
            </Form.Element.Block>
            <Form.Element.Block>
              <Form.Element $spaceBetween $gap="1" $mb="1" disabled={!ventilationChosen}>
                <Form.Element.Wrap>
                  <Form.Subtitle $secondary $mb="0.4">
                    Вход
                  </Form.Subtitle>
                  <Form.Item
                    name="ventilation_subject_temperature_in"
                    rules={[
                      {
                        required: ventilationChosen,
                        message: '',
                      },
                    ]}
                  >
                    <Input.Enhanced type="number" suffix="° С" />
                  </Form.Item>
                </Form.Element.Wrap>
                <Form.Element.Wrap>
                  <Form.Subtitle $secondary $mb="0.4">
                    Выход
                  </Form.Subtitle>
                  <Form.Item
                    name="ventilation_subject_temperature_out"
                    rules={[
                      {
                        required: ventilationChosen,
                        message: '',
                      },
                    ]}
                  >
                    <Input.Enhanced type="number" suffix="° С" />
                  </Form.Item>
                </Form.Element.Wrap>
              </Form.Element>
              <Form.Element $mb="2.4" disabled={!ventilationChosen}>
                <Tag>Зимнего режима</Tag>
              </Form.Element>
              <Form.Element disabled={!ventilationChosen}>
                <Form.Item
                  name="ventilation_type"
                  rules={[
                    {
                      required: ventilationChosen,
                      message: '',
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio $checkbox value="soldered">
                      паяный
                    </Radio>
                    <Radio $checkbox value="demountable">
                      разборный
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Form.Element>
            </Form.Element.Block>
          </Form.Row>

          <Form.Row $padding="3rem 0rem 1rem" className={cn('grid')}>
            <Form.Title className={cn('form-title')} $large>
              Теплоизоляция теплообменника
            </Form.Title>
            <Form.Element disabled={!heatingChosen}>
              <Form.Item name="heating_thermal_insulation" valuePropName="checked">
                <Checkbox $dark />
              </Form.Item>
            </Form.Element>
            <Form.Element disabled={!hotWaterChosen}>
              <Form.Item name="hot_water_thermal_insulation" valuePropName="checked">
                <Checkbox $dark />
              </Form.Item>
            </Form.Element>
            <Form.Element disabled={!ventilationChosen}>
              <Form.Item name="ventilation_thermal_insulation" valuePropName="checked">
                <Checkbox $dark />
              </Form.Item>
            </Form.Element>
          </Form.Row>

          <Form.Row $padding="1rem 0rem 0.7rem" className={cn('grid')}>
            <Form.Title className={cn('form-title')} $large>
              Теплообменники по стандарту AHRI
              <Form.Subtitle $mt="0.7">(с гарантией 3 года с момента поставки)</Form.Subtitle>
            </Form.Title>
            <Form.Element $center disabled={!heatingChosen}>
              <Form.Item name="heating_ahri" valuePropName="checked">
                <Checkbox $dark />
              </Form.Item>
            </Form.Element>
            <Form.Element $center disabled={!hotWaterChosen}>
              <Form.Item name="hot_water_ahri" valuePropName="checked">
                <Checkbox $dark />
              </Form.Item>
            </Form.Element>
            <Form.Element $center disabled={!ventilationChosen}>
              <Form.Item name="ventilation_ahri" valuePropName="checked">
                <Checkbox $dark />
              </Form.Item>
            </Form.Element>
          </Form.Row>

          <Form.Row $padding="0.7rem 0rem 3.2rem" className={cn('grid')}>
            <Form.Title className={cn('form-title')} $large>
              Давление хол.воды на входе
            </Form.Title>
            <Form.Element />
            <Form.Element disabled={!hotWaterChosen}>
              <Form.Item
                name="hot_water_pressure"
                rules={[
                  {
                    required: hotWaterChosen,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced after="кгс/см2" />
              </Form.Item>
            </Form.Element>
          </Form.Row>
        </Form.Block>

        {/* Регулирующая автоматика */}
        <Form.Block className={cn('form-block')}>
          <Form.Head className={cn('title-padding')}>
            <Form.Title $bold className={cn('form-title')}>
              Регулирующая автоматика:
            </Form.Title>
          </Form.Head>

          <Form.Row $padding="2.2rem 0 1rem" className={cn('grid-5')}>
            <Form.Element $center>
              <Form.Title className={cn('form-title')} $large>
                Фирма-изготовитель
              </Form.Title>
            </Form.Element>
            <Form.Element>
              <Form.Item
                name="manufacturer"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced
                  $width="9.2"
                  before="Вписать"
                  after="предпочтительный изготовитель"
                  afterPale
                  beforeAbsolute
                />
              </Form.Item>
            </Form.Element>
          </Form.Row>

          <Form.Row $padding="1rem 0" className={cn('grid-5')}>
            <Form.Element $center>
              <Form.Title className={cn('form-title')} $large>
                Давление в теплосети
              </Form.Title>
            </Form.Element>
            <Form.Element>
              <Form.Item
                name="heating_network_pressure"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="9.2" after="кгс/см2" before="P1" beforeAbsolute />
              </Form.Item>
            </Form.Element>
          </Form.Row>

          <Form.Row $padding="1rem 0" className={cn('grid-5')}>
            <Form.Element $center>
              <Form.Title className={cn('form-title')} $large>
                Прямая, обратная труба
              </Form.Title>
            </Form.Element>
            <Form.Element>
              <Form.Item
                name="straight_pipe"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="9.2" after="кгс/см2" before="ΔΡ" beforeAbsolute />
              </Form.Item>
            </Form.Element>
          </Form.Row>

          <Form.Row $padding="1rem 0 2.6rem" className={cn('grid-5')}>
            <Form.Element $center>
              <Form.Title className={cn('form-title')} $large>
                Или перепад, кгс/см2
              </Form.Title>
            </Form.Element>
            <Form.Element $spaceBetween>
              <Form.Item
                name="difference"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="9.2" after="кгс/см2" before="ΔΡ" beforeAbsolute />
              </Form.Item>
              <Form.Item name="difference_other">
                <Input.Enhanced $width="9.2" after="Другая" />
              </Form.Item>
            </Form.Element>
          </Form.Row>

          <Form.Row $padding="0 0 3rem" className={cn('grid-5')}>
            <Form.Title className={cn('form-title')} $large />
            <Form.Element>
              <Form.Item
                name="control_valves"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="9.2" />
              </Form.Item>
              <Form.Subtitle $secondary $mt="1">
                3-х ходовые регулирующие клапаны с байпасом <br /> (при теплоснабжении от локальной котельной)
              </Form.Subtitle>
            </Form.Element>
          </Form.Row>
        </Form.Block>

        {/* Циркуляционные насосы */}
        <Form.Block className={cn('form-block')}>
          <Form.Head className={cn('grid-4')}>
            <Form.Title $bold className={cn('form-title')} $pl="1">
              Циркуляционные насосы:
            </Form.Title>
            <Form.Title className={cn('form-title')} $large>
              Расход<Form.Subtitle $mt="0.2">(в % от ном. или т/ч)</Form.Subtitle>
            </Form.Title>
            <Form.Title className={cn('form-title')} $large>
              Потери<Form.Subtitle $mt="0.2">(в контуре здания)</Form.Subtitle>
            </Form.Title>
            <Form.Title className={cn('form-title')} $large>
              Тип<Form.Subtitle $mt="0.2">(электропитания)</Form.Subtitle>
            </Form.Title>
          </Form.Head>

          <Form.Row $padding="3.5rem 0 0.6rem" className={cn('grid-4')}>
            <Form.Element $center>
              <Form.Title className={cn('form-title')} $large>
                ГВС
              </Form.Title>
            </Form.Element>
            <Form.Element $padding="0 0 0 1.7rem" disabled={!hotWaterChosen}>
              <Form.Item
                name="hot_water_consumption"
                rules={[
                  {
                    required: hotWaterChosen,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="7.2" />
              </Form.Item>
            </Form.Element>
            <Form.Element disabled={!hotWaterChosen}>
              <Form.Item
                name="hot_water_losses"
                rules={[
                  {
                    required: hotWaterChosen,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="7.2" before="м в.ст." />
              </Form.Item>
            </Form.Element>
            <Form.Element disabled={!hotWaterChosen}>
              <Form.Item
                name="hot_water_power_supply_type"
                rules={[
                  {
                    required: hotWaterChosen,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="7.2" before="фаз x В" />
              </Form.Item>
            </Form.Element>
          </Form.Row>

          <Form.Row $padding="0.6rem 0 0.6rem" className={cn('grid-4')}>
            <Form.Element $center>
              <Form.Title className={cn('form-title')} $large>
                Отопление
              </Form.Title>
            </Form.Element>
            <Form.Element $padding="0 0 0 1.7rem" disabled={!heatingChosen}>
              <Form.Item
                name="heating_consumption"
                rules={[
                  {
                    required: heatingChosen,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="7.2" />
              </Form.Item>
            </Form.Element>
            <Form.Element disabled={!heatingChosen}>
              <Form.Item
                name="heating_losses"
                rules={[
                  {
                    required: heatingChosen,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="7.2" before="м в.ст." />
              </Form.Item>
            </Form.Element>
            <Form.Element disabled={!heatingChosen}>
              <Form.Item
                name="heating_power_supply_type"
                rules={[
                  {
                    required: heatingChosen,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="7.2" before="фаз x В" />
              </Form.Item>
            </Form.Element>
          </Form.Row>

          <Form.Row $bordered $padding="0.6rem 0 3rem" className={cn('grid-4')}>
            <Form.Element $center>
              <Form.Title className={cn('form-title')} $large>
                Вентиляция
              </Form.Title>
            </Form.Element>
            <Form.Element $padding="0 0 0 1.7rem" disabled={!ventilationChosen}>
              <Form.Item
                name="ventilation_consumption"
                rules={[
                  {
                    required: ventilationChosen,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="7.2" />
              </Form.Item>
            </Form.Element>
            <Form.Element disabled={!ventilationChosen}>
              <Form.Item
                name="ventilation_losses"
                rules={[
                  {
                    required: ventilationChosen,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="7.2" before="м в.ст." />
              </Form.Item>
            </Form.Element>
            <Form.Element disabled={!ventilationChosen}>
              <Form.Item
                name="ventilation_power_supply_type"
                rules={[
                  {
                    required: ventilationChosen,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="7.2" before="фаз x В" />
              </Form.Item>
            </Form.Element>
          </Form.Row>

          <Form.Row $padding="1.2rem 0 0.6rem" className={cn('grid-5')}>
            <Form.Element $center>
              <Form.Title className={cn('form-title')} $large>
                ГВС
              </Form.Title>
            </Form.Element>
            <Form.Element $center disabled={!hotWaterChosen}>
              <Form.Item
                name="hot_water_pump_power"
                rules={[
                  {
                    required: hotWaterChosen,
                    message: '',
                  },
                ]}
              >
                <Select
                  $common
                  $noLabel
                  $width="16"
                  suffixIcon={<Icon name="arrow-2" className="select-arrow" />}
                  dropdownClassName={cn('select-dropdown', 'object-status-select-dropdown')}
                  items={pumpPowerOptions}
                  optionLabelProp="label"
                  placeholder="Выбрать"
                />
              </Form.Item>
            </Form.Element>
          </Form.Row>

          <Form.Row $padding="0.6rem 0 0.6rem" className={cn('grid-5')}>
            <Form.Element $center>
              <Form.Title className={cn('form-title')} $large>
                Отопление
              </Form.Title>
            </Form.Element>
            <Form.Element $center disabled={!heatingChosen}>
              <Form.Item
                name="heating_pump_power"
                rules={[
                  {
                    required: heatingChosen,
                    message: '',
                  },
                ]}
              >
                <Select
                  $common
                  $noLabel
                  $width="16"
                  suffixIcon={<Icon name="arrow-2" className="select-arrow" />}
                  dropdownClassName={cn('select-dropdown', 'object-status-select-dropdown')}
                  items={pumpPowerOptions}
                  optionLabelProp="label"
                  placeholder="Выбрать"
                />
              </Form.Item>
            </Form.Element>
          </Form.Row>

          <Form.Row $bordered $padding="0.6rem 0 2rem" className={cn('grid-5')}>
            <Form.Element $center>
              <Form.Title className={cn('form-title')} $large>
                Вентиляция
              </Form.Title>
            </Form.Element>
            <Form.Element $center disabled={!ventilationChosen}>
              <Form.Item
                name="ventilation_pump_power"
                rules={[
                  {
                    required: ventilationChosen,
                    message: '',
                  },
                ]}
              >
                <Select
                  $common
                  $noLabel
                  $width="16"
                  suffixIcon={<Icon name="arrow-2" className="select-arrow" />}
                  dropdownClassName={cn('select-dropdown', 'object-status-select-dropdown')}
                  items={pumpPowerOptions}
                  optionLabelProp="label"
                  placeholder="Выбрать"
                />
              </Form.Item>
            </Form.Element>
          </Form.Row>

          <Form.Row $padding="2rem 0 0.6rem" className={cn('grid-5')}>
            <Form.Element $center>
              <Form.Title className={cn('form-title')} $large>
                Изготовитель насосов
              </Form.Title>
            </Form.Element>
            <Form.Element $center>
              <Form.Item
                name="pump_manufacturer"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="9.2" after="предпочтительный изготовитель" afterPale />
              </Form.Item>
            </Form.Element>
          </Form.Row>

          <Form.Row $padding="0.6rem 0 3rem" className={cn('grid-5')}>
            <Form.Element $center>
              <Form.Title className={cn('form-title')} $large>
                Частотный привод
              </Form.Title>
            </Form.Element>
            <Form.Element $center>
              <Form.Item
                name="frequency_drive"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Select
                  $common
                  $noLabel
                  $width="10"
                  suffixIcon={<Icon name="arrow-2" className="select-arrow" />}
                  dropdownClassName={cn('select-dropdown', 'object-status-select-dropdown')}
                  items={frequencyDriveOptions}
                  optionLabelProp="label"
                  placeholder="Да/Нет"
                />
              </Form.Item>
            </Form.Element>
          </Form.Row>
        </Form.Block>

        {/* Система подпитки и расширения: */}
        <Form.Block className={cn('form-block')}>
          <Form.Head className={cn('title-padding')}>
            <Form.Title $bold className={cn('form-title')}>
              Система подпитки и расширения:
            </Form.Title>
          </Form.Head>

          <Form.Row $bordered $padding="2.7rem 0 2rem" className={cn('grid-5')}>
            <Form.Element>
              <Form.Title className={cn('form-title')} $large>
                Подпитка
              </Form.Title>
            </Form.Element>
            <Form.Element>
              <Form.Item
                name="make_up_system_type"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Radio.Group className={cn('radio-group-column')}>
                  <Radio $checkbox value="manually">
                    вручную
                  </Radio>
                  <Radio $checkbox value="solenoid_valve">
                    с эл.маг.клапаном
                  </Radio>
                  <Radio $checkbox value="automatic">
                    автоматическая механическая
                  </Radio>
                  <Radio $checkbox value="one_pump">
                    с 1 насосом
                  </Radio>
                  <Radio $checkbox value="two_pumps">
                    с 2 нас. (рез.)
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Form.Element>
          </Form.Row>

          <Form.Row $bordered $padding="0.6rem 0">
            <Form.Title $bold className={cn('form-title')}>
              Расширительный бак:
            </Form.Title>
          </Form.Row>

          <Form.Row $padding="2rem 0 0.6rem" className={cn('grid-5')}>
            <Form.Element $center>
              <Form.Title className={cn('form-title')} $large>
                Объем системы
              </Form.Title>
            </Form.Element>
            <Form.Element>
              <Form.Item
                name="expansion_tank_volume"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="7.2" before="л" beforeAbsolute />
              </Form.Item>
            </Form.Element>
          </Form.Row>

          <Form.Row $bordered $padding="0.6rem 0 2rem" className={cn('grid-5')}>
            <Form.Element $center>
              <Form.Title className={cn('form-title')} $large>
                Высота здания
              </Form.Title>
            </Form.Element>
            <Form.Element>
              <Form.Item
                name="expansion_tank_height"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="7.2" before="м" beforeAbsolute />
              </Form.Item>
            </Form.Element>
          </Form.Row>

          <Form.Row $bordered $padding="0.6rem 0">
            <Form.Title $bold className={cn('form-title')}>
              Подпиточный насос:
            </Form.Title>
          </Form.Row>

          <Form.Row $padding="2rem 0 0.6rem" className={cn('grid-5')}>
            <Form.Element $center>
              <Form.Title className={cn('form-title')} $large>
                Производительность
              </Form.Title>
            </Form.Element>
            <Form.Element>
              <Form.Item
                name="make_up_pump_efficiency"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="7.2" before="т / ч" beforeAbsolute />
              </Form.Item>
            </Form.Element>
          </Form.Row>

          <Form.Row $padding="0.6rem 0 2rem" className={cn('grid-5')}>
            <Form.Element $center>
              <Form.Title className={cn('form-title')} $large>
                Напор
              </Form.Title>
            </Form.Element>
            <Form.Element>
              <Form.Item
                name="make_up_pump_pressure"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="7.2" before="м в.ст." beforeAbsolute />
              </Form.Item>
            </Form.Element>
          </Form.Row>
        </Form.Block>

        {/* Электрощит ИТП */}
        <Form.Block className={cn('form-block')}>
          <Form.Head className={cn('title-padding')}>
            <Form.Title $bold className={cn('form-title')}>
              Электрощит ИТП
            </Form.Title>
          </Form.Head>

          <Form.Row $bordered $padding="2rem 0" className={cn('grid-5')}>
            <Form.Element $center>
              <Form.Title className={cn('form-title')} $large>
                (требования к ЭЩ указать в примечаниях)
              </Form.Title>
            </Form.Element>
            <Form.Element>
              <Form.Item
                name="electrical_panel"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="13.6" after="Вписать" afterPale />
              </Form.Item>
            </Form.Element>
          </Form.Row>

          <Form.Row $padding="2rem 0 0.6rem" className={cn('grid-5')}>
            <Form.Element $center>
              <Form.Title className={cn('form-title')} $large>
                Дифф. регулятор перепада давл.
              </Form.Title>
            </Form.Element>
            <Form.Element>
              <Form.Item
                name="electrical_panel_regulator"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="13.6" after="Вписать" afterPale />
              </Form.Item>
            </Form.Element>
          </Form.Row>

          <Form.Row $bordered $padding="0.6rem 0 2rem" className={cn('grid-5')}>
            <Form.Element $center>
              <Form.Title className={cn('form-title')} $large>
                Проставка под теплосчетчик
              </Form.Title>
            </Form.Element>
            <Form.Element>
              <Form.Item
                name="electrical_panel_spacer"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="13.6" after="Вписать" afterPale />
              </Form.Item>
            </Form.Element>
          </Form.Row>
        </Form.Block>

        {/* Габариты теплопункта */}
        <Form.Block className={cn('form-block')}>
          <Form.Head className={cn('grid-6')}>
            <Form.Title $bold className={cn('form-title')} $pl="1">
              Габариты теплопункта
            </Form.Title>
            <Form.Title className={cn('form-title')} $large>
              Длина
            </Form.Title>
            <Form.Title className={cn('form-title')} $large>
              Ширина
            </Form.Title>
            <Form.Title className={cn('form-title')} $large>
              Высота
            </Form.Title>
          </Form.Head>

          <Form.Row $padding="1.2rem 0 0.6rem" className={cn('grid-6')}>
            <Form.Element $center>
              <Form.Title className={cn('form-title')} $large>
                Размер в помещении
              </Form.Title>
            </Form.Element>
            <Form.Element>
              <Form.Item
                name="indoor_length"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="7.2" before="м" beforeAbsolute />
              </Form.Item>
            </Form.Element>
            <Form.Element>
              <Form.Item
                name="indoor_width"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="7.2" />
              </Form.Item>
            </Form.Element>
            <Form.Element>
              <Form.Item
                name="indoor_height"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="7.2" />
              </Form.Item>
            </Form.Element>
          </Form.Row>

          <Form.Row $padding="0.6rem 0 2rem" className={cn('grid-6')}>
            <Form.Element $center>
              <Form.Title className={cn('form-title')} $large>
                Проемы для проноса
              </Form.Title>
            </Form.Element>
            <Form.Element>
              <Form.Item
                name="passage_opening_length"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="7.2" before="м" beforeAbsolute />
              </Form.Item>
            </Form.Element>
            <Form.Element>
              <Form.Item
                name="passage_opening_width"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="7.2" />
              </Form.Item>
            </Form.Element>
            <Form.Element>
              <Form.Item
                name="passage_opening_height"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="7.2" />
              </Form.Item>
            </Form.Element>
          </Form.Row>
        </Form.Block>

        {/* Требования к арматуре */}
        <Form.Block className={cn('form-block')}>
          <Form.Head className={cn('grid-6')}>
            <Form.Title $bold className={cn('form-title')} $pl="1">
              Требования к арматуре
            </Form.Title>
            <Form.Title className={cn('form-title')} $large>
              Сварная
            </Form.Title>
            <Form.Title className={cn('form-title')} $large>
              Фланцевая
            </Form.Title>
            <Form.Title className={cn('form-title')} $large>
              Резьбовая
            </Form.Title>
          </Form.Head>

          <Form.Row $padding="2rem 0" className={cn('grid-6')}>
            <Form.Element $center>
              <Form.Title className={cn('form-title')} $large>
                Греющий контур
              </Form.Title>
            </Form.Element>
            <Form.Element>
              <Form.Item
                name="heating_circuit_welded"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="7.2" />
              </Form.Item>
            </Form.Element>
            <Form.Element>
              <Form.Item
                name="heating_circuit_flanged"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="7.2" />
              </Form.Item>
            </Form.Element>
            <Form.Element>
              <Form.Item
                name="heating_circuit_threaded"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input.Enhanced $width="7.2" />
              </Form.Item>
            </Form.Element>
          </Form.Row>
        </Form.Block>

        {/* Примечания */}
        <Form.Block className={cn('form-block')}>
          <Form.Head>
            <Form.Title $bold className={cn('form-title')} $pl="1">
              Примечания
            </Form.Title>
          </Form.Head>

          <Form.Row $padding="2rem 0" className={cn('grid-5')}>
            <Form.Element>
              <Form.Title className={cn('form-title')} $large>
                Вписать
              </Form.Title>
            </Form.Element>
            <Form.Element>
              <Form.Item name="notes">
                <Input.TextArea $width="41.7" className={cn('textarea')} />
              </Form.Item>
            </Form.Element>
          </Form.Row>
        </Form.Block>
      </Form>
    </QuestionnaireHeatPointContainer>
  );
};

export default QuestionnaireHeatPoint;
