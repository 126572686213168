import React, { Fragment } from 'react';

import { Sider } from '@organisms';
import useLayout from '@hooks/useLayout';

import { GlobalStyles, LayoutContainer, ContentContainer } from './styles';

const { Content } = LayoutContainer;

const Layout = ({ children }) => {
  const { hasSider } = useLayout();

  return (
    <Fragment>
      <LayoutContainer>
        <GlobalStyles />
        {hasSider() && <Sider />}
        <ContentContainer>
          <Content>{children}</Content>
        </ContentContainer>
      </LayoutContainer>
    </Fragment>
  );
};

export default Layout;
