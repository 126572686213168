export const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  REGISTRATION: '/registration',
  NOTIFICATIONS: '/notifications',
  REQUESTS: '/requests',
  SETTINGS: '/settings',
  CHAT_WITH_ADMIN: '/chat',
  CHAT_WITH_USER: '/user_chat',
  EQUIPMENT: '/equipment',
  NOT_FOUND: '*',
};
