const transformToFormData = (values) => {
  const formData = new FormData();

  Object.keys(values).forEach((value) => {
    formData.append(value, values[value]);
  });

  return formData;
};

export default transformToFormData;
