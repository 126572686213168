import styled, { css } from 'styled-components';

export const ProviderInfoTitle = styled.h2`
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ theme, $secondary }) => ($secondary ? theme.palette.providerChosen : theme.palette.secondaryText)};
  margin: 0rem 0rem 1.2rem 0rem;
`;

export const ProviderInfoName = styled.span`
  display: inline-block;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.palette.text};
  margin-bottom: 1.2rem;
`;

export const ProviderInfoBlock = styled.div`
  margin-bottom: ${({ $margin }) => ($margin ? `${$margin}rem` : '0rem')};
`;

export const ProviderInfoContainer = styled.div`
  ${({ $provider }) =>
    $provider &&
    css`
      ${ProviderInfoTitle} {
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: ${({ theme }) => theme.palette.text};
      }
    `}
`;
