import React, { Fragment } from 'react';
import cn from 'classnames';

import { Icon } from '@atoms';

// common constants

export const EQUIP_IDS = {
  HEAT_POINT: 38,
  CHILLER: 41,
  SUPPLY_UNIT: 42,
};

export const USER_ROLES = {
  CLIENT: 'client',
  PROVIDER: 'provider',
  ADMIN: 'admin',
};

export const USER_TITLES = {
  [USER_ROLES.CLIENT]: 'Заказчик',
  [USER_ROLES.PROVIDER]: 'Поставщик',
  [USER_ROLES.ADMIN]: 'Администратор',
};

export const ITEM_TYPES = {
  LINK: 'link',
  ACTION: 'action',
};

export const PROJECT_STATUSES = {
  ACTIVE: {
    name: 'active',
    title: 'Активные',
  },
  FROZEN: {
    name: 'frozen',
    title: 'Заморожено',
  },
  DONE: {
    name: 'done',
    title: 'Завершено',
  },
};

export const ADMIN_REQUEST_STATUSES = {
  ALL: {
    name: 'all',
    title: 'Все заявки',
  },
  ACTIVE: {
    name: 'active',
    title: 'Активные',
  },
  ACCEPTED: {
    name: 'accepted',
    title: 'Принятые',
  },
  CANCELED: {
    name: 'canceled',
    title: 'Отклоненные',
  },
};

export const ADMIN_REQUEST_STATUSES_PATH = {
  active: '',
  accepted: 'Accept',
  canceled: 'Deny',
};

export const REQUEST_PROVIDER_STATUSES = {
  ALL: {
    name: 'all',
    title: 'Все запросы',
  },
  NEW: {
    name: 'new',
    title: 'Новые',
  },
  CANCELED: {
    name: 'canceled',
    title: 'Отклоненные',
  },
  REJECTED: {
    name: 'rejected',
    title: 'Отмененные',
  },
  ARCHIVE: {
    name: 'archive',
    title: 'Архив',
  },
};

export const REQUEST_STATUSES = {
  ALL: {
    name: 'all',
    title: 'Все',
  },
  SENT: {
    name: 'sent',
    title: 'Отправлено',
  },
  DRAFT: {
    name: 'draft',
    title: 'Черновик',
  },
  CANCELED: {
    name: 'canceled',
    title: 'Отменен',
  },
  ARCHIVE: {
    name: 'archive',
    title: 'Архив',
  },
  HAS_PROVIDER: {
    name: 'hasProvider',
    title: 'Поставщик выбран',
  },
};

export const REQUEST_SECONDARY_STATUSES = {
  ALL: {
    name: 'all',
    title: 'Все',
  },
  ANSWERED: {
    name: 'answered',
    title: 'Отвечено',
  },
  NOT_ANSWERED: {
    name: 'not_answered',
    title: 'Не отвечено',
  },
  CLIENT_CANCEL: {
    name: 'client_cancel',
    title: 'Отклонено клиентом',
  },
  CHOSEN_OTHER: {
    name: 'chosen_other',
    title: 'Выбрано другое предложение',
  },
  CHOSEN: {
    name: 'chosen',
    title: 'Выбрано ваше предложение',
  },
};

export const FILTERS = {
  DATE: {
    name: 'date',
    title: 'Дата создания',
  },
  STATUS: {
    name: 'status',
    title: 'Статус',
  },
  TYPE: {
    name: 'type',
    title: 'Тип оборудования',
  },
};

export const REQUEST_CREATION_STEPS = {
  INFO: 'info',
  QUESTIONNAIRE: 'questionnaire',
  PROVIDERS: 'providers',
};

export const EQUIPMENT_CATEGORY_ACTIONS = {
  EDIT: {
    name: 'edit',
    title: 'Редактировать',
  },
  DELETE: {
    name: 'delete',
    title: 'Удалить',
  },
};

// common dropdown menus

export const RELOCATE_DROPDOWN_MENU = [
  {
    type: 'group',
    label: (
      <Fragment>
        <Icon name="move-arrow" className={cn('move-arrow')} />
        Переместить в
      </Fragment>
    ),
    children: [
      {
        key: '1',
        label: (
          <Fragment>
            {PROJECT_STATUSES.ACTIVE.title}
            <Icon name="checked" className="checked" />
          </Fragment>
        ),
      },
      {
        key: '2',
        label: (
          <Fragment>
            {PROJECT_STATUSES.FROZEN.title}
            <Icon name="checked" className="checked" />
          </Fragment>
        ),
      },
      {
        key: '3',
        label: (
          <Fragment>
            {PROJECT_STATUSES.DONE.title}
            <Icon name="checked" className="checked" />
          </Fragment>
        ),
      },
    ],
  },
];

export const RELOCATE_PROVIDER_DROPDOWN_MENU = [
  {
    key: REQUEST_PROVIDER_STATUSES.ALL.name,
    label: (
      <Fragment>
        {REQUEST_PROVIDER_STATUSES.ALL.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
  {
    key: REQUEST_PROVIDER_STATUSES.NEW.name,
    label: (
      <Fragment>
        {REQUEST_PROVIDER_STATUSES.NEW.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
  {
    key: REQUEST_PROVIDER_STATUSES.CANCELED.name,
    label: (
      <Fragment>
        {REQUEST_PROVIDER_STATUSES.CANCELED.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
  {
    key: REQUEST_PROVIDER_STATUSES.REJECTED.name,
    label: (
      <Fragment>
        {REQUEST_PROVIDER_STATUSES.REJECTED.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
  {
    key: REQUEST_PROVIDER_STATUSES.ARCHIVE.name,
    label: (
      <Fragment>
        {REQUEST_PROVIDER_STATUSES.ARCHIVE.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
];

export const ADMIN_REQUEST_STATUS_DROPDOWM_MENU = [
  {
    key: ADMIN_REQUEST_STATUSES.ALL.name,
    label: (
      <Fragment>
        {ADMIN_REQUEST_STATUSES.ALL.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
  {
    key: ADMIN_REQUEST_STATUSES.ACTIVE.name,
    label: (
      <Fragment>
        {ADMIN_REQUEST_STATUSES.ACTIVE.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
  {
    key: ADMIN_REQUEST_STATUSES.ACCEPTED.name,
    label: (
      <Fragment>
        {ADMIN_REQUEST_STATUSES.ACCEPTED.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
  {
    key: ADMIN_REQUEST_STATUSES.CANCELED.name,
    label: (
      <Fragment>
        {ADMIN_REQUEST_STATUSES.CANCELED.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
];

export const REQUEST_STATUS_MENU = [
  {
    key: REQUEST_STATUSES.ALL.name,
    label: (
      <Fragment>
        {REQUEST_STATUSES.ALL.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
  {
    key: REQUEST_STATUSES.SENT.name,
    label: (
      <Fragment>
        <Icon name="square" className={cn('select-list-style')} />
        {REQUEST_STATUSES.SENT.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
  {
    key: REQUEST_STATUSES.DRAFT.name,
    label: (
      <Fragment>
        <Icon name="square" className={cn('select-list-style')} />
        {REQUEST_STATUSES.DRAFT.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
  {
    key: REQUEST_STATUSES.CANCELED.name,
    label: (
      <Fragment>
        <Icon name="square" className={cn('select-list-style')} />
        {REQUEST_STATUSES.CANCELED.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
  {
    key: REQUEST_STATUSES.ARCHIVE.name,
    label: (
      <Fragment>
        <Icon name="square" className={cn('select-list-style')} />
        {REQUEST_STATUSES.ARCHIVE.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
  {
    key: REQUEST_STATUSES.HAS_PROVIDER.name,
    label: (
      <Fragment>
        <Icon name="square" className={cn('select-list-style')} />
        {REQUEST_STATUSES.HAS_PROVIDER.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
];

export const REQUEST_PROVIDER_STATUS_MENU = [
  {
    key: REQUEST_SECONDARY_STATUSES.ALL.name,
    label: (
      <Fragment>
        {REQUEST_SECONDARY_STATUSES.ALL.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
  {
    key: REQUEST_SECONDARY_STATUSES.ANSWERED.name,
    label: (
      <Fragment>
        {REQUEST_SECONDARY_STATUSES.ANSWERED.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
  {
    key: REQUEST_SECONDARY_STATUSES.NOT_ANSWERED.name,
    label: (
      <Fragment>
        {REQUEST_SECONDARY_STATUSES.NOT_ANSWERED.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
  {
    key: REQUEST_SECONDARY_STATUSES.CLIENT_CANCEL.name,
    label: (
      <Fragment>
        {REQUEST_SECONDARY_STATUSES.CLIENT_CANCEL.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
  {
    key: REQUEST_SECONDARY_STATUSES.CHOSEN_OTHER.name,
    label: (
      <Fragment>
        {REQUEST_SECONDARY_STATUSES.CHOSEN_OTHER.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
  {
    key: REQUEST_SECONDARY_STATUSES.CHOSEN.name,
    label: (
      <Fragment>
        {REQUEST_SECONDARY_STATUSES.CHOSEN.title}
        <Icon name="checked" className="checked" />
      </Fragment>
    ),
  },
];

export const FILTERS_DROPDOWN_MENU = [
  {
    key: FILTERS.DATE.name,
    label: FILTERS.DATE.title,
  },
  {
    key: FILTERS.STATUS.name,
    label: FILTERS.STATUS.title,
  },
  {
    key: FILTERS.TYPE.name,
    label: FILTERS.TYPE.title,
  },
];

export const FILTERS_STATUS_MENU = [
  {
    key: 'back',
    label: (
      <div className={cn('dropdown-back-item')}>
        <div id="back" className={cn('dropdown-back-item-button')}>
          <Icon name="arrow-1" className={cn('back-arrow')} />
          Статус
        </div>
        <div id="close" className={cn('dropdown-back-item-close')}>
          <Icon name="close" className={cn('close')} />
        </div>
      </div>
    ),
  },
  {
    key: 'notChosen',
    label: 'Поставщик не выбран',
  },
  {
    key: 'chosen',
    label: 'Поставщик выбран',
  },
];

export const FILTERS_PROVIDER_STATUS_MENU = [
  {
    key: 'back',
    label: (
      <div className={cn('dropdown-back-item')}>
        <div id="back" className={cn('dropdown-back-item-button')}>
          <Icon name="arrow-1" className={cn('back-arrow')} />
          Статус
        </div>
        <div id="close" className={cn('dropdown-back-item-close')}>
          <Icon name="close" className={cn('close')} />
        </div>
      </div>
    ),
  },
  {
    key: 'notChosen',
    label: 'Решение не принято',
  },
  {
    key: 'chosen',
    label: 'Решение принято',
  },
];

export const FILTERS_TYPE_MENU = [
  {
    key: 'back',
    label: (
      <div className={cn('dropdown-back-item')}>
        <div id="back" className={cn('dropdown-back-item-button')}>
          <Icon name="arrow-1" className={cn('back-arrow')} />
          Тип оборудования
        </div>
        <div id="close" className={cn('dropdown-back-item-close')}>
          <Icon name="close" className={cn('close')} />
        </div>
      </div>
    ),
  },
];

export const EQUIPMENT_CAREGORY_ACTIONS_MENU = [
  {
    key: EQUIPMENT_CATEGORY_ACTIONS.EDIT.name,
    label: (
      <Fragment>
        <Icon $transition name="edit" className="edit" />
        {EQUIPMENT_CATEGORY_ACTIONS.EDIT.title}
      </Fragment>
    ),
  },
  {
    key: EQUIPMENT_CATEGORY_ACTIONS.DELETE.name,
    label: (
      <Fragment>
        <Icon $transition name="delete" className="delete" />
        {EQUIPMENT_CATEGORY_ACTIONS.DELETE.title}
      </Fragment>
    ),
  },
];

// status

export const STATUS = {
  draft: {
    name: 'draft',
    title: 'Черновик',
  },
  sent: {
    name: 'sent',
    title: 'Отправлено',
  },
  canceled: {
    name: 'canceled',
    title: 'Отменен',
  },
  canceledByProvider: {
    name: 'canceledByProvider',
    title: 'Отклонен',
  },
  archive: {
    name: 'archive',
    title: 'Архив',
  },
  answered: {
    name: 'answered',
    title: 'Отвечено',
  },
  notAnswered: {
    name: 'notAnswered',
    title: 'Не отвечено',
  },
  clientCanceled: {
    name: 'clientCanceled',
    title: 'Отклонено клиентом',
  },
  clientChosenOther: {
    name: 'clientChosenOther',
    title: 'Выбрано другое предложение',
  },
  clientChosen: {
    name: 'clientChosen',
    title: 'Выбрано ваше предложение',
  },
  hasProvider: {
    name: 'hasProvider',
    title: 'Поставщик выбран',
  },
};

export const ADMIN_STATUS = {
  active: {
    name: 'active',
    title: 'Активные',
  },
  accepted: {
    name: 'accepted',
    title: 'Принятые',
  },
  canceled: {
    name: 'canceled',
    title: 'Отклоненные',
  },
  needsModeration: {
    name: 'needsModeration',
    title: 'Требует модерацию',
  },
};

// tabs

export const TABS = {
  INFO: {
    name: 'info',
    title: 'Информация',
  },
  FORMS: {
    name: 'forms',
    title: 'Опросный лист',
  },
  RESPONSES: {
    name: 'responses',
    title: 'Ответы',
  },
  STATUS: {
    name: 'status',
    title: 'Статус',
  },
};

export const PROVIDER_TABS = {
  INFO: {
    name: 'info',
    title: 'Информация',
  },
  FORMS: {
    name: 'forms',
    title: 'Опросный лист',
  },
  RESPONSES: {
    name: 'responses',
    title: 'Ответ на заявку',
  },
  STATUS: {
    name: 'status',
    title: 'Статус',
  },
  CHAT: {
    name: 'chat',
    title: 'Чат',
  },
};

export const ADMIN_TABS = {
  GENERAL: {
    name: 'general',
    title: 'Основное',
  },
  COMPANY: {
    name: 'company',
    title: 'Компания',
  },
  EQUIPMENT: {
    name: 'equipment',
    title: 'Оборудование',
  },
  COMMENT: {
    name: 'comment',
    title: 'Комментарий',
  },
};

export const NOTIFICATION_TABS = {
  ALL: {
    name: 'all',
    title: 'Все',
  },
  ANSWERS: {
    name: 'answers',
    title: 'Ответы на заявку',
  },
  STATUS: {
    name: 'status',
    title: 'Статус',
  },
  SUPPORT: {
    name: 'support',
    title: 'Поддержка',
  },
};

// request actions

export const ACTION_TYPES = {
  DUPLICATE: 'duplicate',
  DELETE: 'delete',
  SEND: 'send',
  EDIT: 'edit',
  EDIT_SENT: 'editSent',
  ARCHIVE: 'archive',
  CANCEL: 'cancel',
  ACCEPT: 'accept',
};

export const CLIENT_REQUEST_ACTIONS = {
  draft: [
    {
      icon: 'duplicate',
      title: 'Дублировать',
      key: ACTION_TYPES.DUPLICATE,
    },
    {
      icon: 'edit',
      title: 'Редактировать',
      key: ACTION_TYPES.EDIT,
    },
    {
      icon: 'send',
      title: 'Отправить',
      key: ACTION_TYPES.SEND,
    },
    {
      icon: 'delete',
      title: 'Удалить',
      key: ACTION_TYPES.DELETE,
      placement: 'bottomRight',
    },
  ],
  sent: [
    {
      icon: 'duplicate',
      title: 'Дублировать',
      key: ACTION_TYPES.DUPLICATE,
    },
    {
      icon: 'edit',
      title: 'Редактировать',
      key: ACTION_TYPES.EDIT_SENT,
    },
    {
      icon: 'cancel',
      title: 'Отменить запрос',
      key: ACTION_TYPES.CANCEL,
    },
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
      placement: 'bottomRight',
    },
  ],
  canceled: [
    {
      icon: 'duplicate',
      title: 'Дублировать',
      key: ACTION_TYPES.DUPLICATE,
    },
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
      placement: 'bottomRight',
    },
  ],
  archive: [
    {
      icon: 'duplicate',
      title: 'Дублировать',
      key: ACTION_TYPES.DUPLICATE,
      placement: 'bottomRight',
    },
  ],
  hasProvider: [
    {
      icon: 'duplicate',
      title: 'Дублировать',
      key: ACTION_TYPES.DUPLICATE,
    },
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
      placement: 'bottomRight',
    },
  ],
};

export const UNMODERATED_CLIENT_REQUEST_ACTIONS = {
  draft: [
    {
      icon: 'duplicate',
      title: 'Дублировать',
      key: ACTION_TYPES.DUPLICATE,
    },
    {
      icon: 'edit',
      title: 'Редактировать',
      key: ACTION_TYPES.EDIT,
    },
    {
      icon: 'delete',
      title: 'Удалить',
      key: ACTION_TYPES.DELETE,
      placement: 'bottomRight',
    },
  ],
  sent: [
    {
      icon: 'duplicate',
      title: 'Дублировать',
      key: ACTION_TYPES.DUPLICATE,
    },
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
      placement: 'bottomRight',
    },
  ],
  canceled: [
    {
      icon: 'duplicate',
      title: 'Дублировать',
      key: ACTION_TYPES.DUPLICATE,
    },
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
      placement: 'bottomRight',
    },
  ],
  archive: [
    {
      icon: 'duplicate',
      title: 'Дублировать',
      key: ACTION_TYPES.DUPLICATE,
      placement: 'bottomRight',
    },
  ],
  hasProvider: [
    {
      icon: 'duplicate',
      title: 'Дублировать',
      key: ACTION_TYPES.DUPLICATE,
    },
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
      placement: 'bottomRight',
    },
  ],
};

export const UNMODERATED_PROVIDER_REQUEST_ACTIONS = {
  answered: [
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
      placement: 'bottomRight',
    },
  ],
  notAnswered: [
    // {
    //   icon: 'cancel',
    //   title: 'Отклонить',
    //   key: ACTION_TYPES.CANCEL,
    // },
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
      placement: 'bottomRight',
    },
  ],
  canceled: [
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
      placement: 'bottomRight',
    },
  ],
  clientChosen: [
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
      placement: 'bottomRight',
    },
  ],
  clientChosenOther: [
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
      placement: 'bottomRight',
    },
  ],
  clientCanceled: [
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
      placement: 'bottomRight',
    },
  ],
};

export const PROVIDER_REQUEST_ACTIONS = {
  answered: [
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
      placement: 'bottomRight',
    },
  ],
  notAnswered: [
    {
      icon: 'cancel',
      title: 'Отклонить',
      key: ACTION_TYPES.CANCEL,
    },
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
      placement: 'bottomRight',
    },
  ],
  canceled: [
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
      placement: 'bottomRight',
    },
  ],
  canceledByProvider: [
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
      placement: 'bottomRight',
    },
  ],
  clientChosen: [
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
      placement: 'bottomRight',
    },
  ],
  clientChosenOther: [
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
      placement: 'bottomRight',
    },
  ],
  clientCanceled: [
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
      placement: 'bottomRight',
    },
  ],
};

export const ADMIN_REQUEST_ACTIONS = {
  active: [
    {
      icon: 'close',
      title: 'Отклонить заявку',
      key: ACTION_TYPES.CANCEL,
    },
    {
      icon: 'checked',
      title: 'Подтвердить заявку',
      key: ACTION_TYPES.ACCEPT,
    },
    {
      icon: 'send',
      title: 'Написать сообщение',
      key: ACTION_TYPES.SEND,
      placement: 'bottomRight',
    },
  ],
  accepted: [
    {
      icon: 'send',
      title: 'Написать сообщение',
      key: ACTION_TYPES.SEND,
      placement: 'bottomRight',
    },
  ],
  canceled: [
    {
      icon: 'send',
      title: 'Написать сообщение',
      key: ACTION_TYPES.SEND,
      placement: 'bottomRight',
    },
  ],
  needsModeration: [
    {
      icon: 'close',
      title: 'Отклонить заявку',
      key: ACTION_TYPES.CANCEL,
    },
    {
      icon: 'checked',
      title: 'Подтвердить заявку',
      key: ACTION_TYPES.ACCEPT,
    },
    {
      icon: 'send',
      title: 'Написать сообщение',
      key: ACTION_TYPES.SEND,
      placement: 'bottomRight',
    },
  ],
};

// actions for collapsed menu

export const UNMODERATED_MORE_REQUEST_ACTIONS = {
  draft: [
    {
      icon: 'duplicate',
      title: 'Дублировать',
      key: ACTION_TYPES.DUPLICATE,
    },
    {
      icon: 'edit',
      title: 'Редактировать',
      key: ACTION_TYPES.EDIT,
    },
    {
      icon: 'delete',
      title: 'Удалить',
      key: ACTION_TYPES.DELETE,
      placement: 'bottomRight',
    },
  ],
  sent: [
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
    },
  ],
  canceled: [
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
    },
  ],
  archive: [],
  answered: [
    // {
    //   icon: 'cancel',
    //   title: 'Отклонить',
    //   key: ACTION_TYPES.CANCEL,
    // },
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
    },
  ],
  notAnswered: [
    // {
    //   icon: 'cancel',
    //   title: 'Отклонить',
    //   key: ACTION_TYPES.CANCEL,
    // },
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
    },
  ],
};

export const CLIENT_MORE_REQUEST_ACTIONS = {
  draft: [
    {
      icon: 'duplicate',
      title: 'Дублировать',
      key: ACTION_TYPES.DUPLICATE,
    },
    {
      icon: 'edit',
      title: 'Редактировать',
      key: ACTION_TYPES.EDIT,
    },
    {
      icon: 'send',
      title: 'Отправить',
      key: ACTION_TYPES.SEND,
    },
    {
      icon: 'delete',
      title: 'Удалить',
      key: ACTION_TYPES.DELETE,
      placement: 'bottomRight',
    },
  ],
  sent: [
    {
      icon: 'duplicate',
      title: 'Дублировать',
      key: ACTION_TYPES.DUPLICATE,
    },
    {
      icon: 'editSent',
      title: 'Редактировать',
      key: ACTION_TYPES.EDIT_SENT,
    },
    {
      icon: 'cancel',
      title: 'Отменить запрос',
      key: ACTION_TYPES.CANCEL,
    },
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
    },
  ],
  canceled: [
    {
      icon: 'duplicate',
      title: 'Дублировать',
      key: ACTION_TYPES.DUPLICATE,
    },
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
    },
  ],
  archive: [
    {
      icon: 'duplicate',
      title: 'Дублировать',
      key: ACTION_TYPES.DUPLICATE,
    },
  ],
  hasProvider: [
    {
      icon: 'duplicate',
      title: 'Дублировать',
      key: ACTION_TYPES.DUPLICATE,
    },
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
    },
  ],
};

export const PROVIDER_MORE_REQUEST_ACTIONS = {
  canceled: [
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
    },
  ],
  canceledByProvider: [
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
    },
  ],
  archive: [],
  answered: [
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
    },
  ],
  notAnswered: [
    {
      icon: 'cancel',
      title: 'Отклонить',
      key: ACTION_TYPES.CANCEL,
    },
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
    },
  ],
  clientChosen: [
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
    },
  ],
  clientChosenOther: [
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
    },
  ],
  clientCanceled: [
    {
      icon: 'archive',
      title: 'В архив',
      key: ACTION_TYPES.ARCHIVE,
    },
  ],
};

export const ADMIN_MORE_REQUEST_ACTIONS = {
  active: [
    {
      icon: 'cancel',
      title: 'Отклонить',
      key: ACTION_TYPES.CANCEL,
    },
    {
      icon: 'checked',
      title: 'Подтвердить',
      key: ACTION_TYPES.ACCEPT,
    },
    {
      icon: 'send',
      title: 'Написать сообщение',
      key: ACTION_TYPES.SEND,
    },
  ],
  accepted: [
    {
      icon: 'send',
      title: 'Написать сообщение',
      key: ACTION_TYPES.SEND,
    },
  ],
  canceled: [
    {
      icon: 'send',
      title: 'Написать сообщение',
      key: ACTION_TYPES.SEND,
    },
  ],
  needsModeration: [
    {
      icon: 'cancel',
      title: 'Отклонить',
      key: ACTION_TYPES.CANCEL,
    },
    {
      icon: 'checked',
      title: 'Подтвердить',
      key: ACTION_TYPES.ACCEPT,
    },
    {
      icon: 'send',
      title: 'Написать сообщение',
      key: ACTION_TYPES.SEND,
    },
  ],
};
