import { Form } from 'antd';
import React from 'react';
import cn from 'classnames';

import { Input } from '@atoms';

const QuestionnaireChiller = ({ ...rest }) => {
  return (
    <Form {...rest} className={cn('questionnaire', 'questionnaire-chiller')}>
      <Form.Item
        label="Тестовое поле 1"
        name="chiller-test-1"
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
      >
        <Input.Enhanced placeholder="Впишите значение" />
      </Form.Item>
      <Form.Item
        label="Тестовое поле 2"
        name="chiller-test-2"
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
      >
        <Input.Enhanced placeholder="Впишите значение" />
      </Form.Item>
    </Form>
  );
};

export default QuestionnaireChiller;
