import styled, { css } from 'styled-components';

export const LoaderImage = styled.img`
  width: 13rem;
  height: 13rem;
  pointer-events: none;
  user-select: none;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.bodySecondary};

  ${({ small }) =>
    small &&
    css`
      ${LoaderImage} {
        width: 8rem;
        height: 8rem;
      }
    `}

  ${({ $transparent }) =>
    $transparent &&
    css`
      background-color: transparent;
    `}

  ${({ $center }) =>
    $center &&
    css`
      height: 100vh;
    `}
`;
