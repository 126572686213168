import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';

const CLIENT_API_OPTIONS = {
  baseUrl: 'https://teststim.biz-office.ru',
};

export const clientAPI = createApi({
  reducerPath: 'clientAPI',
  baseQuery: fetchBaseQuery({
    ...CLIENT_API_OPTIONS,
    prepareHeaders: (headers) => {
      // const authorizationToken = Cookies.get('access-token');
      const authorizationToken = localStorage.getItem('token');

      if (authorizationToken) {
        headers.set('Authorization', `Bearer ${authorizationToken}`);
      }

      return headers;
    },
  }),
  endpoints: () => ({}),
});
