import styled from 'styled-components';

export const ListContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  font-size: 2rem;
  line-height: 3rem;
  color: ${({ theme }) => theme.palette.buttonText};
  .icon {
    &.list-style {
      width: 3rem;
      height: 3rem;
      margin-right: 2rem;
    }
  }
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;
