import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import cn from 'classnames';

import { REQUEST_STATUSES } from '@constants/common';
import { Button, Checkbox, File, Icon, Table, Modal } from '@atoms';
import {
  useGetActiveAnswersQuery,
  useGetCanceledAnswersQuery,
  useSetViewedMutation,
  useGetRejectedAnswersQuery,
} from '@api/clientRequests';
import { useGetCurrencyQuery } from '@api/additional';
import { useGetAnswerFileQuery } from '@api/providerRequests';
import { setCurrentClickedAnswerID } from '@reducers/requests';
import { setAcceptResponseModalVisibility, setCancelResponseModalVisibility } from '@reducers/application';
import getFileType from '@helpers/getFileType';
import transformToFormData from '@helpers/transformToFormData';
import loading from '@helpers/loading';
import formatPrice from '@helpers/formatPrice';

import { useAddChatMutation } from '../../../api/chat';
import ChatButton from '../ChatButton';

import { ResponsesContainer } from './styles';

const Responses = ({ status, ...rest }) => {
  const { requestId, chatId } = useParams();

  const dispatch = useDispatch();

  const [files, setFiles] = useState({});

  const [compiledFiles, setCompiledFiles] = useState({});
  const [filesPromises, setFilesPromises] = useState([]);
  const [allRequests, setAllRequests] = useState([]);
  // filters
  const [isHiddenViewed, setIsHiddenViewed] = useState(false);
  const [isHiddenRevoked, setIsHiddenRevoked] = useState(false);
  const [priceSortOrder, setPriceSortOrder] = useState(null);
  const [deliveryWeekSortOrder, setDeliveryWeekSortOrder] = useState(null);
  const [nameFilterValue, setNameFilterValue] = useState(null);
  const [viewedFilterValue, setViewedFilterValue] = useState(null);
  const [canceledFilterValue, setCanceledFilterValue] = useState(null);

  const [isAllowedFetch, setIsAllowedFetch] = useState(true);

  const [filesToFetchList, setFilesToFetchList] = useState([]);

  const { currentRequestInfo } = useSelector(({ requests }) => requests);
  const { userInfoStatus, organizationInfoStatus, moderationChecked } = useSelector(({ user }) => user);

  const allParametersSent = userInfoStatus && organizationInfoStatus;
  const onModerationStatus = !moderationChecked && allParametersSent;

  const { data: currencyList } = useGetCurrencyQuery();
  const [addChatRequest, { isSuccess: isSuccessChat }] = useAddChatMutation();
  const {
    data: activeRequestAnswers,
    isSuccess: isSuccessActive,
    isLoading: isLoadingActive,
  } = useGetActiveAnswersQuery(requestId, {
    refetchOnMountOrArgChange: true,
    pollingInterval: 180000,
  });
  const {
    data: canceledRequestAnswers,
    isSuccess: isSuccessCanceled,
    isLoading: isLoadingCanceled,
  } = useGetCanceledAnswersQuery(requestId, {
    refetchOnMountOrArgChange: true,
    pollingInterval: 180000,
  });
  const {
    data: rejectedRequestAnswers,
    isSuccess: isSuccessRejected,
    isLoading: isLoadingRejected,
  } = useGetRejectedAnswersQuery(requestId, {
    refetchOnMountOrArgChange: true,
    pollingInterval: 180000,
  });

  const [setViewed] = useSetViewedMutation();

  const isSuccess = isSuccessActive && isSuccessCanceled && isSuccessRejected;
  const isLoadingTable = isLoadingActive && isLoadingCanceled && isLoadingRejected;

  const rejectedListWithStatus = rejectedRequestAnswers?.map((item) => ({ ...item, canceledByProvider: true }));
  const finalRequestsList = isSuccess ? [].concat(allRequests, rejectedListWithStatus) : [];
  const allRequestsDataSource = finalRequestsList?.map((item) => ({ ...item, key: item.id }));

  useEffect(() => {
    const allRequestsIDs = allRequests.map((item) => item.id);

    if (allRequestsIDs?.length) setFilesToFetchList(allRequestsIDs);
  }, [allRequests]);

  useEffect(() => {
    const canceledAnswersList = canceledRequestAnswers?.map((answer) => ({
      ...answer,
      canceled: true,
      key: answer.id,
    }));
    const activeAnswersList = activeRequestAnswers?.map((answer) => ({ ...answer, canceled: false, key: answer.id }));
    if (isSuccess) {
      setAllRequests([].concat(activeAnswersList, canceledAnswersList));
    }
  }, [activeRequestAnswers, canceledRequestAnswers, isSuccess]);

  // get responses files
  useEffect(() => {
    if (filesToFetchList.length > 0 && isAllowedFetch) {
      const fetchFilesPromises = filesToFetchList?.map((id) =>
        axios.get(`https://teststim.biz-office.ru/Requests/providerRequestsAnswerFilesList?ID_Requests_Answer=${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }),
      );

      setFilesToFetchList([]);

      setFilesPromises(fetchFilesPromises);
      setIsAllowedFetch(false);
    }
  }, [allRequests, compiledFiles, filesToFetchList, isAllowedFetch]);

  useEffect(() => {
    if (filesPromises.length > 0) {
      Promise.all(filesPromises).then((values) => {
        setFiles({
          ...files,
          ...values.map((item) => {
            const url = item.request.responseURL;
            const id = url.split(
              'https://teststim.biz-office.ru/Requests/providerRequestsAnswerFilesList?ID_Requests_Answer=',
            )[1];

            return { [id]: item.data };
          }),
        });

        setFilesPromises([]);
        setIsAllowedFetch(true);
      });
    }
  }, [files, filesPromises]);

  useEffect(() => {
    const arrayFiles = Object.values(files);
    const filesList = arrayFiles.reduce((obj, item) => ({ ...obj, ...item }), {});

    setCompiledFiles(filesList);
  }, [files]);

  const requestSent = status === REQUEST_STATUSES.SENT.name;
  const rejected = currentRequestInfo.iD_Status === -1;
  const providerChosen = allRequests?.find((item) => item.iD_Status === 1);
  const isTableDisabled = rejected;

  const setRowClassName = (record) => {
    const acceptedClassName = providerChosen ? cn(record.iD_Status === 1 ? 'row-accepted' : 'row-disabled') : '';
    const viewedClassName = record.viewed ? 'row-viewed' : '';

    const className = `${acceptedClassName} ${viewedClassName}`;

    return className;
  };

  const handleSubmitButton = (id) => {
    dispatch(setCurrentClickedAnswerID(id));
    dispatch(setAcceptResponseModalVisibility(true));
  };

  const handleCancelButton = (id) => {
    dispatch(setCurrentClickedAnswerID(id));
    dispatch(setCancelResponseModalVisibility(true));
  };

  const handleViewedChange = (event, id) => {
    setViewed(transformToFormData({ ID: id, Checked: event.target.checked }));
  };

  const setActionCell = (statusID, canceled, id, rejectedStatus, canceledByProvider) => {
    if (statusID === 1) {
      return <span className={cn('table-text', 'chosen')}>Выбран</span>;
    }

    if (canceled) {
      return <span className={cn('table-text')}>Отклонен</span>;
    }

    if (statusID === -2) {
      return <span className={cn('table-text')}>Отозван</span>;
    }

    if (canceledByProvider) {
      return <span className={cn('table-text')}>Отклонен поставщиком</span>;
    }

    if (rejectedStatus) {
      return <span className={cn('table-text', 'disabled')}>--</span>;
    }

    return !providerChosen ? (
      <Fragment>
        <Button $action $type="cancel" onClick={() => handleCancelButton(id)}>
          <Icon $transition name="close" className={cn('close')} />
        </Button>
        <Button $action $type="submit" onClick={() => handleSubmitButton(id)}>
          <Icon $transition name="checked" className={cn('checked')} />
        </Button>
      </Fragment>
    ) : (
      <span className={cn('table-text', 'disabled')}>--</span>
    );
  };

  const getTableEmptyText = (statusSent, statusRejected) => {
    if (statusSent || status === REQUEST_STATUSES.ARCHIVE.name || status === REQUEST_STATUSES.HAS_PROVIDER.name) {
      return <div />;
    }
    if (statusRejected) {
      return (
        <Table.Canceled requestName={`${currentRequestInfo.name_Equipment} - ${currentRequestInfo.object_Code}`} />
      );
    }
    if (onModerationStatus) {
      return (
        <Table.Empty
          hideButton
          text="Пока Ваша заявка находится на рассмотрении у администратора, Вы не можете отправлять запросы поставщикам."
        />
      );
    }
    return <Table.Empty text="Вы еще не отправляли свой запрос поставщикам" />;
  };

  const handleTableChange = (_, filters, sorter) => {
    setNameFilterValue(filters.name || null);
    setViewedFilterValue(filters.viewed || null);
    setCanceledFilterValue(filters.actions || null);
    if (sorter.field === 'sum') {
      setPriceSortOrder(sorter.order || null);
      setDeliveryWeekSortOrder(null);
    }
    if (sorter.field === 'delivery_Week') {
      setDeliveryWeekSortOrder(sorter.order || null);
      setPriceSortOrder(null);
    }
  };

  // filters
  const handleSearch = (confirm) => {
    confirm();
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };

  const handleFilterButtonClick = (isHidden, setIsHidden, clearFilters, confirm, setSelectedKeys) => {
    if (isHidden) {
      clearFilters();
      confirm();
      setIsHidden(false);
    } else {
      setSelectedKeys([true]);
      confirm();
      setIsHidden(true);
    }
  };

  const responsesColumns = [
    {
      title: 'Наименование организации',
      dataIndex: 'name',
      key: 'name',
      className: 'first-column',
      defaultFilteredValue: nameFilterValue,
      filterDropdown: useCallback(({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Table.Dropdown>
            <Table.SearchInput
              $secondary
              placeholder="Поиск организации"
              value={selectedKeys[0]}
              onPressEnter={() => handleSearch(confirm)}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            />
            <Table.SearchWrap>
              <Button onClick={() => handleSearch(confirm, selectedKeys)}>Поиск</Button>
              <Button $neutral onClick={() => clearFilters && handleReset(clearFilters, confirm)}>
                Очистить
              </Button>
            </Table.SearchWrap>
          </Table.Dropdown>
        );
      }, []),
      onFilter: (value, record) => {
        return record.organization_Name_Short.toString().toLowerCase().includes(value.toLowerCase());
      },
      render: (_, response) => (
        <p className={cn('table-title', 'transparent')}>
          {response.organization_Name_Short}
          <span>{dayjs(response.date_Add).format('D MMMM YYYY, HH:mm')}</span>
        </p>
      ),
    },
    {
      title: (
        <p className={cn('table-head-title')}>
          Стоимость
          <Icon $transition name="sort" className={cn('sort-icon')} />
        </p>
      ),
      dataIndex: 'sum',
      key: 'sum',
      align: 'center',
      width: '18rem',
      defaultSortOrder: priceSortOrder,
      sorter: (a, b) => {
        const { cB_Curs: firstCurrency } = currencyList.find((currency) => a.code_Currency === currency.code);
        const { cB_Curs: secondCurrency } = currencyList.find((currency) => b.code_Currency === currency.code);

        const firstSum = firstCurrency !== 0 ? a.sum * firstCurrency : a.sum;
        const secondSum = secondCurrency !== 0 ? b.sum * secondCurrency : b.sum;

        return firstSum - secondSum;
      },
      render: (_, response) => (
        <p className={cn('transparent')}>
          {response.sum ? `${formatPrice(response.sum)} ${response.short_Currency}` : '--'}
        </p>
      ),
    },
    {
      title: (
        <p className={cn('table-head-title')}>
          Срок поставки
          <Icon $transition name="sort" className={cn('sort-icon')} />
        </p>
      ),
      dataIndex: 'delivery_Week',
      key: 'delivery_Week',
      align: 'center',
      width: '18rem',
      defaultSortOrder: deliveryWeekSortOrder,
      sorter: (a, b) => a.delivery_Week - b.delivery_Week,
      render: (_, response) => (
        <p className={cn('transparent')}>{response.delivery_Week ? `${response.delivery_Week} (недель)` : '--'}</p>
      ),
    },
    {
      title: 'Тех. Характеристики',
      dataIndex: 'file',
      key: 'file',
      render: (_, response) => (
        <Fragment>
          {compiledFiles[response.id]?.map((file) => (
            <File
              key={file.name}
              className={cn('table', 'transparent')}
              title={file.name}
              link={`https://teststim.biz-office.ru/Additional/download?Folder=Answer&File_Path=${
                file.path
              }&File_Name=${file.name}${getFileType(file.path)}`}
              download
            />
          ))}
        </Fragment>
      ),
    },
    {
      title: (
        <p className={cn('table-head-title')}>
          Просмотрено
          <Icon $transition name="sort" className={cn('sort-icon')} />
        </p>
      ),
      dataIndex: 'viewed',
      key: 'viewed',
      align: 'center',
      filters: [],
      width: '11.5rem',
      defaultFilteredValue: viewedFilterValue,
      filterDropdown: useCallback(
        ({ confirm, clearFilters, setSelectedKeys }) => {
          return (
            <Table.Dropdown $width="10.9" $padding>
              <Table.HideButton
                onClick={() =>
                  handleFilterButtonClick(isHiddenViewed, setIsHiddenViewed, clearFilters, confirm, setSelectedKeys)
                }
              >
                <Icon name={isHiddenViewed ? 'show' : 'hide'} className={cn('hideIcon')} />
                {isHiddenViewed ? 'Показать' : 'Скрыть'}
              </Table.HideButton>
            </Table.Dropdown>
          );
        },
        [isHiddenViewed],
      ),
      onFilter: (value, record) => {
        return record.viewed !== true;
      },
      render: (_, { viewed, id }) => (
        <Checkbox onChange={(event) => handleViewedChange(event, id)} $secondary checked={viewed} />
      ),
    },
    {
      title: (
        <p className={cn('table-head-title')}>
          Чат
          <Icon $transition name="sort" className={cn('sort-icon')} />
        </p>
      ),
      dataIndex: 'chats',
      key: 'chats',
      align: 'center',
      width: '15rem',
      render: (_, response) => (
        <div>
          <ChatButton requestData={response} answerId={response.id} response={response} />
        </div>
      ),
      filterDropdown: useCallback(
        ({ confirm, clearFilters, setSelectedKeys }) => {
          return (
            <Table.Dropdown $width="18.5" $padding>
              <Table.HideButton
                onClick={() =>
                  handleFilterButtonClick(isHiddenRevoked, setIsHiddenRevoked, clearFilters, confirm, setSelectedKeys)
                }
              >
                <Icon name={isHiddenRevoked ? 'show' : 'hide'} className={cn('hideIcon')} />
                {isHiddenRevoked ? 'Показать все' : 'Скрыть отвеченные'}
              </Table.HideButton>
            </Table.Dropdown>
          );
        },
        [isHiddenRevoked],
      ),
      onFilter: (value, record) => {
        const isRevoked = record.iD_Status === -2;

        return isRevoked !== true;
      },
    },
    {
      title: (
        <p className={cn('table-head-title')}>
          Отклик
          <Icon $transition name="sort" className={cn('sort-icon')} />
        </p>
      ),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: '15rem',
      defaultFilteredValue: canceledFilterValue,
      filterDropdown: useCallback(
        ({ confirm, clearFilters, setSelectedKeys }) => {
          return (
            <Table.Dropdown $width="18.5" $padding>
              <Table.HideButton
                onClick={() =>
                  handleFilterButtonClick(isHiddenRevoked, setIsHiddenRevoked, clearFilters, confirm, setSelectedKeys)
                }
              >
                <Icon name={isHiddenRevoked ? 'show' : 'hide'} className={cn('hideIcon')} />
                {isHiddenRevoked ? 'Показать отозванные' : 'Скрыть отозванные'}
              </Table.HideButton>
            </Table.Dropdown>
          );
        },
        [isHiddenRevoked],
      ),
      onFilter: (value, record) => {
        const isRevoked = record.iD_Status === -2;

        return isRevoked !== true;
      },
      render: (_, response) => (
        <div className={cn('table-actions')}>
          {setActionCell(response.iD_Status, response.canceled, response.id, rejected, response.canceledByProvider)}
        </div>
      ),
    },
  ];

  return (
    <ResponsesContainer {...rest}>
      {loading(
        isLoadingTable,
        <Fragment>
          <Table
            onChange={handleTableChange}
            className={cn(isTableDisabled && 'table-disabled')}
            locale={{ emptyText: getTableEmptyText(requestSent, rejected) }}
            columns={responsesColumns}
            rowClassName={setRowClassName}
            dataSource={allRequestsDataSource}
          />
          {allRequests?.length > 0 && rejected && (
            <Table.Canceled
              className={cn('padding')}
              requestName={`${currentRequestInfo.name_Equipment} - ${currentRequestInfo.object_Code}`}
            />
          )}
        </Fragment>,
      )}
      <Modal.AcceptResponse />
      <Modal.CancelResponse />
    </ResponsesContainer>
  );
};

export default Responses;
