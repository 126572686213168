import styled, { css } from 'styled-components';
import { Menu } from 'antd';

export const MenuItemTitleText = styled.span`
  color: ${({ theme }) => theme.palette.siderText};
  font-size: 1.2rem;
  line-height: 1.6rem;
  max-width: 9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.2s;
`;

export const MenuItemTitle = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  transition: 0.2s;
  color: ${({ theme }) => theme.palette.siderText};
  font-size: 1.2rem;
  line-height: 1.6rem;
`;

export const MenuItemInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.6rem;
  border-radius: 50%;
  width: 3.1rem;
  height: 3.1rem;

  ${({ role }) =>
    role === 'client' &&
    css`
      background-color: ${({ theme }) => theme.palette.siderSecondary};
      color: ${({ theme }) => theme.palette.sider};
    `}

  ${({ role }) =>
    role === 'provider' &&
    css`
      background-color: ${({ theme }) => theme.palette.providerUser};
      color: ${({ theme }) => theme.palette.sider};
    `}

  ${({ role }) =>
    role === 'admin' &&
    css`
      background-color: ${({ theme }) => theme.palette.sider};
      color: ${({ theme }) => theme.palette.siderTextActive};
    `}
`;

export const MenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1.4rem 0 2.5rem;
  transition: 0.2s;
  &.active {
    background-color: ${({ theme }) => theme.palette.siderHover};
    .menu-icon {
      fill: ${({ theme }) => theme.palette.siderTextActive};
      &.stroke {
        stroke: ${({ theme }) => theme.palette.siderTextActive};
      }
      &.settings {
        stroke: ${({ theme }) => theme.palette.siderTextActive};
      }
      &.star,
      &.cancel,
      &.archive,
      &.file {
        stroke: ${({ theme }) => theme.palette.siderTextActive};
      }
    }
    ${MenuItemTitle} {
      color: ${({ theme }) => theme.palette.siderTextActive};
    }
    ${MenuItemTitleText} {
      color: ${({ theme }) => theme.palette.siderTextActive};
    }
  }
  &.padding {
    padding-left: 2.1rem;
  }
  &.submenu-item {
    padding-left: 4rem;
    &:hover {
      .icon.more {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .icon {
    margin-right: 1.2rem;
    transition: stroke 0.2s, fill 0.2s;
    width: 1.8rem;
    height: 1.8rem;
    fill: ${({ theme }) => theme.palette.siderText};
    flex-shrink: 0;
    &.menu-arrow {
      width: 0.8rem;
      height: 0.6rem;
      fill: ${({ theme }) => theme.palette.siderText};
    }
    &.stroke {
      stroke: ${({ theme }) => theme.palette.siderText};
      fill: none;
    }
    &.settings {
      stroke: ${({ theme }) => theme.palette.siderText};
    }
    &.more {
      position: relative;
      z-index: 10;
      width: 2rem;
      height: 2rem;
      margin: 0 0 0 auto;
      opacity: 0;
      visibility: hidden;
      transition: 0.2s;
    }
    &.star,
    &.cancel,
    &.archive,
    &.file {
      stroke: ${({ theme }) => theme.palette.siderText};
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.palette.siderHover};
    .menu-icon {
      fill: ${({ theme }) => theme.palette.siderTextActive};
      &.stroke {
        stroke: ${({ theme }) => theme.palette.siderTextActive};
      }
      &.settings {
        stroke: ${({ theme }) => theme.palette.siderTextActive};
      }
      &.star,
      &.cancel,
      &.archive,
      &.file {
        stroke: ${({ theme }) => theme.palette.siderTextActive};
      }
    }
    ${MenuItemTitle} {
      color: ${({ theme }) => theme.palette.siderTextActive};
    }
    ${MenuItemTitleText} {
      color: ${({ theme }) => theme.palette.siderTextActive};
    }
  }
  ${({ $bordered }) =>
    $bordered &&
    css`
      border-top: 1px solid rgba(238, 239, 245, 0.12);
    `}
`;

export const MenuItemBlock = styled.div``;

export const MenuItemStatus = styled.p`
  margin: 0.4rem 0rem 0rem 0rem;
  color: ${({ theme }) => theme.palette.siderTextSecondary};
  font-size: 1.1rem;
  line-height: 1.5rem;
  font-style: italic;
`;

export const MenuItemNotificaionMark = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.notification};
  flex-shrink: 0;
  margin-right: 0.6rem;
  transition: 0.2s;

  ${({ $count }) =>
    $count &&
    css`
      width: auto;
      height: auto;
      padding: 0.2rem 0.7rem;
      color: ${({ theme }) => theme.palette.siderTextActive};
      background-color: ${({ theme }) => theme.palette.notificationSecondary};
      font-size: 1.2rem;
      line-height: 1.6rem;
    `}
`;

export const MenuCategoryItemContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  /* align-items: center; */
  .icon {
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);
    width: 2rem;
    height: 2rem;
    fill: ${({ theme }) => theme.palette.drawerText};
    &.ant-dropdown-open {
      fill: ${({ theme }) => theme.palette.button};
    }
    &:hover {
      fill: ${({ theme }) => theme.palette.button};
    }
  }
`;

export const MenuContainer = styled(Menu)`
  background-color: transparent;
  border: none;
  .ant-menu {
    background-color: transparent;
    &-title-content {
      height: 100%;
    }
    &-submenu {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0.1rem;
        background-color: rgba(238, 239, 245, 0.12);
        transition: 0.2s;
        opacity: 0;
        visibility: hidden;
      }
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.1rem;
        background-color: rgba(238, 239, 245, 0.12);
        transition: 0.2s;
        opacity: 0;
        visibility: hidden;
      }
      &-arrow {
        display: none;
      }
      &-open {
        .ant-menu-title-content:not(.ant-menu-sub *) {
          span {
            color: ${({ theme }) => theme.palette.siderTextActive};
          }
        }
        .menu-arrow {
          transform: rotateX(180deg);
          fill: ${({ theme }) => theme.palette.siderTextActive};
        }
        &::before,
        &::after {
          opacity: 1;
          visibility: visible;
        }
      }
      &-title {
        padding: 0 !important;
        margin: 0;
      }
    }
    &-item {
      padding: 0 !important;
      margin: 0 !important;
      &::after {
        content: none;
      }
    }
  }

  &.category-menu {
    > .ant-menu-item {
      padding-left: 2.5rem !important;
    }
    > .ant-menu-submenu {
      position: relative;
      &.ant-menu-submenu-selected {
        > .ant-menu-submenu-title {
          background-color: ${({ theme }) => theme.palette.card};
          > .ant-menu-title-content {
            a {
              color: ${({ theme }) => theme.palette.button};
            }
            /* color: ${({ theme }) => theme.palette.button}; */
          }
        }
        &::before {
          border-top-color: ${({ theme }) => theme.palette.button};
        }
      }
      > .ant-menu-submenu-title {
        padding-left: 4.5rem !important;
      }

      &::before {
        content: '';
        position: absolute;
        top: 1.7rem;
        left: 2.8rem;
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 5px solid ${({ theme }) => theme.palette.text};
        z-index: 1;
      }
    }
    .ant-menu {
      &-title-content {
        display: flex;
        /* align-items: center; */
        a {
          display: flex;
          align-items: center;
          flex: 100%;
          font-size: 1.2rem;
          line-height: 1.6rem;
          color: ${({ theme }) => theme.palette.text};
        }
      }
      &-item {
        &-active {
          background-color: ${({ theme }) => theme.palette.card};
          .ant-menu-title-content {
            a {
              color: ${({ theme }) => theme.palette.text} !important;
            }
          }
        }
        &-selected {
          background-color: ${({ theme }) => theme.palette.card};
          .ant-menu-title-content {
            color: ${({ theme }) => theme.palette.button};
            a {
              color: ${({ theme }) => theme.palette.button} !important;
            }
          }
        }
      }
      &-submenu {
        &-open {
          > .ant-menu-submenu-title {
            .ant-menu-title-content {
              a {
                color: ${({ theme }) => theme.palette.button};
              }
            }
          }
          &::before {
            transform: rotate(180deg);
            border-top-color: ${({ theme }) => theme.palette.button};
          }
        }
        &-active {
          > .ant-menu-submenu-title {
            background-color: ${({ theme }) => theme.palette.card};
          }
        }
        &-title-content {
          padding: 0 2.5rem !important;
        }
        &-selected {
          > .ant-menu-submenu-title {
            .ant-menu-title-content {
              color: ${({ theme }) => theme.palette.button};
            }
            &::after {
              color: ${({ theme }) => theme.palette.button} !important;
            }
          }
        }
        &::before {
          opacity: 1;
          visibility: visible;
        }
      }
      &-sub {
        > .ant-menu-submenu {
          .ant-menu-submenu-title {
            position: relative;
            &::after {
              content: '+';
              position: absolute;
              left: 5rem;
              font-size: 1.7rem;
              color: ${({ theme }) => theme.palette.text};
              z-index: 1;
            }
          }
        }
        > .ant-menu-submenu-open {
          .ant-menu-submenu-title {
            position: relative;
            &::after {
              content: '-';
              top: -0.1rem;
              left: 5.2rem;
              color: ${({ theme }) => theme.palette.button};
            }
          }
        }
        .ant-menu-title-content {
          padding-left: 7.2rem !important;
        }
      }
    }
  }

  ${({ $admin }) =>
    $admin &&
    css`
      ${MenuItemTitle} {
        color: ${({ theme }) => theme.palette.text};
      }
      ${MenuItemNotificaionMark} {
        color: ${({ theme }) => theme.palette.text};
        background-color: ${({ theme }) => theme.palette.notificationAdmin};
      }
      ${MenuItemContainer} {
        border-color: rgba(40, 35, 85, 0.12);
        .menu-icon {
          fill: ${({ theme }) => theme.palette.text};
          &.stroke {
            stroke: ${({ theme }) => theme.palette.text};
          }
          &.settings {
            stroke: ${({ theme }) => theme.palette.text};
          }
          &.star,
          &.pencil,
          &.database,
          &.file {
            stroke: ${({ theme }) => theme.palette.text};
          }
        }
        &.active {
          background-color: ${({ theme }) => theme.palette.siderSecondary};
          .menu-icon {
            fill: ${({ theme }) => theme.palette.sider};
            &.stroke {
              stroke: ${({ theme }) => theme.palette.sider};
            }
            &.settings {
              stroke: ${({ theme }) => theme.palette.sider};
            }
            &.star,
            &.pencil,
            &.database,
            &.file {
              stroke: ${({ theme }) => theme.palette.sider};
            }
          }
          ${MenuItemTitle} {
            color: ${({ theme }) => theme.palette.sider};
          }
          /* ${MenuItemNotificaionMark} {
            background-color: ${({ theme }) => theme.palette.siderSecondary};
          } */
        }
        &:hover {
          background-color: ${({ theme }) => theme.palette.siderSecondaryHover};
          .menu-icon {
            fill: ${({ theme }) => theme.palette.sider};
            &.stroke {
              stroke: ${({ theme }) => theme.palette.sider};
            }
            &.settings {
              stroke: ${({ theme }) => theme.palette.sider};
            }
            &.star,
            &.pencil,
            &.database,
            &.file {
              stroke: ${({ theme }) => theme.palette.sider};
            }
          }
          ${MenuItemTitle} {
            color: ${({ theme }) => theme.palette.sider};
          }
          ${MenuItemNotificaionMark} {
            background-color: ${({ theme }) => theme.palette.siderSecondary};
          }
        }
      }
    `}
`;
