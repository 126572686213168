/* eslint-disable camelcase */
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';

import { Button, Icon, Radio, Tooltip, Upload } from '@atoms';
import transformToFormData from '@helpers/transformToFormData';
import { useAcceptEquipmentRequestMutation, useDenyEquipmentRequestMutation } from '@api/user';
import getFileType from '@helpers/getFileType';

import {
  EquipmentTableActions,
  EquipmentTableButton,
  EquipmentTableContainer,
  EquipmentTableElement,
  EquipmentTableFile,
  EquipmentTableHead,
  EquipmentTableHeadTitle,
  EquipmentTableRow,
  EquipmentTableRowWrap,
  EquipmentTableSubtitle,
  EquipmentTableTag,
  EquipmentTableText,
  EquipmentTableTitle,
} from './styles';

const EquipmentTableItem = ({ itemData, readOnly, addItem, removeItem, admin, addFileCheck, removeFileCheck }) => {
  // 1 - на модерации
  // 2 - пройдена
  // -1 - не пройдена
  const { id, name, equipType, moderationStatus, file, fileName, available } = itemData;

  const notAvailable = available === false || available === 0;

  const [acceptEquipmentRequest] = useAcceptEquipmentRequestMutation();
  const [denyEquipmentRequest] = useDenyEquipmentRequestMutation();

  const [checkedValue, setCheckedValue] = useState(equipType);
  const [fileList, setFileList] = useState(file ? [file] : []);
  const [previousItemData, setPreviousItemData] = useState(itemData);
  const [currentItemData, setCurrentItemData] = useState(itemData);
  const [uploadedFileUrl, setUploadedFileUrl] = useState(null);

  const [allowEditItem, setAllowEditItem] = useState(false);
  const [allowSendFile, setAllowSendFile] = useState(false);

  const hasFile = fileList.length > 0;
  const showUploadFile = checkedValue === 2 && !hasFile;

  useEffect(() => {
    if (checkedValue === 1) {
      setFileList([]);
    }
  }, [checkedValue]);

  useEffect(() => {
    if (fileList[0] && !file) {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(fileList[0]);

      fileReader.addEventListener('load', () => {
        const url = fileReader.result;

        setUploadedFileUrl(url);
      });
    }
  }, [file, fileList]);

  const uploadProps = {
    beforeUpload: (fileItem) => {
      setFileList([fileItem]);
      setAllowSendFile(true);
      return false;
    },

    fileList,
  };

  const fileType = getFileType(file);

  useEffect(() => {
    if (allowEditItem) {
      if (JSON.stringify(previousItemData) !== JSON.stringify(currentItemData)) {
        addItem(currentItemData);
        setAllowEditItem(false);
      } else {
        removeItem(currentItemData.id);
        setAllowEditItem(false);
      }
    }
  }, [addItem, allowEditItem, currentItemData, previousItemData, removeItem]);

  useEffect(() => {
    if (hasFile && !readOnly && allowSendFile) {
      addFileCheck(fileList, id);
      setAllowSendFile(false);
    }
  }, [addFileCheck, allowSendFile, fileList, hasFile, id, itemData, readOnly]);

  const handleCheck = (event) => {
    setCheckedValue(event.target.value);
    setCurrentItemData({ ...itemData, equipType: event.target.value });
    setAllowEditItem(true);
  };

  const handleRadioClick = (event) => {
    if (event.target.checked) {
      setCheckedValue(0);
      setCurrentItemData({ ...itemData, equipType: 0 });
      setAllowEditItem(true);
    }
  };

  const removeFile = () => {
    setFileList([]);
    removeFileCheck(id);
    setCurrentItemData({ ...itemData, hasFile: false });
  };

  const acceptRequest = () => {
    acceptEquipmentRequest(transformToFormData({ ID_User: itemData.user_Id, ID_Equipment: id }));
  };

  const cancelRequest = () => {
    denyEquipmentRequest(transformToFormData({ ID_User: itemData.user_Id, ID_Equipment: id }));
  };

  const getModerationStatus = () => {
    if (moderationStatus === 1) {
      return <EquipmentTableSubtitle>(На модерации)</EquipmentTableSubtitle>;
    }

    if (moderationStatus === 2) {
      return <EquipmentTableSubtitle className={cn('success')}>(Модерация пройдена)</EquipmentTableSubtitle>;
    }

    if (moderationStatus === -1) {
      return <EquipmentTableSubtitle className={cn('error')}>(Модерация не пройдена)</EquipmentTableSubtitle>;
    }

    return false;
  };

  if (readOnly) {
    return (
      <EquipmentTableRow className={cn(itemData.new && 'new')}>
        <EquipmentTableRowWrap $grid>
          <EquipmentTableElement>
            <EquipmentTableTitle>{name}</EquipmentTableTitle>
            {moderationStatus === 1 && <EquipmentTableTag>новый</EquipmentTableTag>}
          </EquipmentTableElement>

          <EquipmentTableElement>
            <Radio.Group className={cn('radio-group', 'disabled')} value={equipType} $grid>
              <Radio $checkbox value={1} />
              <Radio $checkbox value={2} />
            </Radio.Group>
          </EquipmentTableElement>

          <EquipmentTableElement $end>
            {hasFile && (
              <Fragment>
                <Tooltip
                  overlayClassName={cn('small-tooltip')}
                  title="Скачать файл"
                  placement="bottom"
                  arrowPointAtCenter
                >
                  <EquipmentTableFile
                    as="a"
                    download
                    href={`https://teststim.biz-office.ru/Additional/download?Folder=Equipment&File_Path=${file}&File_Name=${fileName}${fileType}`}
                  >
                    <Icon name="file" className={cn('file')} />
                    Файл загружен
                  </EquipmentTableFile>
                </Tooltip>
              </Fragment>
            )}
          </EquipmentTableElement>
        </EquipmentTableRowWrap>
        {admin && (
          <EquipmentTableActions $disabled={moderationStatus !== 1}>
            <Button
              onClick={cancelRequest}
              className={cn('action-button', 'small', 'cancel', moderationStatus === 2 && 'invisible')}
            >
              <Icon name="close" className={cn('cancel')} />
            </Button>
            <Button
              onClick={acceptRequest}
              className={cn('action-button', 'small', 'accept', moderationStatus === -1 && 'invisible')}
            >
              <Icon name="checked" className={cn('accept')} />
            </Button>
          </EquipmentTableActions>
        )}
      </EquipmentTableRow>
    );
  }

  return (
    <EquipmentTableRow className={cn(notAvailable && 'notAvailable')}>
      <EquipmentTableRowWrap $grid>
        <EquipmentTableElement>
          <EquipmentTableTitle>{name}</EquipmentTableTitle>
          {getModerationStatus()}
        </EquipmentTableElement>

        <EquipmentTableElement>
          {notAvailable ? (
            <EquipmentTableText $error $center>
              Данная категория оборудования уже выбрана другим пользователем в вашей компании.
            </EquipmentTableText>
          ) : (
            <Radio.Group value={checkedValue} onChange={handleCheck} $grid>
              <Radio $checkbox onClick={handleRadioClick} value={1} />
              <Radio $checkbox onClick={handleRadioClick} value={2} />
            </Radio.Group>
          )}
        </EquipmentTableElement>

        <EquipmentTableElement $end>
          {hasFile && (
            <Fragment>
              {file ? (
                <Tooltip
                  overlayClassName={cn('small-tooltip')}
                  title="Скачать файл"
                  placement="bottom"
                  arrowPointAtCenter
                >
                  <EquipmentTableFile
                    as="a"
                    download
                    href={`https://teststim.biz-office.ru/Additional/download?Folder=Equipment&File_Path=${file}&File_Name=${fileName}${fileType}`}
                  >
                    <Icon name="file" className={cn('file')} />
                    Файл загружен
                  </EquipmentTableFile>
                </Tooltip>
              ) : (
                <Tooltip
                  overlayClassName={cn('small-tooltip')}
                  title="Скачать файл"
                  placement="bottom"
                  arrowPointAtCenter
                >
                  <EquipmentTableFile as="a" download href={uploadedFileUrl}>
                    <Icon name="file" className={cn('file')} />
                    Файл готов к загрузке
                  </EquipmentTableFile>
                </Tooltip>
              )}
              <Tooltip
                overlayClassName={cn('small-tooltip', 'delete')}
                title="Удалить"
                placement="bottom"
                arrowPointAtCenter
              >
                <EquipmentTableButton onClick={removeFile} className={cn('equipment-table-delete-button')}>
                  <Icon $transition name="delete" className={cn('delete')} />
                </EquipmentTableButton>
              </Tooltip>
            </Fragment>
          )}
        </EquipmentTableElement>
      </EquipmentTableRowWrap>

      {showUploadFile && (
        <EquipmentTableRowWrap $center>
          <Upload {...uploadProps} maxCount={1} showUploadList={false} accept="image/*,.pdf">
            <Button className={cn('upload-button')}>
              <Icon $transition name="file" className={cn('upload-icon')} />
              Выбрать файл
            </Button>
          </Upload>
          <EquipmentTableText>Выберите файл с лицензией (изображение или pdf)</EquipmentTableText>
        </EquipmentTableRowWrap>
      )}
    </EquipmentTableRow>
  );
};

const EquipmentTable = ({
  equipmentList,
  setChangedEquipment,
  admin,
  isSuccessSetEquipmentType,
  checkEquipFileList,
  setCheckEquipFileList,
  ...rest
}) => {
  const [listWithChanges, setListWithChanges] = useState({});

  const hasEquipment = equipmentList.length > 0;

  useEffect(() => {
    if (setChangedEquipment) {
      setChangedEquipment(Object.values(listWithChanges));
    }
  }, [listWithChanges, setChangedEquipment]);

  useEffect(() => {
    if (isSuccessSetEquipmentType) {
      setListWithChanges({});
    }
  }, [isSuccessSetEquipmentType]);

  const addItem = useCallback(
    (itemData) => {
      setListWithChanges({ ...listWithChanges, [itemData.id]: itemData });
    },
    [listWithChanges],
  );

  const removeItem = useCallback(
    (id) => {
      const filteredItems = { ...listWithChanges };
      delete filteredItems[id];
      setListWithChanges(filteredItems);
    },
    [listWithChanges],
  );

  const addFileCheck = useCallback(
    (files, id) => {
      setCheckEquipFileList({ ...checkEquipFileList, [id]: { files, id } });
    },
    [checkEquipFileList, setCheckEquipFileList],
  );

  const removeFileCheck = useCallback(
    (id) => {
      const filteredItems = { ...checkEquipFileList };
      delete filteredItems[id];
      setCheckEquipFileList(filteredItems);
    },
    [checkEquipFileList, setCheckEquipFileList],
  );

  return (
    <EquipmentTableContainer {...rest}>
      <EquipmentTableHead>
        <EquipmentTableHeadTitle>Наименование</EquipmentTableHeadTitle>
        <EquipmentTableHeadTitle $center>Изготовитель</EquipmentTableHeadTitle>
        <EquipmentTableHeadTitle $center>Дистрибьютор</EquipmentTableHeadTitle>
      </EquipmentTableHead>
      {!hasEquipment && 'В этой категории пока нет оборудования'}
      {equipmentList?.map((item) => (
        <EquipmentTableItem
          addItem={addItem}
          removeItem={removeItem}
          addFileCheck={addFileCheck}
          removeFileCheck={removeFileCheck}
          readOnly={admin}
          key={item.id}
          itemData={item}
          admin={admin}
        />
      ))}
    </EquipmentTableContainer>
  );
};

export default EquipmentTable;
