import { Table } from 'antd';
import styled, { css } from 'styled-components';

import { Input, Button } from '@atoms';

export const TableContainer = styled(Table)`
  .ant-table {
    &-column {
      &-title {
        transition: 0.2s;
      }
      &-has-sorters {
        &:hover {
          background-color: ${({ theme }) => theme.palette.body} !important;
          .ant-table-column {
            &-title {
              color: ${({ theme }) => theme.palette.button};
              .icon {
                fill: ${({ theme }) => theme.palette.button};
              }
            }
          }
        }
      }
      &-sort {
        .ant-table-column {
          &-title {
            color: ${({ theme }) => theme.palette.button};
            .icon {
              fill: ${({ theme }) => theme.palette.button};
            }
          }
        }
      }
      &-sorters {
        justify-content: center;
      }
      &-sorter {
        display: none;
      }
      &-title {
        flex: unset;
      }
    }
    &-cell {
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: ${({ theme }) => theme.palette.text};
      p {
        margin-bottom: 0;
      }
      &-row {
        &-hover {
          background-color: ${({ theme }) => theme.palette.card} !important;
          .table-title {
            &:not(span) {
              color: ${({ theme }) => theme.palette.link};
            }
          }
        }
      }
      &.first-column {
        .ant-table {
          &-filter-column {
            justify-content: flex-start;
          }
          &-column-title {
            color: ${({ theme }) => theme.palette.tableTitle};
          }
        }
      }
    }
    table {
    }
    &-filter {
      &-column {
        justify-content: center;
        &:hover {
          .ant-table-column-title {
            color: ${({ theme }) => theme.palette.button};
            .icon {
              fill: ${({ theme }) => theme.palette.button};
            }
          }
        }
      }
      &-trigger {
        position: absolute;
        z-index: 1;
        margin: 0;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        justify-content: flex-end;
        .anticon-filter {
          display: none;
        }
        &:hover {
          background-color: transparent;
        }
      }
    }
    &-thead {
      border-collapse: separate !important;
      border-radius: 10px;
      .ant-table-cell {
        font-size: 1.1rem;
        line-height: 1.5rem;
        padding: 1.2rem 1rem;
        background-color: ${({ theme }) => theme.palette.body};
        &:before {
          display: none;
        }
      }
    }
    td.ant-table-column-sort {
      background-color: transparent;
    }
    &-pagination {
      display: none;
    }
    &-row {
      &.row-viewed {
        .ant-table-cell {
          background-color: ${({ theme }) => theme.palette.tableRowSeen};
          &-row {
            &-hover {
              background-color: ${({ theme }) => theme.palette.tableRowSeen} !important;
            }
          }
          .transparent {
            /* opacity: 0.6; */
          }
        }
      }
      &.row-disabled {
        /* opacity: 0.7; */
      }
    }
    &-empty {
      .ant-table-cell {
        border: none;
      }
    }
  }
  .icon {
    &.sort-icon {
      fill: ${({ theme }) => theme.palette.secondaryText};
      width: 1.2rem;
      height: 1.2rem;
    }
  }
  .table {
    &-title {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      transition: 0.2s;
      span {
        display: inline-block;
        margin-top: 0.4rem;
        font-size: 1.1rem;
        line-height: 1.5rem;
        color: ${({ theme }) => theme.palette.statusDraft};
      }
    }
    &-actions {
      display: flex;
      justify-content: center;
      gap: 1rem;
      .table-text {
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: ${({ theme }) => theme.palette.revoked};
        &.chosen {
          color: ${({ theme }) => theme.palette.submit};
        }
        &.disabled {
          color: ${({ theme }) => theme.palette.text};
        }
      }
    }
    &-head-title {
      display: flex;
      align-items: center;
      .icon {
        margin-left: 0.5rem;
      }
    }
  }
  &.table-disabled {
    .ant-table {
      &-thead {
        opacity: 0.5;
        pointer-events: none;
      }
      &-cell {
        border: none;
      }
    }
  }
  &.equip-table {
    max-width: 77.5rem;
    .ant-table {
      &-thead {
        .first-column {
          color: ${({ theme }) => theme.palette.tableTitle};
        }
      }
      &-tbody {
        .ant-table-cell {
          padding: 0.7rem 1.5rem 0.7rem 1rem;
        }
      }
      &-empty {
        .ant-table-tbody {
          .ant-table-cell {
            display: none;
          }
        }
      }
    }
    .table-tag {
      margin: 0px auto;
      width: max-content;
      padding: 0.3rem 0.7rem;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: ${({ theme }) => theme.palette.buttonText};
      background-color: ${({ theme }) => theme.palette.providerChosen};
      border-radius: 2px;
      &.secondary {
        background-color: ${({ theme }) => theme.palette.notification};
      }
    }
    .table-link {
      .ant-checkbox-wrapper {
        margin-right: 0.7rem;
      }
      .table-text {
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: ${({ theme }) => theme.palette.text};
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

export const TableDropdown = styled.div`
  width: ${({ $width }) => ($width ? `${$width}rem` : '24.7rem')};
  padding: 0.8rem 0.8rem 1.3rem;
  background: ${({ theme }) => theme.palette.bodySecondary};
  border: 1px solid ${({ theme }) => theme.palette.drawerText};
  border-radius: 4px;
  ${({ $padding }) =>
    $padding &&
    css`
      padding: 1.1rem 1.2rem;
    `}
`;

export const TableSearchWrap = styled.div`
  display: flex;
  gap: 1rem;
  .button {
    width: 100%;
  }
`;

export const TableSearchButton = styled(Button)``;

export const TableSearchInput = styled(Input)`
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.palette.text};
  padding: 0.8rem 1rem;
  border-color: ${({ theme }) => theme.palette.borderNeutral};
  border-radius: 3px;
  &::placeholder {
    color: ${({ theme }) => theme.palette.secondaryText};
    transition: 0.2s;
  }
  &:hover {
    border-color: ${({ theme }) => theme.palette.borderNeutral} !important;
  }
  &:focus {
    border-color: ${({ theme }) => theme.palette.statusSent} !important;
    box-shadow: none;
    &::placeholder {
      color: ${({ theme }) => theme.palette.drawerText};
    }
  }
`;

export const TableHideButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.palette.tertiaryText};
  cursor: pointer;
  .icon {
    width: 1.7rem;
    height: 1.3rem;
    margin-right: 1rem;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2.2rem;

  &.padding {
    margin-top: 1rem;
    padding-left: 1.6rem;
  }
`;

export const TableText = styled.p`
  max-width: 31rem;
  margin: 0;
  text-align: left;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.palette.text};

  ${({ $warning }) =>
    $warning &&
    css`
      max-width: 100%;
      color: ${({ theme }) => theme.palette.statusCanceledSecondary};
    `}

  ${({ $wide }) =>
    $wide &&
    css`
      max-width: 100%;
    `}
`;
