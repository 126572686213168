import styled from 'styled-components';

export const QuestionnaireHeatPointContainer = styled.div`
  .ant-form {
    &-item {
      &-control-input {
        min-height: auto;
      }
    }
  }
  .ant-radio {
    &-group {
      .ant-radio-wrapper {
        margin: 0;
        &:not(:last-child) {
          margin-right: 2rem;
        }
      }
    }
  }
  .form-block {
    margin-bottom: 0 !important;
  }
  .radio-group {
    &-column {
      display: flex;
      flex-direction: column;
    }
  }
  .textarea {
    padding-bottom: 2rem;
    border-color: ${({ theme }) => theme.palette.drawerText};
    box-shadow: none;
    height: 17rem;
    &.ant-input-status-success {
      border-color: ${({ theme }) => theme.palette.borderHighlighted};
    }
    &::-webkit-scrollbar {
      width: 0.4rem;
    }
    &::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.palette.bodySecondary};
      margin: 0.5rem 0;
    }
    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.palette.body};
      border-radius: 10px;
    }
  }
`;
