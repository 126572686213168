import { Button } from 'antd';
import styled, { css } from 'styled-components';

export const ButtonContainer = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.button};
  border-radius: 2px;
  color: ${({ theme }) => theme.palette.buttonText};
  font-size: 1.2rem;
  line-height: 1.6rem;
  border: none;
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.palette.buttonHover};
    color: ${({ theme }) => theme.palette.buttonText};
  }
  &:after {
    display: none;
  }
  &[disabled] {
    opacity: 0.6;
    background-color: ${({ theme }) => theme.palette.button};
    color: ${({ theme }) => theme.palette.buttonText};
    &:hover {
      background-color: ${({ theme }) => theme.palette.button};
      color: ${({ theme }) => theme.palette.buttonText};
    }
  }

  ${({ secondary }) =>
    secondary &&
    css`
      background-color: ${({ theme }) => theme.palette.body};
      color: ${({ theme }) => theme.palette.text};
      &:hover {
        background-color: ${({ theme }) => theme.palette.sider};
        color: ${({ theme }) => theme.palette.siderTextActive};
      }
      &:focus {
        background-color: ${({ theme }) => theme.palette.body};
        color: ${({ theme }) => theme.palette.text};
      }
      &.button-hover-secondary {
        &:hover {
          background-color: ${({ theme }) => theme.palette.button};
          color: ${({ theme }) => theme.palette.buttonText};
        }
      }
    `}

  ${({ $icon }) =>
    $icon &&
    css`
      padding: 0;
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      flex-shrink: 0;
      background-color: ${({ theme }) => theme.palette.body};
      .plus-icon {
        stroke: ${({ theme }) => theme.palette.sider};
        width: 1.7rem;
        height: 1.7rem;
        transition: 0.3s;
      }
      &:hover {
        .plus-icon {
          stroke: ${({ theme }) => theme.palette.bodySecondary};
        }
      }
    `}

  ${({ $action }) =>
    $action &&
    css`
      width: 4rem;
      height: 3.2rem;
      flex-shrink: 0;
      border-radius: 2px;
      background-color: ${({ theme }) => theme.palette.body};
      padding: 0;
      .icon {
        width: 1rem;
        height: 1rem;
      }
      ${({ $type }) =>
        $type && $type === 'cancel'
          ? css`
              transition: 0.2s;
              background-color: ${({ theme }) => theme.palette.cancelButton};
              .icon {
                stroke: ${({ theme }) => theme.palette.cancel};
              }
              &:focus {
                background-color: ${({ theme }) => theme.palette.cancelButton};
                .icon {
                  stroke: ${({ theme }) => theme.palette.cancel};
                }
              }
              &:hover {
                background-color: ${({ theme }) => theme.palette.cancel};
                .icon {
                  stroke: ${({ theme }) => theme.palette.buttonText};
                }
              }
            `
          : css`
              transition: 0.2s;
              background-color: ${({ theme }) => theme.palette.submitButton};
              .icon {
                stroke: ${({ theme }) => theme.palette.submit};
              }
              &:focus {
                background-color: ${({ theme }) => theme.palette.submitButton};
                .icon {
                  stroke: ${({ theme }) => theme.palette.submit};
                }
              }
              &:hover {
                background-color: ${({ theme }) => theme.palette.submit};
                .icon {
                  stroke: ${({ theme }) => theme.palette.buttonText};
                }
              }
            `}
    `}

  ${({ $send }) =>
    $send &&
    css`
      width: max-content;
      background-color: ${({ theme }) => theme.palette.statusSent};
      color: ${({ theme }) => theme.palette.buttonText};
      .icon.send-request {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
      }
      &:hover {
        background-color: ${({ theme }) => theme.palette.statusSentHover};
      }
    `}

  ${({ $rounded }) =>
    $rounded &&
    css`
      border-radius: 9px;
    `}

  ${({ pale }) =>
    pale &&
    css`
      background-color: ${({ theme }) => theme.palette.bodySecondary};
      color: ${({ theme }) => theme.palette.text};
      &:hover {
        background-color: ${({ theme }) => theme.palette.sider};
        color: ${({ theme }) => theme.palette.buttonText};
      }
      &:focus {
        background-color: ${({ theme }) => theme.palette.bodySecondary};
        color: ${({ theme }) => theme.palette.text};
      }
    `}

  ${({ $neutral }) =>
    $neutral &&
    css`
      background-color: ${({ theme }) => theme.palette.borderNeutral};
      color: ${({ theme }) => theme.palette.text};
      &:hover {
        background-color: ${({ theme }) => theme.palette.sider};
        color: ${({ theme }) => theme.palette.buttonText};
      }
      &:focus {
        background-color: ${({ theme }) => theme.palette.borderNeutral};
        color: ${({ theme }) => theme.palette.text};
      }
    `}

  ${({ $menuButton }) =>
    $menuButton &&
    css`
      padding: 1rem 2.5rem;
      justify-content: flex-start;
      height: auto;
      width: 100%;
      background-color: transparent;
      color: ${({ theme }) => theme.palette.text};
      .icon {
        stroke: ${({ theme }) => theme.palette.text};
        width: 1.7rem;
        height: 1.7rem;
        margin-right: 1.8rem;
      }
      &:hover {
        background-color: ${({ theme }) => theme.palette.body};
        color: ${({ theme }) => theme.palette.button};
        .icon {
          stroke: ${({ theme }) => theme.palette.button};
        }
      }
      &:focus {
        background-color: transparent;
        color: ${({ theme }) => theme.palette.text};
      }
    `}

  ${({ $mobile }) =>
    $mobile &&
    css`
      border-radius: 6px;
      background-color: ${({ theme }) => theme.palette.text};
      color: ${({ theme }) => theme.palette.buttonText};
      &:hover {
        background-color: ${({ theme }) => theme.palette.text};
        color: ${({ theme }) => theme.palette.buttonText};
      }
      &:focus {
        background-color: ${({ theme }) => theme.palette.text};
        color: ${({ theme }) => theme.palette.buttonText};
      }
    `}

  &.action-button {
    width: 3.4rem;
    height: 3.4rem;
    padding: 0;
    border-radius: 4px;
    .icon {
      stroke: ${({ theme }) => theme.palette.buttonText};
      &.cancel {
        width: 1rem;
        height: 1rem;
      }
      &.accept {
        width: 1rem;
        height: 0.8rem;
      }
      &.send {
        width: 1.8rem;
        height: 1.8rem;
      }
    }
    &.small {
      width: 2.2rem;
      height: 2.2rem;
    }
    &.cancel {
      background-color: ${({ theme }) => theme.palette.notification};
      &:hover {
        background-color: ${({ theme }) => theme.palette.notificationHover};
      }
    }
    &.accept {
      background-color: ${({ theme }) => theme.palette.providerChosen};
      &:hover {
        background-color: ${({ theme }) => theme.palette.providerChosenHover};
      }
    }
    &.send {
      background-color: ${({ theme }) => theme.palette.actionSend};
      &:hover {
        background-color: ${({ theme }) => theme.palette.actionSendHover};
      }
    }
  }
`;

export const ButtonLabel = styled.span`
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.palette.secondaryText};
  transition: 0.2s;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  .icon {
    &.button-plus {
      width: 2.2rem;
      height: 2.2rem;
      stroke: ${({ theme }) => theme.palette.text};
    }
  }
  ${ButtonContainer} {
    padding: 0;
    width: 2.2rem;
    height: 2.2rem;
    margin-right: 1rem;
    border-radius: 4px;
    &:hover {
      .icon {
        &.button-plus {
          stroke: ${({ theme }) => theme.palette.buttonText};
        }
      }
    }
  }

  &:hover {
    ${ButtonContainer} {
      background-color: ${({ theme }) => theme.palette.sider};
      color: ${({ theme }) => theme.palette.siderTextActive};
      .icon {
        &.button-plus {
          stroke: ${({ theme }) => theme.palette.buttonText};
        }
      }
    }
    ${ButtonLabel} {
      color: ${({ theme }) => theme.palette.sider};
    }
  }
`;

export const ButtonBackLabel = styled.span`
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.palette.secondaryText};
`;

export const ButtonBackContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.7rem 0;
  cursor: pointer;
  .icon {
    &.back {
      width: 1rem;
      height: 0.8rem;
      margin-right: 1rem;
      fill: ${({ theme }) => theme.palette.secondaryText};
    }
  }

  ${({ $large }) =>
    $large &&
    css`
      ${ButtonBackLabel} {
        font-size: 1.4rem;
      }

      .icon {
        &.back {
          width: 1.5rem;
          height: 1rem;
        }
      }
    `}

  &.absolute-button {
    position: absolute;
    top: 6rem;
    left: 4.5rem;
  }
`;
