import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Title from 'antd/es/skeleton/Title';
import { Tag } from 'antd';

import loading from '@helpers/loading';
import {
  MODES,
  CLIENT_SIDER_MENU,
  CLIENT_MENU_ITEMS,
  BOTTOM_SIDER_MENU,
  UNMODERATED_SIDER_MENU,
  PROVIDER_SIDER_MENU,
  ADMIN_SIDER_MENU,
  BOTTOM_ADMIN_SIDER_MENU,
} from '@constants/menu';
import { Loader, Logo } from '@atoms';
import { Menu, SiderDrawer } from '@molecules';
import { ROUTES } from '@constants/routes';
import { ITEM_TYPES, USER_ROLES, PROJECT_STATUSES } from '@constants/common';
import { useGetObjectsQuery } from '@api/objects';
import { setIsLoadingClientObjects, setHasClientObjects, setCurrentObjectInfo } from '@reducers/requests';
import useModerationStatus from '@hooks/useModerationStatus';
import { setDrawerVisibility } from '@reducers/sider';
import { updateChats, setShowPopupChatsInfo, setAdminChatRefresh, setNeedRefresh } from '@reducers/chat';

import { SiderContainer, SiderFooter, SiderHeader, SiderLogo } from './styles';

const Sider = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { currentUser } = useSelector(({ user }) => user);

  const { notModeratedStatus, rejectedStatus, moderationChecked, onModerationStatus } = useModerationStatus();

  const [clientMenuItems, setClientMenuItems] = useState([]);
  const [openedKeys, setOpenedKeys] = useState([]);
  const [currentObjectId, setCurrentObjectId] = useState(null);
  const [allowFirstLoadNavigate, setAllowFirstLoadNavigate] = useState(true);
  const [navigateURL, setNavigateURL] = useState(null);

  const isAdmin = currentUser === USER_ROLES.ADMIN;
  const isClient = currentUser === USER_ROLES.CLIENT;

  const unmoderatedCondition = notModeratedStatus || rejectedStatus;

  // objects for client menu
  const {
    data: activeObjects,
    isLoading: isLoadingActiveObjects,
    isSuccess: isSuccessActiveObjects,
  } = useGetObjectsQuery(1, {
    skip: !isClient,
  });
  const {
    data: frozenObjects,
    isLoading: isLoadingFrozenObjects,
    isSuccess: isSuccessFrozenObjects,
  } = useGetObjectsQuery(2, {
    skip: !isClient,
  });
  const {
    data: doneObjects,
    isLoading: isLoadingDoneObjects,
    isSuccess: isSuccessDoneObjects,
  } = useGetObjectsQuery(3, {
    skip: !isClient,
  });

  const isLoading = isLoadingDoneObjects && isLoadingActiveObjects && isLoadingFrozenObjects;
  const isSuccess = isSuccessActiveObjects && isSuccessFrozenObjects && isSuccessDoneObjects;

  const setMenuChildren = (objectsArray, status) => {
    return objectsArray?.map((object) => ({
      key: object.id,
      label: (
        <Menu.Item.Link
          itemType={ITEM_TYPES.LINK}
          linkTo={`${ROUTES.REQUESTS}/${object.id}`}
          className={cn('submenu-item')}
          icon={status.key}
          iconСlass={cn('submenu-icon', 'stroke')}
          status={object.iD_Status}
          objectId={object.id}
          objectName={object.name}
          objectCode={object.code}
          objectFullName={object.name_Full}
        >
          {object.name}
        </Menu.Item.Link>
      ),
    }));
  };

  const getCurrentNavigateURL = useCallback(() => {
    if (activeObjects?.length > 0) {
      return activeObjects[0]?.id;
    }

    if (frozenObjects?.length > 0) {
      return frozenObjects[0]?.id;
    }

    return doneObjects[0]?.id;
  }, [activeObjects, doneObjects, frozenObjects]);

  const allObjects = [].concat(frozenObjects, doneObjects, activeObjects);
  const hasObjects = activeObjects?.length > 0 || frozenObjects?.length > 0 || doneObjects?.length > 0;

  // navigate effects
  useEffect(() => {
    if (isClient && hasObjects && pathname === ROUTES.REQUESTS) {
      setNavigateURL(getCurrentNavigateURL());
    }
  }, [getCurrentNavigateURL, hasObjects, isClient, navigate, pathname]);

  useEffect(() => {
    if ((moderationChecked || onModerationStatus) && navigateURL && allowFirstLoadNavigate) {
      navigate(`${ROUTES.REQUESTS}/${navigateURL}`);
      setAllowFirstLoadNavigate(false);
    }
  }, [allowFirstLoadNavigate, navigate, navigateURL, moderationChecked, onModerationStatus]);

  useEffect(() => {
    if (currentObjectId) {
      const currentObj = allObjects.find((obj) => obj?.id === currentObjectId);

      dispatch(setCurrentObjectInfo(currentObj));
    }
  }, [allObjects, currentObjectId, dispatch]);

  useEffect(() => {
    if (isClient) {
      if (pathname.includes(ROUTES.REQUESTS)) {
        setCurrentObjectId(+pathname.split('/')[2] || null);
      }
    }
  }, [isClient, pathname]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(setIsLoadingClientObjects(false));
      if (hasObjects) {
        dispatch(setHasClientObjects(true));
      }
    }
  }, [allObjects, dispatch, hasObjects, isLoading, isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      const complementedClientMenu = CLIENT_SIDER_MENU.map((item) => {
        if (item.key === PROJECT_STATUSES.ACTIVE.name) {
          return {
            ...item,
            children: setMenuChildren(activeObjects, item),
          };
        }
        if (item.key === PROJECT_STATUSES.FROZEN.name) {
          return {
            ...item,
            children: setMenuChildren(frozenObjects, item),
          };
        }
        if (item.key === PROJECT_STATUSES.DONE.name) {
          return {
            ...item,
            children: setMenuChildren(doneObjects, item),
          };
        }
        return item;
      });

      setClientMenuItems(complementedClientMenu);
    }
  }, [activeObjects, doneObjects, frozenObjects, isSuccess]);

  const rootSubmenuKeys = Object.keys(CLIENT_MENU_ITEMS.SUBMENU).map((item) => CLIENT_MENU_ITEMS.SUBMENU[item].name);

  const onOpenChange = (keys) => {
    dispatch(setDrawerVisibility(false));

    const latestOpenKey = keys.find((key) => openedKeys.indexOf(key) === -1);

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenedKeys(keys);
    } else {
      setOpenedKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const handleMenuItemsClick = (target) => {
    if (target.key !== 'support') {
      dispatch(setDrawerVisibility(false));
    }
    dispatch(setAdminChatRefresh());
  };

  const { adminUnreadCount, chatsR } = useSelector(({ chat }) => chat);
  const unreadCount = chatsR && Object.keys(chatsR).reduce((a, v) => a + (chatsR[v]?.unread_Message_Count || 0), 0);
  useEffect(() => {
    setTimeout(() => dispatch(updateChats(1)), 1000);
    setTimeout(() => dispatch(updateChats(3)), 1000);
  }, [dispatch]);

  const getSiderItems = useCallback(() => {
    if (unmoderatedCondition && currentUser !== USER_ROLES.ADMIN) {
      return UNMODERATED_SIDER_MENU;
    }

    if (currentUser === USER_ROLES.CLIENT) {
      return clientMenuItems;
    }

    if (currentUser === USER_ROLES.PROVIDER) {
      return PROVIDER_SIDER_MENU;
    }

    if (currentUser === USER_ROLES.ADMIN) {
      return ADMIN_SIDER_MENU({ adminUnreadCount });
    }

    return false;
  }, [clientMenuItems, currentUser, unmoderatedCondition, adminUnreadCount]);

  return (
    <SiderContainer $admin={isAdmin}>
      <SiderHeader>
        <SiderLogo>
          <Logo secondary={isAdmin} />
        </SiderLogo>
        {loading(
          isLoading,
          <Menu
            $admin={isAdmin}
            mode={MODES.INLINE}
            items={getSiderItems()}
            selectable={false}
            openKeys={openedKeys}
            onClick={handleMenuItemsClick}
            onOpenChange={onOpenChange}
          />,
          <Loader $transparent />,
        )}
      </SiderHeader>
      <SiderFooter>
        <Menu
          $admin={isAdmin}
          mode={MODES.INLINE}
          items={isAdmin ? BOTTOM_ADMIN_SIDER_MENU : BOTTOM_SIDER_MENU({ adminUnreadCount })}
          selectable={false}
          onClick={handleMenuItemsClick}
        />
        {!isAdmin && (
          <Tag
            onClick={() => {
              dispatch(setShowPopupChatsInfo(true));
            }}
            style={{ width: '100%', bottom: 0, position: 'absolute' }}
          >
            У Вас {unreadCount} новых сообщений
          </Tag>
        )}
      </SiderFooter>
      <SiderDrawer />
    </SiderContainer>
  );
};

export default Sider;
