import React, { memo } from 'react';

import logo from '@assets/icons/logo.svg';
import logoSecondary from '@assets/icons/logo-2.svg';
import logoTertiary from '@assets/icons/logo-3.svg';
import { ROUTES } from '@constants/routes';

import { ImageContainer, Link } from './styles';

const Logo = memo(({ secondary, tertiary, link, ...rest }) => {
  const getLogoIcon = () => {
    if (secondary) {
      return logoSecondary;
    }

    if (tertiary) {
      return logoTertiary;
    }

    return logo;
  };

  if (link) {
    return (
      <Link as="a" href={link} {...rest}>
        <ImageContainer src={getLogoIcon()} alt="Helpon" />
      </Link>
    );
  }
  return (
    <Link to={ROUTES.ROOT} {...rest}>
      <ImageContainer src={getLogoIcon()} alt="Helpon" />
    </Link>
  );
});

export default Logo;
