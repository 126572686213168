import React from 'react';
import cn from 'classnames';

import { SelectContainer, SelectOptGroup, SelectOption } from './styles';

const Select = ({ items, selectHead, optionLabelProp, ...rest }) => {
  let options;

  if (optionLabelProp) {
    options =
      items?.length > 0 &&
      items?.map((item) => (
        <Select.Option key={item?.key} label={item?.label} value={item?.key} className={cn(item?.key)}>
          <div>{item?.content}</div>
        </Select.Option>
      ));
  } else {
    options =
      items?.length > 0 &&
      items?.map((item) => (
        <Select.Option key={item?.key} value={item?.key} className={cn(item?.key)}>
          {item?.label}
        </Select.Option>
      ));
  }

  if (selectHead) {
    return (
      <SelectContainer optionLabelProp={optionLabelProp} {...rest}>
        <Select.OptGroup label={selectHead}>{options}</Select.OptGroup>
      </SelectContainer>
    );
  }

  return (
    <SelectContainer optionLabelProp={optionLabelProp} {...rest}>
      {options}
    </SelectContainer>
  );
};

Select.Option = SelectOption;
Select.OptGroup = SelectOptGroup;

export default Select;
