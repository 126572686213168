import { Input } from 'antd';
import styled, { css } from 'styled-components';

import { Icon } from '@atoms';

export const InputContainer = styled(Input)`
  background-color: transparent;
  &.ant-input-disabled {
    color: ${({ theme }) => theme.palette.text};
    background-color: ${({ theme }) => theme.palette.bodySecondary};
    border-color: ${({ theme }) => theme.palette.borderNeutral};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
  ${({ $active }) =>
    $active &&
    css`
      max-width: 25rem;
      min-height: 4rem;
      padding: 1rem 1.5rem;
      font-size: 1.4rem;
      line-height: 2rem;
      border-radius: 4px;
      border-color: ${({ theme }) => theme.palette.borderHighlighted};
      color: ${({ theme }) => theme.palette.text};
      &:hover {
        border-color: ${({ theme }) => theme.palette.borderHighlighted};
      }
      &:focus {
        border-color: ${({ theme }) => theme.palette.borderHighlighted};
        box-shadow: none;
      }
    `}

  ${({ $general }) =>
    $general &&
    css`
      font-size: 1.4rem;
      line-height: 2rem;
      border-color: ${({ theme }) => theme.palette.drawerText};
      box-shadow: none !important;
      &.ant-input {
        &-status {
          &-error {
            border-color: ${({ theme }) => theme.palette.inputError} !important;
          }
          &-success {
            border-color: ${({ theme }) => theme.palette.button} !important;
          }
        }
      }
      &:hover {
        border-color: ${({ theme }) => theme.palette.drawerText};
      }
      &:focus {
        border-color: ${({ theme }) => theme.palette.statusSent};
      }

      ${({ $width }) =>
        $width &&
        css`
          width: ${$width}rem;
        `}
    `}
`;

export const InputTextArea = styled(Input.TextArea)`
  resize: none;
  ${({ $active }) =>
    $active &&
    css`
      max-width: 43rem !important;
      padding: 1rem 0.9rem 1.7rem 1.5rem;
      font-size: 1.2rem;
      line-height: 1.6rem;
      border-radius: 4px;
      border-color: ${({ theme }) => theme.palette.borderHighlighted};
      color: ${({ theme }) => theme.palette.text};
      &:hover {
        border-color: ${({ theme }) => theme.palette.borderHighlighted};
      }
      &:focus {
        border-color: ${({ theme }) => theme.palette.borderHighlighted};
        box-shadow: none;
      }
    `}

  ${({ $common }) =>
    $common &&
    css`
      padding: 1rem 4rem 1rem 1.5rem;
      font-size: 1.4rem;
      line-height: 2rem;
      border-color: ${({ theme }) => theme.palette.borderNeutral};
      color: ${({ theme }) => theme.palette.text};
      border-radius: 2px;
      &.ant-input-status {
        &-success {
          border-color: ${({ theme }) => theme.palette.borderHighlighted};
          &:hover,
          &:focus {
            border-color: ${({ theme }) => theme.palette.borderHighlighted};
          }
        }
        &-error {
          border-color: ${({ theme }) => theme.palette.inputError} !important;
          box-shadow: none !important;
          &:hover,
          &:focus {
            border-color: ${({ theme }) => theme.palette.inputError};
          }
        }
      }
      &::placeholder {
        color: ${({ theme }) => theme.palette.tertiaryText};
        transition: 0.2s;
      }
      &:hover {
        border-color: ${({ theme }) => theme.palette.borderNeutral};
      }
      &:focus {
        border-color: ${({ theme }) => theme.palette.statusSent};
        box-shadow: none;
        &::placeholder {
          color: ${({ theme }) => theme.palette.inputPlaceholder};
          opacity: 0.4;
        }
      }
    `}
`;

export const InputPassword = styled(Input.Password)`
  padding: 0 1.5rem;
  background-color: transparent;
  box-shadow: none !important;
  &.ant-input-affix-wrapper {
    &-focused {
      border-color: ${({ theme }) => theme.palette.statusSent} !important;
    }
    &-status {
      &-success {
        border-color: ${({ theme }) => theme.palette.borderHighlighted} !important;
        &:hover,
        &:focus {
          border-color: ${({ theme }) => theme.palette.borderHighlighted};
        }
      }
      &-error {
        border-color: ${({ theme }) => theme.palette.inputError} !important;
        box-shadow: none !important;
        &:hover,
        &:focus {
          border-color: ${({ theme }) => theme.palette.inputError};
        }
      }
    }
  }
  .ant-input {
    padding: 1.4rem 4rem 1rem 0rem !important;
    font-size: 1.4rem;
    line-height: 2rem;
    border-color: ${({ theme }) => theme.palette.borderNeutral};
    color: ${({ theme }) => theme.palette.text};
    border-radius: 2px;
    &-suffix {
      position: absolute;
      top: 50%;
      right: 1.5rem;
      transform: translateY(-50%);
      .icon {
        cursor: pointer;
        width: 2rem;
        height: 1.5rem;
      }
    }
    &::placeholder {
      color: ${({ theme }) => theme.palette.tertiaryText};
      transition: 0.2s;
    }
    &:focus {
      &::placeholder {
        color: ${({ theme }) => theme.palette.inputPlaceholder};
        opacity: 0.4;
      }
    }
  }
  ~ .icon {
    right: 4rem;
  }
  ${({ $rounded }) =>
    $rounded &&
    css`
      border-radius: 9px;
    `}

  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-focused) {
    &:hover {
      border-color: ${({ theme }) => theme.palette.borderNeutral} !important;
    }
  }
`;

export const InputLabel = styled.div`
  position: absolute;
  top: -1.4rem;
  left: 1rem;
  padding: 0.3rem 0.5rem;
  background-color: ${({ theme }) => theme.palette.bodySecondary};
  color: ${({ theme }) => theme.palette.inputLabel};
  font-size: 1.1rem;
  line-height: 1.5rem;
  z-index: 5;
  pointer-events: none;
  transform: translateY(0.2rem);
  opacity: 0;
  visibility: hidden;
  transition: 0.1s;
`;

export const InputIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  right: 1.8rem;
  transform: translateY(-50%);
  transition: 0.2s;
  opacity: 0;
  visibility: hidden;
  z-index: 5;
  &.success {
    width: 1.4rem;
    height: 0.9rem;
    stroke: ${({ theme }) => theme.palette.borderHighlighted};
  }
  &.error {
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  max-width: ${({ $width }) => ($width ? `${$width}rem` : '100%')};
  &.has-value {
    &.secondary {
      .ant-input {
        border-color: ${({ theme }) => theme.palette.borderHighlighted};
      }
    }
    ${InputLabel} {
      color: ${({ theme }) => theme.palette.cardText};
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
  }
  &.secondary {
    .ant-input {
      padding: 1rem 1.5rem;
      box-shadow: none;
      font-size: 1.4rem;
      line-height: 2rem;
      color: ${({ theme }) => theme.palette.text};
      border-color: ${({ theme }) => theme.palette.borderNeutral};
      border-radius: 4px;
      &::placeholder {
        color: ${({ theme }) => theme.palette.secondaryText};
        transition: 0.2s;
      }
      &:focus {
        border-color: ${({ theme }) => theme.palette.statusSent};
        &::placeholder {
          opacity: 0.4;
        }
      }
      &-status {
        &-success {
          border-color: ${({ theme }) => theme.palette.borderHighlighted};
          &:focus {
            border-color: ${({ theme }) => theme.palette.borderHighlighted};
          }
        }
        &-error {
          box-shadow: none !important;
          border-color: ${({ theme }) => theme.palette.inputError} !important;
          &:focus {
            border-color: ${({ theme }) => theme.palette.inputError} !important;
          }
        }
      }
    }
  }
  &.checked {
    .icon {
      &.success {
        opacity: 1;
        visibility: visible;
        stroke: ${({ theme }) => theme.palette.drawerText};
      }
    }
  }
  &.focused {
    ${InputLabel} {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
      color: ${({ theme }) => theme.palette.inputLabel};
    }
  }
`;

export const InputElement = styled.div`
  font-size: 1.2rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.palette.text};

  &.pale {
    color: ${({ theme }) => theme.palette.secondaryText};
  }
`;

export const InputEnhancedContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  ${InputContainer} {
    padding: 0.8rem 3rem 0.7rem 0.7rem;
  }
  ${InputElement} {
    &.input-element {
      &-suffix {
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
        font-size: 1.2rem;
        line-height: 2rem;
        color: ${({ theme }) => theme.palette.text};
      }
      &-before {
        flex-shrink: 0;
        &.absolute {
          position: absolute;
          top: 50%;
          right: calc(100% + 1rem);
          transform: translateY(-50%);
          white-space: nowrap;
        }
      }
      &-after {
        flex-shrink: 0;
        &.absolute {
          position: absolute;
          top: 50%;
          left: calc(100% + 1rem);
          transform: translateY(-50%);
          white-space: nowrap;
        }
      }
    }
  }
`;

export const InputCommonContainer = styled(InputContainer)`
  padding: 1rem 4rem 1rem 1.5rem;
  font-size: 1.4rem;
  line-height: 2rem;
  border-color: ${({ theme }) => theme.palette.borderNeutral};
  color: ${({ theme }) => theme.palette.text};
  border-radius: 2px;
  &.ant-input-status {
    &-success {
      border-color: ${({ theme }) => theme.palette.borderHighlighted};
      &:hover,
      &:focus {
        border-color: ${({ theme }) => theme.palette.borderHighlighted};
      }
    }
    &-error {
      border-color: ${({ theme }) => theme.palette.inputError} !important;
      box-shadow: none !important;
      &:hover,
      &:focus {
        border-color: ${({ theme }) => theme.palette.inputError};
      }
    }
  }
  &::placeholder {
    color: ${({ theme }) => theme.palette.tertiaryText};
    transition: 0.2s;
  }
  &:hover {
    border-color: ${({ theme }) => theme.palette.borderNeutral};
  }
  &:focus {
    border-color: ${({ theme }) => theme.palette.statusSent};
    box-shadow: none;
    &::placeholder {
      color: ${({ theme }) => theme.palette.inputPlaceholder};
      opacity: 0.4;
    }
  }
  ${({ $rounded }) =>
    $rounded &&
    css`
      border-radius: 9px;
    `}
`;
