import React from 'react';
import cn from 'classnames';
import { Badge } from 'antd';

import { Menu } from '@molecules';
import { PROJECT_STATUSES, ITEM_TYPES } from '@constants/common';
import { ROUTES } from '@constants/routes';
import firstSectionIcon from '@assets/icons/section-icon-1.svg';
import secondSectionIcon from '@assets/icons/section-icon-2.svg';
import thirdSectionIcon from '@assets/icons/section-icon-3.svg';

// menu items

export const CLIENT_MENU_ITEMS = {
  NOTIFICATIONS: {
    name: 'notifications',
    title: 'Уведомления',
  },
  NEW: {
    name: 'new',
    title: 'Новый объект',
  },
  SUBMENU: {
    ACTIVE: {
      name: PROJECT_STATUSES.ACTIVE.name,
      title: PROJECT_STATUSES.ACTIVE.title,
    },
    FROZEN: {
      name: PROJECT_STATUSES.FROZEN.name,
      title: PROJECT_STATUSES.FROZEN.title,
    },
    DONE: {
      name: PROJECT_STATUSES.DONE.name,
      title: PROJECT_STATUSES.DONE.title,
    },
  },
};

export const PROVIDER_MENU_ITEMS = {
  NOTIFICATIONS: {
    name: 'notifications',
    title: 'Уведомления',
  },
  REQUESTS: {
    name: 'requests',
    title: 'Запросы',
  },
  NEW_REQUESTS: {
    name: 'new_requests',
    title: 'Новые',
  },
  CANCELED_REQUESTS: {
    name: 'canceled_requests',
    title: 'Отклоненные',
  },
  REJECTED: {
    name: 'rejected',
    title: 'Отмененные',
  },
  ARCHIVE_REQUESTS: {
    name: 'archive_requests',
    title: 'Архив',
  },
};

export const ADMIN_MENU_ITEMS = {
  REQUESTS: {
    name: 'requests',
    title: 'Все заявки',
  },
  NEW_REQUESTS: {
    name: 'new_requests',
    title: 'Новые',
  },
  CHANGED_REQUESTS: {
    name: 'changed_requests',
    title: 'Изменения',
  },
  EQUIPMENT: {
    name: 'equipment',
    title: 'Оборудование',
  },
};

export const BOTTOM_MENU_ITEMS = {
  USER: {
    name: 'user',
    title: 'Пользователь',
    // title: (title) => title,
  },
  CHAT_WITH_ADMIN: {
    name: 'chat_with_admin',
    title: 'Чат с администратором',
  },
  CHAT_WITH_USER: {
    name: 'chat_with_user',
    title: 'Чат с пользователем',
  },
  SETTINGS: {
    name: 'settings',
    title: 'Настройки',
  },
  SUPPORT: {
    name: 'support',
    title: 'Поддержка',
  },
  LOGOUT: {
    name: 'logout',
    title: 'Выйти',
  },
};

// sider menus

export const UNMODERATED_SIDER_MENU = [
  {
    key: CLIENT_MENU_ITEMS.NOTIFICATIONS.name,
    label: (
      <Menu.Item.Notification itemType={ITEM_TYPES.LINK} linkTo={ROUTES.NOTIFICATIONS} icon="notif">
        {CLIENT_MENU_ITEMS.NOTIFICATIONS.title}
      </Menu.Item.Notification>
    ),
  },
];

export const CLIENT_SIDER_MENU = [
  // {
  //   key: CLIENT_MENU_ITEMS.NOTIFICATIONS.name,
  //   label: (
  //     <Menu.Item.Notification itemType={ITEM_TYPES.LINK} linkTo={ROUTES.NOTIFICATIONS} icon="notif">
  //       {CLIENT_MENU_ITEMS.NOTIFICATIONS.title}
  //     </Menu.Item.Notification>
  //   ),
  // },
  {
    key: CLIENT_MENU_ITEMS.NEW.name,
    label: (
      <Menu.Item.CreateObject
        itemType={ITEM_TYPES.ACTION}
        className={cn('padding')}
        icon="plus"
        iconСlass={cn('stroke')}
      >
        {CLIENT_MENU_ITEMS.NEW.title}
      </Menu.Item.CreateObject>
    ),
  },
  {
    key: CLIENT_MENU_ITEMS.SUBMENU.ACTIVE.name,
    label: <Menu.Item icon="menu-arrow">{CLIENT_MENU_ITEMS.SUBMENU.ACTIVE.title}</Menu.Item>,
    children: [],
  },
  {
    key: CLIENT_MENU_ITEMS.SUBMENU.FROZEN.name,
    label: <Menu.Item icon="menu-arrow">{CLIENT_MENU_ITEMS.SUBMENU.FROZEN.title}</Menu.Item>,
    children: [],
  },
  {
    key: CLIENT_MENU_ITEMS.SUBMENU.DONE.name,
    label: <Menu.Item icon="menu-arrow">{CLIENT_MENU_ITEMS.SUBMENU.DONE.title}</Menu.Item>,
    children: [],
  },
];

export const PROVIDER_SIDER_MENU = [
  // {
  //   key: PROVIDER_MENU_ITEMS.NOTIFICATIONS.name,
  //   label: (
  //     <Menu.Item.Notification itemType={ITEM_TYPES.LINK} linkTo={ROUTES.NOTIFICATIONS} icon="notif">
  //       {PROVIDER_MENU_ITEMS.NOTIFICATIONS.title}
  //     </Menu.Item.Notification>
  //   ),
  // },
  {
    key: PROVIDER_MENU_ITEMS.REQUESTS.name,
    label: (
      <Menu.Item itemType={ITEM_TYPES.LINK} linkTo={`${ROUTES.REQUESTS}/all`} icon="file">
        {PROVIDER_MENU_ITEMS.REQUESTS.title}
      </Menu.Item>
    ),
  },
  {
    key: PROVIDER_MENU_ITEMS.NEW_REQUESTS.name,
    label: (
      <Menu.Item.ProviderCount $bordered itemType={ITEM_TYPES.LINK} linkTo={`${ROUTES.REQUESTS}/new`} icon="star">
        {PROVIDER_MENU_ITEMS.NEW_REQUESTS.title}
      </Menu.Item.ProviderCount>
    ),
  },
  {
    key: PROVIDER_MENU_ITEMS.CANCELED_REQUESTS.name,
    label: (
      <Menu.Item itemType={ITEM_TYPES.LINK} linkTo={`${ROUTES.REQUESTS}/canceled`} icon="cancel">
        {PROVIDER_MENU_ITEMS.CANCELED_REQUESTS.title}
      </Menu.Item>
    ),
  },
  {
    key: PROVIDER_MENU_ITEMS.REJECTED.name,
    label: (
      <Menu.Item itemType={ITEM_TYPES.LINK} linkTo={`${ROUTES.REQUESTS}/rejected`} icon="cancel">
        {PROVIDER_MENU_ITEMS.REJECTED.title}
      </Menu.Item>
    ),
  },
  {
    key: PROVIDER_MENU_ITEMS.ARCHIVE_REQUESTS.name,
    label: (
      <Menu.Item itemType={ITEM_TYPES.LINK} linkTo={`${ROUTES.REQUESTS}/archive`} icon="archive">
        {PROVIDER_MENU_ITEMS.ARCHIVE_REQUESTS.title}
      </Menu.Item>
    ),
  },
];

export const ADMIN_SIDER_MENU = (payload) => [
  {
    key: ADMIN_MENU_ITEMS.REQUESTS.name,
    label: (
      <Menu.Item itemType={ITEM_TYPES.LINK} linkTo={`${ROUTES.REQUESTS}/all`} icon="file">
        {ADMIN_MENU_ITEMS.REQUESTS.title}
      </Menu.Item>
    ),
  },
  {
    key: ADMIN_MENU_ITEMS.NEW_REQUESTS.name,
    label: (
      <Menu.Item.RegistrationCount $bordered itemType={ITEM_TYPES.LINK} linkTo={`${ROUTES.REQUESTS}/new`} icon="star">
        {ADMIN_MENU_ITEMS.NEW_REQUESTS.title}
      </Menu.Item.RegistrationCount>
    ),
  },
  {
    key: ADMIN_MENU_ITEMS.CHANGED_REQUESTS.name,
    label: (
      <Menu.Item.EquipmentCount itemType={ITEM_TYPES.LINK} linkTo={`${ROUTES.REQUESTS}/changes`} icon="pencil">
        {ADMIN_MENU_ITEMS.CHANGED_REQUESTS.title}
      </Menu.Item.EquipmentCount>
    ),
  },
  {
    key: ADMIN_MENU_ITEMS.EQUIPMENT.name,
    label: (
      <Menu.Item itemType={ITEM_TYPES.LINK} linkTo={ROUTES.EQUIPMENT} icon="database">
        {ADMIN_MENU_ITEMS.EQUIPMENT.title}
      </Menu.Item>
    ),
  },
  {
    key: BOTTOM_MENU_ITEMS.CHAT_WITH_USER.name,
    label: (
      <Menu.Item.Settings icon="support" itemType={ITEM_TYPES.LINK} linkTo={ROUTES.CHAT_WITH_USER}>
        {BOTTOM_MENU_ITEMS.CHAT_WITH_USER.title}
        <Badge count={payload?.adminUnreadCount} />
      </Menu.Item.Settings>
    ),
  },
];

export const BOTTOM_SIDER_MENU = (payload) => [
  {
    key: BOTTOM_MENU_ITEMS.USER.name,
    label: <Menu.Item.User itemType={ITEM_TYPES.ACTION} />,
  },
  {
    key: BOTTOM_MENU_ITEMS.CHAT_WITH_ADMIN.name,
    label: (
      <Menu.Item.Settings icon="support" itemType={ITEM_TYPES.LINK} linkTo={ROUTES.CHAT_WITH_ADMIN}>
        {BOTTOM_MENU_ITEMS.CHAT_WITH_ADMIN.title}
        <Badge count={payload?.adminUnreadCount} />
      </Menu.Item.Settings>
    ),
  },
  {
    key: BOTTOM_MENU_ITEMS.SETTINGS.name,
    label: (
      <Menu.Item.Settings icon="settings" itemType={ITEM_TYPES.LINK} linkTo={ROUTES.SETTINGS}>
        {BOTTOM_MENU_ITEMS.SETTINGS.title}
      </Menu.Item.Settings>
    ),
  },
  {
    key: BOTTOM_MENU_ITEMS.SUPPORT.name,
    label: (
      <Menu.Item.Support icon="support" itemType={ITEM_TYPES.ACTION}>
        {BOTTOM_MENU_ITEMS.SUPPORT.title}
      </Menu.Item.Support>
    ),
  },
  {
    key: BOTTOM_MENU_ITEMS.LOGOUT.name,
    label: (
      <Menu.Item.Logout icon="logout" iconСlass={cn('stroke')} itemType={ITEM_TYPES.ACTION}>
        {BOTTOM_MENU_ITEMS.LOGOUT.title}
      </Menu.Item.Logout>
    ),
  },
];

export const BOTTOM_ADMIN_SIDER_MENU = [
  {
    key: BOTTOM_MENU_ITEMS.USER.name,
    label: <Menu.Item.User itemType={ITEM_TYPES.ACTION} />,
  },
  {
    key: BOTTOM_MENU_ITEMS.SETTINGS.name,
    label: (
      <Menu.Item icon="settings" itemType={ITEM_TYPES.LINK} linkTo={ROUTES.SETTINGS}>
        {BOTTOM_MENU_ITEMS.SETTINGS.title}
      </Menu.Item>
    ),
  },
  {
    key: BOTTOM_MENU_ITEMS.LOGOUT.name,
    label: (
      <Menu.Item.Logout icon="logout" iconСlass={cn('stroke')} itemType={ITEM_TYPES.ACTION}>
        {BOTTOM_MENU_ITEMS.LOGOUT.title}
      </Menu.Item.Logout>
    ),
  },
];

// menu modes

export const MODES = {
  INLINE: 'inline',
  HORIZONTAL: 'horizontal',
};

// drawer section items

export const DRAWER_SECTION_ITEMS = [
  {
    title: 'FAQ',
    content: 'Популярные вопросы и ответы от наших пользователей',
    link: '#',
    icon: firstSectionIcon,
    key: 1,
  },
  {
    title: 'Справка по функциям',
    content: 'Для своих клиентов компания Helpon подробно расписала весь функционал сервиса',
    link: '#',
    icon: secondSectionIcon,
    key: 2,
  },
  {
    title: 'Служба поддержки',
    content: 'Получайте советы от отдела сопровождения клиентов Helpon в реальном времени',
    link: '#',
    icon: thirdSectionIcon,
    key: 3,
  },
];
