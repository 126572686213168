import { Tooltip } from 'antd';
import styled from 'styled-components';

export const TooltipContainer = styled(Tooltip)``;

export const TooltipTitle = styled.div`
  color: ${({ theme }) => theme.palette.siderTextActive};
  font-size: 1.1rem;
  line-height: 1.3rem;
  p {
    margin: 0;
    &:not(:last-child) {
      margin-bottom: 0.9rem;
    }
  }
  span {
    &.accented {
      color: ${({ theme }) => theme.palette.siderText};
      padding-right: 0.3rem;
      text-decoration: none;
    }
    &.danger {
      color: ${({ theme }) => theme.palette.error};
      text-decoration: underline;
      text-underline-offset: 0.2rem;
    }
    &.warning {
      color: ${({ theme }) => theme.palette.siderSecondary};
      text-decoration: underline;
      text-underline-offset: 0.2rem;
    }
  }
`;
