import React from 'react';
import { Outlet, Navigate } from 'react-router';

import { ROUTES } from '@constants/routes';
import ProtectedRoute from '@pages/common/ProtectedRoute';

// common pages
const Login = React.lazy(() => import('@pages/common/Login'));
const Registration = React.lazy(() => import('@pages/common/Registration'));
const ConfirmEmail = React.lazy(() => import('@pages/common/ConfirmEmail'));
const Home = React.lazy(() => import('@pages/common/Home'));
const Notifications = React.lazy(() => import('@pages/common/Notifications'));
const ChatWithAdmin = React.lazy(() => import('@pages/common/ChatWithAdmin'));
const ChatWithUser = React.lazy(() => import('@pages/common/ChatWithUser'));
const Settings = React.lazy(() => import('@pages/common/Settings'));

// client pages
const ClientProject = React.lazy(() => import('@pages/client/Project'));

// provider pages
const ProviderProject = React.lazy(() => import('@pages/provider/Project'));

// admin pages
const AdminRegisterRequests = React.lazy(() => import('@pages/admin/RegisterRequests'));
const AdminEquipmentRequests = React.lazy(() => import('@pages/admin/EquipmentRequests'));
const AdminAllRequests = React.lazy(() => import('@pages/admin/AllRequests'));
const Equipment = React.lazy(() => import('@pages/admin/Equipment'));

export const unauthenticatedRoutes = [
  {
    path: ROUTES.LOGIN,
    element: <Login />,
  },
  {
    path: ROUTES.REGISTRATION,
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <Registration />,
      },
      {
        path: 'confirmation',
        element: <ConfirmEmail />,
      },
    ],
  },
  {
    path: ROUTES.NOT_FOUND,
    element: <Navigate to={ROUTES.LOGIN} />,
  },
];

export const clientRoutes = [
  ...unauthenticatedRoutes,
  {
    path: ROUTES.NOT_FOUND,
    element: <Home />,
  },
  {
    path: ROUTES.ROOT,
    element: <Home />,
  },
  {
    path: ROUTES.REQUESTS,
    element: <Outlet />,
    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            <ClientProject />
          </ProtectedRoute>
        ),
      },
      {
        path: ':id',
        element: (
          <ProtectedRoute>
            <ClientProject />
          </ProtectedRoute>
        ),
      },
      {
        path: ':id/:requestId',
        element: (
          <ProtectedRoute>
            <ClientProject />
          </ProtectedRoute>
        ),
      },
      {
        path: ':id/:requestId/:chatId/:responseId/:sId',
        element: (
          <ProtectedRoute>
            <ClientProject />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: ROUTES.NOTIFICATIONS,
    element: <Notifications />,
  },
  {
    path: ROUTES.CHAT_WITH_ADMIN,
    element: <ChatWithAdmin />,
  },
  {
    path: ROUTES.SETTINGS,
    element: <Settings />,
  },
];

export const providerRoutes = [
  ...unauthenticatedRoutes,
  {
    path: ROUTES.NOT_FOUND,
    element: <Home />,
  },
  {
    path: ROUTES.ROOT,
    element: <Navigate to={ROUTES.REQUESTS} />,
  },
  {
    path: ROUTES.REQUESTS,
    element: <Outlet />,
    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            <ProviderProject />
          </ProtectedRoute>
        ),
      },
      {
        path: ':id',
        element: (
          <ProtectedRoute>
            <ProviderProject />
          </ProtectedRoute>
        ),
      },
      {
        path: ':id/:requestId',
        element: (
          <ProtectedRoute>
            <ProviderProject />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: ROUTES.NOTIFICATIONS,
    element: <Notifications />,
  },
  {
    path: ROUTES.CHAT_WITH_ADMIN,
    element: <ChatWithAdmin />,
  },
  {
    path: ROUTES.SETTINGS,
    element: <Settings />,
  },
];

export const adminRoutes = [
  ...unauthenticatedRoutes,
  {
    path: ROUTES.NOT_FOUND,
    element: <Home />,
  },
  {
    path: ROUTES.ROOT,
    element: <Navigate to={ROUTES.REQUESTS} />,
  },
  {
    path: ROUTES.SETTINGS,
    element: <Settings />,
  },
  {
    path: ROUTES.CHAT_WITH_USER,
    element: <ChatWithUser />,
    children: [
      {
        path: '',
        element: <ChatWithUser />,
      },
      {
        path: ':chatId',
        element: <ChatWithUser />,
      },
    ],
  },
  {
    path: ROUTES.EQUIPMENT,
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <Equipment />,
      },
      {
        path: ':id',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Equipment />,
          },
          {
            path: ':equipId',
            element: <Equipment equipPage="true" />,
          },
        ],
      },
    ],
  },
  {
    path: ROUTES.REQUESTS,
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <Navigate to={`${ROUTES.REQUESTS}/all`} />,
      },
      {
        path: 'all',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <AdminAllRequests />,
          },
          {
            path: ':requestId',
            element: <AdminAllRequests />,
          },
          {
            path: 'active',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <AdminAllRequests path="active" />,
              },
              {
                path: ':requestId',
                element: <AdminAllRequests path="active" />,
              },
            ],
          },
          {
            path: 'accepted',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <AdminAllRequests path="accepted" />,
              },
              {
                path: ':requestId',
                element: <AdminAllRequests path="accepted" />,
              },
            ],
          },
          {
            path: 'canceled',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <AdminAllRequests path="canceled" />,
              },
              {
                path: ':requestId',
                element: <AdminAllRequests path="canceled" />,
              },
            ],
          },
        ],
      },
      {
        path: 'new',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <AdminRegisterRequests />,
          },
          {
            path: ':requestId',
            element: <AdminRegisterRequests />,
          },
        ],
      },
      {
        path: 'changes',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <AdminEquipmentRequests />,
          },
          {
            path: ':requestId',
            element: <AdminEquipmentRequests />,
          },
        ],
      },
    ],
  },
];
