import React, { memo } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { STATUS, USER_ROLES, ADMIN_STATUS } from '@constants/common';

import { StatusContainer, StatusLabel } from './styles';

const Status = memo(({ status, ...rest }) => {
  const { currentUser } = useSelector(({ user }) => user);

  if (currentUser === USER_ROLES.ADMIN) {
    return (
      <StatusContainer
        className={cn(ADMIN_STATUS[status]?.name, 'admin')}
        title={ADMIN_STATUS[status]?.title}
        {...rest}
      >
        <StatusLabel>{ADMIN_STATUS[status]?.title}</StatusLabel>
      </StatusContainer>
    );
  }

  return (
    <StatusContainer className={cn(STATUS[status]?.name)} title={STATUS[status]?.title} {...rest}>
      <StatusLabel>{STATUS[status]?.title}</StatusLabel>
    </StatusContainer>
  );
});

export default Status;
