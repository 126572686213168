import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { useForm } from 'antd/lib/form/Form';
import { useNavigate, useParams } from 'react-router-dom';

import { ROUTES } from '@constants/routes';
import loading from '@helpers/loading';
import { Checkbox, Form, Icon, Modal, Steps } from '@atoms';
import { setRequestCreationModalVisibility } from '@reducers/application';
import { REQUEST_CREATION_STEPS } from '@constants/common';
import { Questionnaire } from '@molecules';
import {
  useAddRequestMutation,
  useEditRequestMutation,
  useGetOrganizationsListQuery,
  useGetQuestionnaireQuery,
  useSetQuestionnaireMutation,
  useSetProvidersMutation,
} from '@api/clientRequests';
import transformToFormData from '@helpers/transformToFormData';
import { setCreateRequestEditMode, setCurrentRequestInfo } from '@reducers/requests';
import { useGetEquipmentByIdQuery } from '@api/equipment';

import {
  RequestCreationContainer,
  RequestCreationContent,
  RequestCreationErrorText,
  RequestCreationHeader,
  RequestCreationSubtitle,
  RequestCreationTitle,
} from './styles';

const questionnaireFields = {};

const StepsInformation = ({
  form,
  hasRequestInfo,
  setHasRequestInfo,
  setEquipID,
  currentEquipID,
  setCurrentRequestID,
  currentRequestID,
}) => {
  const { currentObjectInfo, createRequestEditMode, currentRequestInfo } = useSelector(({ requests }) => requests);

  const { data: equipmentInfo, isSuccess: isSuccessEquipmentInfo } = useGetEquipmentByIdQuery(
    createRequestEditMode ? currentRequestInfo?.iD_Equipment : currentEquipID,
    { skip: !createRequestEditMode && !currentEquipID },
  );

  const [addRequest] = useAddRequestMutation();
  const [editRequest] = useEditRequestMutation();

  useEffect(() => {
    if (createRequestEditMode) {
      setCurrentRequestID(currentRequestInfo.id);
      form.setFieldsValue({
        Code: currentRequestInfo?.object_Code,
        Name: currentRequestInfo?.name,
        category: equipmentInfo?.iD_Category,
        ID_Equipment: currentRequestInfo?.iD_Equipment,
      });
    }
  }, [createRequestEditMode, currentRequestInfo, equipmentInfo?.iD_Category, form, setCurrentRequestID]);

  useEffect(() => {
    form.setFieldsValue({ Code: currentObjectInfo?.code, Name: currentObjectInfo?.name_Full });
  }, [currentObjectInfo, form]);

  const onFinish = async (values) => {
    if (hasRequestInfo || createRequestEditMode) {
      delete values.Code;
      delete values.category;
      editRequest(transformToFormData({ ...values, ID_Object: currentObjectInfo.id, ID_Request: currentRequestID }));
      setEquipID(values.ID_Equipment);
    } else {
      delete values.Code;
      delete values.category;
      const newRequestID = await addRequest(
        transformToFormData({ ...values, ID_Object: currentObjectInfo.id }),
      ).unwrap();
      setEquipID(values.ID_Equipment);
      setCurrentRequestID(newRequestID);
      setHasRequestInfo(true);
    }
  };

  const changeFormValue = (name, value) => {
    form.setFieldValue(name, value);
  };

  return (
    <RequestCreationContainer>
      <RequestCreationHeader $margin="3.2">
        <RequestCreationTitle>Информация</RequestCreationTitle>
        <RequestCreationSubtitle>
          Пожалуйста, заполните все «Обязательные» поля, чтобы получить точное решение от Изготовителя.
        </RequestCreationSubtitle>
      </RequestCreationHeader>
      <RequestCreationContent>
        <Form.RequestCreateInfo
          form={form}
          hasRequestInfo={hasRequestInfo}
          editMode={createRequestEditMode}
          category={equipmentInfo?.iD_Category}
          isSuccessEquipmentInfo={isSuccessEquipmentInfo}
          changeFormValue={changeFormValue}
          onFinish={onFinish}
          $grid
          $gap="3.2"
        />
      </RequestCreationContent>
    </RequestCreationContainer>
  );
};

const StepsQuestionnaire = ({ form, equipID, currentRequestID }) => {
  const { createRequestEditMode, currentRequestInfo } = useSelector(({ requests }) => requests);

  const [setQuestionnaire] = useSetQuestionnaireMutation();
  const { data: equipmentQuestionnaire } = useGetQuestionnaireQuery({
    ID_Request: createRequestEditMode ? currentRequestInfo.id : currentRequestID,
    ID_Equipment: equipID,
  });

  useEffect(() => {
    equipmentQuestionnaire?.forEach((field) => {
      Object.assign(questionnaireFields, { [field.name]: field.value });
    });

    form.setFieldsValue({
      object_code: currentRequestInfo?.object_Code,
      object_name: currentRequestInfo?.name,
      eq_type: currentRequestInfo?.name_Equipment,
    });
    form.setFieldsValue(questionnaireFields);
  }, [currentRequestInfo, equipmentQuestionnaire, form]);

  const onFinish = (values) => {
    const tranformedData = equipmentQuestionnaire?.map((item) => {
      return {
        ID_Parameter: item.iD_Equipment_Questionnaire,
        ID_Type: item.iD_Type,
        Value: values[item.name],
      };
    });

    const parametersJSON = JSON.stringify({ parameters: tranformedData });

    setQuestionnaire(transformToFormData({ ID_Request: currentRequestID, Json: parametersJSON }));
  };

  return (
    <RequestCreationContainer>
      <RequestCreationHeader $margin="4">
        <RequestCreationTitle>Опросный лист</RequestCreationTitle>
        <RequestCreationSubtitle>
          Пожалуйста, заполните все «Обязательные» поля, чтобы получить точное решение от Изготовителя.
        </RequestCreationSubtitle>
      </RequestCreationHeader>
      <RequestCreationContent>
        <Questionnaire equipID={equipID} form={form} values={equipmentQuestionnaire} onFinish={onFinish} />
      </RequestCreationContent>
    </RequestCreationContainer>
  );
};

const StepsProviders = ({ form, className, currentRequestID, equipID }) => {
  const [checkedManufacturersList, setCheckedManufacturersList] = useState([]);
  const [checkedDistributorsList, setCheckedDistributorsList] = useState([]);

  const [checkAllManufacturers, setCheckAllManufacturers] = useState(false);
  const [checkAllDistributors, setCheckAllDistributors] = useState(false);

  const { data: providers, isFetching: isFetchingProviders } = useGetOrganizationsListQuery({
    ID_Request: currentRequestID,
    ID_Equipment: equipID,
  });

  const [setProviders] = useSetProvidersMutation();

  const manufacturersList = providers?.filter((provider) => provider.iD_AspNetUsers_Equipment_Type === 1);
  const distributorsList = providers?.filter((provider) => provider.iD_AspNetUsers_Equipment_Type === 2);

  const manufacturersIDList = manufacturersList?.map((provider) => provider.inn);
  const distributorsIDList = distributorsList?.map((provider) => provider.inn);

  useEffect(() => {
    form.setFieldsValue({ manufacturers: checkedManufacturersList, distributors: checkedDistributorsList });
  }, [checkedDistributorsList, checkedManufacturersList, form]);

  const onChangeManufacturers = (list) => {
    setCheckedManufacturersList(list);
    setCheckAllManufacturers(list.length === manufacturersIDList.length);
  };

  const onChangeDistributors = (list) => {
    setCheckedDistributorsList(list);
    setCheckAllDistributors(list.length === distributorsIDList.length);
  };

  const onCheckAllManufacturersChange = (e) => {
    setCheckedManufacturersList(e.target.checked ? manufacturersIDList : []);
    setCheckAllManufacturers(e.target.checked);
  };

  const onCheckAllDistributorsChange = (e) => {
    setCheckedDistributorsList(e.target.checked ? distributorsIDList : []);
    setCheckAllDistributors(e.target.checked);
  };

  const onFinish = (values) => {
    const providersList = Object.values(values).flatMap((val) => val);

    const providersListFinal = providers?.map((item) => ({
      Inn: String(item.inn),
      Value: String(providersList.includes(item.inn)),
    }));

    const parametersJSON = JSON.stringify({ parameters: providersListFinal });

    setProviders(transformToFormData({ ID_Request: currentRequestID, Json: parametersJSON }));
  };

  return (
    <RequestCreationContainer>
      <RequestCreationHeader $margin="2.9" className={className}>
        <RequestCreationTitle>Выберите поставщиков</RequestCreationTitle>
        <RequestCreationErrorText>Вы не выбрали поставщиков</RequestCreationErrorText>
      </RequestCreationHeader>
      <RequestCreationContent>
        <Form form={form} onFinish={onFinish} className={cn('request-providers-form')}>
          {loading(
            isFetchingProviders,
            <Form.Wrap>
              <Form.Block>
                <Form.Row className={cn('checkbox-group-head')}>
                  <Checkbox onChange={onCheckAllManufacturersChange} checked={checkAllManufacturers} $dark>
                    Изготовители
                  </Checkbox>
                </Form.Row>
                <Form.Item name="manufacturers">
                  <Checkbox.Group $column onChange={onChangeManufacturers}>
                    {manufacturersList?.map((item) => (
                      <Checkbox $dark key={item.inn} value={item.inn} checked={item.check}>
                        {item.org_Name_Short}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              </Form.Block>
              <Form.Block>
                <Form.Row className={cn('checkbox-group-head', 'padding')}>
                  <Checkbox onChange={onCheckAllDistributorsChange} checked={checkAllDistributors} $dark>
                    Дистрибьюторы
                  </Checkbox>
                </Form.Row>
                <Form.Item className={cn('bordered-item', 'padding')} name="distributors">
                  <Checkbox.Group $column onChange={onChangeDistributors}>
                    {distributorsList?.map((item) => (
                      <Checkbox $dark key={item.inn} value={item.inn} checked={item.check}>
                        {item.org_Name_Short}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              </Form.Block>
            </Form.Wrap>,
          )}
        </Form>
      </RequestCreationContent>
    </RequestCreationContainer>
  );
};

const RequestCreationModal = ({ ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { requestCreationModalVisibility } = useSelector(({ application }) => application);

  const [currentStep, setCurrentStep] = useState(0);
  const [hasRequestInfo, setHasRequestInfo] = useState(false);
  const [providersFormError, setProvidersFormError] = useState(false);

  const [selectedEquipmentId, setSelectedEquipmentId] = useState();
  const [currentRequestID, setCurrentRequestID] = useState();

  const [providersForm] = useForm();
  const [requestInfoForm] = useForm();
  const [questionnaireForm] = useForm();

  const handleClose = () => {
    setCurrentStep(0);
    providersForm.resetFields();
    requestInfoForm.resetFields();
    questionnaireForm.resetFields();
    setHasRequestInfo(false);
    dispatch(setCreateRequestEditMode(false));
    dispatch(setRequestCreationModalVisibility(false));
  };

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      const validated = providersForm.validateFields();

      const formValues = providersForm.getFieldsValue();

      const isOk = Object.keys(formValues)
        .map((value) => {
          return formValues[value];
        })
        .some((arr) => arr.length > 0);

      if (isOk) {
        setProvidersFormError(false);
        validated.then(
          (result) => {
            providersForm.submit();
            requestInfoForm.resetFields();
            questionnaireForm.resetFields();
            setHasRequestInfo(false);
            setCurrentStep(0);
            dispatch(setRequestCreationModalVisibility(false));
            navigate(`${ROUTES.REQUESTS}/${id}/${currentRequestID}`);
          },
          (error) => false,
        );

        return false;
      }
      setProvidersFormError(true);
      return false;
    },
    [currentRequestID, dispatch, id, navigate, providersForm, questionnaireForm, requestInfoForm],
  );

  const nextStep = (current) => {
    if (current === REQUEST_CREATION_STEPS.INFO) {
      const validated = requestInfoForm.validateFields();

      validated.then(
        (result) => {
          requestInfoForm.submit();
          setCurrentStep(currentStep + 1);
        },
        (error) => false,
      );
    }
    if (current === REQUEST_CREATION_STEPS.QUESTIONNAIRE) {
      const validated = questionnaireForm.validateFields();

      validated.then(
        (result) => {
          questionnaireForm.submit();
          setCurrentStep(currentStep + 1);
        },
        (error) => false,
      );
    }
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const steps = [
    {
      title: 'Информация',
      content: (
        <StepsInformation
          hasRequestInfo={hasRequestInfo}
          setHasRequestInfo={setHasRequestInfo}
          form={requestInfoForm}
          currentRequestID={currentRequestID}
          setEquipID={setSelectedEquipmentId}
          currentEquipID={selectedEquipmentId}
          setCurrentRequestID={setCurrentRequestID}
        />
      ),
      key: REQUEST_CREATION_STEPS.INFO,
      icon: '1',
    },
    {
      title: 'Опросный лист',
      content: (
        <StepsQuestionnaire
          currentRequestID={currentRequestID}
          equipID={selectedEquipmentId}
          form={questionnaireForm}
        />
      ),
      key: REQUEST_CREATION_STEPS.QUESTIONNAIRE,
      icon: '2',
    },
    {
      title: 'Поставщики',
      content: (
        <StepsProviders
          currentRequestID={currentRequestID}
          equipID={selectedEquipmentId}
          className={cn(providersFormError && 'error')}
          form={providersForm}
        />
      ),
      key: REQUEST_CREATION_STEPS.PROVIDERS,
      icon: '3',
    },
  ];

  return (
    <Modal.RequestCreation
      destroyOnClose
      visible={requestCreationModalVisibility}
      onCancel={handleClose}
      footer={false}
      closeIcon={<Icon name="close" className="modal-close" />}
      {...rest}
    >
      <Modal.Body>
        <Modal.Header $margin="0.5">
          <Modal.Title>Новый запрос</Modal.Title>
          <Steps current={currentStep}>
            {steps.map((step) => (
              <Steps.Step
                icon={<div className={cn('steps-icon')}>{step.icon}</div>}
                key={step.key}
                title={step.title}
              />
            ))}
          </Steps>
        </Modal.Header>
        <Modal.Content>{steps[currentStep].content}</Modal.Content>
        <Modal.Footer>
          {currentStep > 0 && (
            <Modal.Button pale="true" onClick={() => prevStep()}>
              Назад
            </Modal.Button>
          )}
          {currentStep < steps.length - 1 && (
            <Modal.Button
              className={cn('button-hover-secondary')}
              secondary="true"
              onClick={() => nextStep(steps[currentStep].key)}
            >
              Продолжить
            </Modal.Button>
          )}
          {currentStep === steps.length - 1 && (
            <Modal.Button className={cn('button-hover-secondary')} secondary="true" onClick={handleSubmit}>
              Подтвердить выбор
            </Modal.Button>
          )}
        </Modal.Footer>
      </Modal.Body>
    </Modal.RequestCreation>
  );
};

export default RequestCreationModal;
