import React, { memo } from 'react';

import { StepsContainer, StepsStep } from './styles';

const Steps = memo(({ children, ...rest }) => {
  return <StepsContainer {...rest}>{children}</StepsContainer>;
});

Steps.Step = StepsStep;

export default Steps;
