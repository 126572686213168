import { Descriptions } from 'antd';
import styled from 'styled-components';

export const DescriptionContainer = styled(Descriptions)`
  width: ${({ $width }) => ($width ? `${$width}rem` : '100%')};
  .ant-descriptions {
    &-item {
      &-label,
      &-content {
        width: 50%;
        font-size: 1.4rem;
        line-height: 2rem;
        background-color: transparent;
        border-color: ${({ theme }) => theme.palette.drawerText};
        color: ${({ theme }) => theme.palette.text};
        padding: 1.3rem 2.1rem 1.1rem;
      }
      &-content {
        text-align: center;
      }
    }
    &-row {
      border-color: ${({ theme }) => theme.palette.drawerText};
    }
    &-view {
      border-radius: 4px;
      border-color: ${({ theme }) => theme.palette.drawerText};
    }
  }
`;

export const DescriptionItem = styled(Descriptions.Item)``;
