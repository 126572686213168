import { Drawer } from 'antd';
import styled, { css } from 'styled-components';

export const DrawerContainer = styled(Drawer)`
  .ant-drawer {
    &-body {
      padding: 2rem;
      &::-webkit-scrollbar {
        width: 0rem;
        height: 0rem;
      }
    }
    &-content {
      background-color: ${({ theme }) => theme.palette.drawer};
      color: ${({ theme }) => theme.palette.drawerText};
    }
    &-close {
      width: 2.5rem;
      height: 2.5rem;
      padding: 0.4rem;
      margin: 0;
    }
    &-title {
      color: ${({ theme }) => theme.palette.drawerTextSecondary};
      font-size: 2rem;
      line-height: 2.6rem;
    }
    &-header {
      padding: 2rem;
      border-color: rgba(200, 197, 208, 0.2);
      &-title {
        flex-direction: row-reverse;
        margin-bottom: 0.9rem;
      }
    }
  }
  .icon {
    &.close-icon {
      stroke: ${({ theme }) => theme.palette.drawerTextSecondary};
      width: 1rem;
      height: 1rem;
    }
    &.arrow {
      stroke: ${({ theme }) => theme.palette.drawerText};
      width: 0.6rem;
      height: 1rem;
      transition: 0.2s;
    }
  }

  &.sider-drawer {
    left: 20rem !important;
    z-index: 900;
  }
`;

export const DrawerBlock = styled.div`
  margin-bottom: ${({ $margin }) => ($margin ? `${$margin}rem` : '0rem')};
  ${({ $row }) =>
    $row &&
    css`
      display: flex;
      gap: 2rem;
    `}
`;

export const DrawerBlockHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.4rem;
`;

export const DrawerTitle = styled.h2`
  color: ${({ theme }) => theme.palette.drawerText};
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0;
`;

export const DrawerLink = styled.a`
  color: ${({ theme }) => theme.palette.drawerText};
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 0.3rem 0.5rem;
  border-radius: 2px;
  &:hover {
    color: ${({ theme }) => theme.palette.drawerTextSecondary};
    background-color: ${({ theme }) => theme.palette.sider};
    .icon.arrow {
      stroke: ${({ theme }) => theme.palette.drawerTextSecondary};
    }
  }
`;

export const DrawerList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const DrawerListItem = styled.li``;
