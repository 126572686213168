import styled, { css } from 'styled-components';

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const NotificationHead = styled.div`
  max-width: 54rem;
  width: 100%;
  margin: 0px auto;
  padding: 6.1rem 0 2.6rem;
`;

export const NotificationContent = styled.div`
  width: 100%;
  overflow: hidden;
  max-width: 54rem;
  padding: 2.1rem 0rem 4.1rem;
  margin: 0px auto;
  .ant-tabs {
    height: 100%;
    &-nav-wrap {
      margin: 0px auto;
      max-width: 54rem;
      padding: 0;
    }
    &-content-holder {
      max-width: 54rem;
      width: 100%;
      margin: 0px auto;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0rem;
        height: 0rem;
      }
    }
    &-tabpane {
      padding: 2.1rem 0rem 4.1rem;
    }
  }
`;

export const NotificationAction = styled.div`
  position: absolute;
  top: 5.8rem;
  right: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.4rem;
  height: 3.4rem;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  transition: 0.5s;
  cursor: pointer;
  .icon {
    stroke: ${({ theme }) => theme.palette.sider};
    width: 2rem;
    height: 2rem;
  }
  &:hover {
    &.edit {
      background-color: ${({ theme }) => theme.palette.sider};
      .icon {
        stroke: ${({ theme }) => theme.palette.buttonText};
      }
    }
  }
`;

export const NotificationText = styled.p`
  color: ${({ theme }) => theme.palette.inputError};
  font-size: 1.4rem;
  margin: 2rem 0rem 2rem 0rem;

  ${({ $confirmed }) =>
    $confirmed &&
    css`
      color: ${({ theme }) => theme.palette.submit};
    `}
`;
