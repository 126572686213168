import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useForm } from 'antd/lib/form/Form';
import { message } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  useEditSentRequestMutation,
  useGetQuestionnaireQuery,
  useDeleteRequestMutation,
  useArchiveRequestMutation,
  useCancelRequestMutation,
  useCopyRequestMutation,
  useGetSelectedAnswerQuery,
  useGetAnswersInfoQuery,
} from '@api/clientRequests';
import loading from '@helpers/loading';
import { ROUTES } from '@constants/routes';
import { Button, Form, Icon, Modal, Skeleton, Status, Tabs, Tooltip } from '@atoms';
import {
  TABS,
  CLIENT_REQUEST_ACTIONS,
  ACTION_TYPES,
  REQUEST_STATUSES,
  UNMODERATED_CLIENT_REQUEST_ACTIONS,
  USER_ROLES,
} from '@constants/common';
import { Questionnaire, Responses } from '@molecules';
import { ProviderInfo, Page } from '@organisms';
import { requestInfoForm } from '@constants/forms';
import transformToFormData from '@helpers/transformToFormData';
import { setCurrentRequestInfo, setCreateRequestEditMode, setCurrentClickedRequestID } from '@reducers/requests';
import { setRequestCreationModalVisibility, setSendRequestsModalVisibility } from '@reducers/application';
import { useGetAnswerFileQuery } from '@api/providerRequests';
import useModerationStatus from '@hooks/useModerationStatus';
import { useGetEquipmentByIdQuery } from '@api/equipment';
import { setCurrentChatId } from '@reducers/chat';

import ChatCard from '../../molecules/ChatCard';
import { ContentContainer } from '../../templates/Layout/styles';

import {
  RequestInfoAction,
  RequestInfoActions,
  RequestInfoBlock,
  RequestInfoContainer,
  RequestInfoContent,
  RequestInfoDate,
  RequestInfoHead,
  RequestInfoList,
  RequestInfoListItem,
  RequestInfoSubtitle,
  RequestInfoTitle,
} from './styles';

const questionnaireFields = {};

const RequestInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, requestId, chatId, responseId, sId } = useParams();

  const { hash, pathname } = useLocation();

  const { currentRequestInfo } = useSelector(({ requests }) => requests);

  const { onModerationStatus } = useModerationStatus();

  const { data: questionnaireData, isFetching: isFetchingQuestionnaireData } = useGetQuestionnaireQuery({
    ID_Request: currentRequestInfo?.id,
    ID_Equipment: currentRequestInfo?.iD_Equipment,
  });
  const { data: selectedAnswer, isFetching: isFetchingSelected } = useGetSelectedAnswerQuery(currentRequestInfo?.id);
  const { data: selectedAnswerFiles, isFetching: isFetchingSelectedAnswerFiles } = useGetAnswerFileQuery({
    ID_Requests_Answer: selectedAnswer?.id,
  });
  const { data: equipmentInfo, isSuccess: isSuccessEquipmentInfo } = useGetEquipmentByIdQuery(
    currentRequestInfo?.iD_Equipment,
  );
  const { data: answersInfo, isFetching: isFetchingAnswersInfo } = useGetAnswersInfoQuery(currentRequestInfo?.id, {
    pollingInterval: 180000,
  });

  const [deleteRequestTrigger, { isSuccess: isSuccessDelete }] = useDeleteRequestMutation();
  const [cancelRequestTrigger, { isSuccess: isSuccessCancel }] = useCancelRequestMutation();
  const [archiveRequestTrigger, { isSuccess: isSuccessArchive }] = useArchiveRequestMutation();
  const [copyRequestTrigger, { isSuccess: isSuccessCopy }] = useCopyRequestMutation();
  const [editSentRequestTrigger] = useEditSentRequestMutation();

  const [userInfoFormInstance] = useForm();
  const [questionnaireFormInstance] = useForm();

  const providerFound = currentRequestInfo.iD_Status === 3;
  const statusText =
    currentRequestInfo.iD_Status === -1 ? (
      <Page.Text>
        Запрос на{' '}
        <span className="error">{`${currentRequestInfo.name_Equipment} - ${currentRequestInfo.object_Code}`}</span>{' '}
        отменен
      </Page.Text>
    ) : (
      'Поставщик не выбран'
    );

  const requestActions = onModerationStatus ? UNMODERATED_CLIENT_REQUEST_ACTIONS : CLIENT_REQUEST_ACTIONS;

  const hasRequest = Object.keys(currentRequestInfo).length > 0;

  useEffect(() => {
    if (isSuccessDelete) {
      message.success({
        content: 'Запрос удален',
        key: 'isSuccessDelete-request-msg-2',
      });
    }
    if (isSuccessCancel) {
      message.success({
        content: 'Запрос отменен',
        key: 'isSuccessCancel-request-msg-2',
      });
    }
    if (isSuccessArchive) {
      message.success({
        content: 'Запрос добавлен в архив',
        key: 'isSuccessArchive-request-msg-2',
      });
    }
    if (isSuccessCopy) {
      message.success({
        content: 'Запрос скопирован',
        key: 'isSuccessCopy-request-msg-2',
      });
    }
  }, [isSuccessArchive, isSuccessCancel, isSuccessCopy, isSuccessDelete]);

  useEffect(() => {
    questionnaireData?.forEach((field) => {
      Object.assign(questionnaireFields, { [field.name]: field.value });
    });

    userInfoFormInstance.setFieldsValue({
      object_name: currentRequestInfo?.name,
      object_code: currentRequestInfo?.object_Code,
      eq_category: equipmentInfo?.category_Name,
      eq_type: currentRequestInfo?.name_Equipment,
    });
    questionnaireFormInstance.setFieldsValue(questionnaireFields);
  }, [
    currentRequestInfo,
    equipmentInfo?.category_Name,
    questionnaireData,
    questionnaireFormInstance,
    userInfoFormInstance,
  ]);

  const deleteRequest = () => {
    deleteRequestTrigger(transformToFormData({ ID_Request: currentRequestInfo.id }));
    navigate(`${ROUTES.REQUESTS}/${id}`);
  };

  const cancelRequest = () => {
    cancelRequestTrigger({ iD_Request: currentRequestInfo.id, reason: '' });
    dispatch(setCurrentRequestInfo({ ...currentRequestInfo, status: REQUEST_STATUSES.CANCELED.name }));
  };

  const editRequest = () => {
    dispatch(setCreateRequestEditMode(true));
    dispatch(setRequestCreationModalVisibility(true));
  };

  const editSentRequest = () => {
    // editSentRequestTrigger(transformToFormData({ ID_Request: currentRequestInfo.id })).then((result) => {
    //   navigate(`${ROUTES.REQUESTS}/${id}/${result.data}`);
    //   dispatch(setCreateRequestEditMode(true));
    //   dispatch(setRequestCreationModalVisibility(true));
    // });
  };

  const archiveRequest = () => {
    archiveRequestTrigger(transformToFormData({ ID_Request: currentRequestInfo.id }));
    dispatch(setCurrentRequestInfo({ ...currentRequestInfo, status: REQUEST_STATUSES.ARCHIVE.name }));
  };

  const sendRequest = () => {
    dispatch(setCurrentClickedRequestID(currentRequestInfo.id));
    dispatch(setSendRequestsModalVisibility(true));
  };

  const duplicateRequest = () => {
    copyRequestTrigger(transformToFormData({ ID_Request: currentRequestInfo.id }));
  };

  const handleActionClick = (actionType) => {
    switch (actionType) {
      case ACTION_TYPES.SEND:
        sendRequest();
        break;
      case ACTION_TYPES.ARCHIVE:
        archiveRequest();
        break;
      case ACTION_TYPES.DUPLICATE:
        duplicateRequest();
        break;
      case ACTION_TYPES.CANCEL:
        cancelRequest();
        break;
      case ACTION_TYPES.EDIT:
        editRequest();
        break;
      case ACTION_TYPES.DELETE:
        deleteRequest();
        break;
      case ACTION_TYPES.EDIT_SENT:
        editSentRequest();
        break;
      default:
        break;
    }
  };

  const clearHash = useCallback(() => {
    setTimeout(() => navigate(pathname), 1);
  }, [pathname, navigate]);

  const clearChatId = useCallback(() => {
    dispatch(setCurrentChatId(undefined));
  }, [dispatch]);

  return (
    <RequestInfoContainer>
      <RequestInfoHead>
        <RequestInfoBlock $margin="2.2">
          <RequestInfoDate>
            {loading(
              !hasRequest,
              dayjs(currentRequestInfo?.date_Add).format('D MMMM YYYY, HH:mm'),
              <Skeleton.Button size="small" active />,
            )}
          </RequestInfoDate>
        </RequestInfoBlock>
        <RequestInfoBlock $margin="0.7">
          <RequestInfoTitle>
            {loading(
              !hasRequest,
              currentRequestInfo?.name_Equipment,
              <Skeleton.Button $width="15" size="small" active />,
            )}{' '}
            -{' '}
            {loading(!hasRequest, currentRequestInfo?.object_Code, <Skeleton.Button $width="15" size="small" active />)}
            {loading(
              !hasRequest,
              <Status status={currentRequestInfo?.status} />,
              <Skeleton.Button $width="7" size="small" active />,
            )}
          </RequestInfoTitle>
          <RequestInfoActions className={cn(providerFound && 'providerFound')}>
            {hasRequest &&
              requestActions[currentRequestInfo?.status].map(({ icon, title, key, placement }) => (
                <Tooltip
                  key={key}
                  overlayClassName={cn('small-tooltip', key)}
                  title={title}
                  placement={placement || 'bottom'}
                  arrowPointAtCenter
                >
                  <RequestInfoAction className={cn(key)} onClick={() => handleActionClick(key)}>
                    <Icon $transition name={icon} className={cn(key)} />
                  </RequestInfoAction>
                </Tooltip>
              ))}
          </RequestInfoActions>
        </RequestInfoBlock>
        {onModerationStatus && (
          <RequestInfoSubtitle>
            Пока Ваша заявка находится на рассмотрении у администратора, Вы не можете отправлять запросы поставщикам.
          </RequestInfoSubtitle>
        )}
      </RequestInfoHead>
      <RequestInfoContent>
        {chatId && (
          <div style={{ height: '84vh', paddingLeft: '16px', paddingRight: '16px' }}>
            <Fragment>
              <Button.Back
                onClick={() => {
                  navigate(`${ROUTES.REQUESTS}/${id}/${requestId}/#responses`);
                }}
              >
                <span style={{ fontWeight: 'bold', fontSize: '24px' }}>Назад</span>
              </Button.Back>
              <ChatCard initialChatId={chatId} readOnly={+sId === 0} />
            </Fragment>
          </div>
        )}
        {!chatId && (
          <Tabs
            defaultActiveKey={chatId || hash === '#responses' ? TABS.RESPONSES.name : TABS.INFO.name}
            activeKey={chatId || hash === '#responses' ? TABS.RESPONSES.name : undefined}
            style={{ height: '100vh' }}
            onChange={() => clearChatId()}
          >
            <Tabs.TabPane tab={TABS.INFO.title} key={TABS.INFO.name}>
              {loading(
                !isSuccessEquipmentInfo,
                <Form readOnly form={userInfoFormInstance} $grid $gap="3.2" className={cn('request-info-form')}>
                  <fieldset disabled="disabled">
                    {requestInfoForm.map((item) => (
                      <Form.Item key={item.props.name} {...item.props}>
                        {item.element}
                      </Form.Item>
                    ))}
                  </fieldset>
                </Form>,
              )}
            </Tabs.TabPane>

            <Tabs.TabPane tab={TABS.FORMS.title} key={TABS.FORMS.name}>
              {loading(
                isFetchingQuestionnaireData,
                <Questionnaire
                  values={questionnaireData}
                  form={questionnaireFormInstance}
                  readOnly
                  equipID={currentRequestInfo?.iD_Equipment}
                />,
              )}
            </Tabs.TabPane>

            <Tabs.TabPane tab={TABS.RESPONSES.title} key={TABS.RESPONSES.name} style={{ height: '80vh' }}>
              {!chatId && (
                <Fragment>
                  {loading(
                    isFetchingAnswersInfo,
                    currentRequestInfo?.status !== REQUEST_STATUSES.DRAFT.name && (
                      <RequestInfoList>
                        {answersInfo?.map((item) => (
                          <RequestInfoListItem key={`${item.name}-${Math.random()}`}>
                            {item.name}: <span>{item.value}</span>
                          </RequestInfoListItem>
                        ))}
                      </RequestInfoList>
                    ),
                    <Skeleton.Button $width="25" size="small" active />,
                  )}

                  <Responses status={currentRequestInfo?.status} />
                </Fragment>
              )}
              {hash && clearHash() && ''}
            </Tabs.TabPane>

            <Tabs.TabPane tab={TABS.STATUS.title} key={TABS.STATUS.name}>
              {providerFound
                ? loading(
                    isFetchingSelected && isFetchingSelectedAnswerFiles,
                    <ProviderInfo status={selectedAnswer} files={selectedAnswerFiles} />,
                  )
                : statusText}
            </Tabs.TabPane>
          </Tabs>
        )}
      </RequestInfoContent>
    </RequestInfoContainer>
  );
};

export default RequestInfo;
