import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Drawer } from '@molecules';
import { setDrawerVisibility } from '@reducers/sider';
import { Card, Icon, Video } from '@atoms';
import { DRAWER_SECTION_ITEMS } from '@constants/menu';

const SiderDrawer = () => {
  const dispatch = useDispatch();

  const { drawerVisibility } = useSelector(({ sider }) => sider);

  const onClose = () => {
    dispatch(setDrawerVisibility(false));
  };

  return (
    <Drawer
      title="Поддержка и начало работы"
      placement="left"
      visible={drawerVisibility}
      maskClosable
      destroyOnClose
      onClose={onClose}
      className={cn('sider-drawer')}
    >
      <Drawer.Block $margin="3">
        <Drawer.Block.Head>
          <Drawer.Title>
            На данный момент раздел &quot;Поддержка&quot; еще дорабатывается. <br /> Мы Вам сообщим, когда будет
            добавлена вся информация!
          </Drawer.Title>
        </Drawer.Block.Head>

        <Drawer.Block.Head>
          <Drawer.Title>Видеоинструкции</Drawer.Title>
          <Drawer.Link href="#">
            Далее <Icon name="arrow-1" className="arrow" />
          </Drawer.Link>
        </Drawer.Block.Head>
        <Drawer.Block $row>
          <Video />
          <Video />
        </Drawer.Block>
      </Drawer.Block>
      <Drawer.Block>
        <Drawer.Block.Head>
          <Drawer.Title>Популярные разделы</Drawer.Title>
        </Drawer.Block.Head>
        <Drawer.List>
          {DRAWER_SECTION_ITEMS.map((item) => (
            <Drawer.List.Item key={item.key} as="a" href={item.link}>
              <Card>
                <Card.Icon src={item.icon} />
                <Card.Block>
                  <Card.Title>{item.title}</Card.Title>
                  <Card.Content>{item.content}</Card.Content>
                </Card.Block>
              </Card>
            </Drawer.List.Item>
          ))}
        </Drawer.List>
      </Drawer.Block>
    </Drawer>
  );
};

export default SiderDrawer;
