import { DatePicker } from 'antd';
import styled, { css } from 'styled-components';

import { Input } from '@atoms';

export const DatePickerContainer = styled(DatePicker)`
  ${({ $filter }) =>
    $filter &&
    css`
      position: absolute;
      bottom: 0;
      left: 0;
      visibility: hidden;
      opacity: 0;
      .ant-picker-input {
        display: none;
      }
    `}
`;

export const DatePickerPanelContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.drawerText};
  box-shadow: 0px 12px 20px rgba(117, 115, 111, 0.36);
  border-radius: 5px;
  max-width: 31.4rem;
  .ant-picker {
    &-cell {
      &-today {
        .ant-picker-cell-inner {
          &::before {
            border-color: ${({ theme }) => theme.palette.borderNeutral};
          }
        }
      }
      &-selected {
        .ant-picker-cell-inner {
          background-color: ${({ theme }) => theme.palette.statusCanceledSecondary};
        }
      }
    }
  }
  .icon {
    &.close {
      stroke: ${({ theme }) => theme.palette.text};
      width: 1.2rem;
      height: 1.2rem;
      &.footer {
        stroke: ${({ theme }) => theme.palette.tertiaryText};
        width: 0.7rem;
        height: 0.7rem;
        margin-left: 1rem;
      }
    }
    &.back {
      stroke: ${({ theme }) => theme.palette.text};
      width: 0.8rem;
      height: 1rem;
      transform: rotateY(180deg);
      margin-right: 0.6rem;
    }
  }
`;

export const DatePickerPanelBack = styled.div`
  padding: 1rem 0;
  cursor: pointer;
  ${({ $padding }) =>
    $padding
      ? css`
          padding-left: 1rem;
        `
      : css`
          width: 100%;
        `}
`;

export const DatePickerPanelInner = styled.div`
  padding: 0rem 1.5rem 0.8rem 1.5rem;
`;

export const DatePickerPanelButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.9rem;
  background-color: ${({ theme }) => theme.palette.datePickerButton};
  color: ${({ theme }) => theme.palette.secondaryText};
  font-size: 1.4rem;
  line-height: 1.6rem;
  border: none;
  cursor: pointer;
  transition: 0.2s;
  ${({ $secondary }) =>
    $secondary &&
    css`
      background-color: ${({ theme }) => theme.palette.statusSent};
      color: ${({ theme }) => theme.palette.buttonText};
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      background-color: ${({ theme }) => theme.palette.datePickerButton};
      color: ${({ theme }) => theme.palette.secondaryText};
      pointer-events: none;
    `}
`;

export const DatePickerPanelText = styled.span`
  color: ${({ theme }) => theme.palette.text};
  font-size: 1.3rem;
  line-height: 1.5rem;
`;

export const DatePickerPanelInput = styled(Input)`
  pointer-events: none;
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.palette.input};
  color: ${({ theme }) => theme.palette.text};
  border: none;
  border-radius: 2px;
  &::placeholder {
    color: ${({ theme }) => theme.palette.drawerText};
  }
`;

export const DatePickerPanelWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
