import URI from 'urijs';

import { clientAPI } from '@api';

const _clientAPI = clientAPI.enhanceEndpoints({
  addTagTypes: ['ClientRequests', 'Questionnaire', 'ProvidersAnswers', 'Authenticate'],
});

const clientRequestsAPI = _clientAPI.injectEndpoints({
  endpoints: (build) => ({
    // запросы в статусе "черновик"
    getDraftRequests: build.query({
      query: (objectId) => {
        const URL = new URI('/Requests/clientRequestsDraftListByObject');

        URL.addQuery({ ID_Object: objectId });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['ClientRequests'],
    }),
    // запросы в статусе "отправлено"
    getSendRequests: build.query({
      query: (objectId) => {
        const URL = new URI('/Requests/clientRequestsSendListByObject');

        URL.addQuery({ ID_Object: objectId });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['ClientRequests'],
    }),
    // запросы в статусе "отменено"
    getCancelRequests: build.query({
      query: (objectId) => {
        const URL = new URI('/Requests/clientRequestsCancelListByObject');

        URL.addQuery({ ID_Object: objectId });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['ClientRequests'],
    }),
    // запросы в статусе "архив"
    getArchiveRequests: build.query({
      query: (objectId) => {
        const URL = new URI('/Requests/clientRequestsArchiveListByObject');

        URL.addQuery({ ID_Object: objectId });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['ClientRequests'],
    }),
    // =========================================
    // добавить новый запрос
    addRequest: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Requests/clientRequestsAdd');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['ClientRequests'],
    }),
    // отредактировать новый запрос
    editRequest: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Requests/clientRequestsUpdate');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['ClientRequests'],
    }),
    // сохранить опросный лист
    setQuestionnaire: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Requests/clientRequestsQuestionnaireSet');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['Questionnaire'],
    }),
    // сохранить поставщиков
    setProviders: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Requests/clientRequestsOrganizationsSet');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
    }),
    // =========================================
    // вернет опросный лист с установленными значениями
    getQuestionnaire: build.query({
      query: (params) => {
        const URL = new URI('/Requests/requestsQuestionnaireList');

        URL.addQuery({ ...params });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['Questionnaire'],
    }),
    // вернет список организаций
    getOrganizationsList: build.query({
      query: (params) => {
        const URL = new URI('/Requests/clientRequestsOrganizationsList');

        URL.addQuery({ ...params });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
    }),
    // =========================================
    // удалить запрос
    deleteRequest: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Requests/clientRequestsRemove');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['ClientRequests'],
    }),
    // отправить запрос
    sendRequest: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Requests/clientRequestsSend');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['ClientRequests'],
    }),
    // отменить запрос
    cancelRequest: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Requests/clientRequestsCancel');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['ClientRequests'],
    }),
    // отправить заявку в архив
    archiveRequest: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Requests/clientRequestsArchive');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['ClientRequests'],
    }),
    // скопировать запрос
    copyRequest: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Requests/clientRequestsCopy');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['ClientRequests'],
    }),
    // отредатировать отправленный запрос (создаст новый с такими же параметрами)
    editSentRequest: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Requests/clientRequestsEdit');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['ClientRequests'],
    }),
    // =========================================
    // активные ответы на заявку
    getActiveAnswers: build.query({
      query: (requestID) => {
        const URL = new URI('/Requests/clientRequestsAnswerActiveList');

        URL.addQuery({ ID_Request: requestID });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['ProvidersAnswers'],
    }),
    // отклоненные ответы на заявку
    getCanceledAnswers: build.query({
      query: (requestID) => {
        const URL = new URI('/Requests/clientRequestsAnswerCancelList');

        URL.addQuery({ ID_Request: requestID });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['ProvidersAnswers'],
    }),
    // отклоненные поставщиком ответы
    getRejectedAnswers: build.query({
      query: (requestID) => {
        const URL = new URI('/Requests/clientRequestsAnswerCancelProviderList');

        URL.addQuery({ ID_Request: requestID });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['ProvidersAnswers'],
    }),
    // выбранный ответ
    getSelectedAnswer: build.query({
      query: (requestID) => {
        const URL = new URI('/Requests/clientRequestsAnswerSelected');

        URL.addQuery({ ID_Request: requestID });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['ProvidersAnswers'],
    }),
    // выбрать поставщика
    chooseAnswer: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Requests/clientRequestsAnswerChange');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['ProvidersAnswers', 'ClientRequests'],
    }),
    // отклонить ответ поставщика
    cancelAnswer: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Requests/clientRequestsAnswerCancel');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['ProvidersAnswers', 'ClientRequests'],
    }),
    // возврат отклоненного в работу
    returnCanceledAnswer: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Requests/clientRequestsAnswerCancelReturn');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['ProvidersAnswers'],
    }),
    // отметить просмотренным
    setViewed: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Requests/clientRequestsAnswerViewedChange');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['ProvidersAnswers'],
    }),
    // информация об ответах от поставщиков
    getAnswersInfo: build.query({
      query: (requestID) => {
        const URL = new URI('/Requests/requestsOrganizationsAnswerInfo');

        URL.addQuery({ ID_Request: requestID });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['ClientRequests', 'ProvidersAnswers', 'Authenticate'],
    }),
  }),
});

export const {
  useGetDraftRequestsQuery,
  useGetSendRequestsQuery,
  useGetCancelRequestsQuery,
  useGetArchiveRequestsQuery,

  useAddRequestMutation,
  useEditRequestMutation,
  useSetQuestionnaireMutation,
  useSetProvidersMutation,

  useGetOrganizationsListQuery,
  useGetQuestionnaireQuery,

  useDeleteRequestMutation,
  useSendRequestMutation,
  useCancelRequestMutation,
  useArchiveRequestMutation,
  useCopyRequestMutation,
  useEditSentRequestMutation,

  useGetActiveAnswersQuery,
  useGetCanceledAnswersQuery,
  useGetRejectedAnswersQuery,
  useGetSelectedAnswerQuery,
  useChooseAnswerMutation,
  useCancelAnswerMutation,
  useReturnCanceledAnswerMutation,
  useSetViewedMutation,
  useGetAnswersInfoQuery,
} = clientRequestsAPI;
