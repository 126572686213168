export const commonTheme = {
  fonts: {
    primary: 'Roboto',
  },
  palette: {
    text: '#000000',
    secondaryText: '#5F5D67',
    tertiaryText: '#787680',
    quaternaryText: '#565656',
    body: '#EEEFF5',
    bodySecondary: '#ffffff',
    sider: '#0D073F',
    siderSecondary: '#ECBC6D',
    siderSecondaryHover: '#FFD081',
    siderHover: '#2A2377',
    siderText: '#A6A1FC',
    siderTextActive: '#ffffff',
    siderTextSecondary: '#C2C1FF',
    error: '#FF5449',
    notification: '#D85159',
    notificationHover: '#C43D45',
    notificationSecondary: '#5D598B',
    notificationAdmin: '#F8F0D5',
    borderNeutral: '#E4E1EC',
    borderSecondary: '#e3e4e7',
    borderHighlighted: '#3730B4',
    button: '#2112A2',
    buttonText: '#ffffff',
    buttonHover: '#3F30C0',
    drawer: '#211B53',
    drawerElement: '#373261',
    drawerText: '#C8C5D0',
    drawerTextSecondary: '#ffffff',
    statusSent: '#5B95DE',
    statusSentHover: '#4781CA',
    statusCanceled: '#D0633F',
    statusCanceledSecondary: '#D85159',
    statusArchive: '#BE8612',
    statusArchiveSecondary: '#FFDEAC',
    statusDraft: '#928F9A',
    input: '#F8F9FF',
    inputPlaceholder: '#666666',
    inputLabel: '#5993DC',
    inputError: '#A80710',
    card: '#2A2377',
    cardText: '#aeaeae',
    cardButton: '#efefef',
    cardTextSecondary: '#928F9A',
    filterOption: '#EBF1FF',
    datePickerButton: '#F6F6F6',
    actionDelete: '#883C43',
    actionSend: '#699DDF',
    actionSendHover: '#3A83DF',
    actionArchive: '#6C6B74',
    link: '#3520E6',
    cancelButton: '#FFEDEC',
    cancel: '#680014',
    submitButton: '#ECF8EB',
    submit: '#559645',
    revoked: '#BB585E',
    checkboxIcon: '#B9BDC5',
    providerChosen: '#4C9C62',
    providerChosenHover: '#418A55',
    providerError: '#BA1A1A',
    tableRowSeen: '#F3F3F3',
    tableTitle: '#413F49',
    divider: '#ACAAB4',
    loginClientBackground: '#504CCD',
    tagBase: '#1960A5',
    tagBackgroundBase: '#E5F1FF',
    tagGreen: '#39864F',
    tagBackgroundGreen: '#E2FEEA',
    providerUser: '#E6A3A4',
  },
  breakpoints: {
    xl: '1920px',
    md: '1700px',
    sm: '1440px',
    xs: '1000px',
    xxs: '991.9px',
  },
};

export const adminTheme = {
  ...commonTheme,
  palette: {
    ...commonTheme.palette,
    card: '#FFD081',
    cardText: '#47464F',
    cardButton: '#2112A2',
  },
};
