import styled from 'styled-components';

export const RequestInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.bodySecondary};
  width: 100%;
`;

export const RequestInfoHead = styled.div`
  padding: 2.4rem 2rem 0;
`;

export const RequestInfoTitle = styled.h2`
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 2.2rem;
  line-height: 2.8rem;
  color: ${({ theme }) => theme.palette.text};
`;

export const RequestInfoDate = styled.span`
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.palette.secondaryText};
`;

export const RequestInfoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ $margin }) => ($margin ? `${$margin}rem` : '0rem')};
`;

export const RequestInfoActions = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const RequestInfoAction = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.4rem;
  height: 3.4rem;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  transition: 0.5s;
  cursor: pointer;
  .icon {
    stroke: ${({ theme }) => theme.palette.secondaryText};
    width: 2rem;
    height: 2rem;
  }
  &:hover {
    &.duplicate {
      background-color: ${({ theme }) => theme.palette.siderSecondary};
      .icon {
        stroke: ${({ theme }) => theme.palette.text};
      }
    }
    &.cancel {
      background-color: ${({ theme }) => theme.palette.statusCanceledSecondary};
      .icon {
        stroke: ${({ theme }) => theme.palette.buttonText};
      }
    }
    &.edit {
      background-color: ${({ theme }) => theme.palette.sider};
      .icon {
        stroke: ${({ theme }) => theme.palette.buttonText};
      }
    }
    &.delete {
      background-color: ${({ theme }) => theme.palette.actionDelete};
      .icon {
        stroke: ${({ theme }) => theme.palette.buttonText};
      }
    }
    &.send {
      background-color: ${({ theme }) => theme.palette.actionSend};
      .icon {
        stroke: ${({ theme }) => theme.palette.buttonText};
      }
    }
    &.archive {
      background-color: ${({ theme }) => theme.palette.actionArchive};
      .icon {
        stroke: ${({ theme }) => theme.palette.buttonText};
      }
    }
  }
`;

export const RequestInfoContent = styled.div`
  overflow: hidden;
  .ant-tabs {
    height: 100%;
    &-content-holder {
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0rem;
        height: 0rem;
      }
    }
    &-tabpane {
      padding: 3rem 2.5rem 3rem;
    }
  }
`;
