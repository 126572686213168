import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';

import transformToFormData from '@helpers/transformToFormData';
import { Dropdown, Icon, Tooltip } from '@atoms';
import { setDrawerVisibility } from '@reducers/sider';
import {
  RELOCATE_DROPDOWN_MENU,
  ITEM_TYPES,
  USER_TITLES,
  USER_ROLES,
  EQUIPMENT_CAREGORY_ACTIONS_MENU,
  EQUIPMENT_CATEGORY_ACTIONS,
} from '@constants/common';
import { ROUTES } from '@constants/routes';
import { setObjectCreationModalVisibility, setAddCategoryModalVisibility } from '@reducers/application';
import useAuth from '@hooks/useAuth';
import { useGetUserInfoQuery, useGetRegistrationRequestCountQuery, useGetEquipmentRequestCountQuery } from '@api/user';
import { useDeleteEquipmentCategoryMutation } from '@api/equipment';
import { useEditObjectMutation } from '@api/objects';
import { useGetNewRequestCountQuery } from '@api/providerRequests';
import useModerationStatus from '@hooks/useModerationStatus';

import {
  MenuItemTitleText,
  MenuCategoryItemContainer,
  MenuContainer,
  MenuItemBlock,
  MenuItemContainer,
  MenuItemInfo,
  MenuItemNotificaionMark,
  MenuItemStatus,
  MenuItemTitle,
} from './styles';

const MenuItem = ({ children, itemType, icon, iconСlass, linkTo, hasNotification, count, ...rest }) => {
  const isLink = itemType === ITEM_TYPES.LINK;

  return (
    <MenuItemContainer as={isLink && NavLink} to={isLink ? linkTo : ''} {...rest}>
      {icon && <Icon name={icon} className={cn('menu-icon', icon, iconСlass)} />}
      <MenuItemTitle>{children}</MenuItemTitle>
      {hasNotification && <MenuItemNotificaionMark />}
      {count && <MenuItemNotificaionMark $count>{count}</MenuItemNotificaionMark>}
    </MenuItemContainer>
  );
};

const MenuItemRegistartionCount = ({ children, ...rest }) => {
  const { data: registrationCount, isFetching } = useGetRegistrationRequestCountQuery({}, { pollingInterval: 180000 });

  const count = registrationCount || false;

  return (
    <MenuItem {...rest} count={!isFetching && count}>
      {children}
    </MenuItem>
  );
};

const MenuItemEquipmentCount = ({ children, ...rest }) => {
  const { data: equipmentCount, isFetching } = useGetEquipmentRequestCountQuery({}, { pollingInterval: 180000 });

  const count = equipmentCount || false;

  return (
    <MenuItem {...rest} count={!isFetching && count}>
      {children}
    </MenuItem>
  );
};

const MenuItemProviderCount = ({ children, ...rest }) => {
  const { data: requestCount, isFetching } = useGetNewRequestCountQuery({}, { pollingInterval: 180000 });

  const count = requestCount || false;

  return (
    <MenuItem {...rest} count={!isFetching && count}>
      {children}
    </MenuItem>
  );
};

const MenuItemLogout = ({ children, ...rest }) => {
  const { logout } = useAuth();

  const handleLogoutClick = () => {
    logout();
  };

  return (
    <MenuItem {...rest} onClick={handleLogoutClick}>
      {children}
    </MenuItem>
  );
};

const MenuItemCreateObject = ({ children, ...rest }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    if (!location.pathname.includes(ROUTES.REQUESTS)) {
      navigate(ROUTES.REQUESTS);
    }
    dispatch(setObjectCreationModalVisibility(true));
  };

  return (
    <MenuItem $bordered {...rest} onClick={handleOpenModal}>
      {children}
    </MenuItem>
  );
};

const MenuItemNotification = ({ children, ...rest }) => {
  const { hasNotification } = useSelector(({ application }) => application);

  return (
    <MenuItem {...rest} hasNotification={hasNotification}>
      {children}
    </MenuItem>
  );
};

const MenuItemSettings = ({ children, ...rest }) => {
  const { moderated } = useSelector(({ application }) => application);

  return (
    <MenuItem {...rest} hasNotification={!moderated}>
      {children}
    </MenuItem>
  );
};

const MenuItemSupport = ({ children, ...rest }) => {
  const { drawerVisibility } = useSelector(({ sider }) => sider);
  const dispatch = useDispatch();

  const handleSupportClick = () => {
    if (drawerVisibility) {
      dispatch(setDrawerVisibility(false));
    } else {
      dispatch(setDrawerVisibility(true));
    }
  };

  return (
    <MenuItem {...rest} onClick={handleSupportClick}>
      {children}
    </MenuItem>
  );
};

const MenuItemUser = ({ submitted, userId, userName, ...rest }) => {
  const { currentUser } = useSelector(({ user }) => user);
  const { notModeratedStatus, onModerationStatus, rejectedStatus, moderationChecked } = useModerationStatus();

  const { data: userInfo } = useGetUserInfoQuery();

  const isAdmin = currentUser === USER_ROLES.ADMIN;

  const getInitials = () => {
    if (userName) {
      return userName.split(' ').reduce((a, v, i) => (i === 0 ? a : a + (v[0] || '')), '');
    }
    if (userInfo) {
      if (userInfo.f && userInfo.i) {
        return userInfo.i[0] + userInfo.f[0];
      }
    }

    return '';
  };

  const getUserName = () => {
    if (userName) {
      return userName;
    }
    if (userInfo) {
      if (userInfo.f && userInfo.i) {
        return `${userInfo.i} ${userInfo.f[0]}.`;
      }
    }

    return USER_TITLES[currentUser];
  };

  const getStatus = () => {
    if (rejectedStatus) {
      return 'Отклонен';
    }

    if (onModerationStatus) {
      return 'На модерации';
    }

    if (moderationChecked) {
      return 'Подтвержден';
    }

    return 'Не подтвержден';
  };

  const tooltipTitle = (
    <Tooltip.Title>
      <p>
        <span className={cn('accented')}>Роль:</span>
        <span>{USER_TITLES[currentUser]}</span>
      </p>
      <p>
        <span className={cn('accented')}>Статус:</span>
        <span
          className={cn(
            (notModeratedStatus || rejectedStatus) && 'danger',
            onModerationStatus && !rejectedStatus && 'warning',
          )}
        >
          {getStatus()}
        </span>
      </p>
    </Tooltip.Title>
  );

  if (isAdmin) {
    return (
      <MenuItem {...rest}>
        <MenuItemInfo role={currentUser}>{getInitials()}</MenuItemInfo>
        <MenuItemBlock>{getUserName()}</MenuItemBlock>
      </MenuItem>
    );
  }

  return (
    <Tooltip title={tooltipTitle} placement="rightTop" trigger="click">
      <MenuItem {...rest}>
        <MenuItemInfo role={currentUser}>{getInitials()}</MenuItemInfo>
        <MenuItemBlock>
          {getUserName()}
          {moderationChecked && <MenuItemStatus>Подтвержден</MenuItemStatus>}
        </MenuItemBlock>
      </MenuItem>
    </Tooltip>
  );
};

const MenuItemLink = ({ children, status, objectId, objectName, objectCode, objectFullName, ...rest }) => {
  const [changeStatus, { isSuccess: isSuccessChangeStatus }] = useEditObjectMutation();

  useEffect(() => {
    if (isSuccessChangeStatus) {
      message.success({
        content: 'Статус объекта изменен',
        key: 'isSuccessChangeStatus-message-5',
      });
    }
  }, [isSuccessChangeStatus]);

  const handleDropdownItemClick = (val) => {
    changeStatus(
      transformToFormData({
        ID_Status: Number(val.key),
        ID: objectId,
        Name: objectName,
        Name_Full: objectFullName,
        Code: objectCode,
      }),
    );
  };

  const dropdownMenu = (
    <Dropdown.Menu
      onClick={handleDropdownItemClick}
      selectable
      defaultSelectedKeys={[`${status}`]}
      items={RELOCATE_DROPDOWN_MENU}
    />
  );

  return (
    <MenuItem title={children} {...rest}>
      <MenuItemTitleText>{children}</MenuItemTitleText>
      <Dropdown overlayClassName={cn('move-dropdown')} overlay={dropdownMenu} placement="bottomRight">
        <Icon name="more" className={cn('more')} />
      </Dropdown>
    </MenuItem>
  );
};

const MenuEquipCategoryItem = ({ id, text, setCurrent }) => {
  const dispatch = useDispatch();
  const [openDropdown, setOpenDropdown] = useState(false);

  const [deleteCategoryTrigger, { isError: isErrorDelete, isSuccess: isSuccessDelete }] =
    useDeleteEquipmentCategoryMutation();

  const editCategory = () => {
    setCurrent(id);
    dispatch(setAddCategoryModalVisibility(true));
  };

  const deleteCategory = () => {
    deleteCategoryTrigger(transformToFormData({ ID: id }));
  };

  const handleDropdownClick = (value) => {
    value.domEvent.preventDefault();

    switch (value.key) {
      case EQUIPMENT_CATEGORY_ACTIONS.EDIT.name:
        editCategory();
        break;
      case EQUIPMENT_CATEGORY_ACTIONS.DELETE.name:
        deleteCategory();
        break;
      default:
        break;
    }
  };

  const handleOpenChange = (flag) => {
    setOpenDropdown(flag);
  };

  const dropdownMenu = <Dropdown.Menu onClick={handleDropdownClick} items={EQUIPMENT_CAREGORY_ACTIONS_MENU} />;

  return (
    <MenuCategoryItemContainer>
      <NavLink to={`${ROUTES.EQUIPMENT}/${id}`}>{text}</NavLink>
      <Dropdown
        overlayClassName={cn('category-item-dropdown')}
        overlay={dropdownMenu}
        placement="bottomRight"
        onVisibleChange={handleOpenChange}
        visible={openDropdown}
      >
        <Icon $transition name="more" className={cn('more')} />
      </Dropdown>
    </MenuCategoryItemContainer>
  );
};

const Menu = ({ items, mode, ...rest }) => {
  return <MenuContainer {...rest} mode={mode} items={items} />;
};

Menu.Item = MenuItem;
Menu.Item.Logout = MenuItemLogout;
Menu.Item.User = MenuItemUser;
Menu.Item.Link = MenuItemLink;
Menu.Item.Support = MenuItemSupport;
Menu.Item.Notification = MenuItemNotification;
Menu.Item.CreateObject = MenuItemCreateObject;
Menu.Item.RegistrationCount = MenuItemRegistartionCount;
Menu.Item.EquipmentCount = MenuItemEquipmentCount;
Menu.Item.ProviderCount = MenuItemProviderCount;
Menu.Item.EquipCategory = MenuEquipCategoryItem;
Menu.Item.Settings = MenuItemSettings;

export default Menu;
