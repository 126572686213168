import styled, { css } from 'styled-components';

export const TagContainer = styled.div`
  padding: 0.3rem 0.5rem;
  text-align: center;
  color: ${({ theme }) => theme.palette.tagBase};
  background-color: ${({ theme }) => theme.palette.tagBackgroundBase};

  ${({ $green }) =>
    $green &&
    css`
      color: ${({ theme }) => theme.palette.tagGreen};
      background-color: ${({ theme }) => theme.palette.tagBackgroundGreen};
    `}
`;
