import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import getCurrentPath from '@helpers/getCurrentPath';
import { ROUTES } from '@constants/routes';

const useLayout = () => {
  // test
  const hasAdminAccess = false;
  // const { isAuthenticated, hasAdminAccess } = useAuth();

  const { isAuthenticated } = useSelector(({ user }) => user);

  const { pathname } = useLocation();

  const routesWithoutSider = useMemo(() => [ROUTES.LOGIN, ROUTES.REGISTRATION, ROUTES.NOT_FOUND], []);

  const hasSider = () => {
    const getAvailableRoutes = () => {
      const allAvailableRoutes = Object.values(ROUTES);

      if (hasAdminAccess) {
        return allAvailableRoutes;
      }

      return allAvailableRoutes.filter((route) => route !== '/admin');
    };

    const getIsRouteExisting = (routes) => {
      return routes.includes(getCurrentPath(pathname));
    };

    if (
      !isAuthenticated ||
      routesWithoutSider.includes(getCurrentPath(pathname)) ||
      !getIsRouteExisting(getAvailableRoutes())
    ) {
      return false;
    }

    return true;
  };

  return { hasSider };
};

export default useLayout;
