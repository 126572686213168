import styled from 'styled-components';

export const ChatButtonBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: ${({ theme }) => theme.palette.link};
  }
`;
