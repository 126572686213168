import React from 'react';

import { Description, File, Skeleton } from '@atoms';
import { useGetOrganizationInfoQuery } from '@api/additional';
import getFileType from '@helpers/getFileType';
import formatPrice from '@helpers/formatPrice';

import { ProviderInfoBlock, ProviderInfoContainer, ProviderInfoName, ProviderInfoTitle } from './styles';

const ProviderInfo = ({ status, provider, data, files, ...rest }) => {
  const { data: organizationData, isFetching: isFetchingOrganizationData } = useGetOrganizationInfoQuery(
    status?.inN_Organization,
    { skip: !status },
  );

  if (provider) {
    return (
      <ProviderInfoContainer $provider {...rest}>
        <ProviderInfoBlock $margin="2.2">
          <Description $width="37.1" column={1} bordered>
            <Description.Item label="Сумма">{`${formatPrice(status?.sum)} ${status?.short_Currency}`}</Description.Item>
            <Description.Item label="Срок поставки">{`${status?.delivery_Week} (недель)`}</Description.Item>
          </Description>
        </ProviderInfoBlock>
        <ProviderInfoBlock>
          <ProviderInfoTitle>Технические характеристики</ProviderInfoTitle>
          {files?.map((file) => (
            <File
              $secondary
              key={file.id}
              title={file.name}
              link={`https://teststim.biz-office.ru/Additional/download?Folder=Answer&File_Path=${
                file.path
              }&File_Name=${file.name}${getFileType(file.path)}`}
              download
            />
          ))}
        </ProviderInfoBlock>
      </ProviderInfoContainer>
    );
  }
  return (
    <ProviderInfoContainer {...rest}>
      <ProviderInfoBlock $margin="2.2">
        <ProviderInfoTitle $secondary>Выбранный поставщик</ProviderInfoTitle>
        {isFetchingOrganizationData ? (
          <Skeleton.Input size="small" active />
        ) : (
          <ProviderInfoName>{organizationData?.name_Short}</ProviderInfoName>
        )}
      </ProviderInfoBlock>
      <ProviderInfoBlock $margin="2.2">
        <ProviderInfoTitle>Предложение</ProviderInfoTitle>
        <Description $width="37.2" column={1} bordered>
          <Description.Item label="Сумма">{`${formatPrice(status?.sum)} ${status?.short_Currency}`}</Description.Item>
          <Description.Item label="Срок поставки">{`${status?.delivery_Week} (недель)`}</Description.Item>
        </Description>
      </ProviderInfoBlock>
      <ProviderInfoBlock>
        <ProviderInfoTitle>Технические характеристики</ProviderInfoTitle>
        {files?.map((file) => (
          <File
            $secondary
            key={file.id}
            title={file.name}
            link={`https://teststim.biz-office.ru/Additional/download?Folder=Answer&File_Path=${file.path}&File_Name=${
              file.name
            }${getFileType(file.path)}`}
            download
          />
        ))}
      </ProviderInfoBlock>
    </ProviderInfoContainer>
  );
};

export default ProviderInfo;
