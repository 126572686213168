import React, { memo } from 'react';

import { RadioContainer, RadioGroup } from './styles';

const Radio = memo(({ children, ...rest }) => {
  return <RadioContainer {...rest}>{children}</RadioContainer>;
});

Radio.Group = RadioGroup;

export default Radio;
