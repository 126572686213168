import { Form } from 'antd';
import styled, { css } from 'styled-components';

export const FormItem = styled(Form.Item)`
  width: 100%;
  ${({ $margin }) =>
    $margin &&
    css`
      margin-bottom: ${$margin}rem !important;
    `}

  ${({ $width }) =>
    $width &&
    css`
      max-width: ${$width}rem;
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
    `}
`;

export const FormBlock = styled.div`
  &:not(:last-child) {
    margin-bottom: 3.1rem;
  }
  ${({ $wrapper }) =>
    $wrapper &&
    css`
      padding: 4.2rem 3rem 7.1rem;
      border-radius: 8px;
      background-color: ${({ theme }) => theme.palette.body};
    `}
`;

export const FormTitle = styled.h2`
  margin-bottom: ${({ $margin }) => ($margin ? `${$margin}rem` : '0')};
  font-size: 1.2rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.palette.text};
  font-weight: 500;

  &.required {
    &::after {
      content: '*';
      color: ${({ theme }) => theme.palette.notification};
      font-size: 1.8rem;
      line-height: 0.7rem;
      margin-left: 0.3rem;
    }
  }

  ${({ $large }) =>
    $large &&
    css`
      font-size: 1.4rem;
    `}

  ${({ $bold }) =>
    $bold &&
    css`
      font-weight: 600;
    `}

  ${({ $pl }) =>
    $pl &&
    css`
      padding-left: ${$pl}rem;
    `}
`;

export const FormSubtitle = styled.p`
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.palette.secondaryText};
  margin-bottom: ${({ $mb }) => ($mb ? `${$mb}rem` : '0')};
  margin-top: ${({ $mt }) => ($mt ? `${$mt}rem` : '0')};
  ${({ $secondary }) =>
    $secondary &&
    css`
      color: ${({ theme }) => theme.palette.text};
    `}
`;

export const FormRow = styled.div`
  display: flex;
  padding: ${({ $padding }) => $padding || '0'};
  .ant-form {
    &-item {
      margin-right: 2rem;
      &:not(:last-child) {
        margin-bottom: 3.1rem;
      }
    }
  }

  ${({ $bordered }) =>
    $bordered &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.palette.borderNeutral};
    `}
`;

export const FormWrap = styled.div`
  display: flex;
  flex: 100%;
`;

export const FormHead = styled.div``;

export const FormElement = styled.div`
  margin-bottom: ${({ $mb }) => ($mb ? `${$mb}rem` : '0')};
  transition: 0.2s;

  &.active {
    background-color: ${({ theme }) => theme.palette.body};
    span {
      color: ${({ theme }) => theme.palette.text};
    }
  }

  ${({ $spaceBetween }) =>
    $spaceBetween &&
    css`
      display: flex;
      justify-content: space-between;
      gap: ${({ $gap }) => ($gap ? `${$gap}rem` : '0')};
    `}

  ${({ $padding }) =>
    $padding &&
    css`
      padding: ${$padding};
    `}

  ${({ $center }) =>
    $center &&
    css`
      display: flex;
      align-items: center;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;

export const FormText = styled.p`
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.palette.secondaryText};
`;

export const FormElementWrap = styled.div``;

export const FormElementBlock = styled.div``;

export const FormContainer = styled(Form)`
  .ant-form {
    &-item {
      margin-bottom: ${({ $gap }) => ($gap ? `${$gap}rem` : '0')};
    }
  }
  &.change-password-form {
    .ant-input {
      width: 26rem;
      background-color: ${({ theme }) => theme.palette.bodySecondary};
    }
    ${FormItem} {
      &:not(:last-child) {
        margin-bottom: 3rem;
      }
    }
  }
  &.personal-info-form {
    .ant-input {
      width: 26rem;
    }
    &.admin {
      max-width: 54rem;
      ${FormBlock} {
        margin-bottom: 0;
      }
    }
  }
  &.company-info-form {
    .page-block {
      max-width: 55rem;
      width: 100%;
    }
    .ant-form {
      &-item {
        &:not(:last-child) {
          margin-bottom: 3.1rem;
        }
      }
    }
    &.admin {
      max-width: 55rem;
      ${FormRow} {
        gap: 2rem;
        &:not(:last-child) {
          margin-bottom: 3.1rem;
        }
        ${FormItem} {
          margin: 0;
        }
      }
      ${FormBlock} {
        margin-bottom: 4rem;
      }
    }
    .ant-checkbox {
      &-wrapper {
        margin-bottom: 3rem;
      }
    }
  }
  &.login-form {
    margin-top: 3rem;
    max-width: 40rem;
    .ant-form {
      &-item {
        margin-bottom: 2rem;
      }
    }
  }
  &.register-form {
    .ant-form {
      &-item {
        margin-bottom: 2.8rem;
        .ant-input {
          padding: 1.4rem 4rem 1rem 1.5rem;
          font-size: 1.6rem;
          line-height: 2.4rem;
        }
      }
    }
    .ant-btn {
      width: 100%;
      height: 4.6rem;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
    &.email-confirmation {
      .ant-form {
        &-item {
          margin: 0;
          &:not(:last-child) {
            margin-bottom: 2.8rem;
          }
        }
      }
    }
  }
  &.request-info-form {
    .ant-form {
      &-item {
        &-required {
          &::before,
          &::after {
            display: none !important;
          }
        }
      }
    }
  }
  &.request-providers-form {
    display: flex;
    flex-direction: column;
    flex: 100%;
    ${FormBlock} {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0;
    }
    ${FormRow} {
      &.checkbox-group-head {
        padding: 0.8rem 2rem;
        background-color: ${({ theme }) => theme.palette.card};
        &,
        * {
          color: ${({ theme }) => theme.palette.cardText};
        }

        margin: 0;
      }
    }
    ${FormItem} {
      height: 100%;
      padding: 2.2rem 2rem;
      &.bordered-item {
        border-left: 1px solid ${({ theme }) => theme.palette.borderNeutral};
      }
    }
    .padding {
      padding-left: 4.8rem !important;
    }
  }
  &.mobile-form {
    max-width: 50rem;
    .ant-form {
      &-item {
        margin-bottom: 1.2rem;
      }
    }
    .button {
      width: 100%;
    }
  }
  ${({ $grid }) =>
    $grid &&
    css`
      .ant {
        &-row {
          display: grid;
          grid-template-columns: 18rem 1fr;
        }
        &-form {
          &-item {
            &-label {
              display: flex;
              justify-content: flex-start;
            }
          }
        }
      }
    `}
  &.questionnaire {
    max-width: 70rem;
    width: 100%;
    ${FormHead} {
      background-color: ${({ theme }) => theme.palette.card};
      ${FormElement} {
        padding: 0.7rem 0rem 0.7rem 1rem;
      }
      ${FormTitle} {
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
      }
      &.title-padding {
        ${FormTitle} {
          padding-left: 1rem;
        }
      }
      .ant-checkbox-wrapper {
        color: ${({ theme }) => theme.palette.secondaryText};
      }
    }
    ${FormRow} {
      ${FormTitle} {
        padding-left: 1rem;
      }
      ${FormItem} {
        margin-bottom: 0;
      }
    }
    ${FormRow}, ${FormHead} {
      &.grid {
        display: grid;
        grid-template-columns: repeat(4, 16rem);
        gap: 2rem;
      }
      &.grid-2 {
        display: grid;
        grid-template-columns: 24.5rem 1fr;
      }
      &.grid-3 {
        display: grid;
        grid-template-columns: 24.5rem 2.5rem 1fr;
      }
      &.grid-4 {
        display: grid;
        grid-template-columns: 25.5rem 1fr 1fr 1fr;
      }
      &.grid-5 {
        display: grid;
        grid-template-columns: 27.2rem 1fr;
      }
      &.grid-6 {
        display: grid;
        grid-template-columns: 25.2rem 7.2rem 7.2rem 1fr;
        gap: 2rem;
      }
    }
    &.questionnaire-heat-point {
    }
    .ant-select {
      width: 100%;
    }
    .ant-form {
      &-item {
        margin-right: 0;
      }
    }
  }

  ${({ readOnly }) =>
    readOnly &&
    css`
      .ant {
        &-form {
          &-item {
            .icon {
              display: none;
            }
            .ant-input {
              border-color: ${({ theme }) => theme.palette.drawerText} !important;
            }
            .ant-select {
              &.ant-select-status-success {
                .ant-select-selector {
                  border-color: ${({ theme }) => theme.palette.drawerText} !important;
                }
              }
            }
          }
        }
      }
    `}
`;
