import styled, { css } from 'styled-components';

export const SvgContainer = styled.svg`
  width: 50px;
  height: 50px;

  ${({ $transition }) =>
    $transition &&
    css`
      transition: all 0.2s;
    `}
`;
