import React from 'react';

import { TabPaneContainer, TabsContainer } from './styles';

const Tabs = ({ children, ...rest }) => {
  return <TabsContainer {...rest}>{children}</TabsContainer>;
};

Tabs.TabPane = TabPaneContainer;

export default Tabs;
