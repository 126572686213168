import React from 'react';

import { RegisterBlock, RegisterContainer, RegisterLink, RegisterText, RegisterTitle } from './styles';

const Register = ({ children, ...rest }) => {
  return <RegisterContainer {...rest}>{children}</RegisterContainer>;
};

Register.Block = RegisterBlock;
Register.Text = RegisterText;
Register.Link = RegisterLink;
Register.Title = RegisterTitle;

export default Register;
