import { Select } from 'antd';
import styled from 'styled-components';

export const FilterContainer = styled.div`
  width: 100%;
  padding: 0.6rem 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.borderNeutral};
`;

export const FilterText = styled.span`
  font-size: 1.1rem;
  line-height: 1.5rem;
  transition: 0.2s;
`;

export const FilterOptions = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const FilterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  border-radius: 2px;
  padding: 0.85rem 1rem;
  cursor: pointer;
  transition: 0.2s;
  color: ${({ theme }) => theme.palette.secondaryText};
  .icon {
    &.filter {
      stroke: ${({ theme }) => theme.palette.secondaryText};
      width: 1.6rem;
      height: 1rem;
      margin-right: 1.2rem;
    }
  }
  &:hover,
  &.dropdown-opened {
    background-color: ${({ theme }) => theme.palette.body};
    .icon {
      &.filter {
        stroke: ${({ theme }) => theme.palette.text};
      }
    }
    ${FilterText} {
      color: ${({ theme }) => theme.palette.text};
    }
  }
`;
export const FilterAction = styled.div`
  position: relative;
`;

export const FilterDropdownWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

export const FilterOptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.2rem;
  padding: 0rem 0.6rem 0rem 1rem;
  background-color: ${({ theme }) => theme.palette.filterOption};
  border-radius: 2px;
  .icon {
    &.close {
      stroke: ${({ theme }) => theme.palette.button};
      width: 1rem;
      height: 1rem;
    }
  }
  ${FilterText} {
    color: ${({ theme }) => theme.palette.button};
  }
`;
export const FilterOptionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  padding: 0.4rem;
  cursor: pointer;
  margin-left: 0.7rem;
`;
