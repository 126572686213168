import React, { Fragment, Suspense, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { useNavigate, useRoutes } from 'react-router-dom';
import 'moment/locale/ru';
import locale from 'antd/es/locale/ru_RU';
import { ConfigProvider, notification } from 'antd';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { createSignalRContext } from 'react-signalr';

import { setHasNotification } from '@reducers/application';
import { Loader, Modal } from '@atoms';
import { Layout } from '@templates';
import { adminTheme, commonTheme } from '@theme';
import { adminRoutes, clientRoutes, providerRoutes, unauthenticatedRoutes } from '@routes';
import useAuth from '@hooks/useAuth';
import { useGetCommentStatusQuery, useGetUserRequestsListQuery } from '@api/user';
import { setLastMessage, setNeedRefresh, updateChats } from '@reducers/chat';
import { ROUTES } from '@constants/routes';
import { useConfirmMessageMutation, chatAPI } from '@api/chat';

const { useSignalREffect, Provider, invoke } = createSignalRContext();

require('dayjs/locale/ru');

dayjs.locale('ru');

const BaseApp = ({ element, theme = commonTheme }) => {
  return (
    <Fragment>
      <ConfigProvider locale={locale}>
        <ThemeProvider theme={theme}>
          <Layout>
            <Suspense fallback={<Loader />}>{element}</Suspense>
          </Layout>
        </ThemeProvider>
      </ConfigProvider>
    </Fragment>
  );
};

// application for unauthenticated users: login, registration
const UnauthenticatedApp = () => {
  const element = useRoutes(unauthenticatedRoutes);

  return <BaseApp element={element} />;
};

const ClientApp = () => {
  const element = useRoutes(clientRoutes);

  return <BaseApp element={element} />;
};

const ProviderApp = () => {
  const element = useRoutes(providerRoutes);

  return <BaseApp element={element} />;
};

const AdminApp = () => {
  const element = useRoutes(adminRoutes);

  return <BaseApp element={element} theme={adminTheme} />;
};

const LoadingApp = () => {
  return (
    <Fragment>
      <ConfigProvider locale={locale}>
        <ThemeProvider theme={commonTheme}>
          <Loader $center small="true" />
        </ThemeProvider>
      </ConfigProvider>
    </Fragment>
  );
};

const App = () => {
  const dispatch = useDispatch();
  const {
    hasClientRole,
    hasProviderRole,
    hasAdminRole,
    hasNotAuthenticatedStatus,
    isLoadingApp,
    isAuthenticated,
    token,
  } = useAuth();

  const { data: commentStatus } = useGetCommentStatusQuery(
    {},
    { skip: hasAdminRole || !isAuthenticated, pollingInterval: 180000 },
  );

  useEffect(() => {
    if (commentStatus === 1) {
      dispatch(setHasNotification(true));
    } else {
      dispatch(setHasNotification(false));
    }
  }, [commentStatus, dispatch]);
  const navigate = useNavigate();

  const { currentChatId } = useSelector(({ chat }) => chat);
  useSignalREffect(
    'sendReceive',
    (message) => {
      console.log(message);
      console.log(currentChatId);

      const requestName =
        message.iD_Type_Chat === 1
          ? 'административном чате'
          : `чате ${(message?.name_Object && `${message?.name_Object} - ${message?.name_Equipment}`) || '***'}`;

      if (!currentChatId || +currentChatId !== +message.iD_Chat) {
        notification.info({
          onClick: () => {
            if (message.iD_Type_Chat === 1) {
              if (hasAdminRole) navigate(`${ROUTES.CHAT_WITH_USER}/${message.iD_Chat}`);
              else navigate(`${ROUTES.CHAT_WITH_ADMIN}`);
            } else if (hasClientRole)
              navigate(
                `${ROUTES.REQUESTS}/${message.iD_Object}/${message.iD_Request}/${message.iD_Chat}/${message.iD_Requests_Answer}/1#response`,
              );
            else {
              navigate(`${ROUTES.REQUESTS}/all/${message.iD_Request}#chat`);
            }
          },
          message: `Новое сообщение от: ${
            message.iD_Type_Chat === 1 && !hasAdminRole
              ? 'Администратор'
              : `${message?.user_Add_Fio} (${message?.user_Add_Org})`
          }`,
          description: hasAdminRole ? undefined : <div>В {requestName} у вас новое сообщение.</div>,
          placement: 'topRight',
        });
      }
      dispatch(setNeedRefresh(true));
      dispatch(setLastMessage(message));
      chatAPI.util.invalidateTags(['Chats', 'Messages']);
      setTimeout(() => dispatch(updateChats(1)), 1000);
      setTimeout(() => dispatch(updateChats(3)), 1000);
    },
    [setNeedRefresh, setLastMessage, notification, hasClientRole, hasAdminRole, currentChatId],
  );
  // invoke('send', chat_id, message)

  if (!isLoadingApp) {
    if (hasNotAuthenticatedStatus) return <UnauthenticatedApp />;

    if (hasAdminRole || hasClientRole || hasProviderRole) {
      return (
        <Provider
          connectEnabled={!!token}
          accessTokenFactory={() => token}
          dependencies={[token]}
          withCredentials
          url={`https://teststim.biz-office.ru/chat?token=${token}`}
        >
          {hasAdminRole && <AdminApp />}
          {hasClientRole && <ClientApp />}
          {hasProviderRole && <ProviderApp />}
          <ThemeProvider theme={commonTheme}>
            <Modal.ChatsNotification />
          </ThemeProvider>
        </Provider>
      );
    }
  }

  return <LoadingApp />;
};

export default App;
