import { Upload } from 'antd';
import styled, { css } from 'styled-components';

export const UploadContainer = styled(Upload)`
  display: flex;
  flex-direction: column;

  &.has-uploads {
    .upload-button {
      background-color: ${({ theme }) => theme.palette.body};
      color: ${({ theme }) => theme.palette.text};
      .icon {
        stroke: ${({ theme }) => theme.palette.text};
      }
    }
    .ant-upload {
      &-list {
        margin-bottom: 2rem;
      }
    }
  }

  ${({ $reverse }) =>
    $reverse &&
    css`
      flex-direction: column-reverse;
    `}

  ${({ $relative }) =>
    $relative &&
    css`
      position: relative;
    `}
`;
