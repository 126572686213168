import styled, { css } from 'styled-components';

export const NotificationCardTitle = styled.p`
  margin: 0 0 0.8rem 0;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.palette.secondaryText};
`;

export const NotificationCardDate = styled.span`
  display: inline-block;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.palette.cardText};
`;

export const NotificationCardDescription = styled.p`
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.palette.text};
`;

export const NotificationCardContainer = styled.div`
  position: relative;
  padding: 2.1rem 3rem;
  background-color: ${({ theme }) => theme.palette.card};
  &:not(:last-child) {
    margin-bottom: 0.1rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 2.3rem;
    right: 1.9rem;
    width: 1.2rem;
    height: 1.2rem;
    background-color: ${({ theme }) => theme.palette.statusCanceledSecondary};
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
  }

  ${({ $unread }) =>
    $unread &&
    css`
      ${NotificationCardTitle} {
        color: ${({ theme }) => theme.palette.button};
      }
      &::before {
        opacity: 1;
        visibility: visible;
      }
    `}
`;
