import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import cn from 'classnames';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { message } from 'antd';
import axios from 'axios';

import transformToFormData from '@helpers/transformToFormData';
import { More, Status } from '@atoms';
import {
  setSendMessageModalVisibility,
  setRequestCreationModalVisibility,
  setSendRequestsModalVisibility,
} from '@reducers/application';
import { USER_ROLES, REQUEST_STATUSES } from '@constants/common';
import { setCurrentRequestInfo, setCreateRequestEditMode, setCurrentClickedRequestID } from '@reducers/requests';
import {
  useArchiveRequestMutation,
  useCancelRequestMutation,
  useCopyRequestMutation,
  useDeleteRequestMutation,
  useEditSentRequestMutation,
} from '@api/clientRequests';
import { ROUTES } from '@constants/routes';
import {
  useArchiveProviderRequestMutation,
  useCancelProviderRequestMutation,
  useCancelProviderAnswerMutation,
} from '@api/providerRequests';
import {
  useAcceptEquipmentRequestMutation,
  useAcceptRegistrationRequestMutation,
  useDenyEquipmentRequestMutation,
  useDenyRegistrationRequestMutation,
} from '@api/user';
import { useGetListQuery } from '@api/chat';

import {
  RequestCardBlock,
  RequestCardContainer,
  RequestCardDate,
  RequestCardDescription,
  RequestCardSubtitle,
  RequestCardTitle,
} from './styles';

const RequestCard = ({ requestData, admin, status, setCurrentRequest, handleClick, hideCancelButton, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  // client actions
  const [deleteRequestTrigger, { isSuccess: isSuccessDelete }] = useDeleteRequestMutation();
  const [cancelRequestTrigger, { isSuccess: isSuccessCancel }] = useCancelRequestMutation();
  const [archiveRequestTrigger, { isSuccess: isSuccessArchive }] = useArchiveRequestMutation();
  const [copyRequestTrigger, { isSuccess: isSuccessCopy }] = useCopyRequestMutation();
  // const [editSentRequestTrigger] = useEditSentRequestMutation();

  // provider actions
  const [cancelProviderRequest] = useCancelProviderRequestMutation();
  const [archiveProviderRequest] = useArchiveProviderRequestMutation();
  const [cancelProviderAnswer] = useCancelProviderAnswerMutation();

  // admin actions
  const [acceptRegistrationRequest] = useAcceptRegistrationRequestMutation();
  const [denyRegistrationRequest] = useDenyRegistrationRequestMutation();
  // const [acceptEquipmentRequest] = useAcceptEquipmentRequestMutation();
  // const [denyEquipmentRequest] = useDenyEquipmentRequestMutation();

  const isEquipmentRequest = location.pathname.includes('changes');

  const { currentUser } = useSelector(({ user }) => user);
  const { currentObjectInfo, currentRequestInfo } = useSelector(({ requests }) => requests);
  const isClient = currentUser === USER_ROLES.CLIENT;

  useEffect(() => {
    if (isSuccessDelete) {
      message.success({
        content: 'Запрос удален',
        key: 'isSuccessDelete-request-msg-1',
      });
    }
    if (isSuccessCancel) {
      message.success({
        content: 'Запрос отменен',
        key: 'isSuccessCancel-request-msg-1',
      });
    }
    if (isSuccessArchive) {
      message.success({
        content: 'Запрос добавлен в архив',
        key: 'isSuccessArchive-request-msg-1',
      });
    }
    if (isSuccessCopy) {
      message.success({
        content: 'Запрос скопирован',
        key: 'isSuccessCopy-request-msg-1',
      });
    }
  }, [isSuccessArchive, isSuccessCancel, isSuccessCopy, isSuccessDelete]);

  const { data: chats } = useGetListQuery({
    ID_Type: 1,
    Take: 0,
    Count: 1000,
  });

  const { currentUserId } = useSelector(({ user }) => user);

  const sendMessage = async () => {
    const authorizationToken = localStorage.getItem('token');
    const fullChats = await Promise.all(
      chats?.map(async (chat) => {
        const { data } = await axios.get(`https://teststim.biz-office.ru/api/Chat/chatUserGetList?ID_Chat=${chat.id}`, {
          headers: {
            Authorization: `Bearer ${authorizationToken}`,
          },
        });
        return { ...chat, usersId: [...data] };
      }),
    );
    const chatId = fullChats.find((chat) => chat.usersId.map((ud) => ud.iD_User).includes(requestData.iD_User));
    if (chatId && chatId.id) navigate(`${ROUTES.CHAT_WITH_USER}/${chatId.id}`);
    else {
      const { data: newChatId } = await axios.post(
        `https://teststim.biz-office.ru/api/Chat/chatAdd?ID_Type=1&ID_Users=${currentUserId},${requestData.iD_User}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authorizationToken}`,
          },
        },
      );
      if (newChatId) navigate(`${ROUTES.CHAT_WITH_USER}/${newChatId}`);
    }
  };

  const setRequestData = () => {
    dispatch(setCurrentRequestInfo({ ...requestData, status }));
    dispatch(setCurrentClickedRequestID(requestData.id));
  };

  const handleAdminClick = (val) => {
    switch (val) {
      case 'send':
        sendMessage();
        break;
      case 'cancel':
        if (!isEquipmentRequest) {
          denyRegistrationRequest(transformToFormData({ ID_User: requestData.iD_User }));
        }
        break;
      case 'checked':
        if (!isEquipmentRequest) {
          acceptRegistrationRequest(transformToFormData({ ID_User: requestData.iD_User }));
        }
        break;
      default:
        break;
    }
  };

  const handleClientClick = (val) => {
    switch (val) {
      case 'send':
        dispatch(setCurrentClickedRequestID(requestData.id));
        dispatch(setSendRequestsModalVisibility(true));
        navigate(`${ROUTES.REQUESTS}/${id}/${requestData.id}`);
        break;
      case 'delete':
        deleteRequestTrigger(transformToFormData({ ID_Request: requestData.id }));
        navigate(`${ROUTES.REQUESTS}/${id}`);
        break;
      case 'cancel':
        cancelRequestTrigger({ iD_Request: requestData.id, reason: '' });
        navigate(`${ROUTES.REQUESTS}/${id}/${requestData.id}`);
        break;
      case 'archive':
        archiveRequestTrigger(transformToFormData({ ID_Request: requestData.id }));
        navigate(`${ROUTES.REQUESTS}/${id}/${requestData.id}`);
        break;
      case 'duplicate':
        copyRequestTrigger(transformToFormData({ ID_Request: requestData.id }));
        break;
      case 'edit':
        dispatch(setCreateRequestEditMode(true));
        dispatch(setRequestCreationModalVisibility(true));
        navigate(`${ROUTES.REQUESTS}/${id}/${requestData.id}`);
        break;
      case 'editSent':
        // editSentRequestTrigger(transformToFormData({ ID_Request: requestData.id })).then((result) => {
        //   navigate(`${ROUTES.REQUESTS}/${id}/${result.data}`);
        //   dispatch(setCreateRequestEditMode(true));
        //   dispatch(setRequestCreationModalVisibility(true));
        // });
        break;
      default:
        break;
    }
  };

  const handleProviderClick = (val) => {
    switch (val) {
      case 'cancel':
        if (currentRequestInfo?.iD_Status_Answer === 0) {
          cancelProviderRequest(transformToFormData({ ID_Request: requestData.iD_Request }));
          navigate(`${ROUTES.REQUESTS}/${id || ''}`);
        } else {
          cancelProviderAnswer(transformToFormData({ ID: requestData.iD_Requests_Answer }));
        }
        dispatch(setCurrentRequestInfo({ ...currentRequestInfo, status: REQUEST_STATUSES.CANCELED.name }));
        break;
      case 'archive':
        archiveProviderRequest(transformToFormData({ ID_Request: requestData.iD_Request }));
        dispatch(setCurrentRequestInfo({ ...currentRequestInfo, status: REQUEST_STATUSES.ARCHIVE.name }));
        break;
      default:
        break;
    }
  };

  if (admin) {
    return (
      <RequestCardContainer className={cn('request-card')} onClick={handleClick} $admin {...rest}>
        <RequestCardBlock>
          <RequestCardSubtitle className={cn('request-card-subtitle')}>ИНН: {requestData.inn}</RequestCardSubtitle>
          {status && <More handleClick={handleAdminClick} status={status} />}
        </RequestCardBlock>
        <RequestCardTitle className={cn('request-card-title')}>{requestData.name_Short}</RequestCardTitle>
        <RequestCardDescription className={cn('request-card-description')}>{requestData.phone}</RequestCardDescription>
        <RequestCardDescription className={cn('request-card-description')}>{requestData.fio}</RequestCardDescription>
        <RequestCardBlock>
          {status && <Status status={status} $width="18" />}
          <RequestCardDate>{dayjs(requestData.date_Add).format('D MMMM YYYY, HH:mm')}</RequestCardDate>
        </RequestCardBlock>
      </RequestCardContainer>
    );
  }

  if (isClient) {
    return (
      <RequestCardContainer className={cn('request-card')} onClick={setRequestData} {...rest}>
        <RequestCardBlock>
          <RequestCardSubtitle className={cn('request-card-subtitle')}>
            {requestData.name_Equipment}
          </RequestCardSubtitle>
          {status && <More hideCancelButton={hideCancelButton} handleClick={handleClientClick} status={status} />}
        </RequestCardBlock>
        <RequestCardTitle className={cn('request-card-title')}>{requestData.object_Code}</RequestCardTitle>
        <RequestCardDescription className={cn('request-card-description')}>{requestData.name}</RequestCardDescription>
        <RequestCardBlock>
          {status && <Status status={status} $width="18" />}
          <RequestCardDate>{dayjs(requestData.date_Add).format('D MMMM YYYY, HH:mm')}</RequestCardDate>
        </RequestCardBlock>
      </RequestCardContainer>
    );
  }

  return (
    <RequestCardContainer className={cn('request-card')} {...rest}>
      <RequestCardBlock>
        <RequestCardSubtitle className={cn('request-card-subtitle')}>{requestData.name_Equipment}</RequestCardSubtitle>
        {status && <More handleClick={handleProviderClick} status={status} />}
      </RequestCardBlock>
      <RequestCardTitle className={cn('request-card-title')}>{requestData.object_Code}</RequestCardTitle>
      <RequestCardDescription className={cn('request-card-description')}>
        {requestData.request_Name}
      </RequestCardDescription>
      <RequestCardBlock>
        {status && <Status status={status} $width="18" />}
        <RequestCardDate>{dayjs(requestData.date_Add).format('D MMMM YYYY, HH:mm')}</RequestCardDate>
      </RequestCardBlock>
    </RequestCardContainer>
  );
};

export default RequestCard;
