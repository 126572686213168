import React, { useState } from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import { Page } from '@organisms';
import { Button, Icon, Modal, Skeleton, Table, Tooltip } from '@atoms';
import { setAddFieldModalVisibility } from '@reducers/application';
import {
  useGetEquipmentQuestionnaireQuery,
  useDeleteEquipmentQuestionnaireFieldMutation,
  useGetEquipmentByIdQuery,
} from '@api/equipment';
import transformToFormData from '@helpers/transformToFormData';

import {
  EquipmentEditAction,
  EquipmentEditActions,
  EquipmentEditBlock,
  EquipmentEditContainer,
  EquipmentEditContent,
  EquipmentEditHead,
} from './styles';

const EquipmentEdit = ({ equipId }) => {
  const dispatch = useDispatch();
  const [currentEditingField, setCurrentEditingField] = useState(null);

  const { data: questionnaireFields } = useGetEquipmentQuestionnaireQuery(equipId);
  const { data: equipmentData, isFetching: isFetchingEquipmentData } = useGetEquipmentByIdQuery(equipId);

  const [deleteFieldTrigger] = useDeleteEquipmentQuestionnaireFieldMutation();

  const addField = () => {
    dispatch(setAddFieldModalVisibility(true));
  };

  const editField = (id) => {
    setCurrentEditingField(id);
    dispatch(setAddFieldModalVisibility(true));
  };

  const deleteField = (id) => {
    deleteFieldTrigger(transformToFormData({ ID: id }));
  };

  const fieldsTableColumns = [
    {
      title: 'Название поля',
      dataIndex: 'name',
      className: 'first-column',
      key: 'name',
      width: '20rem',
      render: (_, record) => <p className={cn('table-title')}>{record.name}</p>,
    },
    {
      title: 'Тип',
      dataIndex: 'name_Type',
      align: 'center',
      key: 'name_Type',
      render: (_, record) => <p className={cn('table-text')}>{record.name_Type}</p>,
    },
    {
      title: 'Обязательный',
      dataIndex: 'required',
      key: 'required',
      align: 'center',
      width: '9.5rem',
      render: (_, record) => (
        <div className={cn('table-tag', !record.required && 'secondary')}>{record.required ? 'Да' : 'Нет'}</div>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: 'actions',
      render: (current, record) => (
        <EquipmentEditActions>
          <Tooltip
            overlayClassName={cn('small-tooltip', 'edit')}
            title="Редактировать"
            placement="bottom"
            arrowPointAtCenter
          >
            <EquipmentEditAction className={cn('edit')} onClick={() => editField(current)}>
              <Icon $transition name="edit" className={cn('edit')} />
            </EquipmentEditAction>
          </Tooltip>
          <Tooltip
            overlayClassName={cn('small-tooltip', 'delete')}
            title="Удалить"
            placement="bottom"
            arrowPointAtCenter
          >
            <EquipmentEditAction className={cn('delete')} onClick={() => deleteField(record.id)}>
              <Icon $transition name="delete" className={cn('delete')} />
            </EquipmentEditAction>
          </Tooltip>
        </EquipmentEditActions>
      ),
    },
  ];

  const equipQuestionnaireFields = questionnaireFields?.map((field) => ({
    ...field,
    key: field.id,
  }));

  return (
    <EquipmentEditContainer>
      <EquipmentEditHead>
        <Button.Back>Назад</Button.Back>
        <EquipmentEditBlock $mt="0.4">
          {isFetchingEquipmentData ? (
            <Skeleton.Input size="small" active />
          ) : (
            <Page.Title>{equipmentData?.name}</Page.Title>
          )}
          <Button.Add onClick={addField}>Добавить поле</Button.Add>
        </EquipmentEditBlock>
      </EquipmentEditHead>
      <EquipmentEditContent>
        <Table
          pagination={false}
          className={cn('equip-table')}
          columns={fieldsTableColumns}
          dataSource={equipQuestionnaireFields}
        />
      </EquipmentEditContent>
      <Modal.AddField equipId={equipId} fieldData={currentEditingField} resetCurrent={setCurrentEditingField} />
    </EquipmentEditContainer>
  );
};

export default EquipmentEdit;
