import React, { memo, useState } from 'react';
import cn from 'classnames';

import { Icon, Tooltip } from '@atoms';
import formatBytes from '@helpers/formatBytes';

import { FileAction, FileContainer, FileSize, FileText, FileTitle, FileWrap } from './styles';

const File = memo(({ title, link, secondary, size, className, remove, ...rest }) => {
  const [isActive, setIsActive] = useState(false);

  const handleMouseIn = () => {
    setIsActive(true);
  };

  const handleMouseOut = () => {
    setIsActive(false);
  };

  if (secondary) {
    return (
      <FileContainer as="div" $row className={cn(className, 'plain')} {...rest}>
        <FileWrap>
          <Icon name="file" className={cn('file')} />
          <FileTitle $underline as="a" href={link}>
            <FileText>
              {title}
              <Icon name="checked" className={cn('success')} />
            </FileText>
            {size && <FileSize>{formatBytes(size)}</FileSize>}
          </FileTitle>
        </FileWrap>
        {remove && (
          <Tooltip
            overlayClassName={cn('small-tooltip', 'delete')}
            title="Удалить"
            placement="bottomRight"
            arrowPointAtCenter
          >
            <FileAction onClick={remove}>
              <Icon $transition name="delete" className={cn('delete')} />
            </FileAction>
          </Tooltip>
        )}
      </FileContainer>
    );
  }

  return (
    <FileContainer
      onMouseEnter={handleMouseIn}
      onMouseLeave={handleMouseOut}
      className={cn(isActive && 'active', className)}
      href={link}
      {...rest}
    >
      <FileWrap>
        <Icon name={isActive ? 'file-upload' : 'file'} className={cn('file')} />
        <FileTitle>
          <FileText>{title}</FileText>
          {size && <FileSize>{formatBytes(size)}</FileSize>}
        </FileTitle>
      </FileWrap>
    </FileContainer>
  );
});

export default File;
