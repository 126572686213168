import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { useForm } from 'antd/lib/form/Form';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import Card from 'antd/lib/card/Card';

import {
  setInnModalVisibility,
  setObjectCreationModalVisibility,
  setSendRequestsModalVisibility,
  setAcceptResponseModalVisibility,
  setAnswerRequestModalVisibility,
  setAddCategoryModalVisibility,
  setAddEquipmentModalVisibility,
  setAddFieldModalVisibility,
  setSendMessageModalVisibility,
  setCancelResponseModalVisibility,
} from '@reducers/application';
import loading from '@helpers/loading';
import { Icon, Form, Input, Upload, Button, File, Checkbox } from '@atoms';
import { objectCreationForm } from '@constants/forms';
import { Select } from '@molecules';
import { useGetTypeListQuery, useGetCurrencyQuery, useCheckInnQuery } from '@api/additional';
import {
  useAddEquipmentCategoryMutation,
  useGetEquipmentCategoryListQuery,
  useAddEquipmentMutation,
  useEditEquipmentMutation,
  useGetEquipmentByIdQuery,
  useAddEquipmentQuestionnaireFieldMutation,
  useEditEquipmentQuestionnaireFieldMutation,
  useGetEquipmentCategoryQuery,
  useEditEquipmentCategoryMutation,
} from '@api/equipment';
import transformToFormData from '@helpers/transformToFormData';
import { useSendMessageMutation } from '@api/user';
import { useAddObjectMutation } from '@api/objects';
import { useSendRequestMutation, useChooseAnswerMutation, useCancelAnswerMutation } from '@api/clientRequests';
import { setCurrentRequestInfo, setCurrentClickedAnswerID } from '@reducers/requests';
import { REQUEST_STATUSES } from '@constants/common';
import { useSendAnswerFileMutation, useSendAnswerMutation, useGetAnswerQuery } from '@api/providerRequests';
import { ROUTES } from '@constants/routes';
import { setUserInn } from '@reducers/user';

import { setShowPopupChatsInfo } from '../../../reducers/chat';
import ChatInfo from '../../molecules/ChatInfo';
import useAuth from '../../../hooks/useAuth';

import {
  ModalButton,
  ModalContainer,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalObjectCreationContainer,
  ModalSubtitle,
  ModalTitle,
  ModalContent,
  ModalText,
  ModalSendRequestsContainer,
  ModalRequestCreationContainer,
  ModalAcceptResponseContainer,
  ModalAnswerRequestContainer,
} from './styles';

const Modal = ({ children, ...rest }) => {
  return <ModalContainer {...rest}>{children}</ModalContainer>;
};

const ModalObjectCreation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [allowNavigate, setAllowNavigate] = useState(true);

  const { objectCreationModalVisibility } = useSelector(({ application }) => application);

  const [addObject, { isSuccess: isSuccessObjectAdd, data: newObjectId }] = useAddObjectMutation();

  useEffect(() => {
    if (newObjectId && allowNavigate) {
      navigate(`${ROUTES.REQUESTS}/${newObjectId}`);
      setAllowNavigate(false);
    }
  }, [allowNavigate, navigate, newObjectId]);

  useEffect(() => {
    if (isSuccessObjectAdd) {
      message.success({
        content: 'Объект успешно добавлен',
        key: 'isSuccessObjectAdd-message-1',
      });
    }
  }, [isSuccessObjectAdd]);

  const handleClose = () => {
    dispatch(setObjectCreationModalVisibility(false));
  };

  const handleSubmit = (values) => {
    addObject(transformToFormData(values));
    dispatch(setObjectCreationModalVisibility(false));
  };

  return (
    <ModalObjectCreationContainer
      destroyOnClose
      visible={objectCreationModalVisibility}
      onCancel={handleClose}
      footer={false}
      closeIcon={<Icon name="close" className="modal-close" />}
    >
      <ModalBody>
        <ModalHeader $margin="0.5">
          <ModalTitle>Новый объект</ModalTitle>
          <ModalSubtitle>Для удобства объект можете назвать в свободной форме.</ModalSubtitle>
        </ModalHeader>
        <Form onFinish={handleSubmit}>
          <ModalContent>
            {objectCreationForm.map((item) => (
              <Form.Item key={item.props.name} {...item.props}>
                {item.element}
              </Form.Item>
            ))}
          </ModalContent>
          <ModalFooter>
            <Form.Item>
              <ModalButton type="primary" htmlType="submit">
                Создать объект
              </ModalButton>
            </Form.Item>
            <ModalButton secondary="true" onClick={handleClose}>
              Отменить
            </ModalButton>
          </ModalFooter>
        </Form>
      </ModalBody>
    </ModalObjectCreationContainer>
  );
};

const ModalSendRequests = () => {
  const dispatch = useDispatch();
  const { sendRequestsModalVisibility } = useSelector(({ application }) => application);
  const { currentClickedRequestID } = useSelector(({ requests }) => requests);

  const [sendRequestTrigger, { isSuccess: isSuccessSend }] = useSendRequestMutation();

  useEffect(() => {
    if (isSuccessSend) {
      message.success({
        content: 'Запрос отправлен',
        key: 'isSuccessSend-message-2',
      });
    }
  }, [isSuccessSend]);

  const handleClose = () => {
    dispatch(setSendRequestsModalVisibility(false));
  };

  const handleSubmit = () => {
    sendRequestTrigger(transformToFormData({ ID_Request: currentClickedRequestID }));
    dispatch(setSendRequestsModalVisibility(false));
  };

  return (
    <ModalSendRequestsContainer
      destroyOnClose
      visible={sendRequestsModalVisibility}
      onCancel={handleClose}
      footer={false}
      closeIcon={<Icon name="close" className="modal-close" />}
    >
      <ModalBody>
        <ModalHeader $margin="0.5">
          <ModalTitle>Отправить запрос</ModalTitle>
        </ModalHeader>
        <ModalText>После отправки запроса все выбранные поставщики будут оповещены о Вашей заявке.</ModalText>
      </ModalBody>
      <ModalFooter>
        <ModalButton onClick={handleSubmit}>Отправить</ModalButton>
        <ModalButton secondary="true" onClick={handleClose}>
          Отменить
        </ModalButton>
      </ModalFooter>
    </ModalSendRequestsContainer>
  );
};

const ModalRequestCreation = ({ children, ...rest }) => {
  return <ModalRequestCreationContainer {...rest}>{children}</ModalRequestCreationContainer>;
};

const ModalAcceptResponse = () => {
  const dispatch = useDispatch();

  const { acceptResponseModalVisibility } = useSelector(({ application }) => application);
  const { currentClickedAnswerID } = useSelector(({ requests }) => requests);

  const [chooseAnswer] = useChooseAnswerMutation();

  const handleClose = () => {
    dispatch(setCurrentClickedAnswerID(null));
    dispatch(setAcceptResponseModalVisibility(false));
  };

  const handleSubmit = () => {
    chooseAnswer(transformToFormData({ ID_Requests_Answer: currentClickedAnswerID }));
    dispatch(setAcceptResponseModalVisibility(false));
  };
  return (
    <ModalAcceptResponseContainer
      destroyOnClose
      visible={acceptResponseModalVisibility}
      onCancel={handleClose}
      footer={false}
      closeIcon={<Icon name="close" className="modal-close" />}
    >
      <ModalBody>
        <ModalHeader $margin="0.5">
          <ModalTitle>Выбор поставщика</ModalTitle>
        </ModalHeader>
        <ModalContent>
          <ModalText>
            После выбора поставщика прием заявок будет прекращен. Поставщик получит уведомление на почту. Подтвердите
            выбор поставщика.
          </ModalText>
        </ModalContent>
      </ModalBody>
      <ModalFooter>
        <ModalButton pale="true" onClick={handleClose}>
          Закрыть
        </ModalButton>
        <ModalButton secondary="true" onClick={handleSubmit}>
          Подтвердить выбор
        </ModalButton>
      </ModalFooter>
    </ModalAcceptResponseContainer>
  );
};

const ModalCancelResponse = () => {
  const dispatch = useDispatch();

  const { cancelResponseModalVisibility } = useSelector(({ application }) => application);
  const { currentClickedAnswerID } = useSelector(({ requests }) => requests);

  const [cancelAnswer] = useCancelAnswerMutation();

  const handleClose = () => {
    dispatch(setCurrentClickedAnswerID(null));
    dispatch(setCancelResponseModalVisibility(false));
  };

  const handleSubmit = (values) => {
    cancelAnswer(transformToFormData({ ID_Requests_Answer: currentClickedAnswerID, ...values }));
    dispatch(setCancelResponseModalVisibility(false));
  };
  return (
    <ModalAcceptResponseContainer
      $secondary
      destroyOnClose
      visible={cancelResponseModalVisibility}
      onCancel={handleClose}
      footer={false}
      closeIcon={<Icon name="close" className="modal-close" />}
    >
      <ModalBody>
        <ModalHeader $margin="0.5">
          <ModalTitle>Отлонение предложения</ModalTitle>
        </ModalHeader>
        <Form onFinish={handleSubmit}>
          <ModalContent>
            <Form.Title $large className={cn('form-title')}>
              Вы можете добавить комментарий для поставщика:
            </Form.Title>
            <Form.Item name="Comment">
              <Input.TextArea />
            </Form.Item>
          </ModalContent>
          <ModalFooter>
            <ModalButton pale="true" onClick={handleClose}>
              Отменить
            </ModalButton>
            <Form.Item>
              <ModalButton secondary="true" type="primary" htmlType="submit">
                Отклонить предложение
              </ModalButton>
            </Form.Item>
          </ModalFooter>
        </Form>
      </ModalBody>
      {/* <ModalFooter>
        <ModalButton pale="true" onClick={handleClose}>
          Закрыть
        </ModalButton>
        <ModalButton secondary="true" onClick={handleSubmit}>
          Отклонить предложение
        </ModalButton>
      </ModalFooter> */}
    </ModalAcceptResponseContainer>
  );
};

const ModalChatsNotification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { showPopupChatsInfo, chats } = useSelector(({ chat }) => chat);
  const { hasClientRole, hasAdminRole } = useAuth();

  const handleClose = () => {
    dispatch(setShowPopupChatsInfo(false));
  };

  return (
    <ModalContainer
      destroyOnClose
      visible={showPopupChatsInfo}
      onCancel={handleClose}
      footer={false}
      closeIcon={<Icon name="close" className="modal-close" />}
    >
      <ModalBody>
        <ModalHeader $margin="0.5">
          <ModalTitle>Список сообщений</ModalTitle>
        </ModalHeader>
        <ModalContent style={{ width: '100%', padding: '16px' }}>
          {Object.keys(chats)
            .reduce((a, v) => {
              const chat = chats[v];
              const groupName = `${chat?.iD_Type_Chat}-${chat?.iD_Request}`;
              a[groupName] = a[groupName] || { items: [] };
              a[groupName].name =
                (chat?.name_Object && `${chat?.name_Object} - ${chat?.name_Equipment}`) ||
                chat?.iD_Requests_Answer ||
                chat?.name ||
                chat?.iD_Request ||
                (chat?.iD_Type_Chat === 1 ? 'Административный чат' : `${chat?.iD_Type_Chat} Неопознанный чат`);
              a[groupName].items.push(v);
              a[groupName].id = groupName;
              a.groups = Object.keys(a)
                .filter((b) => b !== 'groups')
                .map((g) => a[g]);
              return a;
            }, {})
            .groups?.map((group) => {
              return (
                <Card key={group.id} title={group.name}>
                  {group.items.map((c) => {
                    const chat = chats[c];
                    return (
                      <ChatInfo
                        chat={chat}
                        chatId={chat.id}
                        key={chat.id}
                        onClick={() => {
                          if (chat.iD_Type_Chat === 1) {
                            if (hasAdminRole) navigate(`${ROUTES.CHAT_WITH_USER}/${chat.id}`);
                            else navigate(`${ROUTES.CHAT_WITH_ADMIN}`);
                          } else if (hasClientRole)
                            navigate(
                              `${ROUTES.REQUESTS}/${chat.iD_Object}/${chat.iD_Request}/${chat.id}/${chat.iD_Requests_Answer}/1#response`,
                            );
                          else if (chat.iD_Request) navigate(`${ROUTES.REQUESTS}/all/${chat.iD_Request}#chat`);
                          handleClose();
                        }}
                      />
                    );
                  })}
                </Card>
              );
            })}
        </ModalContent>
      </ModalBody>
      <ModalFooter>
        <ModalButton pale="true" onClick={handleClose}>
          Закрыть
        </ModalButton>
      </ModalFooter>
    </ModalContainer>
  );
};

const ModalSetInn = () => {
  const dispatch = useDispatch();

  const [userCurrentInn, setUserCurrentInn] = useState(null);
  const [innError, setInnError] = useState(false);

  const { innModalVisibility } = useSelector(({ application }) => application);

  const { isError: isInnCheckError, isFetching: isFetchingInnCheckError } = useCheckInnQuery(
    { Inn: userCurrentInn },
    { skip: !userCurrentInn },
  );

  useEffect(() => {
    if (!isFetchingInnCheckError && userCurrentInn) {
      if (!isInnCheckError) {
        localStorage.setItem('user-inn', userCurrentInn);
        dispatch(setUserInn(userCurrentInn));
        dispatch(setInnModalVisibility(false));
      } else {
        setInnError(true);
      }
    }
  }, [dispatch, innModalVisibility, isFetchingInnCheckError, isInnCheckError, userCurrentInn]);

  const handleClose = () => {
    dispatch(setInnModalVisibility(false));
  };

  const handleSubmit = (values) => {
    setUserCurrentInn(values.inn);
  };

  const handleInputChange = () => {
    setInnError(false);
  };

  return (
    <ModalAcceptResponseContainer
      $secondary
      destroyOnClose
      visible={innModalVisibility}
      onCancel={handleClose}
      footer={false}
      closeIcon={<Icon name="close" className="modal-close" />}
      className={cn('full-width-form')}
    >
      <ModalBody>
        <ModalHeader $margin="0.5">
          <ModalTitle>Укажите ИНН организации</ModalTitle>
        </ModalHeader>
        <Form onFinish={handleSubmit}>
          <ModalContent>
            <Form.Item
              name="inn"
              onChange={handleInputChange}
              rules={[
                {
                  type: 'string',
                },
                {
                  len: 10,
                  message: 'ИНН должен состоять из 10 цифр',
                },
                {
                  required: true,
                  message: 'Введите ИНН организации',
                },
              ]}
            >
              <Input.Common type="number" placeholder="ИНН" />
            </Form.Item>
            {innError && (
              <ModalText $error $mt="1">
                Организации с таким ИНН не существует
              </ModalText>
            )}
          </ModalContent>
          <ModalFooter>
            <ModalButton pale="true" onClick={handleClose}>
              Закрыть
            </ModalButton>
            <Form.Item>
              <ModalButton secondary="true" type="primary" htmlType="submit">
                Сохранить
              </ModalButton>
            </Form.Item>
          </ModalFooter>
        </Form>
      </ModalBody>
    </ModalAcceptResponseContainer>
  );
};

const ModalAnswerRequest = () => {
  const dispatch = useDispatch();

  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [errorText, setErrorText] = useState();
  const [currentFileURL, setCurrentFileURL] = useState(null);

  const { answerRequestModalVisibility } = useSelector(({ application }) => application);
  const { currentRequestInfo } = useSelector(({ requests }) => requests);

  const { data: requestAnswer, isFetching: isFetchingRequestAnswer } = useGetAnswerQuery(
    {
      ID_Request: currentRequestInfo?.iD_Request,
    },
    { skip: !currentRequestInfo?.iD_Request },
  );
  const { data: currencyList } = useGetCurrencyQuery();
  const [sendAnswer] = useSendAnswerMutation();
  const [sendFiles] = useSendAnswerFileMutation();

  const currencyOptions = currencyList?.map((item) => ({
    key: item.code,
    label: item.code,
    content: <Fragment>{item.code}</Fragment>,
  }));

  const hasUploads = fileList.length > 0;

  const [form] = useForm();

  const renderUploadItem = useCallback(
    (_, file, list, actions) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);
      fileReader.addEventListener('load', () => {
        setCurrentFileURL(fileReader.result);
      });
      return (
        <File
          secondary="true"
          className={cn('upload')}
          link={currentFileURL}
          title={file.name}
          size={file.size}
          remove={actions.remove}
          download
        />
      );
    },
    [currentFileURL],
  );

  const uploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },

    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },

    itemRender: renderUploadItem,

    fileList,
  };

  const handleClose = () => {
    setFileList([]);
    form.resetFields();
    dispatch(setAnswerRequestModalVisibility(false));
  };

  const onFinish = (values) => {
    sendAnswer(transformToFormData({ ...values, ID: requestAnswer?.id }));
    form.resetFields();
  };

  const handleSubmit = () => {
    const formData = new FormData();

    fileList.forEach((fileItem) => {
      formData.append('Files', fileItem);
    });
    formData.append('ID_Requests_Answer', requestAnswer?.id);

    sendFiles(formData)
      .then(({ error }) => {
        if (error && error.data) {
          // eslint-disable-next-line no-alert
          alert(
            'Извините, не удалось загрузить файл. Попробуйте еще раз. В случае неудачной попытки обратитесь за помощью к администратору.',
          );
          return;
        }
        form.submit();
        setFileList([]);
        dispatch(setAnswerRequestModalVisibility(false));
      })
      .catch((ex) => {
        // eslint-disable-next-line no-alert
        alert(
          'Извините, не удалось загрузить файл. Попробуйте еще раз. В случае неудачной попытки обратитесь за помощью к администратору.',
        );
      });
  };

  return (
    <ModalAnswerRequestContainer
      destroyOnClose
      visible={answerRequestModalVisibility}
      onCancel={handleClose}
      footer={false}
      closeIcon={<Icon name="close" className="modal-close" />}
    >
      <ModalBody>
        <ModalHeader $margin="0.5">
          <ModalTitle>Ответ на запрос</ModalTitle>
        </ModalHeader>
        <ModalContent>
          <Form form={form} initialValues={{ Code_Currency: 'USD' }} onFinish={onFinish}>
            <Form.Row className={cn('form-row')}>
              <Form.Item
                name="Sum"
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста, укажите стоимость',
                  },
                ]}
              >
                <Input.Common type="number" placeholder="Стоимость" />
              </Form.Item>
              <Form.Item name="Code_Currency" $width="10">
                <Select
                  $common
                  $noLabel
                  suffixIcon={<Icon name="arrow-2" className="select-arrow" />}
                  className={cn('currency-select')}
                  dropdownClassName={cn('select-dropdown', 'object-status-select-dropdown')}
                  items={currencyOptions}
                  optionLabelProp="label"
                  placeholder="Выбрать"
                />
              </Form.Item>
            </Form.Row>
            <Form.Row className={cn('form-row')}>
              <Form.Item
                name="Delivery_Week"
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста, укажите срок поставки',
                  },
                ]}
              >
                <Input.Common type="number" placeholder="Срок поставки (недель)" />
              </Form.Item>
            </Form.Row>
            <Form.Element.Block>
              <Form.Title $margin="1.2" $large>
                Технические характеристики
              </Form.Title>
              <Form.Text className={cn('form-text')}>
                Для добавления файлов к ответу нажмите «Выбрать файлы». Максимальный размер одного файла 3мб
              </Form.Text>
              <Upload
                $relative
                $reverse
                className={cn(hasUploads && 'has-uploads')}
                accept="image/*,.pdf"
                {...uploadProps}
              >
                <Button className={cn('upload-button')}>
                  <Icon $transition name="file" className={cn('upload-icon')} />
                  Выберите файл
                </Button>
                <Modal.Text className={cn('error', errorText && 'visible')}>{errorText}</Modal.Text>
              </Upload>
            </Form.Element.Block>
          </Form>
        </ModalContent>
      </ModalBody>
      {hasUploads && (
        <ModalFooter>
          <ModalButton pale="true" onClick={handleClose}>
            Отменить
          </ModalButton>
          <ModalButton loading={uploading} onClick={handleSubmit}>
            {uploading ? 'Отправляем' : 'Отправить'}
          </ModalButton>
        </ModalFooter>
      )}
    </ModalAnswerRequestContainer>
  );
};

const ModalAddCategory = ({ categoryId, resetCurrent }) => {
  const dispatch = useDispatch();
  const { addCategoryModalVisibility } = useSelector(({ application }) => application);

  // get data
  const { data: categoryData, isFetching: isFetchingCategoryData } = useGetEquipmentCategoryQuery(categoryId, {
    skip: !categoryId,
  });
  const { data: categoriesList, isFetching: isFetchingCategoriesList } = useGetEquipmentCategoryListQuery();

  // set data
  const [addCategory, { data: addCategoryData, isError: addCategoryError, isSuccess: addCategorySuccess }] =
    useAddEquipmentCategoryMutation();
  const [editCategory, { data: editCategoryData, isError: editCategoryError, isSuccess: editCategorySuccess }] =
    useEditEquipmentCategoryMutation();

  const isFetching = categoryId && isFetchingCategoryData;

  useEffect(() => {
    if (addCategorySuccess) {
      message.success({
        content: 'Категория успешно создана',
        key: 'addCategorySuccess-message-1',
      });
    }
    if (editCategorySuccess) {
      message.success({
        content: 'Категория отредактирована',
        key: 'editCategorySuccess-message-1',
      });
    }
  }, [addCategorySuccess, editCategorySuccess]);

  const handleClose = () => {
    resetCurrent(null);
    dispatch(setAddCategoryModalVisibility(false));
  };

  const handleSubmit = (values) => {
    if (categoryId) {
      editCategory(transformToFormData({ ...values, ID: categoryId }));
    } else {
      addCategory(transformToFormData(values));
    }
    resetCurrent(null);
    dispatch(setAddCategoryModalVisibility(false));
  };

  const categoriesOptions = categoriesList?.map((category) => ({
    key: category.id,
    label: category.name,
  }));

  return (
    <ModalObjectCreationContainer
      destroyOnClose
      visible={addCategoryModalVisibility}
      onCancel={handleClose}
      footer={false}
      closeIcon={<Icon name="close" className="modal-close" />}
      className={cn('add-category')}
    >
      <ModalBody>
        <ModalHeader $margin="0.5">
          <ModalTitle>{categoryId ? 'Редактировать категорию' : 'Добавить категорию'}</ModalTitle>
        </ModalHeader>
        {loading(
          isFetching,
          <Form
            initialValues={
              categoryId
                ? {
                    Name: categoryData?.name,
                    ID_Parent: categoryData?.iD_Parent === null ? 0 : categoryData?.iD_Parent,
                  }
                : {}
            }
            onFinish={handleSubmit}
          >
            <ModalContent>
              <Form.Item name="ID_Parent">
                {!isFetchingCategoriesList && (
                  <Select
                    $common
                    suffixIcon={<Icon name="arrow-2" className="select-arrow" />}
                    dropdownClassName={cn('select-dropdown', 'object-status-select-dropdown')}
                    items={[...categoriesOptions, { key: 0, label: 'Корневая' }]}
                    loading={isFetchingCategoriesList}
                    placeholder="Родительская категория"
                  />
                )}
              </Form.Item>
              <Form.Item
                name="Name"
                rules={[
                  {
                    required: true,
                    message: 'Введите название категории',
                  },
                ]}
              >
                <Input.Common placeholder="Название категории" />
              </Form.Item>
            </ModalContent>
            <ModalFooter>
              <Form.Item>
                <ModalButton type="primary" htmlType="submit">
                  Добавить
                </ModalButton>
              </Form.Item>
              <ModalButton secondary="true" onClick={handleClose}>
                Отменить
              </ModalButton>
            </ModalFooter>
          </Form>,
        )}
      </ModalBody>
    </ModalObjectCreationContainer>
  );
};

const ModalAddEquipment = ({ equipID, categoryId, resetCurrent }) => {
  const dispatch = useDispatch();
  const { addEquipmentModalVisibility } = useSelector(({ application }) => application);

  const { data: categoriesList, isFetching: isFetchingCategoriesList } = useGetEquipmentCategoryListQuery();
  const { data: equipmentData, isFetching: isFetchingEquipmentData } = useGetEquipmentByIdQuery(equipID, {
    skip: !equipID,
  });

  const isFetching = equipID && isFetchingEquipmentData;

  const [addEquipment, { isError: addEquipmentError, isSuccess: addEquipmentSuccess }] = useAddEquipmentMutation();
  const [editEquipment, { isError: editEquipmentError, isSuccess: editEquipmentSuccess }] = useEditEquipmentMutation();

  useEffect(() => {
    if (addEquipmentSuccess) {
      message.success({
        content: 'Оборудование успешно создано',
        key: 'addEquipmentSuccess-message-2',
      });
    }
    if (editEquipmentSuccess) {
      message.success({
        content: 'Оборудование успешно отредактировано',
        key: 'editEquipmentSuccess-message-2',
      });
    }
  }, [addEquipmentSuccess, editEquipmentSuccess]);

  const handleClose = () => {
    resetCurrent(null);
    dispatch(setAddEquipmentModalVisibility(false));
  };

  const handleSubmit = (values) => {
    if (equipID) {
      editEquipment(transformToFormData({ ...values, ID: equipID }));
    } else {
      addEquipment(transformToFormData(values));
    }
    resetCurrent(null);
    dispatch(setAddEquipmentModalVisibility(false));
  };

  const categoriesOptions = categoriesList?.map((category) => ({
    key: category.id,
    label: category.name,
  }));

  return (
    <ModalObjectCreationContainer
      destroyOnClose
      visible={addEquipmentModalVisibility}
      onCancel={handleClose}
      footer={false}
      closeIcon={<Icon name="close" className="modal-close" />}
      className={cn('add-equip')}
    >
      <ModalBody>
        <ModalHeader $margin="0.5">
          <ModalTitle>{equipID ? 'Изменить оборудование' : 'Добавить оборудование'}</ModalTitle>
        </ModalHeader>
        {loading(
          isFetching,
          <Form
            initialValues={
              equipID
                ? { Name: equipmentData?.name, ID_Category: equipmentData?.iD_Category, Draft: equipmentData?.draft }
                : { ID_Category: +categoryId, Draft: false }
            }
            onFinish={handleSubmit}
          >
            <ModalContent>
              <Form.Item
                name="ID_Category"
                rules={[
                  {
                    required: true,
                    message: 'Укажите категорию',
                  },
                ]}
              >
                <Select
                  $common
                  loading={isFetchingCategoriesList}
                  suffixIcon={<Icon name="arrow-2" className="select-arrow" />}
                  dropdownClassName={cn('select-dropdown', 'object-status-select-dropdown')}
                  items={categoriesOptions}
                  placeholder="Категория"
                />
              </Form.Item>
              <Form.Item
                name="Name"
                className={cn('margin')}
                rules={[
                  {
                    required: true,
                    message: 'Введите название оборудования',
                  },
                ]}
              >
                <Input.Common placeholder="Название оборудования" />
              </Form.Item>
              <Form.Item name="Draft" valuePropName="checked">
                <Checkbox>Черновик?</Checkbox>
              </Form.Item>
            </ModalContent>
            <ModalFooter>
              <Form.Item>
                <ModalButton type="primary" htmlType="submit">
                  Добавить
                </ModalButton>
              </Form.Item>
              <ModalButton secondary="true" onClick={handleClose}>
                Отменить
              </ModalButton>
            </ModalFooter>
          </Form>,
        )}
      </ModalBody>
    </ModalObjectCreationContainer>
  );
};

const ModalAddField = ({ equipId, fieldData, resetCurrent }) => {
  const dispatch = useDispatch();
  const { addFieldModalVisibility } = useSelector(({ application }) => application);

  const { data: typeList, isLoading } = useGetTypeListQuery();

  const [addField, { isError: addFieldError, isSuccess: addFieldSuccess }] =
    useAddEquipmentQuestionnaireFieldMutation();
  const [editField, { isError: editFieldError, isSuccess: editFieldSuccess }] =
    useEditEquipmentQuestionnaireFieldMutation();

  useEffect(() => {
    if (addFieldSuccess) {
      message.success({
        content: 'Поле успешно создано',
        key: 'addFieldSuccess-message-3',
      });
    }
    if (editFieldSuccess) {
      message.success({
        content: 'Поле отредактировано',
        key: 'editFieldSuccess-message-3',
      });
    }
  }, [addFieldSuccess, editFieldSuccess]);

  const handleClose = () => {
    resetCurrent(null);
    dispatch(setAddFieldModalVisibility(false));
  };

  const handleSubmit = (values) => {
    if (fieldData) {
      editField(transformToFormData({ ...values, ID: fieldData.id }));
    } else {
      addField(transformToFormData({ ...values, ID_Equipment: equipId }));
    }
    resetCurrent(null);
    dispatch(setAddFieldModalVisibility(false));
  };

  return (
    <ModalObjectCreationContainer
      destroyOnClose
      visible={addFieldModalVisibility}
      onCancel={handleClose}
      footer={false}
      closeIcon={<Icon name="close" className="modal-close" />}
      className={cn('add-equip')}
    >
      <ModalBody>
        <ModalHeader $margin="0.5">
          <ModalTitle>{equipId ? 'Изменить поле' : 'Добавить поле'}</ModalTitle>
        </ModalHeader>
        <Form
          initialValues={
            fieldData
              ? { Name: fieldData.name, ID_Type: fieldData.iD_Type, Required: fieldData.required }
              : { Required: false }
          }
          onFinish={handleSubmit}
        >
          <ModalContent>
            <Form.Item
              name="Name"
              className={cn('margin')}
              rules={[
                {
                  required: true,
                  message: 'Введите название поля',
                },
              ]}
            >
              <Input.Common placeholder="Название поля" />
            </Form.Item>
            <Form.Item
              name="ID_Type"
              rules={[
                {
                  required: true,
                  message: 'Укажите тип данных',
                },
              ]}
            >
              <Select
                $common
                loading={isLoading}
                suffixIcon={<Icon name="arrow-2" className="select-arrow" />}
                dropdownClassName={cn('select-dropdown', 'object-status-select-dropdown')}
                items={typeList}
                placeholder="Тип данных"
              />
            </Form.Item>
            <Form.Item name="Required" valuePropName="checked">
              <Checkbox>Обязательный к заполнению</Checkbox>
            </Form.Item>
          </ModalContent>
          <ModalFooter>
            <Form.Item>
              <ModalButton type="primary" htmlType="submit">
                Добавить
              </ModalButton>
            </Form.Item>
            <ModalButton secondary="true" onClick={handleClose}>
              Отменить
            </ModalButton>
          </ModalFooter>
        </Form>
      </ModalBody>
    </ModalObjectCreationContainer>
  );
};

const ModalSendMessage = ({ userId, resetCurrent }) => {
  const dispatch = useDispatch();

  const { sendMessageModalVisibility } = useSelector(({ application }) => application);

  const [sendMessage, { isSuccess: isSuccessSendMessage, isError: isErrorSendMessage }] = useSendMessageMutation();

  useEffect(() => {
    if (isSuccessSendMessage) {
      message.success({
        content: 'Сообщение отправлено',
        key: 'isSuccessSendMessage-message-4',
      });
    }
    if (isErrorSendMessage) {
      message.error({
        content: 'При отправке сообщения произошла ошибка',
        key: 'isErrorSendMessage-message-4',
      });
    }
  }, [isErrorSendMessage, isSuccessSendMessage]);

  const handleClose = () => {
    resetCurrent(null);
    dispatch(setSendMessageModalVisibility(false));
  };

  const handleSubmit = (values) => {
    sendMessage(transformToFormData({ ...values, ID_User: userId }));
    resetCurrent(null);
    dispatch(setSendMessageModalVisibility(false));
  };

  return (
    <ModalAnswerRequestContainer
      $secondary
      destroyOnClose
      visible={sendMessageModalVisibility}
      onCancel={handleClose}
      footer={false}
      closeIcon={<Icon name="close" className="modal-close" />}
    >
      <ModalBody>
        <ModalHeader $margin="0.5">
          <ModalTitle>Сообщение</ModalTitle>
        </ModalHeader>
        <Form onFinish={handleSubmit}>
          <ModalContent>
            <Form.Title $large className={cn('form-title')}>
              Впишите текст сообщения
            </Form.Title>
            <Form.Item
              name="Comment"
              rules={[
                {
                  required: true,
                  message: 'Пожалуйста, впишите текст сообщения',
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </ModalContent>
          <ModalFooter>
            <ModalButton pale="true" onClick={handleClose}>
              Отменить
            </ModalButton>
            <Form.Item>
              <ModalButton type="primary" htmlType="submit">
                Отправить
              </ModalButton>
            </Form.Item>
          </ModalFooter>
        </Form>
      </ModalBody>
    </ModalAnswerRequestContainer>
  );
};

Modal.ObjectCreation = ModalObjectCreation;
Modal.SendRequests = ModalSendRequests;
Modal.RequestCreation = ModalRequestCreation;
Modal.AcceptResponse = ModalAcceptResponse;
Modal.CancelResponse = ModalCancelResponse;
Modal.AnswerRequest = ModalAnswerRequest;
Modal.AddCategory = ModalAddCategory;
Modal.AddEquipment = ModalAddEquipment;
Modal.AddField = ModalAddField;
Modal.SendMessage = ModalSendMessage;
Modal.SetInn = ModalSetInn;
Modal.ChatsNotification = ModalChatsNotification;

Modal.Header = ModalHeader;
Modal.Footer = ModalFooter;
Modal.Title = ModalTitle;
Modal.Text = ModalText;
Modal.Content = ModalContent;
Modal.Body = ModalBody;
Modal.Button = ModalButton;

export default Modal;
