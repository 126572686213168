import React from 'react';

import { SkeletonButton, SkeletonContainer, SkeletonInput } from './styles';

const Skeleton = ({ children, ...rest }) => {
  return <SkeletonContainer {...rest}>{children}</SkeletonContainer>;
};

Skeleton.Input = SkeletonInput;
Skeleton.Button = SkeletonButton;

export default Skeleton;
