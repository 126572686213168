import URI from 'urijs';

import { clientAPI } from '@api';

const _clientAPI = clientAPI.enhanceEndpoints({
  addTagTypes: ['ProviderRequest', 'Answer'],
});

const providerRequestsAPI = _clientAPI.injectEndpoints({
  endpoints: (build) => ({
    // количество новых запросов
    getNewRequestCount: build.query({
      query: () => {
        const URL = new URI('/Requests/providerRequestsNew');

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['ProviderRequest', 'Authenticate'],
    }),
    // новые запросы
    getNewRequestsList: build.query({
      query: () => {
        const URL = new URI('/Requests/providerRequestsList');

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['ProviderRequest', 'Authenticate'],
    }),
    // отклоненные запросы
    getCanceledRequestsList: build.query({
      query: () => {
        const URL = new URI('/Requests/providerRequestsRejectedList');

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['ProviderRequest', 'Authenticate'],
    }),
    // запросы из архива
    getArchiveRequestsList: build.query({
      query: () => {
        const URL = new URI('/Requests/providerRequestsArchiveList');

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['ProviderRequest', 'Authenticate'],
    }),
    // отмененные клиентом запросы
    getRejectedRequestsList: build.query({
      query: () => {
        const URL = new URI('/Requests/providerRequestsCancelList');

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['ProviderRequest', 'Authenticate'],
    }),
    // ===========================================
    // вернет ответ на заявку
    getAnswer: build.query({
      query: (params) => {
        const URL = new URI('/Requests/providerRequestsAnswerByIdRequest');

        URL.addQuery({ ...params });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['Answer', 'Authenticate'],
    }),
    // отправить ответ на заявку
    sendAnswer: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Requests/providerRequestsAnswerSend');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['ProviderRequest', 'Answer'],
    }),
    // отозвать ответ на заявку
    cancelProviderAnswer: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Requests/providerRequestsAnswerCancel');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['ProviderRequest', 'Answer'],
    }),
    // отправить файл
    sendAnswerFile: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Requests/providerRequestsAnswerFileAdd');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['Answer'],
    }),
    // вернет список файлов
    getAnswerFile: build.query({
      query: (params) => {
        const URL = new URI('/Requests/providerRequestsAnswerFilesList');

        URL.addQuery({ ...params });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['Answer'],
    }),
    // ============================================
    // отклонить заявку
    cancelProviderRequest: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Requests/providerRequestsCancel');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['ProviderRequest'],
    }),
    // отправить заявку в архив
    archiveProviderRequest: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Requests/providerRequestsArchive');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['ProviderRequest'],
    }),
  }),
});

export const {
  useGetNewRequestCountQuery,
  useGetArchiveRequestsListQuery,
  useGetCanceledRequestsListQuery,
  useGetNewRequestsListQuery,
  useGetRejectedRequestsListQuery,

  useGetAnswerQuery,
  useSendAnswerMutation,
  useSendAnswerFileMutation,
  useGetAnswerFileQuery,
  useCancelProviderAnswerMutation,

  useCancelProviderRequestMutation,
  useArchiveProviderRequestMutation,
} = providerRequestsAPI;
