import React, { memo } from 'react';

import { CardBlock, CardContainer, CardContent, CardIcon, CardTitle } from './styles';

const Card = memo(({ children }) => {
  return <CardContainer>{children}</CardContainer>;
});

Card.Block = CardBlock;
Card.Title = CardTitle;
Card.Content = CardContent;
Card.Icon = CardIcon;

export default Card;
