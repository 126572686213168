import React, { memo } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import Icon from '../Icon';

import { ButtonBackContainer, ButtonBackLabel, ButtonContainer, ButtonLabel, ButtonWrapper } from './styles';

const Button = memo(({ children, ...rest }) => {
  return (
    <ButtonContainer className={cn('button')} {...rest}>
      {children}
    </ButtonContainer>
  );
});

const ButtonAdd = memo(({ children, ...rest }) => {
  return (
    <ButtonWrapper {...rest}>
      <Button secondary="true">
        <Icon $transition name="plus" className={cn('button-plus')} />
      </Button>
      <ButtonLabel>{children}</ButtonLabel>
    </ButtonWrapper>
  );
});

const ButtonBack = memo(({ children, ...rest }) => {
  const navigate = useNavigate();

  const back = () => {
    navigate(-1);
  };

  return (
    <ButtonBackContainer onClick={back} {...rest}>
      <Icon name="back-arrow" className={cn('back')} />
      <ButtonBackLabel>{children}</ButtonBackLabel>
    </ButtonBackContainer>
  );
});

Button.Add = ButtonAdd;
Button.Back = ButtonBack;

export default Button;
