import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentUser: null,
  currentUserId: null,
  isAuthenticated: false,
  moderationChecked: false,
  isEmailConfirmed: null,
  isLoading: false,
  hasNotification: false,
  notificationCount: null,
  moderatedStatus: null,
  userInfoStatus: null,
  organizationInfoStatus: null,
  userEquipmentStatus: null,
  userInn: null,
  token: null,
};

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    setCredentials: (state, { payload }) => {
      state.currentUser = payload;
      state.isAuthenticated = payload !== null;
    },
    setCurrentUserId: (state, { payload }) => {
      state.currentUserId = payload;
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setModerationChecked: (state, { payload }) => {
      state.moderationChecked = payload;
    },
    setIsEmailConfirmed: (state, { payload }) => {
      state.isEmailConfirmed = payload;
    },
    setModeratedStatus: (state, { payload }) => {
      state.moderatedStatus = payload;
    },
    setUserInfoStatus: (state, { payload }) => {
      state.userInfoStatus = payload;
    },
    setOrganizationInfoStatus: (state, { payload }) => {
      state.organizationInfoStatus = payload;
    },
    setUserEquipmentStatus: (state, { payload }) => {
      state.userEquipmentStatus = payload;
    },
    setUserInn: (state, { payload }) => {
      state.userInn = payload;
    },
  },
});

export const {
  setCredentials,
  setIsLoading,
  setModerationChecked,
  setIsEmailConfirmed,
  setModeratedStatus,
  setUserInfoStatus,
  setOrganizationInfoStatus,
  setUserEquipmentStatus,
  setCurrentUserId,
  setUserInn,
  setToken,
} = user.actions;

export default user.reducer;
