import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Link = styled(NavLink)`
  display: block;
  padding: 3px;
`;

export const ImageContainer = styled.img`
  width: 103px;
  height: 27px;
  object-fit: contain;
`;
