import React, { Fragment, useEffect, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useForm } from 'antd/lib/form/Form';
import axios from 'axios';

import { Button, Checkbox, Form, Icon, Input, Modal, Radio, Status, Tabs, Tooltip } from '@atoms';
import {
  ACTION_TYPES,
  ADMIN_TABS,
  ADMIN_REQUEST_ACTIONS,
  ADMIN_REQUEST_STATUSES_PATH,
  USER_ROLES,
  ADMIN_REQUEST_STATUSES,
} from '@constants/common';
import { EquipmentTable, Page } from '@organisms';
import { setSendMessageModalVisibility } from '@reducers/application';
import {
  useGetUserRequestQuery,
  useGetEquipmentRequestQuery,
  useAcceptRegistrationRequestMutation,
  useDenyRegistrationRequestMutation,
  useAcceptEquipmentRequestMutation,
  useDenyEquipmentRequestMutation,
} from '@api/user';
import loading from '@helpers/loading';
import transformToFormData from '@helpers/transformToFormData';
import { useGetUserChangesEquipmentListQuery } from '@api/equipment';

import { useGetListQuery } from '../../../api/chat';
import { ROUTES } from '../../../constants/routes';

import {
  RequestInfoActions,
  RequestInfoBlock,
  RequestInfoContainer,
  RequestInfoContent,
  RequestInfoDate,
  RequestInfoHead,
  RequestInfoTitle,
} from './styles';

const RequestInfo = ({ status, setCurrentRequest }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // forms
  const [userInfoFormInstance] = useForm();
  const [companyInfoFormInstance] = useForm();

  const { requestId } = useParams();

  const isRegisterRequest = location.pathname.includes('new') || location.pathname.includes('all');
  const isEquipmentRequest = location.pathname.includes('changes');

  const { currentRequestInfo } = useSelector(({ requests }) => requests);

  const currentStatus = status || currentRequestInfo.status;

  const [acceptRegistrationRequest] = useAcceptRegistrationRequestMutation();
  const [denyRegistrationRequest] = useDenyRegistrationRequestMutation();
  const [acceptEquipmentRequest] = useAcceptEquipmentRequestMutation();
  const [denyEquipmentRequest] = useDenyEquipmentRequestMutation();

  const { data: registerRequestData, isFetching: isFetchingRegisterRequest } = useGetUserRequestQuery(
    {
      requestId,
      path: ADMIN_REQUEST_STATUSES_PATH[currentStatus] || ADMIN_REQUEST_STATUSES_PATH.active,
    },
    { skip: !isRegisterRequest },
  );
  const { data: equipmentRequestData, isFetching: isFetchingEquipmentRequest } = useGetEquipmentRequestQuery(
    requestId,
    { skip: !isEquipmentRequest },
  );

  const requestData = isRegisterRequest ? registerRequestData : equipmentRequestData;
  const isFetching = isRegisterRequest ? isFetchingRegisterRequest : isFetchingEquipmentRequest;

  const commentConfirmed = requestData?.comment_Status === 2;
  const commentStatusText = commentConfirmed ? 'Требование исправлено' : 'Требование не исправлено';

  const { data: userEquipmentList, isFetching: isFetchingUserEquipmentList } = useGetUserChangesEquipmentListQuery(
    requestData?.iD_User,
    { skip: requestData?.roleId !== USER_ROLES.PROVIDER && !requestData?.iD_User },
  );

  useEffect(() => {
    userInfoFormInstance.setFieldsValue(requestData);
    companyInfoFormInstance.setFieldsValue(requestData);
  }, [companyInfoFormInstance, requestData, userInfoFormInstance]);

  const cancelRequest = () => {
    if (isRegisterRequest) {
      denyRegistrationRequest(transformToFormData({ ID_User: requestId }));
    } else if (isEquipmentRequest) {
      const equipListToDeny = userEquipmentList?.filter((item) => item.iD_Moderation_Status === 1);

      equipListToDeny?.forEach((item) => {
        denyEquipmentRequest(transformToFormData({ ID_User: item.iD_User, ID_Equipment: item.iD_Equipment }));
      });
    }
    navigate(location.pathname.replace(requestId, ''));
  };

  const { data: chats } = useGetListQuery({
    ID_Type: 1,
    Take: 0,
    Count: 1000,
  });

  const { currentUserId } = useSelector(({ user }) => user);

  const sendRequestMessage = async () => {
    const authorizationToken = localStorage.getItem('token');
    const fullChats = await Promise.all(
      chats?.map(async (chat) => {
        const { data } = await axios.get(`https://teststim.biz-office.ru/api/Chat/chatUserGetList?ID_Chat=${chat.id}`, {
          headers: {
            Authorization: `Bearer ${authorizationToken}`,
          },
        });
        return { ...chat, usersId: [...data] };
      }),
    );
    const chatId = fullChats.find((chat) => chat.usersId.map((ud) => ud.iD_User).includes(requestData.iD_User));
    if (chatId && chatId.id) navigate(`${ROUTES.CHAT_WITH_USER}/${chatId.id}`);
    else {
      const { data: newChatId } = await axios.post(
        `https://teststim.biz-office.ru/api/Chat/chatAdd?ID_Type=1&ID_Users=${currentUserId},${requestData.iD_User}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authorizationToken}`,
          },
        },
      );
      if (newChatId) navigate(`${ROUTES.CHAT_WITH_USER}/${newChatId}`);
    }
  };

  const acceptRequest = () => {
    if (isRegisterRequest) {
      acceptRegistrationRequest({ ID_User: requestId });
    } else if (isEquipmentRequest) {
      const equipListToAccept = userEquipmentList?.filter((item) => item.iD_Moderation_Status === 1);

      equipListToAccept?.forEach((item) => {
        acceptEquipmentRequest(transformToFormData({ ID_User: item.iD_User, ID_Equipment: item.iD_Equipment }));
      });
    }
    navigate(location.pathname.replace(requestId, ''));
  };

  const handleActionClick = (actionType) => {
    switch (actionType) {
      case ACTION_TYPES.SEND:
        sendRequestMessage();
        break;
      case ACTION_TYPES.CANCEL:
        cancelRequest();
        break;
      case ACTION_TYPES.ACCEPT:
        acceptRequest();
        break;
      default:
        break;
    }
  };

  const equipmentList = userEquipmentList?.map((equip) => {
    return {
      id: equip.iD_Equipment,
      user_Id: equip.iD_User,
      name: equip.equipment_Name,
      equipType: equip.iD_AspNetUsers_Equipment_Type,
      moderationStatus: equip.iD_Moderation_Status,
      file: equip.file,
      fileName: equip.file_Name,
      key: equip.id,
    };
  });

  return (
    <RequestInfoContainer>
      {loading(
        isFetching,
        <Fragment>
          <RequestInfoHead>
            <RequestInfoBlock $margin="2.2">
              <RequestInfoDate>{dayjs(requestData?.date_Add).format('D MMMM, HH:mm')}</RequestInfoDate>
            </RequestInfoBlock>
            <RequestInfoBlock $margin="0.7">
              <RequestInfoTitle>
                {requestData?.name_Short}
                {currentStatus && <Status status={currentStatus} />}
              </RequestInfoTitle>
              <RequestInfoActions>
                {currentStatus
                  ? ADMIN_REQUEST_ACTIONS[currentStatus]?.map(({ icon, title, key, placement }) => (
                      <Tooltip
                        key={key}
                        overlayClassName={cn('small-tooltip', key)}
                        title={title}
                        placement={placement || 'bottom'}
                        arrowPointAtCenter
                      >
                        <Button className={cn('action-button', key)} onClick={() => handleActionClick(key)}>
                          <Icon $transition name={icon} className={cn(key)} />
                        </Button>
                      </Tooltip>
                    ))
                  : ADMIN_REQUEST_ACTIONS.active.map(({ icon, title, key, placement }) => (
                      <Tooltip
                        key={key}
                        overlayClassName={cn('small-tooltip', key)}
                        title={title}
                        placement={placement || 'bottom'}
                        arrowPointAtCenter
                      >
                        <Button className={cn('action-button', key)} onClick={() => handleActionClick(key)}>
                          <Icon $transition name={icon} className={cn(key)} />
                        </Button>
                      </Tooltip>
                    ))}
              </RequestInfoActions>
            </RequestInfoBlock>
          </RequestInfoHead>
          <RequestInfoContent>
            <Tabs defaultActiveKey={ADMIN_TABS.GENERAL.name}>
              <Tabs.TabPane tab={ADMIN_TABS.GENERAL.title} key={ADMIN_TABS.GENERAL.name}>
                <fieldset disabled="disabled">
                  <Form readOnly form={userInfoFormInstance} className={cn('personal-info-form', 'admin')}>
                    <Form.Block>
                      <Form.Title $margin="3.1">Личная информация</Form.Title>
                      <Form.Row>
                        <Form.Item name="f">
                          <Input.Common placeholder="Фамилия" />
                        </Form.Item>
                        <Form.Item name="i">
                          <Input.Common placeholder="Имя" />
                        </Form.Item>
                      </Form.Row>
                      <Form.Row>
                        <Form.Item name="o">
                          <Input.Common placeholder="Отчество" />
                        </Form.Item>
                        <Form.Item name="position">
                          <Input.Common placeholder="Должность в организации" />
                        </Form.Item>
                      </Form.Row>
                    </Form.Block>
                    <Form.Block>
                      <Form.Title $margin="3.1">Контактные данные</Form.Title>
                      <Form.Row>
                        <Form.Item name="email">
                          <Input.Common placeholder="Электронная почта" />
                        </Form.Item>
                        <Form.Item name="phone">
                          <Input.Common placeholder="Телефон" />
                        </Form.Item>
                      </Form.Row>
                      <Form.Row>
                        <Form.Item name="phone_Org">
                          <Input.Common placeholder="Телефон организации" />
                        </Form.Item>
                        <Form.Item name="phone_Org_Additional">
                          <Input.Common placeholder="Добавочный" />
                        </Form.Item>
                      </Form.Row>
                    </Form.Block>
                  </Form>
                </fieldset>
              </Tabs.TabPane>

              <Tabs.TabPane tab={ADMIN_TABS.COMPANY.title} key={ADMIN_TABS.COMPANY.name}>
                <fieldset disabled="disabled">
                  <Form readOnly form={companyInfoFormInstance} className={cn('company-info-form', 'admin')}>
                    <Form.Block>
                      <Form.Title $margin="3.1">Свидетельства о государственной регистрации</Form.Title>
                      <Form.Row>
                        <Form.Item name="inn">
                          <Input.Common placeholder="ИНН" />
                        </Form.Item>
                        <Form.Item name="ogrn">
                          <Input.Common placeholder="ОГРН" />
                        </Form.Item>
                      </Form.Row>
                      <Form.Row>
                        <Form.Item name="okpo">
                          <Input.Common placeholder="ОКПО" />
                        </Form.Item>
                        <Form.Item name="opf">
                          <Input.Common placeholder="ОПФ" />
                        </Form.Item>
                      </Form.Row>
                    </Form.Block>
                    <Form.Block>
                      <Form.Title $margin="3.1">Наименование организации</Form.Title>
                      <Form.Item name="name_Full">
                        <Input.TextArea.Common autoSize placeholder="Полное наименование" />
                      </Form.Item>
                      <Form.Item name="name_Short">
                        <Input.TextArea.Common autoSize placeholder="Краткое наименование" />
                      </Form.Item>
                    </Form.Block>
                    <Form.Block>
                      <Form.Title $margin="3.1">Адрес организации</Form.Title>
                      <Form.Item name="address_Ur" $margin="4.5">
                        <Input.TextArea.Common autoSize placeholder="Юридический адрес" />
                      </Form.Item>
                      <Form.Item name="address_Fact">
                        <Input.TextArea.Common autoSize placeholder="Фактический адрес" />
                      </Form.Item>
                    </Form.Block>
                    <Form.Block>
                      <Form.Title $margin="3.1">Руководитель</Form.Title>
                      <Form.Item name="director_Fio">
                        <Input.Common placeholder="ФИО Руководителя" />
                      </Form.Item>
                    </Form.Block>
                    {requestData?.roleId === USER_ROLES.CLIENT && (
                      <Form.Block>
                        <Form.Title $margin="2.2">Тип организации</Form.Title>
                        <Form.Item name="organizations_Type">
                          <Radio.Group>
                            <Radio $checkbox value="Проектная">
                              Проектная
                            </Radio>
                            <Radio $checkbox value="Проектно-монтажная">
                              Проектно-монтажная
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Form.Block>
                    )}
                  </Form>
                </fieldset>
              </Tabs.TabPane>

              {requestData?.roleId === USER_ROLES.PROVIDER && (
                <Tabs.TabPane tab={ADMIN_TABS.EQUIPMENT.title} key={ADMIN_TABS.EQUIPMENT.name}>
                  {loading(
                    isFetchingUserEquipmentList,
                    <EquipmentTable admin equipmentList={equipmentList?.reverse()} />,
                  )}
                </Tabs.TabPane>
              )}

              <Tabs.TabPane tab={ADMIN_TABS.COMMENT.title} key={ADMIN_TABS.COMMENT.name}>
                {loading(
                  isFetching,
                  requestData?.comment ? (
                    <Fragment>
                      <Page.Text $comment>{requestData?.comment}</Page.Text>
                      {currentStatus !== ADMIN_REQUEST_STATUSES.CANCELED.name && (
                        <Page.Text $error={!commentConfirmed} $success={commentConfirmed} $small>
                          {commentStatusText}
                        </Page.Text>
                      )}
                    </Fragment>
                  ) : (
                    <Page.Text>Нет комментария</Page.Text>
                  ),
                )}
              </Tabs.TabPane>
            </Tabs>
          </RequestInfoContent>
        </Fragment>,
      )}
    </RequestInfoContainer>
  );
};

export default RequestInfo;
