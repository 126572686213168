import styled from 'styled-components';

import Select from '@molecules/Select';

export const SearchContainer = styled(Select)`
  width: 100%;
  &.ant-select:not(.ant-select-customize-input) {
    .ant-select {
      &-selector {
        height: 3.5rem;
        padding-left: 4.2rem;
        border: 1px solid transparent;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.palette.input};
      }
      &-arrow {
        width: 1.2rem;
        height: 1.3rem;
        right: unset;
        left: 1.5rem;
        margin-top: 0;
        transform: translateY(-50%);
      }
      &-selection {
        &-search {
          padding-left: 3.2rem;
          &-input {
            height: 100%;
            font-size: 1.2rem;
            line-height: 1.6rem;
            color: ${({ theme }) => theme.palette.text};
          }
        }
        &-placeholder {
          display: flex;
          align-items: center;
          font-size: 1.2rem;
          line-height: 1.6rem;
          color: ${({ theme }) => theme.palette.secondaryText};
          transition: 0.3s;
        }
      }
    }
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
    .ant-select {
      &-selector {
        border-color: ${({ theme }) => theme.palette.statusSent};
        background-color: ${({ theme }) => theme.palette.bodySecondary};
      }
      &-selection {
        &-placeholder {
          color: ${({ theme }) => theme.palette.tertiaryText};
          opacity: 0.5;
        }
      }
    }
    .icon {
      &.search {
        stroke: ${({ theme }) => theme.palette.button};
      }
    }
  }
  .icon {
    &.search {
      width: 1.2rem;
      height: 1.3rem;
      stroke: ${({ theme }) => theme.palette.secondaryText};
    }
  }
  &.ant-select-open {
    .ant-select-arrow {
      transform: translateY(-50%) rotate(0);
      margin-top: 0rem;
    }
  }
  &:hover {
    &.ant-select:not(.ant-select-customize-input) {
      .ant-select {
        &-selector {
          border-color: ${({ theme }) => theme.palette.statusSent};
          background-color: ${({ theme }) => theme.palette.bodySecondary};
        }
        &-selection {
          &-placeholder {
            color: ${({ theme }) => theme.palette.tertiaryText};
            opacity: 0.5;
          }
        }
      }
    }
    .icon {
      &.search {
        stroke: ${({ theme }) => theme.palette.button};
      }
    }
  }
`;
