import React from 'react';
import { Navigate } from 'react-router-dom';

import { ROUTES } from '@constants/routes';
import useModerationStatus from '@hooks/useModerationStatus';

const ProtectedRoute = ({ children }) => {
  const { notModeratedStatus, rejectedStatus } = useModerationStatus();

  if (notModeratedStatus || rejectedStatus) {
    return <Navigate to={ROUTES.SETTINGS} replace />;
  }

  return children;
};

export default ProtectedRoute;
