import styled, { css } from 'styled-components';

export const StatusLabel = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.palette.buttonText};
`;

export const StatusContainer = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem 0.7rem;
  border-radius: 2px;
  &.draft {
    background-color: ${({ theme }) => theme.palette.statusDraft};
  }
  &.sent {
    background-color: ${({ theme }) => theme.palette.statusSent};
  }
  &.canceled {
    &.admin {
      background-color: ${({ theme }) => theme.palette.secondaryText};
    }
    background-color: ${({ theme }) => theme.palette.statusCanceledSecondary};
  }
  &.canceledByProvider {
    background-color: ${({ theme }) => theme.palette.statusCanceledSecondary};
  }
  &.archive {
    background-color: ${({ theme }) => theme.palette.statusArchiveSecondary};
    ${StatusLabel} {
      color: ${({ theme }) => theme.palette.text};
    }
  }
  &.notAnswered {
    background-color: ${({ theme }) => theme.palette.statusArchiveSecondary};
    ${StatusLabel} {
      color: ${({ theme }) => theme.palette.text};
    }
  }
  &.answered {
    background-color: ${({ theme }) => theme.palette.statusSent};
  }
  &.active {
    background-color: ${({ theme }) => theme.palette.siderSecondary};
    ${StatusLabel} {
      color: ${({ theme }) => theme.palette.sider};
    }
  }
  &.accepted {
    background-color: ${({ theme }) => theme.palette.providerChosen};
  }
  &.needsModeration {
    background-color: ${({ theme }) => theme.palette.statusSent};
  }
  &.clientChosen {
    background-color: ${({ theme }) => theme.palette.providerChosen};
  }
  &.clientChosenOther {
    background-color: ${({ theme }) => theme.palette.secondaryText};
  }
  &.clientCanceled {
    background-color: ${({ theme }) => theme.palette.statusCanceledSecondary};
  }
  &.hasProvider {
    background-color: ${({ theme }) => theme.palette.providerChosen};
  }

  ${({ $width }) =>
    $width &&
    css`
      max-width: ${$width}rem;
    `}
`;
