import React from 'react';
import cn from 'classnames';

import { TreeSelectContainer } from './styles';

const TreeSelect = ({ ...rest }) => {
  return <TreeSelectContainer {...rest} popupClassName={cn('tree-select-dropdown')} />;
};

export default TreeSelect;
