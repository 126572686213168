import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasClientObjects: false,
  isLoadingClientObjects: true,
  currentObjectInfo: {},
  currentRequestInfo: {},
  createRequestEditMode: false,
  currentClickedRequestID: null,
  currentClickedAnswerID: null,
};

export const requests = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    setHasClientObjects: (state, { payload }) => {
      state.hasClientObjects = payload;
    },
    setIsLoadingClientObjects: (state, { payload }) => {
      state.isLoadingClientObjects = payload;
    },
    setCurrentObjectInfo: (state, { payload }) => {
      state.currentObjectInfo = payload;
    },
    setCurrentRequestInfo: (state, { payload }) => {
      state.currentRequestInfo = payload;
    },
    setCreateRequestEditMode: (state, { payload }) => {
      state.createRequestEditMode = payload;
    },
    setCurrentClickedRequestID: (state, { payload }) => {
      state.currentClickedRequestID = payload;
    },
    setCurrentClickedAnswerID: (state, { payload }) => {
      state.currentClickedAnswerID = payload;
    },
  },
});

export const {
  setHasClientObjects,
  setIsLoadingClientObjects,
  setCurrentObjectInfo,
  setCurrentRequestInfo,
  setCreateRequestEditMode,
  setCurrentClickedRequestID,
  setCurrentClickedAnswerID,
} = requests.actions;

export default requests.reducer;
