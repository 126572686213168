import styled from 'styled-components';

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 8.5rem 1fr;
  gap: 1rem;
  align-items: center;
  padding: 2rem;
  background-color: ${({ theme }) => theme.palette.drawerElement};
  border-radius: 4px;
  transition: 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.palette.sider};
  }
`;

export const CardBlock = styled.div``;

export const CardIcon = styled.img`
  margin: 0px auto;
`;

export const CardTitle = styled.h3`
  color: ${({ theme }) => theme.palette.drawerTextSecondary};
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 0rem 0rem 1rem 0rem;
`;

export const CardContent = styled.p`
  color: ${({ theme }) => theme.palette.drawerText};
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin: 0;
`;
