import { TreeSelect } from 'antd';
import styled from 'styled-components';

export const TreeSelectContainer = styled(TreeSelect)`
  display: block;
  width: 100%;
  max-width: ${({ $mw }) => ($mw ? `${$mw}rem` : '25rem')};
  margin-bottom: ${({ $mb }) => ($mb ? `${$mb}rem` : '0')};
  cursor: pointer;

  .tree-select-arrow {
    fill: ${({ theme }) => theme.palette.text};
    width: 1.3rem;
    height: 0.7rem;
  }

  .ant-select {
    &-selector {
      height: auto;
      min-height: 4rem;
      border-color: ${({ theme }) => theme.palette.borderNeutral};
    }
    &-selection {
      &-placeholder {
        font-size: 1.4rem;
        line-height: 2rem;
        color: ${({ theme }) => theme.palette.tertiaryText};
      }
      &-item {
        /* display: flex;
        align-items: center;
        font-size: 1.4rem;
        line-height: 2rem;
        color: ${({ theme }) => theme.palette.text} !important; */
        background-color: ${({ theme }) => theme.palette.filterOption};
        &-content {
          display: flex;
          align-items: center;
          font-size: 1.4rem;
          line-height: 2rem;
          color: ${({ theme }) => theme.palette.button};
        }
        &-remove {
          display: none;
        }
      }
    }
  }
  &:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${({ theme }) => theme.palette.borderNeutral};
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-color: ${({ theme }) => theme.palette.statusSent};
      box-shadow: none;
    }
  }
  &.ant-select-open {
    .ant-select-selector {
      border-color: ${({ theme }) => theme.palette.statusSent};
    }
    .tree-select-arrow {
      fill: ${({ theme }) => theme.palette.statusSent};
      transform: rotate(180deg);
    }
  }
`;
