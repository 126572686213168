import styled from 'styled-components';

export const MobileContainer = styled.div`
  padding: 1rem 1.8rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.bodySecondary};

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    width: 16rem;
    height: 5rem;
  }

  .logo {
    padding: 0;
    width: max-content;
    img {
      width: 12.4rem;
      height: 3.2rem;
    }
  }
`;

export const MobileHeader = styled.div`
  padding: 0.8rem 2.2rem;
  margin-bottom: 7.2rem;
  box-shadow: 0px 12px 12px rgba(126, 126, 133, 0.24);
  border-radius: 8px;
`;

export const MobileContent = styled.div`
  margin: 0px auto;
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon {
    &.back {
      margin-right: 0.7rem;
      width: 0.9rem;
      height: 0.8rem;
    }
  }
`;

export const MobileTitle = styled.h1`
  text-align: center;
  margin: 0 0 2.2rem 0;
  font-size: 2.4rem;
  line-height: 3.2rem;
  color: ${({ theme }) => theme.palette.cardText};
`;

export const MobileDescription = styled.p`
  text-align: center;
  margin: 0 0 3rem 0;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.palette.cardText};
`;

export const MobileIcon = styled.img`
  width: 6rem;
  height: 3rem;
  margin-bottom: 3.2rem;
`;
