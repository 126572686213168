import styled, { css } from 'styled-components';

export const EquipmentTableContainer = styled.div`
  .upload {
    &-button {
      background-color: ${({ theme }) => theme.palette.tertiaryText};
      &:hover {
        background-color: ${({ theme }) => theme.palette.buttonHover};
      }
    }
    &-icon {
      margin-right: 1rem;
      width: 1.4rem;
      height: 1.6rem;
      stroke: ${({ theme }) => theme.palette.buttonText};
    }
  }
  .ant-radio-inner {
    background-color: transparent;
  }
  .radio-group {
    &.disabled {
      pointer-events: none;
    }
  }
  &.moderating {
    .equipment-table-delete-button {
      // display: none;
    }
    .ant-radio-wrapper {
      pointer-events: none;
    }
  }
`;

export const EquipmentTableHead = styled.div`
  display: grid;
  grid-template-columns: 23rem 9rem 9rem 1fr;
  gap: 4rem;
  margin-bottom: 2rem;
`;

export const EquipmentTableHeadTitle = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.palette.text};

  ${({ $center }) =>
    $center &&
    css`
      text-align: center;
    `}
`;

export const EquipmentTableRowWrap = styled.div`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  ${({ $grid }) =>
    $grid &&
    css`
      display: grid;
      grid-template-columns: 25rem 22rem 1fr;
    `}

  ${({ $center }) =>
    $center &&
    css`
      display: flex;
      align-items: center;
      gap: 1.6rem;
    `}
`;

export const EquipmentTableElement = styled.div`
  display: flex;
  align-items: center;

  ${({ $end }) =>
    $end &&
    css`
      justify-content: flex-end;
    `}
`;

export const EquipmentTableTitle = styled.p`
  margin: 0;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.palette.text};
`;

export const EquipmentTableSubtitle = styled.p`
  margin: 0rem 0rem 0rem 1.7rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.palette.text};
  &.success {
    color: ${({ theme }) => theme.palette.providerChosen};
  }
  &.error {
    color: ${({ theme }) => theme.palette.notification};
  }
`;

export const EquipmentTableFile = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.palette.providerChosen};
  .icon {
    &.file {
      margin-right: 0.5rem;
      width: 1.4rem;
      height: 1.6rem;
      stroke: ${({ theme }) => theme.palette.providerChosen};
    }
  }
`;

export const EquipmentTableButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.1rem;
  height: 3.1rem;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  transition: 0.5s;
  cursor: pointer;
  .icon {
    &.delete {
      margin: 0;
      stroke: ${({ theme }) => theme.palette.secondaryText};
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.palette.actionDelete};
    .icon {
      stroke: ${({ theme }) => theme.palette.buttonText} !important;
    }
  }
`;

export const EquipmentTableText = styled.span`
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.palette.text};
  ${({ $error }) =>
    $error &&
    css`
      color: ${({ theme }) => theme.palette.inputError};
    `}
  ${({ $center }) =>
    $center &&
    css`
      text-align: center;
    `}
`;

export const EquipmentTableTag = styled.div`
  margin-left: 1rem;
  padding: 0.3rem 0.7rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.palette.buttonText};
  background-color: ${({ theme }) => theme.palette.statusSent};
  border-radius: 2px;
`;

export const EquipmentTableActions = styled.div`
  display: flex;
  gap: 0.6rem;
  position: absolute;
  top: 50%;
  left: calc(100% + 1.5rem);
  transform: translateY(-50%);

  .action-button {
    &.invisible {
      opacity: 0.25;
    }
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
    `}
`;

export const EquipmentTableRow = styled.div`
  position: relative;
  max-width: 74.5rem;
  padding: 1rem 2rem;
  &:nth-child(even) {
    background-color: ${({ theme }) => theme.palette.body};
  }
  ${EquipmentTableFile} {
    color: ${({ theme }) => theme.palette.providerChosen};
  }

  &.new {
    position: relative;
    background-color: rgba(212, 227, 255, 0.7);
    border: 1px solid ${({ theme }) => theme.palette.statusSent};
    ${EquipmentTableRowWrap} {
      margin-bottom: 0;
    }
    ${EquipmentTableFile} {
      color: ${({ theme }) => theme.palette.button};
      .icon {
        &.file {
          stroke: ${({ theme }) => theme.palette.button};
        }
      }
    }
  }
  &.notAvailable {
    opacity: 0.7;
    pointer-events: none;
  }
`;
