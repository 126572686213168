import URI from 'urijs';

import { clientAPI } from '@api';

const _clientAPI = clientAPI.enhanceEndpoints({
  addTagTypes: ['Objects'],
});

const objectsAPI = _clientAPI.injectEndpoints({
  endpoints: (build) => ({
    // статусы объектов
    getObjectsStatusList: build.query({
      query: () => {
        const URL = new URI('/Objects/objectsStatusList');

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
    }),
    // список объектов пользователя
    getObjects: build.query({
      query: (statusId) => {
        const URL = new URI('/Objects/getList');

        URL.addQuery({ ID_Status: statusId });

        return {
          url: URL.toString(),
          method: 'GET',
        };
      },
      providesTags: ['Objects', 'Authenticate'],
    }),
    // создать объект
    addObject: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Objects/add');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['Objects'],
    }),
    // обновить объект
    editObject: build.mutation({
      query: (attributes) => {
        const URL = new URI('/Objects/update');

        return {
          url: URL.toString(),
          method: 'POST',
          body: attributes,
        };
      },
      invalidatesTags: ['Objects'],
    }),
  }),
});

export const { useGetObjectsStatusListQuery, useGetObjectsQuery, useAddObjectMutation, useEditObjectMutation } =
  objectsAPI;
