import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';

import {
  setCurrentClickedAnswerID,
  setCurrentClickedRequestID,
  setCurrentObjectInfo,
  setCurrentRequestInfo,
  setHasClientObjects,
  setIsLoadingClientObjects,
} from '@reducers/requests';
import {
  setIsEmailConfirmed,
  setCurrentUserId,
  setCredentials,
  setIsLoading,
  setModerationChecked,
  setModeratedStatus,
  setUserInfoStatus,
  setOrganizationInfoStatus,
  setUserEquipmentStatus,
  setToken,
} from '@reducers/user';
import {
  useAuthenticateMutation,
  useRegistrationMutation,
  useCheckIsModeratedQuery,
  useGetUserParameterQuery,
  useGetUserModerationStatusQuery,
  useCheckIsEmailConfirmedQuery,
} from '@api/user';
import { saveState, clearState, loadState } from '@helpers/localStorage';
import { USER_ROLES } from '@constants/common';
import { setIsLoadingApp } from '@reducers/application';
import { chatAPI } from '@api/chat';

const useAuth = () => {
  const dispatch = useDispatch();

  // Get current user and his authentication status
  const { currentUser, isAuthenticated, token } = useSelector(({ user }) => user);
  const { isLoadingApp } = useSelector(({ application }) => application);

  // Get API services
  const [login, { data: loginData, error: loginError, isLoading: isLoadingLogin }] = useAuthenticateMutation();
  const [
    registration,
    {
      data: registrationData,
      error: registrationError,
      isLoading: isLoadingRegistration,
      isSuccess: isSuccessRegistration,
    },
  ] = useRegistrationMutation();

  const {
    currentData: isEmailConfirmed,
    isSuccess: isSuccessEmailConfirmed,
    isLoading: isLoadingEmailConfirmed,
  } = useCheckIsEmailConfirmedQuery({}, { skip: !isAuthenticated });

  const {
    data: isModerated,
    isSuccess: isSuccessModerationCheck,
    isLoading: isLoadingModerated,
  } = useCheckIsModeratedQuery({}, { skip: !isAuthenticated, pollingInterval: 180000 });

  // moderation status / parameters
  const {
    data: userModeratedStatus,
    isSuccess: isSuccessModeratedStatusCheck,
    isLoading: isLoadingModeratedStatus,
  } = useGetUserModerationStatusQuery(
    {},
    {
      skip: !isAuthenticated,
      pollingInterval: 180000,
    },
  );

  const {
    data: userInfoStatusSent,
    isSuccess: isSuccessUserInfoStatusSent,
    isLoading: isLoadingUserInfoStatusSent,
  } = useGetUserParameterQuery(16, {
    skip: !isAuthenticated,
  });
  const {
    data: organizationInfoStatusSent,
    isSuccess: isSuccessOrganizationInfoStatusSent,
    isLoading: isLoadingOrganizationInfoStatusSent,
  } = useGetUserParameterQuery(18, {
    skip: !isAuthenticated,
  });
  const {
    data: userEquipmentStatusSent,
    isSuccess: isSuccessUserEquipmentStatusSent,
    isLoading: isLoadingUserEquipmentStatusSent,
  } = useGetUserParameterQuery(17, {
    skip: !isAuthenticated,
  });

  const loginMutation = useCallback(login, [login]);
  const registrationMutation = useCallback(registration, [registration]);
  useEffect(() => {
    dispatch(setToken(localStorage.getItem('token')));
  }, [dispatch]);
  // Save JWT to cookies
  const saveAccessToken = useCallback(
    (atoken) => {
      if (atoken) {
        Cookies.set('access-token', atoken, { expires: 7, secure: true });
        localStorage.setItem('token', atoken);
        dispatch(setToken(atoken));
      } else {
        Cookies.remove('access-token');
      }
    },
    [dispatch],
  );

  // Save user to LS
  const saveCurrentUser = useCallback((user, id) => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('userID', JSON.stringify(id));
    } else {
      localStorage.removeItem('user');
      localStorage.removeItem('userID');
    }
  }, []);

  // Provide saved user creds to redux
  useEffect(() => {
    if (!currentUser && !isAuthenticated) {
      dispatch(setCredentials(JSON.parse(localStorage.getItem('user'))));
      dispatch(setCurrentUserId(JSON.parse(localStorage.getItem('userID'))));
    }
  }, [currentUser, dispatch, isAuthenticated]);

  // Clear current user's data
  const clearModerationStatuses = useCallback(() => {
    dispatch(setUserEquipmentStatus(null));
    dispatch(setOrganizationInfoStatus(null));
    dispatch(setUserInfoStatus(null));
    dispatch(setModeratedStatus(null));
    dispatch(setModerationChecked(false));
  }, [dispatch]);

  const clearRequestsInfo = useCallback(() => {
    dispatch(setCurrentRequestInfo({}));
    dispatch(setCurrentObjectInfo({}));
    dispatch(setHasClientObjects(false));
    dispatch(setCurrentClickedAnswerID(null));
    dispatch(setCurrentClickedRequestID(null));
    dispatch(setIsLoadingClientObjects(true));
  }, [dispatch]);

  const logout = useCallback(() => {
    saveAccessToken(null);
    dispatch(setCredentials(null));
    dispatch(setIsEmailConfirmed(null));
    clearModerationStatuses();
    clearRequestsInfo();
    saveCurrentUser(null, null);
    dispatch(chatAPI.util.resetApiState());
  }, [clearModerationStatuses, clearRequestsInfo, dispatch, saveAccessToken, saveCurrentUser]);

  const setLoginData = useCallback(
    (data) => {
      if (data.token) {
        if (!loadState('tokenExpiresAt')) {
          saveState(dayjs().add(7, 'day'), 'tokenExpiresAt');
        }
        saveAccessToken(data.token);
        saveCurrentUser(data.role, data.iD_User);
        dispatch(setCurrentUserId(data.iD_User));
        dispatch(setCredentials(data.role));
        dispatch(setIsLoading(false));
      } else {
        logout();
      }
    },
    [saveAccessToken, saveCurrentUser, dispatch, logout],
  );

  useEffect(() => {
    if (loadState('tokenExpiresAt') && dayjs(loadState('tokenExpiresAt')) < dayjs()) {
      clearState('tokenExpiresAt');
      logout();
    }
  }, [logout]);

  // Watch registration happens...
  useEffect(() => {
    // Setup data if exist
    if (registrationData) {
      setLoginData(registrationData);
    }

    // Handle some errors
    if (registrationError) {
      dispatch(setIsLoading(false));
      if (registrationError.status === 422) {
        console.log('error');
      } else {
        console.log('error 2');
      }
    }
  }, [registrationData, saveAccessToken, dispatch, saveCurrentUser, registrationError, setLoginData]);

  // Watch login happens...
  useEffect(() => {
    // Setup data if exist
    if (loginData) {
      setLoginData(loginData);
    }

    // Handle some errors
    if (loginError) {
      dispatch(setIsLoading(false));
      if (loginError.status === 422) {
        console.log('login error');
      } else {
        localStorage.setItem(
          'login-error-counter',
          localStorage.getItem('login-error-counter') ? +localStorage.getItem('login-error-counter') + 1 : 1,
        );
      }
    }
  }, [loginData, saveAccessToken, dispatch, saveCurrentUser, loginError, setLoginData]);

  useEffect(() => {
    if (
      !isLoadingModerated &&
      !isLoadingModeratedStatus &&
      !isLoadingUserInfoStatusSent &&
      !isLoadingOrganizationInfoStatusSent &&
      !isLoadingUserEquipmentStatusSent &&
      !isLoadingEmailConfirmed
    ) {
      dispatch(setIsLoadingApp(false));
    }
  }, [
    dispatch,
    isLoadingEmailConfirmed,
    isLoadingModerated,
    isLoadingModeratedStatus,
    isLoadingOrganizationInfoStatusSent,
    isLoadingUserEquipmentStatusSent,
    isLoadingUserInfoStatusSent,
  ]);

  useEffect(() => {
    if (isSuccessEmailConfirmed) {
      dispatch(setIsEmailConfirmed(isEmailConfirmed));
    }
  }, [dispatch, isEmailConfirmed, isModerated, isSuccessEmailConfirmed]);

  useEffect(() => {
    if (isSuccessModerationCheck) {
      dispatch(setModerationChecked(isModerated));
    }
  }, [dispatch, isModerated, isSuccessModerationCheck]);

  // moderation status
  useEffect(() => {
    if (isSuccessModeratedStatusCheck) {
      dispatch(setModeratedStatus(userModeratedStatus));
    }
  }, [dispatch, isSuccessModeratedStatusCheck, userModeratedStatus]);

  // user info status
  useEffect(() => {
    if (isSuccessUserInfoStatusSent) {
      // if (userInfoNull) {
      //   dispatch(setUserInfoStatus(userInfoStatusSent));
      // }
      dispatch(setUserInfoStatus(userInfoStatusSent));
    }
  }, [dispatch, isSuccessUserInfoStatusSent, userInfoStatusSent]);

  // organization status
  useEffect(() => {
    if (isSuccessOrganizationInfoStatusSent) {
      dispatch(setOrganizationInfoStatus(organizationInfoStatusSent));
    }
  }, [dispatch, isSuccessOrganizationInfoStatusSent, organizationInfoStatusSent]);

  // equipment status
  useEffect(() => {
    if (isSuccessUserEquipmentStatusSent) {
      dispatch(setUserEquipmentStatus(userEquipmentStatusSent));
    }
  }, [dispatch, isSuccessUserEquipmentStatusSent, userEquipmentStatusSent]);

  const isClient = currentUser === USER_ROLES.CLIENT;

  const equipParam = isClient ? true : !!userEquipmentStatusSent;

  const allParametersSent = !!organizationInfoStatusSent && equipParam && !!userInfoStatusSent;
  const notModeratedStatus = !allParametersSent && !isModerated;

  const unmoderatedCondition = userModeratedStatus === undefined || notModeratedStatus;

  const hasNotAuthenticatedStatus = !isAuthenticated;
  const hasRejectedStatus = isAuthenticated && userModeratedStatus === -1;
  const hasUnmoderatedStatus = isAuthenticated && unmoderatedCondition && userModeratedStatus !== -1;
  const hasClientRole = isAuthenticated && isClient;
  const hasProviderRole = isAuthenticated && currentUser === USER_ROLES.PROVIDER;
  const hasAdminRole = isAuthenticated && currentUser === USER_ROLES.ADMIN;

  return {
    isAuthenticated,
    isModerated,
    currentUser,
    login: loginMutation,
    logout,
    registration: registrationMutation,
    setLoginData,
    hasRejectedStatus,
    hasUnmoderatedStatus,
    hasNotAuthenticatedStatus,
    hasClientRole,
    hasProviderRole,
    hasAdminRole,
    registrationError,
    loginError,
    isLoadingLogin,
    isLoadingRegistration,
    isLoadingApp,
    isSuccessRegistration,
    token,
  };
};

export default useAuth;
