import styled, { css } from 'styled-components';

export const FileText = styled.span`
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.palette.text};
  text-decoration: underline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const FileTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.4rem;
  ${({ $underline }) =>
    $underline &&
    css`
      &:hover {
        ${FileText} {
          text-decoration: none;
        }
      }
    `}
`;

export const FileSize = styled.span`
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.palette.tertiaryText};
`;

export const FileWrap = styled.div`
  display: flex;
`;

export const FileContainer = styled.a`
  display: flex;
  flex-direction: column;
  width: max-content;
  .icon {
    margin-right: 0.5rem;
    &.file {
      width: 1.4rem;
      height: 1.6rem;
      stroke: ${({ theme }) => theme.palette.text};
    }
    &.success {
      width: 1rem;
      height: 0.7rem;
      margin-left: 1rem;
      stroke: ${({ theme }) => theme.palette.button} !important;
    }
  }

  &.upload {
    ${FileText} {
      color: ${({ theme }) => theme.palette.link};
    }
    .icon {
      stroke: ${({ theme }) => theme.palette.link};
    }
  }
  &.table {
    ${FileText} {
      max-width: 11rem;
    }
    ${FileTitle} {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &:hover {
    ${FileText} {
      color: ${({ theme }) => theme.palette.link};
    }
    .icon {
      stroke: ${({ theme }) => theme.palette.link};
    }
  }

  ${({ $row }) =>
    $row &&
    css`
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    `}

  ${({ $secondary }) =>
    $secondary &&
    css`
      ${FileText} {
        color: ${({ theme }) => theme.palette.button};
        margin-bottom: 0.4rem;
      }
      ${FileTitle} {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .icon {
        &.file {
          width: 2rem;
          height: 2.3rem;
          stroke: ${({ theme }) => theme.palette.button};
        }
      }
    `}
`;

export const FileAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.1rem;
  height: 3.1rem;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  transition: 0.5s;
  margin-top: -0.5rem;
  cursor: pointer;
  .icon {
    &.delete {
      margin: 0;
      stroke: ${({ theme }) => theme.palette.secondaryText};
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.palette.actionDelete};
    .icon {
      stroke: ${({ theme }) => theme.palette.buttonText} !important;
    }
  }
`;
