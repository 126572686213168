import styled, { css } from 'styled-components';
import { Checkbox } from 'antd';

export const CheckboxContainer = styled(Checkbox)`
  border-radius: 2px;
  .ant-checkbox {
    &-input {
      &:focus {
        + .ant-checkbox-inner {
          border-color: ${({ theme }) => theme.palette.drawerText} !important;
        }
      }
    }
    &-checked {
      .ant-checkbox {
        &-inner {
          border-color: ${({ theme }) => theme.palette.button};
          background-color: ${({ theme }) => theme.palette.button};
          &::after {
            border-width: 1px;
            border-color: ${({ theme }) => theme.palette.checkboxIcon};
          }
        }
        &-input {
          &:focus {
            + .ant-checkbox-inner {
              border-color: ${({ theme }) => theme.palette.button} !important;
            }
          }
        }
      }
      &::after {
        border: none;
      }
    }
    &-inner {
      border: 1px solid ${({ theme }) => theme.palette.borderSecondary};
      background-color: transparent;
      &::after {
        width: 0.4rem;
        height: 0.7rem;
        left: 31.5%;
      }
    }
  }
  &:hover {
    .ant-checkbox {
      &-inner {
        border: 1px solid ${({ theme }) => theme.palette.drawerText};
      }
      &-checked {
        .ant-checkbox {
          &-inner {
            border-color: ${({ theme }) => theme.palette.button};
          }
        }
      }
    }
  }
  ${({ $secondary }) =>
    $secondary &&
    css`
      .ant-checkbox {
        &-checked {
          .ant-checkbox {
            &-inner {
              background-color: ${({ theme }) => theme.palette.borderSecondary};
              border-color: ${({ theme }) => theme.palette.borderSecondary} !important;
              &::after {
                border-width: 1px;
                border-color: ${({ theme }) => theme.palette.checkboxIcon};
              }
            }
            &-input {
            &:focus {
              + .ant-checkbox-inner {
                border-color: ${({ theme }) => theme.palette.borderSecondary} !important;
              }
            }
          }
          }
          &::after {
            border: none;
          }
        }
        &-input {
          &:focus {
            + .ant-checkbox-inner {
              border-color: ${({ theme }) => theme.palette.borderSecondary} !important;
            }
          }
        }
        &-inner {
          border: 1px solid ${({ theme }) => theme.palette.borderSecondary};
          background-color: transparent;
        }
      }
      &:hover {
        .ant-checkbox  {
          &-inner {
          border: 1px solid ${({ theme }) => theme.palette.borderSecondary};
          }
        }
    `}

  ${({ $dark }) =>
    $dark &&
    css`
      .ant-checkbox {
        &-inner {
          border: 1px solid ${({ theme }) => theme.palette.drawerText};
        }
      }
    `}

  ${({ $light }) =>
    $light &&
    css`
      .ant-checkbox {
        &-checked {
          .ant-checkbox {
            &-inner {
              background-color: ${({ theme }) => theme.palette.statusSent};
              border-color: ${({ theme }) => theme.palette.statusSent} !important;
              &::after {
                border-color: ${({ theme }) => theme.palette.buttonText};
              }
            }
          }
        }
      }
    `}
`;

export const CheckboxGroup = styled(Checkbox.Group)`
  ${({ $column }) =>
    $column &&
    css`
      display: flex;
      flex-direction: column;
      gap: 1.8rem;
      .ant-checkbox {
        &-wrapper {
          margin: 0;
        }
      }
    `}
`;
