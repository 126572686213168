import styled from 'styled-components';

export const RequestCreationContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 100%;
  max-height: 65rem;
`;

export const RequestCreationErrorText = styled.span`
  position: absolute;
  bottom: -1.8rem;
  left: 0;
  color: ${({ theme }) => theme.palette.inputError};
  font-size: 1.2rem;
  line-height: 1.6rem;
  transform: translateY(-5px);
  opacity: 0;
  visibility: hidden;
  transition: 0.1s;
`;

export const RequestCreationHeader = styled.div`
  position: relative;
  max-width: 52rem;
  margin-bottom: ${({ $margin }) => ($margin ? `${$margin}rem` : '0')};
  &.error {
    ${RequestCreationErrorText} {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const RequestCreationTitle = styled.h2`
  margin: 0;
  font-size: 2.4rem;
  line-height: 3.2rem;
  color: ${({ theme }) => theme.palette.text};
`;

export const RequestCreationSubtitle = styled.p`
  margin: 1.2rem 0rem 0rem 0rem;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.palette.secondaryText};
`;

export const RequestCreationContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 100%;
`;
