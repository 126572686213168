import React from 'react';

import { SearchContainer } from './styles';

const Search = ({ onSearch, onChange, items, placeholder, ...rest }) => {
  const finalItems = items?.some((item) => item?.key === undefined) ? [] : items;

  return (
    <SearchContainer
      showSearch
      onSearch={onSearch}
      onChange={onChange}
      items={finalItems}
      filterOption={false}
      defaultActiveFirstOption={false}
      placeholder={placeholder}
      allowClear
      {...rest}
    />
  );
};

export default Search;
