import styled from 'styled-components';

export const ChatCardBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ChatCardTitle = styled.h3`
  margin: 0rem 0rem 1rem 0rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  transition: 0.2s;
  color: ${({ theme }) => theme.palette.text};
`;

export const ChatCardSubtitle = styled.span`
  display: block;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  transition: 0.2s;
  color: ${({ theme }) => theme.palette.cardText};
`;

export const ChatCardDescription = styled.p`
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin: 0rem 0rem 1.2rem 0rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  transition: 0.2s;
  color: ${({ theme }) => theme.palette.tertiaryText};
`;

export const ChatCardDate = styled.span`
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.palette.cardTextSecondary};
`;

export const ChatCardContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  &.chat-card {
    justify-content: unset;
    align-items: unset;
    flex-flow: column;
  }
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  }

  .chat-with-line:before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 1px solid #f0f0f0;
    content: '';
    margin-top: -25px;
  }

`;

export const ChatInfoRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  height: fit-content;
  justify-content: space-between;
  align-items: center;
`;

export const CompanyInfoBlock = styled.div`
  display: flex;
  flex-wrap: nowrap;
  text-wrap: nowrap;
`;

export const ChatMessage = styled.div`
  margin: 16px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: auto;
  display: flex;
  .highlighted,
  .a-highlighted {
    background-color: yellow;
  }
  .a-highlighted {
    font-weight: bolder;
  }
  .spacer {
    min-width: 30%;
  }
  .msg-wrapper {
    &.c-f-m {
      background: #aaaff5;
    }
    padding: 7px 16px 16px;
    border-radius: 7px;
    background: var(--helpon-power-color-bg, #eeeff5);
    display: flex;
    flex-flow: column;
    .msg {
    }
    sub {
      text-align: right;
      color: #5f5d67;
      letter-spacing: 0.5px;
    }
  }
`;
