import React from 'react';

import {
  NotificationHead,
  NotificationContainer,
  NotificationContent,
  NotificationAction,
  NotificationText,
} from './styles';

const Notification = ({ children, ...rest }) => {
  return <NotificationContainer {...rest}>{children}</NotificationContainer>;
};

Notification.Head = NotificationHead;
Notification.Content = NotificationContent;
Notification.Action = NotificationAction;
Notification.Text = NotificationText;

export default Notification;
